import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logout from './Logout';
import Select from 'react-select';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import $ from 'jquery';

const HomePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);
  const [jalurData, setJalurData] = useState([]);
  const [selectedJalur, setSelectedJalur] = useState(null);
  const [kpnameData, setKpnameData] = useState([]);
  const [selectedKpname, setSelectedKpname] = useState(null);
  const [selectedCrossing, setSelectedCrossing] = useState(null);
  const [selectedExposuretypethirdparty, setSelectedExposuretypethirdparty] = useState(null);
  const [selectedDepthofcovertype, setSelectedDepthofcovertype] = useState(null);
  const [selectedPipecasing, setSelectedPipecasing] = useState(null);
  const [selectedSignandmarkerrowcondition, setSelectedSignandmarkerrowcondition] = useState(null);
  const [selectedPatrolfrequency3rdparty, setSelectedPatrolfrequency3rdparty] = useState(null);
  const [selectedPubliceducation, setSelectedPubliceducation] = useState(null);
  const [selectedResistancetypethirdparty, setSelectedResistancetypethirdparty] = useState(null);
  const [selectedCleaningpigging, setSelectedCleaningpigging] = useState(null);
  const [selectedCoatingsystem, setSelectedCoatingsystem] = useState(null);
  const [selectedCathodicprotectionsystem, setSelectedCathodicprotectionsystem] = useState(null);
  const [selectedCrackpropagationpreventer, setSelectedCrackpropagationpreventer] = useState(null);
  const [selectedExposuretypegeohazard, setSelectedExposuretypegeohazard] = useState(null);
  const [selectedPipelinestabilization, setSelectedPipelinestabilization] = useState(null);
  const [selectedGroundreinforcement, setSelectedGroundreinforcement] = useState(null);
  const [selectedAvoidance, setSelectedAvoidance] = useState(null);
  const [selectedInspectionsurvey, setSelectedInspectionsurvey] = useState(null);
  const [selectedDitchmodification, setSelectedDitchmodification] = useState(null);
  const [selectedExposuretypeincorrectoperation, setSelectedExposuretypeincorrectoperation] = useState(null);
  const [selectedControlsafetysystem, setSelectedControlsafetysystem] = useState(null);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [selectedTrainingprogramonpipelineoperation, setSelectedTrainingprogramonpipelineoperation] = useState(null);
  const [selectedMechanicalpreventer, setSelectedMechanicalpreventer] = useState(null);
  const [selectedSubstanceabuse, setSelectedSubstanceabuse] = useState(null);
  const [selectedExposuretypesabotage, setSelectedExposuretypesabotage] = useState(null);
  const [selectedCommunitypartnering, setSelectedCommunitypartnering] = useState(null);
  const [selectedSecurity, setSelectedSecurity] = useState(null);
  const [selectedResolve, setSelectedResolve] = useState(null);
  const [selectedIndustrycooperation, setSelectedIndustrycooperation] = useState(null);
  const [selectedAccessability, setSelectedAccessability] = useState(null);
  
  const generateRandomNumber = () => {
    return Math.floor(100000000 + Math.random() * 900000000); // menghasilkan angka dari 100000000 hingga 999999999
  };

const capitalizeLabel = (label) => {
  
  return label.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

const [formData, setFormData] = useState({
	
	Jalur:"",
	genrannum: generateRandomNumber(),
	Kpname: "",
	Kpname2: "",
	Namaasset: "",
	Metalloss:10,
	Asmeclasslocation:3,
	Yearofconstruction:2006,
	Yearofinspection:2016,
	DesignlifeYear:20,
	Pipelinematerialgrade:"API 5L-X65",
	Pipelineservice:"Natural Gas",
	Designpressure:1150,
	Alignmentdepthofcover:1.265,
	Crossing:"",
	Exposuretypethirdparty:"",
	Depthofcovertype:"",
	Pipecasing:"",
	Signandmarkerrowcondition:"",
	Patrolfrequency3rdparty:"",
	Publiceducation:"",
	Resistancetypethirdparty:"",
	Cleaningpigging:"",
	Coatingsystem:"",
	Cathodicprotectionsystem:"",
	Crackpropagationpreventer:"",
	Exposuretypegeohazard:"",
	Pipelinestabilization:"",
	Groundreinforcement:"",
	Avoidance:"",
	Inspectionsurvey:"",
	Ditchmodification:"",
	Exposuretypeincorrectoperation:"",
	Controlsafetysystem:"",
	Procedure:"",
	Trainingprogramonpipelineoperation:"",
	Mechanicalpreventer:"",
	Substanceabuse:"",
	Exposuretypesabotage:"",
	Communitypartnering:"",
	Intelligence:0,
	Security:"",
	Resolve:"",
	Industrycooperation:"",
	Accessability:"",
	Gasunitprice:8,
	Heatcapacityratio:1.27,
	Dischargecoefficient:1,
	Molecularweightm:19.5,
	Idealgasconstant:1545,
	Accelarationofgravity:32.17,
	Probabilityofignition:0.1,
	
 });

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit
    const hours = String(now.getHours()).padStart(2, '0'); // jam dalam format 2 digit
    const minutes = String(now.getMinutes()).padStart(2, '0'); // menit dalam format 2 digit

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
			
			setUserData(response.data);			
			 
			try {
				const response = await axios.post('https://alive.my.id/restapialive3/api/data', {
				  tgl: getCurrentDateTime()
				}, {
				  headers: {
					Authorization: `Bearer ${storedToken}`,
					'Content-Type': 'application/json'
				  }
				});

				console.log('Data successfully sent:', response.data);
			} catch (error) {
				console.error('Error sending data:', error);
			}
            
          } else {
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();
  }, [navigate]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleJalurChange = (selectedOption) => {
	document.getElementById('kpnamejalur').style.display="none";
	setLoading2(true);
    setSelectedJalur(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Jalur: selectedOption ? selectedOption.value : ""
    }));
    console.log("Jalur Construction selected:", selectedOption);
	
	const fetchData2 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbxngd2wNPG4V0scNBd44_bSt0EudLo5BgqYTUp--3VmNEhdqWRUF27FDV-ua50v9wGeLA/exec?prm=asset&jalur=${selectedOption.value}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setKpnameData(data);

        const defaultKpname = data.find(Kpname => Kpname === "");
        if (defaultKpname) {
          const defaultOption = { value: defaultKpname, label: defaultKpname };
          setSelectedKpname(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Kpname: defaultKpname
          }));
        }
		
		setLoading2(false);
		document.getElementById('kpnamejalur').style.display="block";
		
        
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData2();
	
  };
  
  const [riskData, setRiskData] = useState(null);
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Mencegah refresh halaman setelah form submit
    console.log("Data yang dikirim: ", formData); // Menampilkan data yang akan dikirim
	setLoading(true);

    try {
        // Melakukan request POST ke Google Apps Script
        await fetch('https://script.google.com/macros/s/AKfycbxngd2wNPG4V0scNBd44_bSt0EudLo5BgqYTUp--3VmNEhdqWRUF27FDV-ua50v9wGeLA/exec', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData) // Pastikan ini adalah data yang benar
        });
		
		const id = formData.genrannum; // ID yang akan dikirim sebagai parameter
        const getResultUrl = `https://script.google.com/macros/s/AKfycbxngd2wNPG4V0scNBd44_bSt0EudLo5BgqYTUp--3VmNEhdqWRUF27FDV-ua50v9wGeLA/exec?prm=resultcalc&nomor=${id}`;
        
        const getResultResponse = await fetch(getResultUrl);
        const result = await getResultResponse.json(); // Membaca hasil respons dari server
        console.log("Hasil dari server:", result); // Menampilkan hasil respons dari server

		if (result && result.length > 0) {
                setRiskData(result[0]); // Asumsi result[0] berisi objek dengan Risk_matrix dan Risk_level
            } else {
                setRiskData(null); // Mengatur ulang jika tidak ada hasil yang ditemukan
            }
     
		
    } catch (error) {
        console.error('Error:', error); // Menangani jika ada error dalam request
    }finally {
      setLoading(false);
	  setTimeout(() => {
          document.getElementById('printmuhlbauericon').style.display="block";
        }, 5000);
    }
};
  
  const handleKpnameChange = (selectedOption) => {
	  //alert(selectedOption);
    setSelectedKpname(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Kpname: selectedOption ? selectedOption.value : "",
      Kpname2: selectedOption ? selectedOption.label : ""
	  
    }));
    console.log("Jalur Construction selected:", selectedOption);
  };
  
  const handleCrossingChange = (selectedOption) => {
    setSelectedCrossing(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Crossing: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleExposuretypethirdpartyChange = (selectedOption) => {
    setSelectedExposuretypethirdparty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Exposuretypethirdparty: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleDepthofcovertypeChange = (selectedOption) => {
    setSelectedDepthofcovertype(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Depthofcovertype: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePipecasingChange = (selectedOption) => {
    setSelectedPipecasing(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Pipecasing: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSignandmarkerrowconditionChange = (selectedOption) => {
    setSelectedSignandmarkerrowcondition(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Signandmarkerrowcondition: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePatrolfrequency3rdpartyChange = (selectedOption) => {
    setSelectedPatrolfrequency3rdparty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Patrolfrequency3rdparty: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePubliceducationChange = (selectedOption) => {
    setSelectedPubliceducation(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Publiceducation: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleResistancetypethirdpartyChange = (selectedOption) => {
    setSelectedResistancetypethirdparty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Resistancetypethirdparty: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCleaningpiggingChange = (selectedOption) => {
    setSelectedCleaningpigging(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Cleaningpigging: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCoatingsystemChange = (selectedOption) => {
    setSelectedCoatingsystem(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Coatingsystem: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCathodicprotectionsystemChange = (selectedOption) => {
    setSelectedCathodicprotectionsystem(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Cathodicprotectionsystem: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCrackpropagationpreventerChange = (selectedOption) => {
    setSelectedCrackpropagationpreventer(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Crackpropagationpreventer: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleExposuretypegeohazardChange = (selectedOption) => {
    setSelectedExposuretypegeohazard(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Exposuretypegeohazard: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePipelinestabilizationChange = (selectedOption) => {
    setSelectedPipelinestabilization(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Pipelinestabilization: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleGroundreinforcementChange = (selectedOption) => {
    setSelectedGroundreinforcement(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Groundreinforcement: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAvoidanceChange = (selectedOption) => {
    setSelectedAvoidance(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Avoidance: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInspectionsurveyChange = (selectedOption) => {
    setSelectedInspectionsurvey(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Inspectionsurvey: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleDitchmodificationChange = (selectedOption) => {
    setSelectedDitchmodification(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Ditchmodification: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleExposuretypeincorrectoperationChange = (selectedOption) => {
    setSelectedExposuretypeincorrectoperation(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Exposuretypeincorrectoperation: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleControlsafetysystemChange = (selectedOption) => {
    setSelectedControlsafetysystem(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Controlsafetysystem: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleProcedureChange = (selectedOption) => {
    setSelectedProcedure(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Procedure: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleTrainingprogramonpipelineoperationChange = (selectedOption) => {
    setSelectedTrainingprogramonpipelineoperation(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Trainingprogramonpipelineoperation: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleMechanicalpreventerChange = (selectedOption) => {
    setSelectedMechanicalpreventer(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Mechanicalpreventer: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSubstanceabuseChange = (selectedOption) => {
    setSelectedSubstanceabuse(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Substanceabuse: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleExposuretypesabotageChange = (selectedOption) => {
    setSelectedExposuretypesabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Exposuretypesabotage: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCommunitypartneringChange = (selectedOption) => {
    setSelectedCommunitypartnering(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Communitypartnering: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSecurityChange = (selectedOption) => {
    setSelectedSecurity(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Security: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleResolveChange = (selectedOption) => {
    setSelectedResolve(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Resolve: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleIndustrycooperationChange = (selectedOption) => {
    setSelectedIndustrycooperation(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Industrycooperation: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAccessabilityChange = (selectedOption) => {
    setSelectedAccessability(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Accessability: selectedOption ? selectedOption.value : ""
    }));
  };
  
	
	const handleOpenMuhlbauerprint = () => {
	  // Simpan data ke localStorage
	  localStorage.setItem('printData', JSON.stringify({ riskData, formData }));

	  // Buka tab baru
	  window.open('/muhlbaueronshoreprint', '_blank');
	};


  
  useEffect(() => {
    const fetchData = async () => {
      const url = `https://script.google.com/macros/s/AKfycbxngd2wNPG4V0scNBd44_bSt0EudLo5BgqYTUp--3VmNEhdqWRUF27FDV-ua50v9wGeLA/exec?prm=jalur`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setJalurData(data);

        const defaultJalur = data.find(Jalur => Jalur === "");
        if (defaultJalur) {
          const defaultOption = { value: defaultJalur, label: defaultJalur };
          setSelectedJalur(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Jalur: defaultJalur
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
	
	const defaultOptionCrossing = { value: "Crossing road", label: "Crossing road" };
    setSelectedCrossing(defaultOptionCrossing);
	handleCrossingChange(defaultOptionCrossing);
	
	const defaultOptionExposuretypethirdparty = { value: "Commercial & Industrial Area", label: "Commercial & Industrial Area" };
    setSelectedExposuretypethirdparty(defaultOptionExposuretypethirdparty);
	handleExposuretypethirdpartyChange(defaultOptionExposuretypethirdparty);
	
	const defaultOptionDepthofcovertype = { value: "4 in Asphalt", label: "4 in Asphalt" };
    setSelectedDepthofcovertype(defaultOptionDepthofcovertype);
	handleDepthofcovertypeChange(defaultOptionDepthofcovertype);
	
	const defaultOptionPipecasing = { value: "None", label: "None" };
    setSelectedPipecasing(defaultOptionPipecasing);
	handlePipecasingChange(defaultOptionPipecasing);
	
	const defaultOptionSignandmarkerrowcondition = { value: "Sign and Markers and ROW Condition Good", label: "Sign and Markers and ROW Condition Good" };
    setSelectedSignandmarkerrowcondition(defaultOptionSignandmarkerrowcondition);
	handleSignandmarkerrowconditionChange(defaultOptionSignandmarkerrowcondition);
	
	const defaultOptionPatrolfrequency3rdparty = { value: "Daily", label: "Daily" };
    setSelectedPatrolfrequency3rdparty(defaultOptionPatrolfrequency3rdparty);
	handlePatrolfrequency3rdpartyChange(defaultOptionPatrolfrequency3rdparty);
	
	const defaultOptionPubliceducation = { value: "None", label: "None" };
    setSelectedPubliceducation(defaultOptionPubliceducation);
	handlePubliceducationChange(defaultOptionPubliceducation);
	
	const defaultOptionResistancetypethirdparty = { value: "High Impact Resistance Steel", label: "High Impact Resistance Steel" };
    setSelectedResistancetypethirdparty(defaultOptionResistancetypethirdparty);
	handleResistancetypethirdpartyChange(defaultOptionResistancetypethirdparty);
	
	const defaultOptionCleaningpigging = { value: "None", label: "None" };
    setSelectedCleaningpigging(defaultOptionCleaningpigging);
	handleCleaningpiggingChange(defaultOptionCleaningpigging);
	
	const defaultOptionCoatingsystem = { value: "Good", label: "Good" };
    setSelectedCoatingsystem(defaultOptionCoatingsystem);
	handleCoatingsystemChange(defaultOptionCoatingsystem);
	
	const defaultOptionCathodicprotectionsystem = { value: "(-850) mV to (-1200) mV", label: "(-850) mV to (-1200) mV" };
    setSelectedCathodicprotectionsystem(defaultOptionCathodicprotectionsystem);
	handleCathodicprotectionsystemChange(defaultOptionCathodicprotectionsystem);
	
	const defaultOptionCrackpropagationpreventer = { value: "None", label: "None" };
    setSelectedCrackpropagationpreventer(defaultOptionCrackpropagationpreventer);
	handleCrackpropagationpreventerChange(defaultOptionCrackpropagationpreventer);
	
	const defaultOptionExposuretypegeohazard = { value: "Slight Slope", label: "Slight Slope" };
    setSelectedExposuretypegeohazard(defaultOptionExposuretypegeohazard);
	handleExposuretypegeohazardChange(defaultOptionExposuretypegeohazard);
	
	const defaultOptionPipelinestabilization = { value: "None", label: "None" };
    setSelectedPipelinestabilization(defaultOptionPipelinestabilization);
	handlePipelinestabilizationChange(defaultOptionPipelinestabilization);
	
	const defaultOptionGroundreinforcement = { value: "None", label: "None" };
    setSelectedGroundreinforcement(defaultOptionGroundreinforcement);
	handleGroundreinforcementChange(defaultOptionGroundreinforcement);
	
	const defaultOptionAvoidance = { value: "None", label: "None" };
    setSelectedAvoidance(defaultOptionAvoidance);
	handleAvoidanceChange(defaultOptionAvoidance);
	
	const defaultOptionInspectionsurvey = { value: "Bi-Weekly", label: "Bi-Weekly" };
    setSelectedInspectionsurvey(defaultOptionInspectionsurvey);
	handleInspectionsurveyChange(defaultOptionInspectionsurvey);
	
	const defaultOptionDitchmodification = { value: "None", label: "None" };
    setSelectedDitchmodification(defaultOptionDitchmodification);
	handleDitchmodificationChange(defaultOptionDitchmodification);
	
	const defaultOptionExposuretypeincorrectoperation = { value: "Remote or Never", label: "Remote or Never" };
    setSelectedExposuretypeincorrectoperation(defaultOptionExposuretypeincorrectoperation);
	handleExposuretypeincorrectoperationChange(defaultOptionExposuretypeincorrectoperation);
	
	const defaultOptionControlsafetysystem = { value: "Relief Valve Reliability", label: "Relief Valve Reliability" };
    setSelectedControlsafetysystem(defaultOptionControlsafetysystem);
	handleControlsafetysystemChange(defaultOptionControlsafetysystem);
	
	const defaultOptionProcedure = { value: "Fully Followed", label: "Fully Followed" };
    setSelectedProcedure(defaultOptionProcedure);
	handleProcedureChange(defaultOptionProcedure);
	
	const defaultOptionTrainingprogramonpipelineoperation = { value: "Excellent Training Program", label: "Excellent Training Program" };
    setSelectedTrainingprogramonpipelineoperation(defaultOptionTrainingprogramonpipelineoperation);
	handleTrainingprogramonpipelineoperationChange(defaultOptionTrainingprogramonpipelineoperation);
	
	const defaultOptionMechanicalpreventer = { value: "Medium Mechanical Preventer", label: "Medium Mechanical Preventer" };
    setSelectedMechanicalpreventer(defaultOptionMechanicalpreventer);
	handleMechanicalpreventerChange(defaultOptionMechanicalpreventer);
	
	const defaultOptionSubstanceabuse = { value: "Mediocare Alcohol & Drugs Program", label: "Mediocare Alcohol & Drugs Program" };
    setSelectedSubstanceabuse(defaultOptionSubstanceabuse);
	handleSubstanceabuseChange(defaultOptionSubstanceabuse);
	
	const defaultOptionExposuretypesabotage = { value: "Remote or Never", label: "Remote or Never" };
    setSelectedExposuretypesabotage(defaultOptionExposuretypesabotage);
	handleExposuretypesabotageChange(defaultOptionExposuretypesabotage);
	
	const defaultOptionCommunitypartnering = { value: "Quite Effective", label: "Quite Effective" };
    setSelectedCommunitypartnering(defaultOptionCommunitypartnering);
	handleCommunitypartneringChange(defaultOptionCommunitypartnering);
	
	const defaultOptionSecurity = { value: "Quite Effective", label: "Quite Effective" };
    setSelectedSecurity(defaultOptionSecurity);
	handleSecurityChange(defaultOptionSecurity);
	
	const defaultOptionResolve = { value: "Quite Effective", label: "Quite Effective" };
    setSelectedResolve(defaultOptionResolve);
	handleResolveChange(defaultOptionResolve);
	
	const defaultOptionIndustrycooperation = { value: "Quite Effective", label: "Quite Effective" };
    setSelectedIndustrycooperation(defaultOptionIndustrycooperation);
	handleIndustrycooperationChange(defaultOptionIndustrycooperation);
	
	const defaultOptionAccessability = { value: "Easy to Access (Above Ground Facility)", label: "Easy to Access (Above Ground Facility)" };
    setSelectedAccessability(defaultOptionAccessability);
	handleAccessabilityChange(defaultOptionAccessability);
	
  }, []);



  const [selectedPointOfConcern, setSelectedPointOfConcern] = useState(null);

	
	
	const JalurOptions = jalurData.map(JalurObj => {
		return {
		  value: JalurObj,
		  label: JalurObj,
		};
	  });
	  
	  const KpnameOptions = kpnameData.map(KpnameObj => {
		return {
		  value: KpnameObj['value'],
		  label: KpnameObj['label'],
		};
	  });
	  
	const CrossingOptions = [
	  { value: 'Crossing road', label: 'Crossing road' },
	  { value: 'Crossing river', label: 'Crossing river' },
	  { value: 'Crossing river and road', label: 'Crossing river and road' },
	  { value: 'Crossing bridge', label: 'Crossing bridge' },
	  { value: 'no Crossing', label: 'no Crossing' },
	];
	
	const ExposuretypethirdpartyOptions = [
	  { value: 'Forest, Swamp, Remote Areas', label: 'Forest, Swamp, Remote Areas' },
	  { value: 'Agriculture Areas', label: 'Agriculture Areas' },
	  { value: 'Commercial & Industrial Area', label: 'Commercial & Industrial Area' },
	];
	
	const DepthofcovertypeOptions = [
	  { value: 'None', label: 'None' },
	  { value: '12 in', label: '12 in' },
	  { value: '36 in', label: '36 in' },
	  { value: '4 in Asphalt', label: '4 in Asphalt' },
	  { value: 'Concrete Slab (Re-inforced)', label: 'Concrete Slab (Re-inforced)' },
	  { value: 'Pipe Casing', label: 'Pipe Casing' },
	];
	
	const PipecasingOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Chain Link Fences', label: 'Chain Link Fences' },
	  { value: 'Ditch', label: 'Ditch' },
	  { value: 'Waterbodies', label: 'Waterbodies' },
	  { value: 'Concrete Traffic', label: 'Concrete Traffic' },
	];
	
	const SignandmarkerrowconditionOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Sign and Markers and ROW Condition Poor', label: 'Sign and Markers and ROW Condition Poor' },
	  { value: 'Sign and Markers and ROW Condition Good', label: 'Sign and Markers and ROW Condition Good' },
	];
	
	const Patrolfrequency3rdpartyOptions = [
	  { value: 'Twice a Day', label: 'Twice a Day' },
	  { value: 'Daily', label: 'Daily' },
	  { value: 'Every 3 Days', label: 'Every 3 Days' },
	  { value: 'Weekly', label: 'Weekly' },
	  { value: 'Bi-Weekly', label: 'Bi-Weekly' },
	  { value: 'Semi Annually', label: 'Semi Annually' },
	  { value: 'Annually', label: 'Annually' },
	];
	
	const PubliceducationOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Anually Meeting', label: 'Anually Meeting' },
	  { value: 'Semi Anually Meeting', label: 'Semi Anually Meeting' },
	  { value: 'Three Monly Meeting', label: 'Three Monly Meeting' },
	];
	
	const ResistancetypethirdpartyOptions = [
	  { value: 'High Impact Resistance Steel', label: 'High Impact Resistance Steel' },
	  { value: 'Medium Impact Resistance Steel', label: 'Medium Impact Resistance Steel' },
	  { value: 'Low Impact Resistance Steel', label: 'Low Impact Resistance Steel' },
	];
	
	const CleaningpiggingOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Annually', label: 'Annually' },
	  { value: 'Bi-Annually', label: 'Bi-Annually' },
	  { value: 'Three Annually', label: 'Three Annually' },
	  { value: 'based on Intelligent Pigging Schedule while condensate free', label: 'based on Intelligent Pigging Schedule while condensate free' },
	];
	
	const CoatingsystemOptions = [
	  { value: 'None Coating Defect/ Pipe Section Exposed', label: 'None Coating Defect/ Pipe Section Exposed' },
	  { value: 'Poor', label: 'Poor' },
	  { value: 'Medium', label: 'Medium' },
	  { value: 'Good', label: 'Good' },
	];
	
	const CathodicprotectionsystemOptions = [
	  { value: 'More negative than (-1300) mV', label: 'More negative than (-1300) mV' },
	  { value: '(-1200) mV to (-1300) mV', label: '(-1200) mV to (-1300) mV' },
	  { value: '(-850) mV to (-1200) mV', label: '(-850) mV to (-1200) mV' },
	  { value: '(-800) mV to (-850) mV', label: '(-800) mV to (-850) mV' },
	  { value: 'more positive than (-800) mV', label: 'more positive than (-800) mV' },
	];
	
	const CrackpropagationpreventerOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Mechanical Clamp w/o Filler', label: 'Mechanical Clamp w/o Filler' },
	  { value: 'Composite Wrapping', label: 'Composite Wrapping' },
	  { value: 'Mechanical Clamp with Filler', label: 'Mechanical Clamp with Filler' },
	];
	
	const ExposuretypegeohazardOptions = [
	  { value: 'Slight Slope', label: 'Slight Slope' },
	  { value: 'Moderate Slope', label: 'Moderate Slope' },
	  { value: 'Steep Slope', label: 'Steep Slope' },
	];
	
	const PipelinestabilizationOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Pile Anchoring', label: 'Pile Anchoring' },
	  { value: 'Concrete Anchoring', label: 'Concrete Anchoring' },
	];
	
	const GroundreinforcementOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Retaining Walls', label: 'Retaining Walls' },
	  { value: 'Slope Re-grading', label: 'Slope Re-grading' },
	  { value: 'Erosion Control Vegetation', label: 'Erosion Control Vegetation' },
	  { value: 'Armouring Rocks', label: 'Armouring Rocks' },
	];
	
	const AvoidanceOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Above Ground Pipe', label: 'Above Ground Pipe' },
	  { value: 'Pipe Re-routing', label: 'Pipe Re-routing' },
	];
	
	const InspectionsurveyOptions = [
	  { value: 'Twice a Day', label: 'Twice a Day' },
	  { value: 'Daily', label: 'Daily' },
	  { value: 'Every 3 Days', label: 'Every 3 Days' },
	  { value: 'Weekly', label: 'Weekly' },
	  { value: 'Bi-Weekly', label: 'Bi-Weekly' },
	  { value: 'Monthly', label: 'Monthly' },
	  { value: 'Semi Annually', label: 'Semi Annually' },
	  { value: 'Annually', label: 'Annually' },
	];
	
	const DitchmodificationOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Wider Ditching', label: 'Wider Ditching' },
	  { value: 'Bedding and Padding', label: 'Bedding and Padding' },
	  { value: 'Excavation to Relieve Strain Loading', label: 'Excavation to Relieve Strain Loading' },
	];
	
	const ExposuretypeincorrectoperationOptions = [
	  { value: 'Remote or Never', label: 'Remote or Never' },
	  { value: 'Very Low', label: 'Very Low' },
	  { value: 'Low', label: 'Low' },
	  { value: 'Often', label: 'Often' },
	  { value: 'Very Often', label: 'Very Often' },
	];
	
	const ControlsafetysystemOptions = [
	  { value: 'Human Error', label: 'Human Error' },
	  { value: 'Relief Valve Reliability', label: 'Relief Valve Reliability' },
	  { value: 'Overpressure of Well Maintained Pipeline', label: 'Overpressure of Well Maintained Pipeline' },
	];
	
	const ProcedureOptions = [
	  { value: 'Partially Followed', label: 'Partially Followed' },
	  { value: 'Mainly Followed', label: 'Mainly Followed' },
	  { value: 'Fully Followed', label: 'Fully Followed' },
	];
	
	const TrainingprogramonpipelineoperationOptions = [
	  { value: 'Improper Training Program', label: 'Improper Training Program' },
	  { value: 'Medium Training Program', label: 'Medium Training Program' },
	  { value: 'Excellent Training Program', label: 'Excellent Training Program' },
	];
	
	const MechanicalpreventerOptions = [
	  { value: 'Improper Mechanical Preventer', label: 'Improper Mechanical Preventer' },
	  { value: 'Medium Mechanical Preventer', label: 'Medium Mechanical Preventer' },
	  { value: 'Excellent Mechanical Preventer', label: 'Excellent Mechanical Preventer' },
	];
	
	const SubstanceabuseOptions = [
	  { value: 'Poor Alcohol & Drugs Program', label: 'Poor Alcohol & Drugs Program' },
	  { value: 'Mediocare Alcohol & Drugs Program', label: 'Mediocare Alcohol & Drugs Program' },
	  { value: 'Good Alcohol & Drugs Program', label: 'Good Alcohol & Drugs Program' },
	];
	
	const CommunitypartneringOptions = [
	  { value: 'Not Effective', label: 'Not Effective' },
	  { value: 'Quite Effective', label: 'Quite Effective' },
	  { value: 'Very Effective', label: 'Very Effective' },
	];
	
	const AccessabilityOptions = [
	  { value: 'Easy to Access (Above Ground Facility)', label: 'Easy to Access (Above Ground Facility)' },
	  { value: 'Med to Access (Pipeline with Vent)', label: 'Med to Access (Pipeline with Vent)' },
	  { value: 'Difficult to Access (Burried Pipeline)', label: 'Difficult to Access (Burried Pipeline)' },
	];
	
  return (
  
    <div style={{marginTop: '30px',padding: '10px'}}>
		 
      <div style={{ clear: 'both' }}></div>
		
		<div class="row">
			<div class="card col-4" style={{margin: '20px 5px 5px 5px', border: 'none'}}>
				
				<div class="card-header" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
					<div style={{float: 'left'}}>
						<h5 style={{textAlign: 'left'}}>Result</h5>
					</div>
					
					<div style={{float: 'right'}}>
						<div style={{float: 'left', margin: "5px"}}>
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/Manual Book 2.png`} 
								alt="Alive home" 
								style={{ width: '20px', height: '20px', borderRadius: '5px'}} 
							/>
						</div>
						<div id="printmuhlbauericon" style={{float: 'left', margin: '5px', display: 'none'}}>
							
							<a 
							onClick={handleOpenMuhlbauerprint}
							style={{fontSize: '20px'}}
							>
								<i class="fa fa-print" aria-hidden="true"></i>
							</a>
						</div>
					</div>
					
				</div>
						
				<div class="card-body" style={{backgroundColor: "#646464", color: 'white'}}>
						
					{riskData ? (
						<div>
							<p><strong>Risk Matrix:</strong> {riskData.pof}{riskData.cof}</p>
							<p><strong>Risk Level:</strong> {riskData.risk_category}</p>
						</div>
					) : (
						
						<p>Hasil Perhitungan dan Kalkulasi</p>
					)}
					
					{loading && (
					  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						
						<div>Sedang memproses...</div>
					  </div>
					)}
					
				</div>
				
			</div>
			
			
		</div>
		
		
		<form onSubmit={handleSubmit}>
		
		<button style={{ margin: '10px 10px 0px 0px', float: 'right' }} className="btn btn-success" type="submit">Submit</button>
		
		<div style={{clear: 'both'}}></div>
		
		<div class="row">
		
		<div class="col-lg-4 col-md-4 mb-2">
				
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Parameter</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
							
						<div className="col-5">
							Jalur 
						</div>
										
						<div className="col-7">
											
						<Select
							options={JalurOptions}
							onChange={handleJalurChange}
							placeholder="Jalur"
							value={selectedJalur}
							required
						/>
											
						</div>
										
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							KP Name
						</div>
										
						<div className="col-7">
						
						{loading2 && (
						  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							
							<div>Sedang memproses...</div>
						  </div>
						)}
						
						<div id="kpnamejalur" style={{display: 'none'}}>				
							<Select
								options={KpnameOptions}
								onChange={handleKpnameChange}
								placeholder="KP Name"
								value={selectedKpname}
								required
							/>
						</div>
											
						</div>
										
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Nama Asset
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Namaasset"
								value={formData.Namaasset}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Metal Loss
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Metalloss"
								value={formData.Metalloss}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Year of Construction
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Yearofconstruction"
								value={formData.Yearofconstruction}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Year of Inspection
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Yearofinspection"
								value={formData.Yearofinspection}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Design Life (Year)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="DesignlifeYear"
								value={formData.DesignlifeYear}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Pipeline Material Grade
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Pipelinematerialgrade"
								value={formData.Pipelinematerialgrade}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Pipeline Service
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Pipelineservice"
								value={formData.Pipelineservice}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Design Pressure
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Designpressure"
								value={formData.Designpressure}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Alignment Depth of Cover (m)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Alignmentdepthofcover"
								value={formData.Alignmentdepthofcover}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Crossing
						</div>
										
						<div className="col-7">
							
							<Select
								options={CrossingOptions}
								onChange={handleCrossingChange}
								placeholder="Crossing"
								value={selectedCrossing}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Gas Unit Price (USD/MMBTU)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Gasunitprice"
								value={formData.Gasunitprice}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Heat Capacity Ratio (γ)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Heatcapacityratio"
								value={formData.Heatcapacityratio}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Discharge Coefficient (C0)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Dischargecoefficient"
								value={formData.Dischargecoefficient}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Molecular Weight M (lbm/lb-mol)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Molecularweightm"
								value={formData.Molecularweightm}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Ideal Gas Constant (ft lbf/lb-moloR)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Idealgasconstant"
								value={formData.Idealgasconstant}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Accelaration of gravity (ft lbm/lbf s2)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Accelarationofgravity"
								value={formData.Accelarationofgravity}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Probability of Ignition
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Probabilityofignition"
								value={formData.Probabilityofignition}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
				
				</div>
				
			
			</div>
		</div>
		
		<div class="col-lg-4 col-md-4 mb-2">
			
			<div>
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Third Party Damage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Exposure Type Third Party
						</div>
										
						<div className="col-7">
							
							<Select
								options={ExposuretypethirdpartyOptions}
								onChange={handleExposuretypethirdpartyChange}
								placeholder="Exposure Type Third Party"
								value={selectedExposuretypethirdparty}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Depth of Cover Type
						</div>
										
						<div className="col-7">
							
							<Select
								options={DepthofcovertypeOptions}
								onChange={handleDepthofcovertypeChange}
								placeholder="Depth of Cover Type"
								value={selectedDepthofcovertype}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Pipe Casing
						</div>
										
						<div className="col-7">
							
							<Select
								options={PipecasingOptions}
								onChange={handlePipecasingChange}
								placeholder="Pipe Casing"
								value={selectedPipecasing}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Sign and Marker & ROW Condition
						</div>
										
						<div className="col-7">
							
							<Select
								options={SignandmarkerrowconditionOptions}
								onChange={handleSignandmarkerrowconditionChange}
								placeholder="Sign and Marker & ROW Condition"
								value={selectedSignandmarkerrowcondition}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Patrol Frequency (3rd party)
						</div>
										
						<div className="col-7">
							
							<Select
								options={Patrolfrequency3rdpartyOptions}
								onChange={handlePatrolfrequency3rdpartyChange}
								placeholder="Patrol Frequency (3rd party)"
								value={selectedPatrolfrequency3rdparty}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Public Education
						</div>
										
						<div className="col-7">
							
							<Select
								options={PubliceducationOptions}
								onChange={handlePubliceducationChange}
								placeholder="Public Education"
								value={selectedPubliceducation}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Resistance Type Third Party
						</div>
										
						<div className="col-7">
							
							<Select
								options={ResistancetypethirdpartyOptions}
								onChange={handleResistancetypethirdpartyChange}
								placeholder="Resistance Type Third Party"
								value={selectedResistancetypethirdparty}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
			
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Time Dependent Failure Mechanism</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Cleaning Pigging
						</div>
										
						<div className="col-7">
							
							<Select
								options={CleaningpiggingOptions}
								onChange={handleCleaningpiggingChange}
								placeholder="Cleaning Pigging"
								value={selectedCleaningpigging}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Coating System
						</div>
										
						<div className="col-7">
							
							<Select
								options={CoatingsystemOptions}
								onChange={handleCoatingsystemChange}
								placeholder="Coating System"
								value={selectedCoatingsystem}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Cathodic Protection System
						</div>
										
						<div className="col-7">
							
							<Select
								options={CathodicprotectionsystemOptions}
								onChange={handleCathodicprotectionsystemChange}
								placeholder="Cathodic Protection System"
								value={selectedCathodicprotectionsystem}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Crack Propagation Preventer
						</div>
										
						<div className="col-7">
							
							<Select
								options={CrackpropagationpreventerOptions}
								onChange={handleCrackpropagationpreventerChange}
								placeholder="Crack Propagation Preventer"
								value={selectedCrackpropagationpreventer}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Geohazards Related Damage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Exposure Type Geohazard
						</div>
										
						<div className="col-7">
							
							<Select
								options={ExposuretypegeohazardOptions}
								onChange={handleExposuretypegeohazardChange}
								placeholder="Exposure Type Geohazard"
								value={selectedExposuretypegeohazard}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Pipeline Stabilization
						</div>
										
						<div className="col-7">
							
							<Select
								options={PipelinestabilizationOptions}
								onChange={handlePipelinestabilizationChange}
								placeholder="Pipeline Stabilization"
								value={selectedPipelinestabilization}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Ground Reinforcement
						</div>
										
						<div className="col-7">
							
							<Select
								options={GroundreinforcementOptions}
								onChange={handleGroundreinforcementChange}
								placeholder="Ground Reinforcement"
								value={selectedGroundreinforcement}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Avoidance
						</div>
										
						<div className="col-7">
							
							<Select
								options={AvoidanceOptions}
								onChange={handleAvoidanceChange}
								placeholder="Avoidance"
								value={selectedAvoidance}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Inspection / Survey
						</div>
										
						<div className="col-7">
							
							<Select
								options={InspectionsurveyOptions}
								onChange={handleInspectionsurveyChange}
								placeholder="Inspection / Survey"
								value={selectedInspectionsurvey}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Ditch Modification
						</div>
										
						<div className="col-7">
							
							<Select
								options={DitchmodificationOptions}
								onChange={handleDitchmodificationChange}
								placeholder="Ditch Modification"
								value={selectedDitchmodification}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			</div>
		
		</div>
		
		<div class="col-lg-4 col-md-4 mb-2">
			
			<div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>In Correct Operations</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Exposure Type Incorrect Operation
						</div>
										
						<div className="col-7">
							
							<Select
								options={ExposuretypeincorrectoperationOptions}
								onChange={handleExposuretypeincorrectoperationChange}
								placeholder="Exposure Type Incorrect Operation"
								value={selectedExposuretypeincorrectoperation}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Control & Safety System
						</div>
										
						<div className="col-7">
							
							<Select
								options={ControlsafetysystemOptions}
								onChange={handleControlsafetysystemChange}
								placeholder="Control & Safety System"
								value={selectedControlsafetysystem}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Procedure
						</div>
										
						<div className="col-7">
							
							<Select
								options={ProcedureOptions}
								onChange={handleProcedureChange}
								placeholder="Procedure"
								value={selectedProcedure}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Training Program on Pipeline Operation
						</div>
										
						<div className="col-7">
							
							<Select
								options={TrainingprogramonpipelineoperationOptions}
								onChange={handleTrainingprogramonpipelineoperationChange}
								placeholder="Training Program on Pipeline Operation"
								value={selectedTrainingprogramonpipelineoperation}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Mechanical Preventer
						</div>
										
						<div className="col-7">
							
							<Select
								options={MechanicalpreventerOptions}
								onChange={handleMechanicalpreventerChange}
								placeholder="Mechanical Preventer"
								value={selectedMechanicalpreventer}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Substance Abuse
						</div>
										
						<div className="col-7">
							
							<Select
								options={SubstanceabuseOptions}
								onChange={handleSubstanceabuseChange}
								placeholder="Substance Abuse"
								value={selectedSubstanceabuse}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Sabotage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Exposure Type Sabotage
						</div>
										
						<div className="col-7">
							
							<Select
								options={ExposuretypeincorrectoperationOptions}
								onChange={handleExposuretypesabotageChange}
								placeholder="Exposure Type Sabotage"
								value={selectedExposuretypesabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Community Partnering
						</div>
										
						<div className="col-7">
							
							<Select
								options={CommunitypartneringOptions}
								onChange={handleCommunitypartneringChange}
								placeholder="Community Partnering"
								value={selectedCommunitypartnering}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Intelligent
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Intelligence"
								value={formData.Intelligence}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Security
						</div>
										
						<div className="col-7">
							
							<Select
								options={CommunitypartneringOptions}
								onChange={handleSecurityChange}
								placeholder="Security"
								value={selectedSecurity}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Resolve
						</div>
										
						<div className="col-7">
							
							<Select
								options={CommunitypartneringOptions}
								onChange={handleResolveChange}
								placeholder="Resolve"
								value={selectedResolve}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Industry Cooperation
						</div>
										
						<div className="col-7">
							
							<Select
								options={CommunitypartneringOptions}
								onChange={handleIndustrycooperationChange}
								placeholder="Industry Cooperation"
								value={selectedIndustrycooperation}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Accessability
						</div>
										
						<div className="col-7">
							
							<Select
								options={AccessabilityOptions}
								onChange={handleAccessabilityChange}
								placeholder="Accessability"
								value={selectedAccessability}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Consequence of Failure Calculation</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
							
						<div className="col-5">
							ASME Class Location
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Asmeclasslocation"
								value={formData.Asmeclasslocation}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					
				
				</div>
				
			</div>
			
			</div>
			
		</div>
		
		</div>
		
		</form>		
		
      <div style={{
        position: 'fixed', 
        bottom: '0', 
        width: '100%', 
        backgroundColor: '#ffff', 
        padding: '5px', 
        fontSize: '12px', 
        textAlign: 'center'
      }}>
        ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
        <div style={{
          position: 'absolute', 
          bottom: '5px', 
          right: '50px'
        }}>
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
//sebelumnya