import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Nativestock_pdg = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var photostocklist = {
        
        control_system:{
            "1":"1AwWWnAXIumsRZgDbrWEZ9LWL0_yvNSm9",
			"2":"1pbnuqYT2vbBOO_K9ZI5zCscEwsCC7HYA",
        },
		
		electrical_engineering:{
            "1":"11rOlop1s1mGt1bWe8xYTYLkXKJsSjdFj",
			"2":"12B28nCla8NvMID15r4MJXrjJRxHLCTzh",
			"3":"1DSmYWKQv9FlWHd4iDOs2AzzMVK4gI-1m",
			"4":"1TiZuGeutvVGHQFukH3yzIly5Qb3DmstZ",
			"5":"1wD5boGETZYml-vkNMmWZkxn4e-XSv4hN",
			"6":"19x6X133YkmsanGrW5lNax6YnU-2Peela",
			"7":"1KrfXai2rzNnWQ4hv221b7EmgMnHIgzEg",
			"8":"1RQML7cvgccPwpQE24hv8gcl-zBvLGmTA",
			"9":"1a14wV8Exq3EuAJ_J5SY2VlL-FnsSUJAX",
			"10":"1b6x0izlES8sUprpM5qOENe5pRs9FSwms",
			"11":"12sTenFBoUEIut_zN-c88O72KKqoU7LOF",
			"12":"1GVZ5maCkaVL33JgUydu1Qb0UPYMwpTnV",
			"13":"1I09Spwl-wjm2pSrlEDLtDlihLFHy1GZr",
			"14":"1RfRa9d9UVpOgZcHRWON3ZgE2QmullI2c",
			"15":"1WD7aJnx3XLRpGWejjJHGSW397cmKWkbG",
			"16":"1mwXRd4HRI6Z0EXBGtVxLulVTfVCBFxbQ",
			"17":"1pUwVoYNy24ZPUGyCvcJQWEaEvWPk7c3j",
        },
		
		instrument_engineering:{
            "1":"1N2IUumG9aVAAc0qPngVg27Hq3R1-16Rm",
			"2":"10jFoc639JuR1rNpF-py3uKuEaab8xo22",
			"3":"1vEahT9W9lO3x2AGE8s0Ld_LbQ-Yw9xl5",
			"4":"14hHMf92Ds9EVp8c_f0vE9J8uLoZf2gIG",
			"5":"1OhnwDTK7L-O0T2UsRYBgijZv1BTXYrpD",
			"6":"1M49-akRQHRh9gYKUngCTdh5E76681jC_",
			"7":"1j2sE5hHuLLuiuTnHs_GzqltOwiATyF14",
			"8":"1UFJj4qdaA5WETYl7Y91Hg0jBsGP1wy9x",
			"9":"1fDUOpSjqN4-0YasES5nyUq6wr9TqRx5E",
        },
		
		structural_engineering:{
            "1":"13pSH6ZVhXYU_tg1jEgZb5oZ0agApCTvp",
			"2":"13tBvVw8HV4Xmz6Km7iCj--bG0YnLC4Ph",
			"3":"14eSiPw82eL_eWoEHZF4mt_DzpIVnA6IM",
			"4":"16cFDOs0bDvJDFs8h-pysKkV06VrshwEd",
			"5":"1DWC1Wj0PF-ldTCLCZIWdNBR33qQ5vveO",
			"6":"1HVjC69A37HAbXRPtWk5MarvMr_u5GJY_",
			"7":"1HgHCI-7vDlfUUAWFEXSZvgm04Fdv59Ev",
			"8":"1I36hfEgw7QeWWoAGpFdkiC6CjNyGzvb7",
			"9":"1IY4s_fXX1oYypVVn7GLny66WoPDUBEp6",
			"10":"1Itc7_s7dVn9X-bgtzJ-md3WtSWPmCs72",
			"11":"1PalMgO3czzcNiCBYLqNs9uaJGY00e65d",
			"12":"1R01ksrfS_V0Bm0C29_XiEM_n_alrsXOv",
			"13":"1YZJhKNHsFvya0WjbLZDqO3kX40P4sXS4",
			"14":"1dS5bzpdc867VSMDPyMOgWxZ-Yzg-4rMt",
			"15":"1hQ2fzgt_Kz-amVK_GGM_Q3Rwmw5g-SEf",
			"16":"1ib0q07qgLvjchy_vyoGfnk7hwU3V0IV9",
			"17":"1jFKnSaq7ln9fPulRwjSUmdk9Wj7j_Dv8",
			"18":"1lSdXUvbPk9T0R-_rb9tn2FKMEK4FSPgB",
			"19":"1looSYQFWM9-jvaBBWXhpcxxT6fVgwjpd",
			"20":"1sYHAcr_MlhDPmQfkQaQNx2Tdf0U4enOU",
			"21":"1sbuwwcqCqn7it4fkm3pBI_gtuiSNikiL",
			"22":"1ywXTH4fFsUJaWKncLjmVTuOgL0le72zV",
			"23":"176fy8FqqjX_d9uXYNI2hueOszxqZcfRW",
			"24":"18qQxDMy_bYTk9p_2AfTERq9NAnbB4WGt",
			"25":"1F_3-mY56YiIIyFOwLBH3cMMBqFFVcsOe",
			"26":"1UVX5_f94pBk_kWtW-MbI9AM8JINv1pFv",
			"27":"1XwCAewaViQn_gx3AN_pnvHBlshrLHrnN",
			"28":"1d6KuAP2XdTvEJznS48JZnxu2c0_E_nae",
			"29":"1eAQWAZSsX5O5jvEbqYe5FJE4XwVWgCjF",
			"30":"1jCYhQgDYxNLQk2Kp4OaM-WKp7orPlkRA",
			"31":"11jPk54Yu81Su-taiC2lANRstPS0Sm2WC",
			"32":"17118ZdTwIBq7ogqVPyOI4z4BrdTM1Ani",
			"33":"18tU81iTC6bglnjAmiWwLzKvgyGMQQt8y",
			"34":"1c8_kSTEEIl_s71jmlHdACowTtRL_vYpV",
			"35":"1nrjxHmV9a33ZSG0iIkOoNZxzSLYUvRYx",
			"36":"1pfGN2CewRoAAyB5sSW3C1ey2m_WCGSwV",
			"37":"1_qviwd4rQzASMPC7UdW_vIkKV-VqJk1x",
			"38":"15w3jB-DMkVPH6sdMCqtTnbSkJIkIG1MB",
			"39":"1Ew31RrTOFBE2s362Mg2KWvEFHbth50Wn",
			"40":"1gBd95Zr7HQHWWl-K3YojlitOpJyTM5nu",
        },
		
		mechanical_engineering:{
            "1":"1ONDXNcyPQZ5_qJ0nwAN6wPDFbP_0bLy8",
			"2":"1gWi-ZG_gBGvJR0EYyurxJrsCxaaAq80z",
			"3":"1hAeF-6J2kzjBOu6_xiOzsaNJeyW62Ag1",
        },
		
		piping_engineering:{
            "1":"16v0BnobXyFHJfjvqF-lvlC2Py1eGZGCa",
			"2":"17cIKitkU9pXybr0bdOW0VDBQoRnYoPDA",
			"3":"1FUTT-U8q6Ngg8gdc-whczK3gQklI6yRi",
			"4":"1HjK2fg1F66JjlNZsNhK2TQTO0BDOfEAJ",
			"5":"1NNaUzeyAItnlP_ra42XL8wJYS2ZhYxPT",
			"6":"1OMndx5PxVIGj9slsVl0TAV5jPIs_lk2V",
			"7":"1PlETrnO6-_yHirIAWIA_v70D9l06uXN6",
			"8":"1UbFeu0h81j88uBtmJ0IRXEIDF8WS3yJu",
			"9":"1WYuVE-wEwfBlmWJnQdTYKnRUvJhR8WM7",
			"10":"1YAfgScQlBniD39fzg8laQV4n1Sn97c3k",
			"11":"1p3fA9CY536Haq8UBHZpD-8O0_1Na3q6d",
			"12":"1zL-JrPYEjlLu_puLmj9HoU6ShAzrvevL",
			"13":"11qtuaTZqu4_6AWx50aVz1h05LoSciMyb",
			"14":"158xlPE5OAgftlQpYAenjR1INJ9kKtcC0",
			"15":"1Bf6N1oTCLn10xoBYCar57DCX0a1WxJV6",
			"16":"1GGAJK1R7QCf-LlK0haPtHR4UkrHurKTS",
			"17":"11W_Ool4OiUn4v7yuODf2a_Yf5e41CybP",
			"18":"1BIaDa_ijhoVLUznfnskCcydntK4xqoSt",
			"19":"1GwubM2r9A-JLOv56afElIib9cKtL3WEk",
			"20":"1W3JKLi5w2Yhleovc0ZDQ7F0YHn5AbMTX",
			"21":"1nNcDuVE4-fuzEDVr5uw6D4xIhr8m5sWe",
			"22":"1pT7z_9uo7gqircobdZ5KH6XOwzKSW6xL",
			"23":"1r-dTXmioM0GPBvlT3JhYhr2iHrKA1tQI",
			"24":"1agr998UisX4nR9sO2plESxRVJOtvjZHP",
        },
        
    };
	
 const photostockData = [
  { id: 'control_system', label: 'As Built - Control System' },
  { id: 'electrical_engineering', label: 'As Built - Electrical Engineering' },
  { id: 'instrument_engineering', label: 'As Built - Instrument Engineering' },
  { id: 'structural_engineering', label: 'As Built - Structural Engineering' },
  { id: 'mechanical_engineering', label: 'As Built - Mechanical Engineering' },
  { id: 'piping_engineering', label: 'As Built - Piping Engineering' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesNativeStock, setSelectedTablesNativeStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};
	
	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
  const [tablesNativeStockOptions, setTablesNativeStockOptions] = useState([
    { value: '', label: 'NativeStock' },
    
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'kosong', label: '2. Pertamina Gas' },
	{ value: 'nativestock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'nativestock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'nativestock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'nativestock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'nativestock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'nativestock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'nativestock_sor1', label: '16. PGN-SOR1' },
	{ value: 'nativestock_sor2', label: '17. PGN-SOR2' },
	{ value: 'nativestock_sor3', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesNativeStock) {
		
		if(selectedTablesNativeStock.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesNativeStock(null);
		}else{
			navigate("/"+selectedTablesNativeStock.value);
		}
	  
    }
  }, [selectedTablesNativeStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tnts" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesNativeStockOptions}
			  onChange={setSelectedTablesNativeStock}
			  placeholder="NativeStock"
			  value={selectedTablesNativeStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/homealive_home.png`}
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Nativestock_pdg;
