import React, { useEffect, useState } from 'react';

const View3d = () => {
  const [Urlautodesk, setUrlautodesk] = useState(null);

  useEffect(() => {
    const storedUrl = localStorage.getItem('Urlautodesk');
    if (storedUrl) {
      setUrlautodesk(storedUrl);
    }
  }, []);

  const styles = {
    styleifrcad3: {
      width: '100%',
      height: '105vh',
      marginTop: '-55px',
    },
  };

  return (
    <div>
      <iframe
        style={styles.styleifrcad3}
        id="iframecad3"
        src={Urlautodesk ? Urlautodesk : 'https://a360.co/494eNQB'}
        title="Modelling"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default View3d;
