import React, { useRef, useState, useEffect } from "react";
import Select from 'react-select';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";


const RBIprint = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const componentRef = useRef();
  const [formData, setFormData] = useState({});
  const [riskMatrix, setRiskMatrix] = useState(null);
  const [riskLevel, setRiskLevel] = useState(null);
  const [loading, setLoading] = useState(true);
  const defaultOptionPWHT = false;
  const [selectedPWHT, setSelectedPWHT] = useState(defaultOptionPWHT);
  const defaultOptionLining = false;
  const [selectedLining, setSelectedLining] = useState(defaultOptionLining);
  const defaultOptionCoating = true;
  const [selectedCoating, setSelectedCoating] = useState(defaultOptionCoating);
  const defaultOptionHeattracing = false;
  const [selectedHeattracing, setSelectedHeattracing] = useState(defaultOptionHeattracing);
  const defaultOptionCladding = false;
  const [selectedCladding, setSelectedCladding] = useState(defaultOptionCladding);
  const defaultOptionInsulation = false;
  const [selectedInsulation, setSelectedInsulation] = useState(defaultOptionInsulation);
  const defaultOptionDatainspeksi = false;
  const [selectedDatainspeksi, setSelectedDatainspeksi] = useState(defaultOptionDatainspeksi);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedEquipmenttype, setSelectedEquipmenttype] = useState(null);
  const [selectedComponenttype, setSelectedComponenttype] = useState(null);
  const [selectedGeometrytype, setSelectedGeometrytype] = useState(null);
  const [materialData, setMaterialData] = useState([]);
  const [EquipmenttypeData, setEquipmenttypeData] = useState([]);
  const [Componenttype, setComponenttype] = useState([]);
  const [Geometrytype, setGeometrytype] = useState([]);
  const [selectedyearoffabricationmaterial, setSelectedyearoffabricationmaterial] = useState(null);
  const [Representativefluid, setRepresentativefluid] = useState([]);
  const [selectedRepresentativefluid, setSelectedRepresentativefluid] = useState(null);
  const [selectedFluidphase, setSelectedFluidphase] = useState(null);
  const [selectedFluidatnormaloperatingconditions, setSelectedFluidatnormaloperatingconditions] = useState(null);
  const [selectedFluidatambientconditions, setSelectedFluidatambientconditions] = useState(null);
  const [selectedCausticpresen, setSelectedCausticpresen] = useState(null);
  const [selectedAlkalinewater, setSelectedAlkalinewater] = useState(null);
  const [selectedOilwithsulfurcompounds, setSelectedOilwithsulfurcompounds] = useState(null);
  const [selectedChloridesppm, setSelectedChloridesppm] = useState(null);
  const [selectedFreewater, setSelectedFreewater] = useState(null);
  const [selectedH2Sandhydrogen, setSelectedH2Sandhydrogen] = useState(null);
  const [selectedOxygen, setSelectedOxygen] = useState(null);
  const [selectedH2S, setSelectedH2S] = useState(null);
  const [selectedHCl, setSelectedHCl] = useState(null);
  const [selectedH2SO4, setSelectedH2SO4] = useState(null);
  const [selectedHF, setSelectedHF] = useState(null);
  const [selectedCO2, setSelectedCO2] = useState(null);
  const [selectedAllregular300, setSelectedAllregular300] = useState(null);
  const [selectedHGrade300, setSelectedHGrade300] = useState(null);
  const [selectedLGrade300, setSelectedLGrade300] = useState(null);
  const [selectedStainlesssteel321, setSelectedStainlesssteel321] = useState(null);
  const [selectedStainlesssteel347, setSelectedStainlesssteel347] = useState(null);
  const [selectedTF350, setSelectedTF350] = useState(null);
  const [selectedTF300, setSelectedTF300] = useState(null);
  const [selectedT100, setSelectedT100] = useState(null);
  const [selectedT350, setSelectedT350] = useState(null);
  const [selectedT1070, setSelectedT1070] = useState(null);
  const [selectedT1050, setSelectedT1050] = useState(null);
  const [selectedT1700, setSelectedT1700] = useState(null);
  const [selectedT400, setSelectedT400] = useState(null);
  const [selectedT900, setSelectedT900] = useState(null);
  const [selectedDataconfidencedamagestate1, setSelectedDataconfidencedamagestate1] = useState(null);
  const [selectedDataconfidencedamagestate2, setSelectedDataconfidencedamagestate2] = useState(null);
  const [selectedDataconfidencedamagestate3, setSelectedDataconfidencedamagestate3] = useState(null);
  const [selectedMistoverspray, setSelectedMistoverspray] = useState(null);
  const [selectedSteamvents, setSelectedSteamvents] = useState(null);
  const [selectedDelugesystems, setSelectedDelugesystems] = useState(null);
  const [selectedChloridecontaining, setSelectedChloridecontaining] = useState(null);
  const [selectedSulfurbearing, setSelectedSulfurbearing] = useState(null);
  const [selectedChlorideswndwater, setSelectedChlorideswndwater] = useState(null);
  const [selectedHydrofluoricacid, setSelectedHydrofluoricacid] = useState(null);
  const [selectedAcidgastreatingamines, setSelectedAcidgastreatingamines] = useState(null);
  const [selectedSoil, setSelectedSoil] = useState(null);
  const [selectedHydrogenpartial, setSelectedHydrogenpartial] = useState(null);
  const [selectedSubjecttoprocess, setSelectedSubjecttoprocess] = useState(null);
  const [selectedNotnormally, setSelectedNotnormally] = useState(null);
  const [selectedWithdeteriorated, setSelectedWithdeteriorated] = useState(null);
  const [selectedPenetrationsandvisually, setSelectedPenetrationsandvisually] = useState(null);
  const [selectedMDMTorMAT, setSelectedMDMTorMAT] = useState(null);
  const [selectedHavebeenpast, setSelectedHavebeenpast] = useState(null);
  const [selectedPh7, setSelectedPh7] = useState(null);
  
  //DF thinning lining
  const [selectedApplicable, setSelectedApplicable] = useState(null);
  const [selectedInjectionmixpoints, setSelectedInjectionmixpoints] = useState(null);
  const [selectedDeadlegcontain, setSelectedDeadlegcontain] = useState(null);
  const [selectedWeldedconstructionwelded, setSelectedWeldedconstructionwelded] = useState(null);
  const [selectedInspectioneffectivenesscategory, setSelectedInspectioneffectivenesscategory] = useState(null);
  const [selectedMaintenanceAPISTD563Maintained, setSelectedMaintenanceAPISTD563Maintained] = useState(null);
  const [selectedSettlementcriteria, setSelectedSettlementcriteria] = useState(null);
  const [selectedOnlinemonitoringThinningmechanism, setSelectedOnlinemonitoringThinningmechanism] = useState(null);
  const [selectedOnlinemonitoringMonitoringtype, setSelectedOnlinemonitoringMonitoringtype] = useState(null);
  const [selectedLiningtype, setSelectedLiningtype] = useState(null);
  const [selectedAgeofLiningoryearage, setSelectedAgeofLiningoryearage] = useState(null);
  const [selectedInorganicliningstype, setSelectedInorganicliningstype] = useState(null);
  const [selectedOrgaliningstype, setSelectedOrgaliningstype] = useState(null);
  const [selectedMonitoringcontain, setSelectedMonitoringcontain] = useState(null);
  const [selectedLiningconditionqualitativecondition, setSelectedLiningconditionqualitativecondition] = useState(null);
  
  //DF External
  const [selectedExtDriver, setSelectedExtDriver] = useState(null);
  const [selectedEquipmentDesignorFabrication, setSelectedEquipmentDesignorFabrication] = useState(null);
  const [selectedInterface, setSelectedInterface] = useState(null);
  const [selectedCoatingQuality, setSelectedCoatingQuality] = useState(null);
  const [selectedCfcDriver, setSelectedCfcDriver] = useState(null);
  const [selectedInsulationType, setSelectedInsulationType] = useState(null);
  const [selectedComplexity, setSelectedComplexity] = useState(null);
  const [selectedInsulationCondition, setSelectedInsulationCondition] = useState(null);
  const [selectedCfcDesignorFabrication, setSelectedCfcDesignorFabrication] = useState(null);
  const [selectedInterfaceenterssoilorwater, setSelectedInterfaceenterssoilorwater] = useState(null);
  const [selectedEcfDrive, setSelectedEcfDrive] = useState(null);
  const [selectedEcfDrivecui, setSelectedEcfDrivecui] = useState(null);
  const [selectedEcfCracking, setSelectedEcfCracking] = useState(null);
  const [selectedEcfCrackingcui, setSelectedEcfCrackingcui] = useState(null);
  const [selectedChloridefreecui, setSelectedChloridefreecui] = useState(null);
  const [EcfInspectionPerformed, setEcfInspectionPerformed] = useState([]);
  const [selectedEcfInspectionPerformed, setSelectedEcfInspectionPerformed] = useState(null);
  const [InspectionPerformedcui, setInspectionPerformedcui] = useState([]);
  const [selectedInspectionPerformedcui, setSelectedInspectionPerformedcui] = useState(null);
  
  //DF SCC
  const [DetermineSusceptibility, setDetermineSusceptibility] = useState([]);
  const [selectedDetermineSusceptibility, setSelectedDetermineSusceptibility] = useState(null);
  const [ACDetermineSusceptibility, setACDetermineSusceptibility] = useState([]);
  const [selectedACDetermineSusceptibility, setSelectedACDetermineSusceptibility] = useState(null);
  const [CRNumberofInspections, setCRNumberofInspections] = useState([]);
  const [selectedCRNumberofInspections, setSelectedCRNumberofInspections] = useState(null);
  const [ACNumberofInspections, setACNumberofInspections] = useState([]);
  const [selectedACNumberofInspections, setSelectedACNumberofInspections] = useState(null);
  const [Sscphwater, setSscphwater] = useState([]);
  const [selectedSscphwater, setSelectedSscphwater] = useState(null);
  const [Sscfunctionh2swater, setSscfunctionh2swater] = useState([]);
  const [selectedSscfunctionh2swater, setSelectedSscfunctionh2swater] = useState(null);
  const [selectedSsccyanides, setSelectedSsccyanides] = useState(null);
  const [selectedSscfunctiontreatment, setSelectedSscfunctiontreatment] = useState(null);
  const [selectedSscmaxbrinnell, setSelectedSscmaxbrinnell] = useState(null);
  const [selectedSsccracking, setSelectedSsccracking] = useState(null);
  const [SCCNumberofInspections, setSCCNumberofInspections] = useState([]);
  const [selectedSCCNumberofInspections, setSelectedSCCNumberofInspections] = useState(null);
  const [Shicphwater, setShicphwater] = useState([]);
  const [selectedShicphwater, setSelectedShicphwater] = useState(null);
  const [Shicfunctionh2swater, setShicfunctionh2swater] = useState([]);
  const [selectedShicfunctionh2swater, setSelectedShicfunctionh2swater] = useState(null);
  const [selectedShiccyanides, setSelectedShiccyanides] = useState(null);
  const [selectedShiccracking, setSelectedShiccracking] = useState(null);
  const [selectedShicsulfurcontent, setSelectedShicsulfurcontent] = useState(null);
  const [ShicNumberofInspections, setShicNumberofInspections] = useState([]);
  const [selectedShicNumberofInspections, setSelectedShicNumberofInspections] = useState(null);
  const [selectedHicmonitoringmethod, setSelectedHicmonitoringmethod] = useState(null);
  const [Acephwater, setAcephwater] = useState([]);
  const [selectedAcephwater, setSelectedAcephwater] = useState(null);
  const [selectedAceCO3, setSelectedAceCO3] = useState(null);
  const [selectedAcecracking, setSelectedAcecracking] = useState(null);
  const [selectedPACracking, setSelectedPACracking] = useState(null);
  const [selectedCscccracking, setSelectedCscccracking] = useState(null);
  const [selectedHsccracking, setSelectedHsccracking] = useState(null);
  const [selectedHicsohiccracking, setSelectedHicsohiccracking] = useState(null);
  const [selectedHicsohicsulfurcontent, setSelectedHicsohicsulfurcontent] = useState(null);
  const [selectedHicsohicadjustment, setSelectedHicsohicadjustment] = useState(null);
  const [selectedPamaterial, setSelectedPamaterial] = useState(null);
  const [selectedCsccoperatingtemperature, setSelectedCsccoperatingtemperature] = useState(null);
  const [selectedCsccchloride, setSelectedCsccchloride] = useState(null);
  const [selectedAcefoht, setSelectedAcefoht] = useState(null);
  const [selectedShicfunctiontreatment, setSelectedShicfunctiontreatment] = useState(null);
  const [selectedHsccondition, setSelectedHsccondition] = useState(null);
  const [selectedHscmaxbrinnell, setSelectedHscmaxbrinnell] = useState(null);
  
  //DF Brittle
  const [selectedHthadamagecondition, setSelectedHthadamagecondition] = useState(null);
  const [selectedAdmprocon, setSelectedAdmprocon] = useState(null);
  const [selectedBfasmeexcurve, setSelectedBfasmeexcurve] = useState(null);
  const [selectedLaseaorprocon, setSelectedLaseaorprocon] = useState(null);
  const [selectedLasasmecurve, setSelectedLasasmecurve] = useState(null);
  const [selectedEmbaopc, setSelectedEmbaopc] = useState(null);
  const [selectedSigmaembcontent, setSelectedSigmaembcontent] = useState(null);
  
  //DF Fatigue
  const [selectedPrefacriteria, setSelectedPrefacriteria] = useState(null);
  const [selectedPrefadfbpf, setSelectedPrefadfbpf] = useState(null);
  const [selectedVisshcriteria, setSelectedVisshcriteria] = useState(null);
  const [selectedTocycriteria, setSelectedTocycriteria] = useState(null);
  const [selectedAdcocriteria, setSelectedAdcocriteria] = useState(null);
  const [selectedAdpicriteria, setSelectedAdpicriteria] = useState(null);
  const [selectedAdcoicriteria, setSelectedAdcoicriteria] = useState(null);
  const [selectedAdjotcriteria, setSelectedAdjotcriteria] = useState(null);
  const [selectedAdbrdicriteria, setSelectedAdbrdicriteria] = useState(null);
  
  //COF
  const [selectedHolesize1, setSelectedHolesize1] = useState(null);
  const [selectedHolesize2, setSelectedHolesize2] = useState(null);
  const [selectedHolesize3, setSelectedHolesize3] = useState(null);
  const [selectedHolesize4, setSelectedHolesize4] = useState(null);
  const [selectedDetsysty, setSelectedDetsysty] = useState(null);
  const [selectedIsosysty, setSelectedIsosysty] = useState(null);
  const [selectedConarmit, setSelectedConarmit] = useState(null);
  const [selectedFluidtypecof, setSelectedFluidtypecof] = useState(null);
  const [selectedToxflucov, setSelectedToxflucov] = useState(null);
  
  const [error, setError] = useState(null);

  //Option
  const PositionOptions = [
  { value: 'Aboveground', label: 'Aboveground' },
  { value: 'Underground', label: 'Underground' },
  { value: 'Partially', label: 'Partially' },
];

const materialOptions = materialData.map(materialObj => {
    return {
      value: materialObj,
      label: materialObj,
    };
  });
  
  const EquipmenttypeOptions = EquipmenttypeData.map(EquipmenttypeDataObj => {
    return {
      value: EquipmenttypeDataObj,
      label: EquipmenttypeDataObj,
    };
  });
  
  const ComponenttypeOptions = Componenttype.map(ComponenttypeDataObj => {
    return {
      value: ComponenttypeDataObj,
      label: ComponenttypeDataObj,
    };
  });
  
  const GeometrytypeOptions = Geometrytype.map(GeometrytypeDataObj => {
    return {
      value: GeometrytypeDataObj,
      label: GeometrytypeDataObj,
    };
  });


  // Ambil nilai genrannum dari query parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const genrannum = params.get("genrannum");
    setFormData({ genrannum: genrannum || "" });
  }, [location]);

  const handleDownloadPDF = async () => {
  const input = componentRef.current;
  if (!input) return;

  try {
    // Tangkap elemen HTML sebagai gambar menggunakan html2canvas
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");

    // Dapatkan ukuran elemen yang akan dicetak
    const width = canvas.width;  // Lebar gambar (dalam px)
    const height = canvas.height; // Tinggi gambar (dalam px)

    // Tentukan ukuran kertas dengan margin 10px (dalam piksel)
    const margin = 10;  // Margin 10px
    const pageWidth = width + margin * 2;  // Lebar kertas dengan margin
    const pageHeight = height + margin * 2; // Tinggi kertas dengan margin

    // Membuat instance jsPDF dengan ukuran kertas sesuai dengan ukuran gambar
    const pdf = new jsPDF({
      orientation: "portrait",  // Orientasi portrait
      unit: "px",               // Satuan ukuran dalam piksel
      format: [pageWidth, pageHeight] // Ukuran kertas sesuai dengan gambar dan margin
    });

    // Menambahkan gambar ke PDF dengan margin 10px
    pdf.addImage(imgData, "PNG", margin, margin, width, height);

    // Simpan file PDF
    pdf.save("RBI_Calculator_Result.pdf");
  } catch (error) {
    console.error("Gagal membuat PDF:", error);
  }
};



	const [formData2, setFormData2] = useState({
	  
    tag_number: "",
    piping_service: "",
    location_from: "",
    location_to: "",
    installation_date: "",
    Thicknessactual: "",
    coating_installation_date: "",
    grade: "",
    material: "", 
    Designcode: "",
    Outsidediameter: "",
    Length: "",
    Corrosionallowance: "",
    Nominalthickness: "",
    Inspectiondate: "",
    Inspectiondatecomplete: "",
    Remaininglifeofthecladding: "",
    Corrosionratecladding: "",
    Weldjointefficiency: "",
    Yieldstrength: "",
    Tensilestrength: "",
    Allowablestress: "",
    Designpressure: "",
    Designtemperature: "",
    Operatingpressure: "",
    Operatingtemperature: "",
    Minimumdesigntemperature: "",
    Upsettemperature: "",
    Coatinginstallationdate: "",
    Previousinspectiondate: "",
    Previousthicknessreading: "",
    Corrosionprobe: "",
    Corrosioncoupon: "",
	PIDNumber: "",
	position: "",
	
	//Screening
	yearoffabricationmaterial:"",
	Representativefluid:"",
	Fluidphase:"",
	Fluidatnormaloperatingconditions:"",
	Fluidatambientconditions:"",
	Areaft2:2000,
	Dataconfidencedamagestate1:"",
	Dataconfidencedamagestate2:"",
	Dataconfidencedamagestate3:"",
	Numberofpastinspection: 0,
	InspectionA: 0,
	InspectionB: 0,
	InspectionC: 0,
	InspectionD: 0,
	Causticpresen:"",
	Alkalinewater:"",
	Oilwithsulfurcompounds:"",
	Chloridesppm:"",
	Freewater:"",
	H2Sandhydrogen:"",
	Oxygen:"",
	H2S:"",
	HCl:"",
	H2SO4:"",
	HF:"",
	CO2:"",
	Mistoverspray:"",
	Steamvents:"",
	Delugesystems:"",
	Chloridecontaining:"",
	Sulfurbearing:"",
	Chlorideswndwater:"",
	Hydrofluoricacid:"",
	Acidgastreatingamines:"",
	Soil:"",
	TF350:"",
	TF300:"",
	T100:"",
	T350:"",
	T1070:"",
	T1050:"",
	T1700:"",
	T400:"",
	T900:"",
	Hydrogenpartial:"",
	Subjecttoprocess:"",
	Notnormally:"",
	Withdeteriorated:"",
	Penetrationsandvisually:"",
	MDMTorMAT:"",
	Havebeenpast:"",
	Ph7:"",
	Allregular300:"",
	HGrade300:"",
	LGrade300:"",
	Stainlesssteel321:"",
	Stainlesssteel347:"",
	
	//DF Thinning
	Applicable:"",
	ReliabilityindicesDS1:1,
	ReliabilityindicesDS2:2,
	ReliabilityindicesDS3:4,
	ReliabilityindicesCOVAT:0.2,
	ReliabilityindicesCOVSf:0.2,
	ReliabilityindicesCOVP:0.05,
	Inspectioneffectivenesscategory:"",
	Injectionmixpoints:"",
	Deadlegcontain:"",
	Weldedconstructionwelded:"",
	MaintenanceAPISTD563Maintained:"",
	Settlementcriteria:"",
	OnlinemonitoringThinningmechanism:"",
	OnlinemonitoringMonitoringtype:"",
	Liningtype:"",
	AgeofLiningoryearage:"",
	Inorganicliningstype:"",
	Orgaliningstype:"",
	Monitoringcontain:"",
	Liningconditionqualitativecondition:"",
	
	//DF External
	ExtDriver: "",
	EquipmentDesignorFabrication:"",
	Interface: "",
	CoatingQuality: "",
	CfcDriver: "",
	InsulationType: "",
	Complexity: "",
	InsulationCondition: "",
	CfcDesignorFabrication: "",
	Interfaceenterssoilorwater: "",
	EcfDrive: "",
	EcfCracking: "",
	EcfInspectionPerformed: "",
	EcfDrivecui: "",
	EcfCrackingcui: "",
	Chloridefreecui: "",
	InspectionPerformedcui: "",
	
	//DF SCC
	DetermineSusceptibility: "",
	CRNumberofInspections: "",
	ACDetermineSusceptibility: "",
	ACNumberofInspections: "",
	Sscphwater: "",
	Sscfunctionh2swater: "",
	Ssccyanides: "",
	Sscfunctiontreatment:"",
	Sscmaxbrinnell: "",
	Ssccracking: "",
	SCCNumberofInspections: "",
	Shicphwater: "",
	Shicfunctionh2swater: "",
	Shiccyanides: "",
	Shiccracking: "",
	Shicfunctiontreatment: "",
	Shicsulfurcontent: "",
	ShicNumberofInspections: "",
	Hicmonitoringmethod: "",
	Acephwater: "",
	AceCO3: "",
	Acecracking: "",
	Acefoht: "",
	PACracking: "",
	Pamaterial: "",
	Csccoperatingtemperature: "",
	Csccchloride: "",
	Cscccracking: "",
	Hsccondition: "",
	Hscmaxbrinnell: "",
	Hsccracking: "",
	Hicsohiccracking: "",
	Hicsohicsulfurcontent: "",
	Hicsohicadjustment: "",
	
	//DF Brittle
	Hthadamagecondition: "",
	Admprocon: "",
	Bfasmeexcurve: "",
	Laseaorprocon: "",
	Lasasmecurve: "",
	Embaopc: "",
	Embreftem:80,
	Sigmaembcontent: "",
	
	//DF Fatigue
	Prefacriteria: "",
	Prefadfbpf: "",
	Visshcriteria: "",
	Tocycriteria: "",
	Adcocriteria: "",
	Adpicriteria: "",
	Adcoicriteria: "",
	Adjotcriteria: "",
	Adbrdicriteria: "",
	
	//COF
	Holesize1: "",
	Holesize2: "",
	Holesize3: "",
	Holesize4: "",
	Atmosprepatm: "",
	Confac2: "",
	Detsysty: "",
	Isosysty: "",
	Toledur: "",
	Conarmit: "",
	Fluidtypecof: "",
	Toxperthetoxcom: "",
	Toxflucov: "",
	Acidorcaus5: "",
	Acidorcaus6: "",
	Acidorcaus7: "",
	Acidorcaus8: "",
	Blenfac1: "",
	Blenfac2: "",
	Blenfac3: "",
	Blenfac4: "",
	Caforstoracleaknon1: "",
	Caforstoracleaknon2: "",
	Caforstoracleaknon3: "",
	Caforstoracleaknon4: "",
	Finoflnotoxcon: "",
	Matcosfacmat: "",
	Eqcosfac: "",
	Eqoutmul: "",
	Procoxprod: "",
	Encosen: "",
	
  });

const formatDate = (dateString) => {
	
	
  // Mengonversi string tanggal ISO 8601 ke objek Date
  const date = new Date(dateString);
  
  // Mengambil hari, bulan, dan tahun
  const day = String(date.getDate()).padStart(2, '0');  // Menambahkan angka 0 di depan jika kurang dari dua digit
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Mengambil bulan (dimulai dari 0, jadi tambahkan 1)
  const year = date.getFullYear();  // Mengambil tahun

  // Mengembalikan tanggal dalam format DD-MM-YYYY
  return `${year}-${month}-${day}`;
};

useEffect(() => {
  const fetchData = async () => {
    if (!formData.genrannum) return;

    try {
      setLoading(true);

      const getResultUrl = `https://script.google.com/macros/s/AKfycbyOfnVnieLqBeOz6rV8PxafT-HEfLT3d_c2ritchTVzEI1SgFPxGnKoZNmcHjF0GvDlUg/exec?id=${formData.genrannum}&print=print`;
      const getResultResponse = await fetch(getResultUrl);
      const result = await getResultResponse.json();

      console.log("Result Data:", result);

      const resultSheet = result["Result"];
      const basicdataSheet = result["BasicData"];
      const screeningSheet = result["Screening"];
      const DFthiningliningSheet = result["DFthinninglining"];
      const DFexternalSheet = result["DFexternal"];
      const DFsccSheet = result["DFscc"];
      const DFbrittlefractureSheet = result["DFbrittlefracture"];
	  const DFfatigueSheet = result['DFfatigue'];
	  const cofSheet = result['cof'];

      if (resultSheet && resultSheet.length > 0) {
        const matchedResult = resultSheet.filter(row => row[27]?.toString() === formData.genrannum.toString());

        if (matchedResult.length > 0) {
          setRiskMatrix(matchedResult[0][23]);
          setRiskLevel(matchedResult[0][25]);
        } else {
          setRiskMatrix(null);
          setRiskLevel(null);
        }
      } else {
        setRiskMatrix(null);
        setRiskLevel(null);
      }

      if (basicdataSheet && basicdataSheet.length > 0) {
        const isYes = (value) => value?.toString().toLowerCase() === "yes";

        setSelectedCoating(isYes(basicdataSheet[0][47]));
        setSelectedPWHT(isYes(basicdataSheet[0][45]));
        setSelectedLining(isYes(basicdataSheet[0][46]));
        setSelectedCladding(isYes(basicdataSheet[0][43]));
        setSelectedHeattracing(isYes(basicdataSheet[0][42]));
        setSelectedInsulation(isYes(basicdataSheet[0][44]));

        setSelectedPosition({ value: basicdataSheet[0][37], label: basicdataSheet[0][37] });
        setSelectedEquipmenttype({ value: basicdataSheet[0][38], label: basicdataSheet[0][38] });
        setSelectedComponenttype({ value: basicdataSheet[0][39], label: basicdataSheet[0][39] });
        setSelectedGeometrytype({ value: basicdataSheet[0][40], label: basicdataSheet[0][40] });
        setSelectedMaterial({ value: basicdataSheet[0][9], label: basicdataSheet[0][9] });
        setSelectedyearoffabricationmaterial({ value: screeningSheet[0][4], label: screeningSheet[0][4] });
        setSelectedRepresentativefluid({ value: screeningSheet[0][5], label: screeningSheet[0][5] });
        setSelectedFluidphase({ value: screeningSheet[0][6], label: screeningSheet[0][6] });
        setSelectedFluidatnormaloperatingconditions({ value: screeningSheet[0][7], label: screeningSheet[0][7] });
        setSelectedFluidatambientconditions({ value: screeningSheet[0][8], label: screeningSheet[0][8] });
        setSelectedCausticpresen({ value: screeningSheet[0][33], label: screeningSheet[0][33] });
        setSelectedAlkalinewater({ value: screeningSheet[0][34], label: screeningSheet[0][34] });
        setSelectedOilwithsulfurcompounds({ value: screeningSheet[0][35], label: screeningSheet[0][35] });
        setSelectedChloridesppm({ value: screeningSheet[0][36], label: screeningSheet[0][36] });
        setSelectedFreewater({ value: screeningSheet[0][37], label: screeningSheet[0][37] });
        setSelectedH2Sandhydrogen({ value: screeningSheet[0][38], label: screeningSheet[0][38] });
        setSelectedOxygen({ value: screeningSheet[0][39], label: screeningSheet[0][39] });
        setSelectedH2S({ value: screeningSheet[0][40], label: screeningSheet[0][40] });
        setSelectedHCl({ value: screeningSheet[0][41], label: screeningSheet[0][41] });
        setSelectedH2SO4({ value: screeningSheet[0][42], label: screeningSheet[0][42] });
        setSelectedHF({ value: screeningSheet[0][43], label: screeningSheet[0][43] });
        setSelectedCO2({ value: screeningSheet[0][44], label: screeningSheet[0][44] });
        setSelectedDataconfidencedamagestate1({ value: screeningSheet[0][10], label: screeningSheet[0][10] });
        setSelectedDataconfidencedamagestate2({ value: screeningSheet[0][11], label: screeningSheet[0][11] });
        setSelectedDataconfidencedamagestate3({ value: screeningSheet[0][12], label: screeningSheet[0][12] });
        setSelectedAllregular300({ value: screeningSheet[0][84], label: screeningSheet[0][84] });
        setSelectedHGrade300({ value: screeningSheet[0][85], label: screeningSheet[0][85] });
        setSelectedLGrade300({ value: screeningSheet[0][86], label: screeningSheet[0][86] });
        setSelectedStainlesssteel321({ value: screeningSheet[0][87], label: screeningSheet[0][87] });
        setSelectedStainlesssteel347({ value: screeningSheet[0][88], label: screeningSheet[0][88] });
        setSelectedTF350({ value: screeningSheet[0][54], label: screeningSheet[0][54] });
        setSelectedTF300({ value: screeningSheet[0][55], label: screeningSheet[0][55] });
        setSelectedT100({ value: screeningSheet[0][56], label: screeningSheet[0][56] });
        setSelectedT350({ value: screeningSheet[0][57], label: screeningSheet[0][57] });
        setSelectedT1070({ value: screeningSheet[0][58], label: screeningSheet[0][58] });
        setSelectedT1050({ value: screeningSheet[0][59], label: screeningSheet[0][59] });
        setSelectedT1700({ value: screeningSheet[0][60], label: screeningSheet[0][60] });
        setSelectedT400({ value: screeningSheet[0][61], label: screeningSheet[0][61] });
        setSelectedT900({ value: screeningSheet[0][62], label: screeningSheet[0][62] });
        setSelectedMistoverspray({ value: screeningSheet[0][45], label: screeningSheet[0][45] });
        setSelectedSteamvents({ value: screeningSheet[0][46], label: screeningSheet[0][46] });
        setSelectedDelugesystems({ value: screeningSheet[0][47], label: screeningSheet[0][47] });
        setSelectedChloridecontaining({ value: screeningSheet[0][48], label: screeningSheet[0][48] });
        setSelectedSulfurbearing({ value: screeningSheet[0][49], label: screeningSheet[0][49] });
        setSelectedChlorideswndwater({ value: screeningSheet[0][50], label: screeningSheet[0][50] });
        setSelectedHydrofluoricacid({ value: screeningSheet[0][51], label: screeningSheet[0][51] });
        setSelectedAcidgastreatingamines({ value: screeningSheet[0][52], label: screeningSheet[0][52] });
        setSelectedSoil({ value: screeningSheet[0][53], label: screeningSheet[0][53] });
        setSelectedHydrogenpartial({ value: screeningSheet[0][63], label: screeningSheet[0][63] });
        setSelectedSubjecttoprocess({ value: screeningSheet[0][64], label: screeningSheet[0][64] });
        setSelectedNotnormally({ value: screeningSheet[0][65], label: screeningSheet[0][65] });
        setSelectedWithdeteriorated({ value: screeningSheet[0][66], label: screeningSheet[0][66] });
        setSelectedPenetrationsandvisually({ value: screeningSheet[0][67], label: screeningSheet[0][67] });
        setSelectedMDMTorMAT({ value: screeningSheet[0][68], label: screeningSheet[0][68] });
        setSelectedHavebeenpast({ value: screeningSheet[0][69], label: screeningSheet[0][69] });
        setSelectedPh7({ value: screeningSheet[0][70], label: screeningSheet[0][70] });
        setSelectedApplicable({ value: DFthiningliningSheet[0][4], label: DFthiningliningSheet[0][4] });
        setSelectedInspectioneffectivenesscategory({ value: DFthiningliningSheet[0][31], label: DFthiningliningSheet[0][31] });
        setSelectedInjectionmixpoints({ value: DFthiningliningSheet[0][34], label: DFthiningliningSheet[0][34] });
        setSelectedDeadlegcontain({ value: DFthiningliningSheet[0][36], label: DFthiningliningSheet[0][36] });
        setSelectedWeldedconstructionwelded({ value: DFthiningliningSheet[0][38], label: DFthiningliningSheet[0][38] });
        setSelectedMaintenanceAPISTD563Maintained({ value: DFthiningliningSheet[0][40], label: DFthiningliningSheet[0][40] });
        setSelectedSettlementcriteria({ value: DFthiningliningSheet[0][42], label: DFthiningliningSheet[0][42] });
        setSelectedOnlinemonitoringThinningmechanism({ value: DFthiningliningSheet[0][44], label: DFthiningliningSheet[0][44] });
        setSelectedOnlinemonitoringMonitoringtype({ value: DFthiningliningSheet[0][45], label: DFthiningliningSheet[0][45] });
        setSelectedLiningtype({ value: DFthiningliningSheet[0][50], label: DFthiningliningSheet[0][50] });
        setSelectedAgeofLiningoryearage({ value: DFthiningliningSheet[0][51], label: DFthiningliningSheet[0][51] });
        setSelectedInorganicliningstype({ value: DFthiningliningSheet[0][52], label: DFthiningliningSheet[0][52] });
        setSelectedOrgaliningstype({ value: DFthiningliningSheet[0][54], label: DFthiningliningSheet[0][54] });
        setSelectedMonitoringcontain({ value: DFthiningliningSheet[0][58], label: DFthiningliningSheet[0][58] });
        setSelectedLiningconditionqualitativecondition({ value: DFthiningliningSheet[0][56], label: DFthiningliningSheet[0][56] });
        
		setSelectedExtDriver({ value: DFexternalSheet[0][6], label: DFexternalSheet[0][6] });
		setSelectedEquipmentDesignorFabrication({ value: DFexternalSheet[0][10], label: DFexternalSheet[0][10] });
		setSelectedCoatingQuality({ value: DFexternalSheet[0][16], label: DFexternalSheet[0][16] });
		setSelectedEcfDrive({ value: DFexternalSheet[0][85], label: DFexternalSheet[0][85] });
		setSelectedEcfCracking({ value: DFexternalSheet[0][86], label: DFexternalSheet[0][86] });
		setSelectedEcfInspectionPerformed({ value: DFexternalSheet[0][94], label: DFexternalSheet[0][94] });
		setSelectedCfcDriver({ value: DFexternalSheet[0][43], label: DFexternalSheet[0][43] });
		setSelectedInsulationType({ value: DFexternalSheet[0][47], label: DFexternalSheet[0][47] });
		setSelectedComplexity({ value: DFexternalSheet[0][49], label: DFexternalSheet[0][49] });
		setSelectedInsulationCondition({ value: DFexternalSheet[0][51], label: DFexternalSheet[0][51] });
		setSelectedCfcDesignorFabrication({ value: DFexternalSheet[0][53], label: DFexternalSheet[0][53] });
		setSelectedInterfaceenterssoilorwater({ value: DFexternalSheet[0][55], label: DFexternalSheet[0][55] });
		setSelectedEcfDrivecui({ value: DFexternalSheet[0][98], label: DFexternalSheet[0][98] });
		setSelectedEcfCrackingcui({ value: DFexternalSheet[0][99], label: DFexternalSheet[0][99] });
		setSelectedChloridefreecui({ value: DFexternalSheet[0][101], label: DFexternalSheet[0][101] });
		setSelectedInspectionPerformedcui({ value: DFexternalSheet[0][108], label: DFexternalSheet[0][108] });
		setSelectedInterface({ value: DFexternalSheet[0][12], label: DFexternalSheet[0][12] });
		
		setSelectedDetermineSusceptibility({ value: DFsccSheet[0][5], label: DFsccSheet[0][5] });
		setSelectedCRNumberofInspections({ value: DFsccSheet[0][8], label: DFsccSheet[0][8] });
		setSelectedACDetermineSusceptibility({ value: DFsccSheet[0][12], label: DFsccSheet[0][12] });
		setSelectedACNumberofInspections({ value: DFsccSheet[0][15], label: DFsccSheet[0][15] });
		setSelectedAcephwater({ value: DFsccSheet[0][19], label: DFsccSheet[0][19] });
		setSelectedAceCO3({ value: DFsccSheet[0][50], label: DFsccSheet[0][50] });
		setSelectedAcecracking({ value: DFsccSheet[0][52], label: DFsccSheet[0][52] });
		setSelectedAcefoht({ value: DFsccSheet[0][61], label: DFsccSheet[0][61] });
		setSelectedPACracking({ value: DFsccSheet[0][62], label: DFsccSheet[0][62] });
		setSelectedPamaterial({ value: DFsccSheet[0][63], label: DFsccSheet[0][63] });
		setSelectedSscphwater({ value: DFsccSheet[0][19], label: DFsccSheet[0][19] });
		setSelectedSscfunctionh2swater({ value: DFsccSheet[0][20], label: DFsccSheet[0][20] });
		setSelectedSsccyanides({ value: DFsccSheet[0][21], label: DFsccSheet[0][21] });
		setSelectedSscfunctiontreatment({ value: DFsccSheet[0][23], label: DFsccSheet[0][23] });
		setSelectedSscmaxbrinnell({ value: DFsccSheet[0][24], label: DFsccSheet[0][24] });
		setSelectedSsccracking({ value: DFsccSheet[0][25], label: DFsccSheet[0][25] });
		setSelectedSCCNumberofInspections({ value: DFsccSheet[0][29], label: DFsccSheet[0][29] });
		setSelectedCsccoperatingtemperature({ value: DFsccSheet[0][73], label: DFsccSheet[0][73] });
		setSelectedCsccchloride({ value: DFsccSheet[0][74], label: DFsccSheet[0][74] });
		setSelectedCscccracking({ value: DFsccSheet[0][75], label: DFsccSheet[0][75] });
		setSelectedHsccondition({ value: DFsccSheet[0][83], label: DFsccSheet[0][83] });
		setSelectedHscmaxbrinnell({ value: DFsccSheet[0][84], label: DFsccSheet[0][84] });
		setSelectedHsccracking({ value: DFsccSheet[0][85], label: DFsccSheet[0][85] });
		setSelectedShicphwater({ value: DFsccSheet[0][33], label: DFsccSheet[0][33] });
		setSelectedShicfunctionh2swater({ value: DFsccSheet[0][34], label: DFsccSheet[0][34] });
		setSelectedShiccyanides({ value: DFsccSheet[0][35], label: DFsccSheet[0][35] });
		setSelectedShiccracking({ value: DFsccSheet[0][36], label: DFsccSheet[0][36] });
		setSelectedShicfunctiontreatment({ value: DFsccSheet[0][38], label: DFsccSheet[0][38] });
		setSelectedShicsulfurcontent({ value: DFsccSheet[0][39], label: DFsccSheet[0][39] });
		setSelectedShicNumberofInspections({ value: DFsccSheet[0][43], label: DFsccSheet[0][43] });
		setSelectedHicmonitoringmethod({ value: DFsccSheet[0][44], label: DFsccSheet[0][44] });
		setSelectedHicsohiccracking({ value: DFsccSheet[0][94], label: DFsccSheet[0][94] });
		setSelectedHicsohicsulfurcontent({ value: DFsccSheet[0][95], label: DFsccSheet[0][95] });
		setSelectedHicsohicadjustment({ value: DFsccSheet[0][98], label: DFsccSheet[0][98] });
		
		setSelectedHthadamagecondition({ value: DFbrittlefractureSheet[0][5], label: DFbrittlefractureSheet[0][5] });
		setSelectedAdmprocon({ value: DFbrittlefractureSheet[0][11], label: DFbrittlefractureSheet[0][11] });
		setSelectedBfasmeexcurve({ value: DFbrittlefractureSheet[0][16], label: DFbrittlefractureSheet[0][16] });
		setSelectedLaseaorprocon({ value: DFbrittlefractureSheet[0][45], label: DFbrittlefractureSheet[0][45] });
		setSelectedLasasmecurve({ value: DFbrittlefractureSheet[0][49], label: DFbrittlefractureSheet[0][49] });
		setSelectedEmbaopc({ value: DFbrittlefractureSheet[0][72], label: DFbrittlefractureSheet[0][72] });
		setSelectedSigmaembcontent({ value: DFbrittlefractureSheet[0][80], label: DFbrittlefractureSheet[0][80] });
		
		setSelectedPrefacriteria({ value: DFfatigueSheet[0][5], label: DFfatigueSheet[0][5] });
		setSelectedPrefadfbpf({ value: DFfatigueSheet[0][7], label: DFfatigueSheet[0][7] });
		setSelectedVisshcriteria({ value: DFfatigueSheet[0][9], label: DFfatigueSheet[0][9] });
		setSelectedTocycriteria({ value: DFfatigueSheet[0][11], label: DFfatigueSheet[0][11] });
		setSelectedAdcocriteria({ value: DFfatigueSheet[0][14], label: DFfatigueSheet[0][14] });
		setSelectedAdpicriteria({ value: DFfatigueSheet[0][16], label: DFfatigueSheet[0][16] });
		setSelectedAdcoicriteria({ value: DFfatigueSheet[0][18], label: DFfatigueSheet[0][18] });
		setSelectedAdjotcriteria({ value: DFfatigueSheet[0][20], label: DFfatigueSheet[0][20] });
		setSelectedAdbrdicriteria({ value: DFfatigueSheet[0][22], label: DFfatigueSheet[0][22] });
		
		setSelectedAdbrdicriteria({ value: DFfatigueSheet[0][22], label: DFfatigueSheet[0][22] });
		
		setSelectedHolesize1({ value: cofSheet[0][25], label: cofSheet[0][25] });
		setSelectedHolesize2({ value: cofSheet[0][26], label: cofSheet[0][26] });
		setSelectedHolesize3({ value: cofSheet[0][27], label: cofSheet[0][27] });
		setSelectedHolesize4({ value: cofSheet[0][28], label: cofSheet[0][28] });

        setFormData2((prevFormData2) => ({
          ...prevFormData2,
          tag_number: basicdataSheet[0][3],
          piping_service: basicdataSheet[0][4],
          location_from: basicdataSheet[0][5],
          location_to: basicdataSheet[0][6],
          installation_date: basicdataSheet[0][7],
          Inspectiondate: basicdataSheet[0][21],
          Inspectiondatecomplete: formatDate(basicdataSheet[0][22]),
          PIDNumber: basicdataSheet[0][57],
          Thicknessactual: basicdataSheet[0][59],
          Corrosionallowance: basicdataSheet[0][15],
          Corrosionprobe: basicdataSheet[0][53],
          Corrosioncoupon: basicdataSheet[0][54],
          coating_installation_date: formatDate(basicdataSheet[0][8]),
          grade: basicdataSheet[0][10],
          Designcode: basicdataSheet[0][11],
          Outsidediameter: basicdataSheet[0][12],
          Length: basicdataSheet[0][14],
          Nominalthickness: basicdataSheet[0][16],
          Remaininglifeofthecladding: basicdataSheet[0][24],
          Corrosionratecladding: basicdataSheet[0][26],
          Weldjointefficiency: basicdataSheet[0][27],
          Yieldstrength: basicdataSheet[0][28],
          Tensilestrength: basicdataSheet[0][29],
          Allowablestress: basicdataSheet[0][30],
          Coatinginstallationdate: basicdataSheet[0][48],
          Previousinspectiondate: basicdataSheet[0][49],
          Previousthicknessreading: basicdataSheet[0][50],
          Designpressure: basicdataSheet[0][31],
          Designtemperature: basicdataSheet[0][32],
          Operatingpressure: basicdataSheet[0][33],
          Operatingtemperature: basicdataSheet[0][34],
          Minimumdesigntemperature: basicdataSheet[0][35],
          Upsettemperature: basicdataSheet[0][36],
          Numberofpastinspection: screeningSheet[0][13],
          Areaft2: screeningSheet[0][9],
          InspectionA: screeningSheet[0][14],
          InspectionB: screeningSheet[0][15],
          InspectionC: screeningSheet[0][16],
          InspectionD: screeningSheet[0][17],
          ReliabilityindicesDS1: DFthiningliningSheet[0][21],
          ReliabilityindicesDS2: DFthiningliningSheet[0][22],
          ReliabilityindicesDS3: DFthiningliningSheet[0][23],
          ReliabilityindicesCOVAT: DFthiningliningSheet[0][24],
          ReliabilityindicesCOVSf: DFthiningliningSheet[0][25],
          ReliabilityindicesCOVP: DFthiningliningSheet[0][26],
		  
		  Embreftem: DFbrittlefractureSheet[0][74],
		  
		  Atmosprepatm: cofSheet[0][49],
		  Confac2: cofSheet[0][70],
		  Detsysty: cofSheet[0][79],
		  Isosysty: cofSheet[0][81],
		  Toledur: cofSheet[0][87],
		  Conarmit: cofSheet[0][100],
		  Fluidtypecof: cofSheet[0][106],
		  Toxperthetoxcom: cofSheet[0][190],
		  Toxflucov: cofSheet[0][199],
		  Acidorcaus5: cofSheet[0][235],
		  Acidorcaus6: cofSheet[0][236],
		  Acidorcaus7: cofSheet[0][237],
		  Acidorcaus8: cofSheet[0][238],
		  Blenfac1: cofSheet[0][243],
		  Blenfac2: cofSheet[0][244],
		  Blenfac3: cofSheet[0][245],
		  Blenfac4: cofSheet[0][246],
		  Caforstoracleaknon1: cofSheet[0][251],
		  Caforstoracleaknon2: cofSheet[0][252],
		  Caforstoracleaknon3: cofSheet[0][253],
		  Caforstoracleaknon4: cofSheet[0][254],
		  Finoflnotoxcon: cofSheet[0][255],
		  Matcosfacmat: cofSheet[0][264],
		  Eqcosfac: cofSheet[0][269],
		  Eqoutmul: cofSheet[0][275],
		  Procoxprod: cofSheet[0][281],
		  Encosen: cofSheet[0][291],
		  
        }));

        setTimeout(() => {
          handleDownloadPDF();
		  navigate(`/rbi_calc`);
        }, 5000);
      } else {
        console.warn("Basic Data tidak valid atau kosong.");
      }
    } catch (error) {
      console.error("Error saat mengambil data:", error);
      setError("Gagal mengambil data. Silakan coba lagi.");
    } finally {

      setLoading(false);
    }
  };

  fetchData();
}, [formData]);

const yearoffabricationmaterialOptions = [
  { value: 'After 1988', label: 'After 1988' },
  { value: '1981 to 1987', label: '1981 to 1987' },
  { value: '1973 to 1980', label: '1973 to 1980' },
  { value: '1965 to 1972', label: '1965 to 1972' },
];

const FluidphaseOptions = [
  { value: 'Vapor or Gas', label: 'Vapor or Gas' },
  { value: 'Liquid', label: 'Liquid' },
];

const GasliquidOptions = [
  { value: 'Gas', label: 'Gas' },
  { value: 'Liquid', label: 'Liquid' },
];

const Dataconfidencedamagestate123Options = [
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
];

const ExtDriverOptions = [
  { value: 'Marine', label: 'Marine' },
  { value: 'Temperate', label: 'Temperate' },
  { value: 'Arid / Dry', label: 'Arid / Dry' },
  { value: 'Severe', label: 'Severe' },
];

const CfcDriverOptions = [
  { value: 'Marine', label: 'Marine' },
  { value: 'Temperate', label: 'Temperate' },
  { value: 'Arid / Dry', label: 'Arid / Dry' },
  { value: 'Severe', label: 'Severe' },
];

const EcfDriveOptions = [
  { value: 'Marine', label: 'Marine' },
  { value: 'Temperate', label: 'Temperate' },
  { value: 'Arid / Dry', label: 'Arid / Dry' },
  { value: 'Severe', label: 'Severe' },
];

const EcfDrivecuiOptions = [
  { value: 'Marine', label: 'Marine' },
  { value: 'Temperate', label: 'Temperate' },
  { value: 'Arid / Dry', label: 'Arid / Dry' },
  { value: 'Severe', label: 'Severe' },
];

const EcfCrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const CrackingcuiOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const ChloridefreecuiOptions = [
  { value: 'Contains', label: 'Contains' },
  { value: 'Not contain', label: 'Not contain' },
];

const CfcInsulationTypeOptions = [
  { value: 'Unknown', label: 'Unknown' },
  { value: 'Foamglass', label: 'Foamglass' },
  { value: 'Pearlite', label: 'Pearlite' },
  { value: 'Fiberglass', label: 'Fiberglass' },
  { value: 'Mineral Wool', label: 'Mineral Wool' },
  { value: 'Calcium Silicate', label: 'Calcium Silicate' },
  { value: 'Asbestos', label: 'Asbestos' },
];

const ComplexityOptions = [
  { value: 'Below average', label: 'Below average' },
  { value: 'Average', label: 'Average' },
  { value: 'Above average', label: 'Above average' },
];

const InsulationConditionOptions = [
  { value: 'Below average', label: 'Below average' },
  { value: 'Average', label: 'Average' },
  { value: 'Above average', label: 'Above average' },
];

const CfcDesignorFabricationOptions = [
  { value: 'Allow water', label: 'Allow water' },
  { value: 'Not allow water', label: 'Not allow water' },
];

const CfcInterfacewaterOptions = [
  { value: 'Enter', label: 'Enter' },
  { value: 'Not enter', label: 'Not enter' },
];

const CoatingQualityOptions = [
  { value: 'Poor', label: 'Poor' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
];

//DF SCC
const DetermineSusceptibilityOptions = [
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Low', label: 'Low' },
  { value: 'None', label: 'None' },
];

const ACDetermineSusceptibilityOptions = [
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Low', label: 'Low' },
  { value: 'None', label: 'None' },
];

const SsccyanidesOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const SscfunctiontreatmentOptions = [
  { value: 'As-Welded', label: 'As-Welded' },
  { value: 'PWHT', label: 'PWHT' },
];

const HscconditionOptions = [
  { value: 'As-Welded', label: 'As-Welded' },
  { value: 'PWHT', label: 'PWHT' },
];

const SscmaxbrinnellOptions = [
  { value: '< 200', label: '< 200' },
  { value: '200-237', label: '200-237' },
  { value: '> 237', label: '> 237' },
];

const HscmaxbrinnellOptions = [
  { value: '<200', label: '<200' },
  { value: '200-237', label: '200-237' },
  { value: '>237', label: '>237' },
];

const SsccrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const ShiccyanidesOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const ShiccrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const ShicsulfurcontentOptions = [
  { value: 'High Sulfur Steel', label: 'High Sulfur Steel' },
  { value: 'Low Sulfur Steel', label: 'Low Sulfur Steel' },
  { value: 'Product Form - Seamless/Extruded Pipe', label: 'Product Form - Seamless/Extruded Pipe' },
];

const HicmonitoringmethodOptions = [
  { value: 'Key Process Variables', label: 'Key Process Variables' },
  { value: 'Hydrogen Probes', label: 'Hydrogen Probes' },
  { value: 'Key Process Variables and Hydrogen Probes', label: 'Key Process Variables and Hydrogen Probes' },
];

const AceCO3Options = [
  { value: 'CO3 All Concentrations', label: 'CO3 All Concentrations' },
  { value: 'CO3 < 100 ppm', label: 'CO3 < 100 ppm' },
  { value: 'CO3 > 100 ppm', label: 'CO3 > 100 ppm' },
];

const AcecrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const PacrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const CscrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const HsccrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const HicsohiccrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const HicsohicsulfurcontentOptions = [
  { value: 'High Sulfur Steel  > 0.01% S', label: 'High Sulfur Steel  > 0.01% S' },
  { value: 'Low Sulfur Steel  < 0.01% S', label: 'Low Sulfur Steel  < 0.01% S' },
  { value: 'Product Form – Seamless/Extruded Pipe', label: 'Product Form – Seamless/Extruded Pipe' },
];

const HicsohicadjustmentOptions = [
  { value: 'Key Process Variables', label: 'Key Process Variables' },
  { value: 'Hydrogen Probes', label: 'Hydrogen Probes' },
  { value: 'Key Process Variables and Hydrogen Probes', label: 'Key Process Variables and Hydrogen Probes' },
];

const PamaterialOptions = [
  { value: 'All regular 300 series Stainless Steels and Alloys 600 and 800 ', label: 'All regular 300 series Stainless Steels and Alloys 600 and 800 ' },
  { value: 'H Grade 300 series SS', label: 'H Grade 300 series SS' },
  { value: 'L Grade 300 series SS ', label: 'L Grade 300 series SS ' },
  { value: '321 Stainless Steel ', label: '321 Stainless Steel ' },
  { value: '347 Stainless Steel, Alloy ', label: '347 Stainless Steel, Alloy ' },
];

const CsccoperatingtemperatureOptions = [
  { value: '<100', label: '<100' },
  { value: '>100 - 150', label: '>100 - 150' },
  { value: '>100 - 200', label: '>100 - 200' },
  { value: '>150 - 200', label: '>150 - 200' },
  { value: '>200 - 300', label: '>200 - 300' },
  { value: '>300', label: '>300' },
];

const CsccchlorideOptions = [
  { value: "'1-10", label: "'1-10" },
  { value: "11-100", label: "11-100" },
  { value: "101-1000", label: "101-1000" },
  { value: ">1000", label: ">1000" },
];

const AcefohtOptions = [
  { value: 'Solution Annealed', label: 'Solution Annealed' },
  { value: 'Stabilized Before Welding', label: 'Stabilized Before Welding' },
  { value: 'Stabilized After Welding', label: 'Stabilized After Welding' },
];

const ShicfunctiontreatmentOptions = [
  { value: 'As-Welded', label: 'As-Welded' },
  { value: 'PWHT', label: 'PWHT' },
];

const YesnoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const RepresentativefluidOptions = Representativefluid.map(RepresentativefluidDataObj => {
    return {
      value: RepresentativefluidDataObj,
      label: RepresentativefluidDataObj,
    };
  });
  
const InspectioneffectivenesscategoryOptions = [
  { value: 'N/A', label: 'N/A' },
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
];

const SettlementcriteriaOptions = [
  { value: 'Exceeds', label: 'Exceeds' },
  { value: 'Meets', label: 'Meets' },
  { value: 'Never evaluated', label: 'Never evaluated' },
  { value: 'No settlement', label: 'No settlement' },
  { value: 'N/A', label: 'N/A' },
];

const OnlinemonitoringThinningmechanismOptions = [
  { value: 'Hydrochloric Acid (HCl) Corrosion', label: 'Hydrochloric Acid (HCl) Corrosion' },
  { value: 'High Temp Sulfidic/Naphthenic Acid Corrosion', label: 'High Temp Sulfidic/Naphthenic Acid Corrosion' },
  { value: 'High Temp H2S/H2 Corrosion', label: 'High Temp H2S/H2 Corrosion' },
  { value: 'Sulfuric Acid (H2S/H2) Corrosion Low Velocity', label: 'Sulfuric Acid (H2S/H2) Corrosion Low Velocity' },
  { value: 'Sulfuric Acid (H2S/H2) Corrosion High Velocity', label: 'Sulfuric Acid (H2S/H2) Corrosion High Velocity' },
  { value: 'Hydrofluoric Acid (HF) Corrosion', label: 'Hydrofluoric Acid (HF) Corrosion' },
  { value: 'Sour Water Corrosion Low Velocity', label: 'Sour Water Corrosion Low Velocity' },
  { value: 'Sour Water Corrosion High Velocity', label: 'Sour Water Corrosion High Velocity' },
  { value: 'Amine Low Velocity', label: 'Amine Low Velocity' },
  { value: 'Amine High Velocity', label: 'Amine High Velocity' },
  { value: 'Other Corrosion Mechanism', label: 'Other Corrosion Mechanism' },
];

const OnlinemonitoringMonitoringtypeOptions = [
  { value: 'Key Process Variable', label: 'Key Process Variable' },
  { value: 'Electrical Resistance Probes', label: 'Electrical Resistance Probes' },
  { value: 'Corrosion Coupon', label: 'Corrosion Coupon' },
  { value: 'On-Line Monitoring Absent', label: 'On-Line Monitoring Absent' },
];

const LiningtypeOptions = [
  { value: 'Organic', label: 'Organic' },
  { value: 'Inorganic', label: 'Inorganic' },
];

const AgeofLiningoryearageOptions = Array.from({ length: 25 }, (v, i) => ({
  value: i + 1,
  label: i + 1
}));

const InorganicliningstypeOptions = [
  { value: 'Strip Lined Alloy', label: 'Strip Lined Alloy' },
  { value: 'Castable Refractory', label: 'Castable Refractory' },
  { value: 'Castable Refractory Severe Conditions', label: 'Castable Refractory Severe Conditions' },
  { value: 'Glass Lined', label: 'Glass Lined' },
  { value: 'Acid Brick', label: 'Acid Brick' },
  { value: 'Fiberglass', label: 'Fiberglass' },
];

const OrgaliningstypeOptions = [
  { value: 'Low Quality (Spray)', label: 'Low Quality (Spray)' },
  { value: 'Medium Quality (Filled)', label: 'Medium Quality (Filled)' },
  { value: 'High Quality (Reinforced)', label: 'High Quality (Reinforced)' },
];

const YesnowithnaOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'N/A', label: 'N/A' },
];

 const EcfPerformedOptions = EcfInspectionPerformed.map(EcfInspectionPerformedDataObj => {
    return {
      value: EcfInspectionPerformedDataObj,
      label: EcfInspectionPerformedDataObj,
    };
  });
  
  const PerformedcuiOptions = InspectionPerformedcui.map(InspectionPerformedcuiDataObj => {
    return {
      value: InspectionPerformedcuiDataObj,
      label: InspectionPerformedcuiDataObj,
    };
  });
  
  const CRInspectionsOptions = CRNumberofInspections.map(CRNumberofInspectionsDataObj => {
    return {
      value: CRNumberofInspectionsDataObj,
      label: CRNumberofInspectionsDataObj,
    };
  });
  
  const ACInspectionsOptions = ACNumberofInspections.map(ACNumberofInspectionsDataObj => {
    return {
      value: ACNumberofInspectionsDataObj,
      label: ACNumberofInspectionsDataObj,
    };
  });
  
  const AcephwaterOptions = Acephwater.map(AcephwaterDataObj => {
    return {
      value: AcephwaterDataObj,
      label: AcephwaterDataObj,
    };
  });
  
  const SscphwaterOptions = Sscphwater.map(SscphwaterDataObj => {
    return {
      value: SscphwaterDataObj,
      label: SscphwaterDataObj,
    };
  });
  
  const SscfunctionhwaterOptions = Sscfunctionh2swater.map(Sscfunctionh2swaterDataObj => {
    return {
      value: Sscfunctionh2swaterDataObj,
      label: Sscfunctionh2swaterDataObj,
    };
  });
  
  const SCCInspectionsOptions = SCCNumberofInspections.map(SCCNumberofInspectionsDataObj => {
    return {
      value: SCCNumberofInspectionsDataObj,
      label: SCCNumberofInspectionsDataObj,
    };
  });
  
  const ShicphwaterOptions = Shicphwater.map(ShicphwaterDataObj => {
    return {
      value: ShicphwaterDataObj,
      label: ShicphwaterDataObj,
    };
  });

  const ShicfunctionwaterOptions = Shicfunctionh2swater.map(Shicfunctionh2swaterDataObj => {
    return {
      value: Shicfunctionh2swaterDataObj,
      label: Shicfunctionh2swaterDataObj,
    };
  });
  
  const ShicInspectionsOptions = ShicNumberofInspections.map(ShicNumberofInspectionsDataObj => {
    return {
      value: ShicNumberofInspectionsDataObj,
      label: ShicNumberofInspectionsDataObj,
    };
  });
  
  //DF Brittle
const HthadamageconditionOptions = [
  { value: 'Yes and  component has not been replaced', label: 'Yes and  component has not been replaced' },
  { value: 'Yes and the component has been replaced in kind', label: 'Yes and the component has been replaced in kind' },
  { value: 'Component has beed replaced with an upgrade in the material construction', label: 'Component has beed replaced with an upgrade in the material construction' },
];

const BfasmeexcurveOptions = [
	{ value: 'A', label: 'A'},
	{ value: 'B', label: 'B'},
	{ value: 'C', label: 'C'},
	{ value: 'D', label: 'D'},
];

const LasasmecurveOptions = [
	{ value: 'A', label: 'A'},
	{ value: 'B', label: 'B'},
	{ value: 'C', label: 'C'},
	{ value: 'D', label: 'D'},
];

const LaseaorproconOptions = [
	{ value: 'Exist', label: 'Exist'},
	{ value: 'No', label: 'No'},
];

const SigmaembcontentOptions = [
	{ value: 'Low', label: 'Low'},
	{ value: 'Medium', label: 'Medium'},
	{ value: 'High', label: 'High'},
];

const EmbaopcOptions = [
	{ value: 'Exist', label: 'Exist'},
	{ value: 'No', label: 'No'},
];

const PrefacriteriaOptions = [
	{ value: 'None', label: 'None'},
	{ value: 'One', label: 'One'},
	{ value: 'Greater than one', label: 'Greater than one'},
];

const PrefadfbpffatigueOptions = [
	{ value: 'Minor', label: 'Minor'},
	{ value: 'Moderate', label: 'Moderate'},
	{ value: 'Severe', label: 'Severe'},
];

const VisshcriteriaOptions = [
	{ value: 'Shaking less than 2 weeks', label: 'Shaking less than 2 weeks'},
	{ value: 'Shaking between 2 and 13 weeks', label: 'Shaking between 2 and 13 weeks'},
	{ value: 'Shaking between 13 and 52 weeks', label: 'Shaking between 13 and 52 weeks'},
];

const TocycriteriaOptions = [
	{ value: 'Reciprocating Machinery', label: 'Reciprocating Machinery'},
	{ value: 'PRV Chatter', label: 'PRV Chatter'},
	{ value: 'Valve with high pressure drop', label: 'Valve with high pressure drop'},
	{ value: 'None', label: 'None'},
];

const AdcocriteriaOptions = [
	{ value: 'No modification', label: 'No modification'},
	{ value: 'Modification based on experience', label: 'Modification based on experience'},
	{ value: 'Modification based on complete engineering analysis', label: 'Modification based on complete engineering analysis'},
];

const AdpicriteriaOptions = [
	{ value: '0 to 5 total pipe fittings', label: '0 to 5 total pipe fittings'},
	{ value: '6 to 10 total pipe fittings', label: '6 to 10 total pipe fittings'},
	{ value: 'Greater than 10 total pipe fittings', label: 'Greater than 10 total pipe fittings'},
];

const AdcoicriteriaOptions = [
	{ value: 'Missing', label: 'Missing'},
	{ value: 'Broken', label: 'Broken'},
	{ value: 'Good', label: 'Good'},
];

const AdjotcriteriaOptions = [
	{ value: 'Threaded', label: 'Threaded'},
	{ value: 'socketweld', label: 'socketweld'},
	{ value: 'saddle on', label: 'saddle on'},
	{ value: 'Saddle in fittings', label: 'Saddle in fittings'},
	{ value: 'Piping tee', label: 'Piping tee'},
	{ value: 'Weldolets', label: 'Weldolets'},
	{ value: 'Sweepolets', label: 'Sweepolets'},
];

const AdbrdicriteriaOptions = [
	{ value: 'Less than or equal to 2 NPS', label: 'Less than or equal to 2 NPS'},
	{ value: 'Greater than 2 NPS', label: 'Greater than 2 NPS'},
];

const Holesize1234Options = [
	{ value: 'Small', label: 'Small'},
	{ value: 'Medium', label: 'Medium'},
	{ value: 'Large', label: 'Large'},
	{ value: 'Rupture', label: 'Rupture'},
	{ value: '-', label: '-'},
];

const DetsystyOptions = [
	{ value: 'Instrument designed specifically to detect material losses by changes in operating conditions (i.e., loss of pressure or flow) in the system.', label: 'Instrument designed specifically to detect material losses by changes in operating conditions (i.e., loss of pressure or flow) in the system.'},
	{ value: 'Suitably located detectors to detemine when the material is present outside the pressure-containing envelope.', label: 'Suitably located detectors to detemine when the material is present outside the pressure-containing envelope.'},
	{ value: 'Visual detection, cameras, or detectors with marginal coverage', label: 'Visual detection, cameras, or detectors with marginal coverage'},
];

const IsosystypeOptions = [
	{ value: 'Isolation or shutdown systems activated directly from process instrumentation or detectors, with no operator intervention. ', label: 'Isolation or shutdown systems activated directly from process instrumentation or detectors, with no operator intervention. '},
	{ value: 'Isolation or shutdown systems activated by operators in the control room or other suitable locations remote from the leak. ', label: 'Isolation or shutdown systems activated by operators in the control room or other suitable locations remote from the leak. '},
	{ value: 'Isolation dependent on manually-operated valves. ', label: 'Isolation dependent on manually-operated valves. '},
];

const ConarmitOptions = [
	{ value: 'Inventory blowdown, coupled with isolation system classification B or higher ', label: 'Inventory blowdown, coupled with isolation system classification B or higher '},
	{ value: 'Fire water deluge system and monitors', label: 'Fire water deluge system and monitors'},
	{ value: 'Fire water monitors only ', label: 'Fire water monitors only '},
	{ value: 'Foam spray system ', label: 'Foam spray system '},
];

const FluidtypecofOptions = [
	{ value: 'TYPE 0', label: 'TYPE 0'},
	{ value: 'TYPE 1', label: 'TYPE 1'},
];

const ToxflucovOptions = [
	{ value: 'HF acid', label: 'HF acid'},
	{ value: 'H2S', label: 'H2S'},
	{ value: 'Ammonia', label: 'Ammonia'},
	{ value: 'Chlorine', label: 'Chlorine'},
	{ value: 'Aluminium chloride', label: 'Aluminium chloride'},
	{ value: 'Carbon monoxide', label: 'Carbon monoxide'},
	{ value: 'Hydrogen chloride', label: 'Hydrogen chloride'},
	{ value: 'Nitric acid', label: 'Nitric acid'},
	{ value: 'Nitrogen dioxide', label: 'Nitrogen dioxide'},
	{ value: 'Phosgene', label: 'Phosgene'},
	{ value: 'TDI', label: 'TDI'},
	{ value: 'EE', label: 'EE'},
	{ value: 'Propylene oxide', label: 'Propylene oxide'},
	{ value: 'None', label: 'None'},
];

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        cursor: 'not-allowed',
    },
};


  return (
    <div id="print_layout" ref={componentRef}>
	
	<div style={styles.overlay} ></div>
	
	<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`}
				style={{ width: '150px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', marginTop: '0px', marginRight: '15px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		  
		<div style={{ clear: 'both' }}></div>
	
      <div
        
        style={{ padding: "20px", marginLeft: '-20px'}}
      >
		<div style={{marginLeft: '10px'}}>
			<u>
			  <h2>RBI Calculator</h2>
			</u>
			<h5 style={{ marginTop: "-10px" }}>Based API 580</h5>
		</div>
		
		<div class="row">
			
			<div className="card col-4" style={{ margin: "20px 5px 5px 5px" }}>
			  <div
				className="card-header"
				style={{ backgroundColor: "#d3d3d3", color: "black" }}
			  >
				<h5 style={{ textAlign: "left" }}>Result</h5>
			  </div>
			  <div
				className="card-body"
				style={{ backgroundColor: "#646464", color: "white" }}
			  >
				{loading ? (
				  <p>Loading data...</p>
				) : riskMatrix ? (
				  <>
					<p>
					  <strong>Risk Matrix:</strong> {riskMatrix}
					</p>
					<p>
					  <strong>Risk Level:</strong> {riskLevel}
					</p>
				  </>
				) : (
				  <p>Data tidak ditemukan.</p>
				)}
			  </div>
			</div>
			
			<div className="card col-4" style={{ margin: "20px 5px 5px 5px", border: 'none' }}>
				
				{loading ? (
				  
					<a href="./">
						<img 
							src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/awal risk.png`}
							style={{ width: '150px', marginLeft: '-10px' }} 
						/>
					</a>
				  
				) : riskMatrix ? (
				  
				  <>
					{riskMatrix === "1A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "1B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "1C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskMatrix === "1D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "1E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "2A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "2B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "2C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskMatrix === "2D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "2E" && (
					  
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "3A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "3B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "3C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskMatrix === "3D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "3E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "4A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "4B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "4C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskMatrix === "4D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "4E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "5A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "5B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskMatrix === "5C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskMatrix === "5D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskMatrix === "5E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
				  </>
				  
				) : (
				  <p>Data tidak ditemukan.</p>
				)}
				
			</div>
			
		</div>
		
      </div>
	  
	  <div class="row">
		
			<div style={{float: 'left', width: '90%'}}>
				
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				Basic Data
			</button>

			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				Screening
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF Thinning Lining
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF External
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF SCC
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF Brittle Fracture
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF Fatigue
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				COF
			</button>
				
			</div>
			
			<div style={{float: 'left', width: '10%'}}>
			
				<div className="col-12">
					<button style={{ marginTop: '10px', float: 'right' }} className="btn btn-success" type="submit">Submit</button>
				</div>
			
			</div>
			
		</div>
	  
	  <hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
	  <h5 style={{backgroundColor: '#d3d3d3', textAlign: 'center', width: '20%', margin: '0% 39% 50px 39%', padding: '15px'}}>Basic Data</h5>
	  
	  {/*Inisialisasi*/}
	  <div class="card" style={{margin: '20px 5px 5px 5px', width: '650px' }}>
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Inisialisasi</h5>
				</div>
						
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2" style={{textAlign: 'center'}}>
								
							
						<div className="col-12">
									
							<input
								style={{marginRight: '2px'}}
								type="checkbox"
								checked={selectedPWHT}
								
							/>
							PWHT
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedLining}
								
							/>
							Lining
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedCoating}
								
							/>
							Coating
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedCladding}
								
							/>
							Cladding
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedHeattracing}
								
							/>
							Heat Tracing
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedInsulation}
								
							/>
							Insulation
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedDatainspeksi}
								
							/>
							Data Inspeksi
							
						</div>
							
								
					</div>
				
				</div>
				
			</div>
		{/*end Inisialisasi*/}
		
		
		{/*Basic Data*/}
		
		<div class="row g-3">
			
			{/*start identifier*/}
			<div class="col-lg-4 col-md-3 mb-2">
				<div style={{margin: '5px'}} class="card">
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Identifier</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Tag Number
							</div>
							
							<div className="col-7">
								
								<input
										className="form-control"
										type="text"
										name="tag_number"
										value={formData2.tag_number}
										required
									/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Piping Service
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="piping_service"
									value={formData2.piping_service}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Location From
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="location_from"
									value={formData2.location_from}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Location To
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="location_to"
									value={formData2.location_to}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="installation_date"
									value={formData2.installation_date}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Inspection Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Inspectiondate"
									value={formData2.Inspectiondate}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Inspection Date (complete)
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="date"
									name="Inspectiondatecomplete"
									value={formData2.Inspectiondatecomplete}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Position
							</div>
							
							<div className="col-7">
								
								<Select
									options={PositionOptions}
									placeholder="Position"
									value={selectedPosition}
									required
								>
								</Select>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Equipment Type
							</div>
							
							<div className="col-7">
								
								<Select
									options={EquipmenttypeOptions}
									placeholder="Equipment Type"
									value={selectedEquipmenttype}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Component Type
							</div>
							
							<div className="col-7">
								
								<Select
									options={ComponenttypeOptions}
									placeholder="Component Type"
									value={selectedComponenttype}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Geometry Type
							</div>
							
							<div className="col-7">
								
								<Select
									options={GeometrytypeOptions}
									placeholder="Geometry Type"
									value={selectedGeometrytype}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								P&ID Number
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="PIDNumber"
									value={formData2.PIDNumber}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Thickness Actual
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Thicknessactual"
									value={formData2.Thicknessactual}
									disabled={!selectedDatainspeksi}
								/>
								
							</div>
							
						</div>
						
					</div>
					
				</div>
				
				{/*start Corrosion Data*/}
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Corrosion Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Allowance
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosionallowance"
									value={formData2.Corrosionallowance}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Probe
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosionprobe"
									value={formData2.Corrosionprobe}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Coupon
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosioncoupon"
									value={formData2.Corrosioncoupon}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			{/*end Corrosion Data*/}
				
			</div>
			{/*end identifier*/}
		
			{/*start Construction Design Data*/}
			<div class="col-lg-4 col-md-4 mb-2">
				<div style={{margin: '5px', height: '100%'}} class="card">
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Construction Design Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Coating Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="date"
									name="coating_installation_date"
									value={formData2.coating_installation_date}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Material Construction
							</div>
							
							<div className="col-7">
								
								<Select
									options={materialOptions}
									placeholder="Material Construction"
									value={selectedMaterial}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Grade
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="grade"
									value={formData2.grade}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Design Code
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Designcode"
									value={formData2.Designcode}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Outside Diameter
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Outsidediameter"
									value={formData2.Outsidediameter}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Length
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Length"
									value={formData2.Length}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Nominal Thickness
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Nominalthickness"
									value={formData2.Nominalthickness}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Remaining Life of the Cladding
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Remaininglifeofthecladding"
									value={formData2.Remaininglifeofthecladding}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Rate Cladding
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Corrosionratecladding"
									value={formData2.Corrosionratecladding}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Weld Joint Efficiency
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Weldjointefficiency"
									value={formData2.Weldjointefficiency}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Yield Strength
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Yieldstrength"
									value={formData2.Yieldstrength}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Tensile Strength
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Tensilestrength"
									value={formData2.Tensilestrength}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Allowable Stress
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Allowablestress"
									value={formData2.Allowablestress}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Coating Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Coatinginstallationdate"
									value={formData2.Coatinginstallationdate}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Previous Inspection Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Previousinspectiondate"
									value={formData2.Previousinspectiondate}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Previous Thickness Reading
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Previousthicknessreading"
									value={formData2.Previousthicknessreading}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			</div>
			{/*end Construction Design Data*/}
			
			{/*start Operation Data*/}
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Operation Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Design Pressure
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Designpressure"
									value={formData2.Designpressure}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Design Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Designtemperature"
									value={formData2.Designtemperature}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Operating Pressure
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Operatingpressure"
									value={formData2.Operatingpressure}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Operating Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Operatingtemperature"
									value={formData2.Operatingtemperature}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Minimum Design Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Minimumdesigntemperature"
									value={formData2.Minimumdesigntemperature}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Upset Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Upsettemperature"
									value={formData2.Upsettemperature}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			</div>
			{/*end Operation Data*/}
			
			</div>
		
		{/*end Basic Data*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		<h5 style={{backgroundColor: '#d3d3d3', textAlign: 'center', width: '20%', margin: '20px 39% 50px 39%', padding: '15px'}}>Screening</h5>
		
		{/*start Screening*/}
		
			<div class="col-lg-5 col-md-5 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Year of Fabrication Material
							</div>
										
							<div className="col-7">
											
								<Select
									options={yearoffabricationmaterialOptions}
									placeholder="Year of Fabrication Material"
									value={selectedyearoffabricationmaterial}
									required
								>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Representative Fluid
							</div>
										
							<div className="col-7">
											
								<Select
									options={RepresentativefluidOptions}
									placeholder="Representative Fluid"
									value={selectedRepresentativefluid}
									required
								/>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid Phase
							</div>
										
							<div className="col-7">
											
								<Select
									options={FluidphaseOptions}
									placeholder="Fluid Phase"
									value={selectedFluidphase}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid at Normal Operating Conditions
							</div>
										
							<div className="col-7">
											
								<Select
									options={GasliquidOptions}
									placeholder="Fluid at Normal Operating Conditions"
									value={selectedFluidatnormaloperatingconditions}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid at Ambient Conditions
							</div>
										
							<div className="col-7">
											
								<Select
									options={GasliquidOptions}
									placeholder="Fluid at Ambient Conditions"
									value={selectedFluidatambientconditions}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Number of Past Inspection
							</div>
										
							<div className="col-7">
											
								<input
									className="form-control"
									type="number"
									name="Numberofpastinspection"
									value={formData2.Numberofpastinspection}
									required
								/>
											
							</div>
							
						</div>
						
						
					</div>
				</div>
			</div>
			
			<div class="row">
			
			{/* What Is Contained the process ? */}
			
   
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px', height: '810px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What Is Contained the process ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Caustic (Presence of NaOH at Elevated Temperature)
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Caustic (Presence of NaOH at Elevated Temperature)"
									value={selectedCausticpresen}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Alkaline Water (pH > 7.5)
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Alkaline Water (pH > 7.5)"
									value={selectedAlkalinewater}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Oil With Sulfur Compounds
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Oil With Sulfur Compounds"
									value={selectedOilwithsulfurcompounds}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chlorides {"<"}50 ppm
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Chlorides <50 ppm"
									value={selectedChloridesppm}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Free Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Free Water"
									value={selectedFreewater}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂S and Hydrogen
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="H₂S and Hydrogen"
									value={selectedH2Sandhydrogen}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Oxygen
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Oxygen"
									value={selectedOxygen}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂S
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="H₂S"
									value={selectedH2S}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								HCl
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="HCl"
									value={selectedHCl}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂SO₄
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="H₂SO₄"
									value={selectedH2SO4}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								HF
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="HF"
									value={selectedHF}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2 div">
										
							<div className="col-7">
								CO₂
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="CO₂"
									value={selectedCO2}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
				<div class="card" style={{margin: '5px', height: '610px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What Is Exposed To ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Mist Overspray from Cooling Towers
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Mist Overspray from Cooling Towers"
									value={selectedMistoverspray}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Steam Vents
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Steam Vents"
									value={selectedSteamvents}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Deluge Systems
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Deluge Systems"
									value={selectedDelugesystems}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Containing Fluids, Mists, and Solids
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Chloride Containing Fluids, Mists, and Solids"
									value={selectedChloridecontaining}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Bearing Compounds
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Sulfur Bearing Compounds"
									value={selectedSulfurbearing}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chlorides And Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Chlorides And Water"
									value={selectedChlorideswndwater}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Hydrofluoric Acid
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Hydrofluoric Acid"
									value={selectedHydrofluoricacid}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Acid Gas Treating Amines
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Acid Gas Treating Amines"
									value={selectedAcidgastreatingamines}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Soil
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Soil"
									value={selectedSoil}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
			</div>
			
			{/* */}
			
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				
				{/*Area */}
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Area</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-5">
								Ft2
							</div>
										
							<div className="col-7">
											
								<input
									className="form-control"
									type="number"
									name="Areaft2"
									value={formData2.Areaft2}
									required
								/>
											
							</div>
							
						</div>
					
					</div>
				</div>
				{/*end Area*/}
				
				{/*Data Confidence Damage State*/}
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Data Confidence Damage State</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-3">
								State 1
							</div>
										
							<div className="col-9">
											
								<Select
									options={Dataconfidencedamagestate123Options}
									placeholder="Data Confidence Damage State 1"
									value={selectedDataconfidencedamagestate1}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								State 2
							</div>
										
							<div className="col-9">
											
								<Select
									options={Dataconfidencedamagestate123Options}
									placeholder="Data Confidence Damage State 2"
									value={selectedDataconfidencedamagestate2}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								State 3
							</div>
										
							<div className="col-9">
											
								<Select
									options={Dataconfidencedamagestate123Options}
									placeholder="Data Confidence Damage State 3"
									value={selectedDataconfidencedamagestate3}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
					
					</div>
				</div>
				
				{/*end Data Confidence Damage State*/}
				
				{/*start Material Grade Alloy (For  DF Polythionic Acid)*/}
				
				<div class="card" style={{margin: '5px', height: '450px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Material Grade Alloy (For  DF Polythionic Acid)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								All regular 300 series Stainless Steels and Alloys 600 and 800 
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="All regular 300 series Stainless Steels and Alloys 600 and 800 "
									value={selectedAllregular300}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H Grade 300 series SS
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="H Grade 300 series SS"
									value={selectedHGrade300}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								L Grade 300 series SS 
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="L Grade 300 series SS"
									value={selectedLGrade300}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								321 Stainless Steel 
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="321 Stainless Steel"
									value={selectedStainlesssteel321}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								347 Stainless Steel, Alloy 20, Alloy 625, All austenitic weld overlay 
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="347 Stainless Steel, Alloy 20, Alloy 625, All austenitic weld overlay"
									value={selectedStainlesssteel347}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				{/*end Material Grade Alloy (For  DF Polythionic Acid)*/}
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Other Conditions ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Hydrogen Partial Pressure P > 50 psia
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Hydrogen Partial Pressure P > 50 psia"
									value={selectedHydrogenpartial}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Subject to Process Spills, Ingress of Moisture, or Acid Vapors
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Subject to Process Spills, Ingress of Moisture, or Acid Vapors"
									value={selectedSubjecttoprocess}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Not Normally Operate Between 10 {"<"} TO {"<"} 350 °F, but Cool or Heat
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Not Normally Operate Between 10 < TO < 350 °F, but Cool or Heat"
									value={selectedNotnormally}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								With Deteriorated Coating and/or Wrapping
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="With Deteriorated Coating and/or Wrapping"
									value={selectedWithdeteriorated}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Penetrations and Visually Damage Insulation Areas
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Penetrations and Visually Damage Insulation Areas"
									value={selectedPenetrationsandvisually}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								MDMT or MAT is Unknown or The Component is Known to Operate at or Below or Under Normal or Upset Conditions
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="MDMT or MAT is Unknown or The Component is Known to Operate at or Below or Under Normal or Upset Conditions"
									value={selectedMDMTorMAT}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Have Been Past Fatigue Failures or There is Visible/Audible Shaking in This Piping
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Have Been Past Fatigue Failures or There is Visible/Audible Shaking in This Piping"
									value={selectedHavebeenpast}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								pH {"<"} 7.0
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="pH < 7.0"
									value={selectedPh7}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Inspection</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection A ( NAThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionA"
									value={formData2.InspectionA}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection B ( NBThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionB"
									value={formData2.InspectionB}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection C ( NCThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionC"
									value={formData2.InspectionC}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection D ( NDThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionD"
									value={formData2.InspectionD}
									required
								/>
							
							</div>
							
						</div>
						
					
					</div>
				</div>
				
				{/*start What is The Operating Temparature ?*/}
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What is The Operating Temparature ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								10 {"<"} T {"<"} 350 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="10 < T < 350 °F"
									value={selectedTF350}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								120 {"<"} T {"<"} 300 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="120 < T < 300 °F"
									value={selectedTF300}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 100 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="T > 100 °F"
									value={selectedT100}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 350 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="T > 350 °F"
									value={selectedT350}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								650{"<"} T {"<"} 1070 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="650< T < 1070 °F"
									value={selectedT1070}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								700 {"<"} T {"<"} 1050 °C
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="700 < T < 1050 °C"
									value={selectedT1050}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								1100 {"<"} T {"<"} 1700 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="1100 < T < 1700 °F"
									value={selectedT1700}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 400 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="T > 400 °F"
									value={selectedT400}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T ≥ 900 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="T ≥ 900 °F"
									value={selectedT900}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				{/*end What is The Operating Temparature ?*/}
				
				
			</div>
			</div>
		
		{/*end Screening*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		<h5 style={{backgroundColor: '#d3d3d3', textAlign: 'center', width: '20%', margin: '20px 39% 50px 39%', padding: '15px'}}>DF Thinning Lining</h5>
		
		{/*start DF Thinning Lining*/}
		
		<div class="col-lg-5 col-md-5 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Applicable
							</div>
										
							<div className="col-7">
											
								<Select
									options={YesnoOptions}
									placeholder="Applicable"
									value={selectedApplicable}
									required
								>
							</Select>
											
							</div>
							
						</div>
					
					</div>
				</div>
			</div>
			
			
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Thinning</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 1)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS1"
									value={formData2.ReliabilityindicesDS1}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 2)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS2"
									value={formData2.ReliabilityindicesDS2}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 3)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS3"
									value={formData2.ReliabilityindicesDS3}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVΔt)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVAT"
									value={formData2.ReliabilityindicesCOVAT}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVSf)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVSf"
									value={formData2.ReliabilityindicesCOVSf}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVP)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVP"
									value={formData2.ReliabilityindicesCOVP}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Inspection Effectiveness (For Tank Bottom)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Category
							</div>
										
							<div className="col-5">
							
								<Select
									options={InspectioneffectivenesscategoryOptions}
									placeholder="Injection/Mix Points - Contain"
									value={selectedInspectioneffectivenesscategory}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Injection/Mix Points</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Contain
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Injection/Mix Points - Contain"
									value={selectedInjectionmixpoints}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Deadleg</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Contain
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Deadleg - Contain"
									value={selectedDeadlegcontain}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Welded Construction (Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Welded
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnowithnaOptions}
									placeholder="Deadleg - Contain"
									value={selectedWeldedconstructionwelded}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Maintenance API STD 563 (Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Maintained
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnowithnaOptions}
									placeholder="Maintenance API STD 563 (Applicable only for AST) - Maintained"
									value={selectedMaintenanceAPISTD563Maintained}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Settlement(Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={SettlementcriteriaOptions}
									placeholder="Settlement(Applicable only for AST) - Criteria"
									value={selectedSettlementcriteria}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>On-Line Monitoring</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Thinning Mechanism
							</div>
										
							<div className="col-5">
							
								<Select
									options={OnlinemonitoringThinningmechanismOptions}
									placeholder="On-Line Monitoring - Thinning Mechanism"
									value={selectedOnlinemonitoringThinningmechanism}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Monitoring Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={OnlinemonitoringMonitoringtypeOptions}
									placeholder="On-Line Monitoring - Monitoring Type"
									value={selectedOnlinemonitoringMonitoringtype}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>COMPONENT LINING</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Lining Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={LiningtypeOptions}
									placeholder="Lining Type"
									value={selectedLiningtype}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Age of Lining or Years Since Last Thorough Visual Inspection
							</div>
										
							<div className="col-5">
							
								<Select
									options={AgeofLiningoryearageOptions}
									placeholder="Age of Lining or Years Since Last Thorough Visual Inspection"
									value={selectedAgeofLiningoryearage}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Inorganic Linings - Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={InorganicliningstypeOptions}
									placeholder="Inorganic Linings - Type"
									value={selectedInorganicliningstype}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Organic Linings - Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={OrgaliningstypeOptions}
									placeholder="Organic Linings - Type"
									value={selectedOrgaliningstype}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Lining Condition - Qualitative Condition
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Lining Condition - Qualitative Condition"
									value={selectedLiningconditionqualitativecondition}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On-Line Monitoring  - Contain
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="On-Line Monitoring - Contain"
									value={selectedMonitoringcontain}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			</div>
		
		{/*end DF Thinning Lining*/}

		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		<h5 style={{backgroundColor: '#d3d3d3', textAlign: 'center', width: '20%', margin: '20px 39% 50px 39%', padding: '15px'}}>DF External</h5>
		
		{/*start DF External*/}
		
		<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External Ferritic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver - Arid / Dry
							</div>
										
							<div className="col-5">
							
								<Select
									options={ExtDriverOptions}
									placeholder="Driver"
									value={selectedExtDriver}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Equipment Design or Fabrication
							</div>
										
							<div className="col-5">
							
								<Select
								options={YesnoOptions}
								placeholder="Equipment Design or Fabrication"
								value={selectedEquipmentDesignorFabrication}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Interface
							</div>
										
							<div className="col-5">
							
								<Select
								options={YesnoOptions}
								placeholder="Interface"
								value={selectedInterface}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Coating Quality
							</div>
										
							<div className="col-5">
							
								<Select
								options={CoatingQualityOptions}
								placeholder="Coating Quality"
								value={selectedCoatingQuality}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Coating Quality
							</div>
										
							<div className="col-5">
							
								<Select
								options={CoatingQualityOptions}
								placeholder="Coating Quality"
								value={selectedCoatingQuality}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External CLSCC Ferritic</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									options={EcfDriveOptions}
									placeholder="External CLSCC Ferritic - Drive"
									value={selectedEcfDrive}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={EcfCrackingOptions}
									placeholder="External CLSCC Ferritic - Cracking"
									value={selectedEcfCracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspection Performed
							</div>
										
							<div className="col-5">
							
								<Select
									options={EcfPerformedOptions}
									value={selectedEcfInspectionPerformed}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
			
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CUI Ferritic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									options={CfcDriverOptions}
									placeholder="Driver"
									value={selectedCfcDriver}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Insulation Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={CfcInsulationTypeOptions}
									placeholder="Insulation Type"
									value={selectedInsulationType}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Complexity
							</div>
										
							<div className="col-5">
							
								<Select
									options={ComplexityOptions}
									placeholder="Complexity"
									value={selectedComplexity}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Insulation Condition
							</div>
										
							<div className="col-5">
							
								<Select
									options={InsulationConditionOptions}
									placeholder="Insulation Condition"
									value={selectedInsulationCondition}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Design or Fabrication
							</div>
										
							<div className="col-5">
							
								<Select
									options={CfcDesignorFabricationOptions}
									placeholder="CUI Ferritic Component - Design or Fabrication"
									value={selectedCfcDesignorFabrication}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Interface Enters Soil or Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={CfcInterfacewaterOptions}
									placeholder="Interface Enters Soil or Water"
									value={selectedInterfaceenterssoilorwater}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External CLSCC CUI</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									options={EcfDrivecuiOptions}
									placeholder="External CLSCC CUI - Drive"
									value={selectedEcfDrivecui}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={CrackingcuiOptions}
									placeholder="External CLSCC CUI - Cracking"
									value={selectedEcfCrackingcui}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Free Insulation
							</div>
										
							<div className="col-5">
							
								<Select
									options={ChloridefreecuiOptions}
									placeholder="External CLSCC CUI - Chloride Free Insulation"
									value={selectedChloridefreecui}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspection Performed
							</div>
										
							<div className="col-5">
							
								<Select
									options={PerformedcuiOptions}
									value={selectedInspectionPerformedcui}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
			
				
			</div>
			
			
			</div>
		
		{/*end DF External*/}

		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		<h5 style={{backgroundColor: '#d3d3d3', textAlign: 'center', width: '20%', margin: '20px 39% 50px 39%', padding: '15px'}}>DF SCC</h5>
		
		{/*start DF SCC*/}
		
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Caustic Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Determine Susceptibility for Cracking
							</div>
										
							<div className="col-5">
							
								<Select
								options={DetermineSusceptibilityOptions}
								value={selectedDetermineSusceptibility}
								required
							>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									options={CRInspectionsOptions}
									value={selectedCRNumberofInspections}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Amine Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Determine Susceptibility for Cracking
							</div>
										
							<div className="col-5">
							
								<Select
								options={ACDetermineSusceptibilityOptions}
								value={selectedACDetermineSusceptibility}
								required
							>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									options={ACInspectionsOptions}
									value={selectedACNumberofInspections}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Alkaline Carbonate</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={AcephwaterOptions}
									value={selectedAcephwater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								CO3 Concentration
							</div>
										
							<div className="col-5">
							
								<Select
									options={AceCO3Options}
									placeholder="Alkaline Carbonate - CO3 Concentration"
									value={selectedAceCO3}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={AcecrackingOptions}
									placeholder="ALkaline Carbonate - Cracking"
									value={selectedAcecracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									options={AcefohtOptions}
									placeholder="ALkaline Carbonate - Function of Heat Treatment"
									value={selectedAcefoht}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
			
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Polythionic Acid</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={PacrackingOptions}
									placeholder="Polythionic Acid - Cracking"
									value={selectedPACracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Material
							</div>
										
							<div className="col-5">
							
								<Select
									options={PamaterialOptions}
									placeholder="Polythionic Acid - Material"
									value={selectedPamaterial}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Sulfide Stress Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={SscphwaterOptions}
									value={selectedSscphwater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of H2S Content of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={SscfunctionhwaterOptions}
									value={selectedSscfunctionh2swater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cyanides
							</div>
										
							<div className="col-5">
							
								<Select
									options={SsccyanidesOptions}
									placeholder="Sulfide Stress Cracking - Cyanides"
									value={selectedSsccyanides}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									options={SscfunctiontreatmentOptions}
									placeholder="Sulfide Stress Cracking - Function of Heat Treatment"
									value={selectedSscfunctiontreatment}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Max Brinnell Hardness
							</div>
										
							<div className="col-5">
							
								<Select
									options={SscmaxbrinnellOptions}
									placeholder="Sulfide Stress Cracking - Max Brinnell Hardness"
									value={selectedSscmaxbrinnell}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={SsccrackingOptions}
									placeholder="Sulfide Stress Cracking - Cracking"
									value={selectedSsccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									options={SCCInspectionsOptions}
									value={selectedSCCNumberofInspections}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Chloride SCC</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Operating Temperature (°F)
							</div>
										
							<div className="col-5">
							
								<Select
									options={CsccoperatingtemperatureOptions}
									placeholder="Chloride SCC - Operating Temperature (°F)"
									value={selectedCsccoperatingtemperature}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Ion
							</div>
										
							<div className="col-5">
							
								<Select
									options={CsccchlorideOptions}
									placeholder="Chloride SCC - Chloride Ion"
									value={selectedCsccchloride}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={CscrackingOptions}
									placeholder="Chloride SCC - Cracking"
									value={selectedCscccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Hydrogen Stress Cracking HF</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Condition
							</div>
										
							<div className="col-5">
							
								<Select
									options={HscconditionOptions}
									placeholder="Hydrogen Stress Cracking HF - Condition"
									value={selectedHsccondition}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Max Brinnell Hardness
							</div>
										
							<div className="col-5">
							
								<Select
									options={HscmaxbrinnellOptions}
									placeholder="Hydrogen Stress Cracking HF - Max Brinnell Hardness"
									value={selectedHscmaxbrinnell}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={HsccrackingOptions}
									placeholder="Hydrogen Stress Cracking HF - Cracking"
									value={selectedHsccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
						
						
					</div>
				</div>
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>HIC/SOHIC-H2S</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicphwaterOptions}
									value={selectedShicphwater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of H2S Content of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicfunctionwaterOptions}
									value={selectedShicfunctionh2swater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cyanides
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShiccyanidesOptions}
									placeholder="HIC/SOHIC-H2S - Cyanides"
									value={selectedShiccyanides}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShiccrackingOptions}
									placeholder="HIC/SOHIC-H2S - Cracking"
									value={selectedShiccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicfunctiontreatmentOptions}
									placeholder="HIC/SOHIC-H2S - Function of Heat Treatment"
									value={selectedShicfunctiontreatment}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Content
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicsulfurcontentOptions}
									placeholder="HIC/SOHIC-H2S - Sulfur Content"
									value={selectedShicsulfurcontent}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicInspectionsOptions}
									value={selectedShicNumberofInspections}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On-Line Monitoring - Monitoring Method
							</div>
										
							<div className="col-5">
							
								<Select
									options={HicmonitoringmethodOptions}
									placeholder="HIC/SOHIC-H2S - On-Line Monitoring - Monitoring Method"
									value={selectedHicmonitoringmethod}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>HIC/SOHIC HF</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={HicsohiccrackingOptions}
									placeholder="HIC/SOHIC HF - Cracking"
									value={selectedHicsohiccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Content
							</div>
										
							<div className="col-5">
							
								<Select
									options={HicsohicsulfurcontentOptions}
									placeholder="HIC/SOHIC HF - Sulfur Content"
									value={selectedHicsohicsulfurcontent}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On Line Monitoring - Adjustment
							</div>
										
							<div className="col-5">
							
								<Select
									options={HicsohicadjustmentOptions}
									placeholder="HIC/SOHIC HF - Sulfur Content"
									value={selectedHicsohicadjustment}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
						
						
					</div>
				</div>
			
			</div>
			
			</div>
		
		{/*end DF SCC*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		<h5 style={{backgroundColor: '#d3d3d3', textAlign: 'center', width: '20%', margin: '20px 39% 50px 39%', padding: '15px'}}>DF Brittle Fracture</h5>
		
		{/*start DF Brittle Fracture*/}
		
		<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>High Temperature Hydrogen Attack</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								HTHA Damage - Condition
							</div>
										
							<div className="col-5">
							
								<Select
									options={HthadamageconditionOptions}
									placeholder="HIGH TEMPERATURE HYDROGEN ATTACK - HTHA Damage - Condition"
									value={selectedHthadamagecondition}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Brittle Fracture</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Administrative or Process Control"
									value={selectedAdmprocon}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								ASME Exemption - Curve
							</div>
										
							<div className="col-5">
							
								<Select
									options={BfasmeexcurveOptions}
									placeholder="Brittle Fracture - ASME Exemption - Curve"
									value={selectedBfasmeexcurve}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Low Alloy Steel Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									options={LaseaorproconOptions}
									placeholder="LOW ALLOY STEEL EMBRITTLEMENT - Administrative or Process Control"
									value={selectedLaseaorprocon}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								ASME Exemption - Curve
							</div>
										
							<div className="col-5">
							
								<Select
									options={LasasmecurveOptions}
									placeholder="LOW ALLOY STEEL EMBRITTLEMENT - ASME Exemption - Curve"
									value={selectedLasasmecurve}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Sigma Phase Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Sigma Content
							</div>
										
							<div className="col-5">
							
								<Select
									options={SigmaembcontentOptions}
									placeholder="Sigma Phase Embrittlement - Sigma Content"
									value={selectedSigmaembcontent}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>885  °F Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									options={EmbaopcOptions}
									placeholder="885  °F Embrittlement - Administrative or Process Control"
									value={selectedEmbaopc}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reference Temperature - Tref
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="Embreftem"
									value={formData2.Embreftem}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
			</div>
			
		</div>
		
		{/*end DF DF Brittle Fracture*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		
		<h5 style={{backgroundColor: '#d3d3d3', textAlign: 'center', width: '20%', margin: '20px 39% 50px 39%', padding: '15px'}}>DF Fatigue</h5>
		
		{/*start DF Fatigue*/}
		
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Previous Failure</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={PrefacriteriaOptions}
									placeholder="Previous Failure - Criteria"
									value={selectedPrefacriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Amount of Visible/Audible Shaking ot Audible Noise</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={PrefadfbpffatigueOptions}
									placeholder="Amount of Visible/Audible Shaking ot Audible Noise - Criteria"
									value={selectedPrefadfbpf}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Visible/Audible Shaking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={VisshcriteriaOptions}
									placeholder="Previous Failure - Criteria"
									value={selectedVisshcriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Type of Cyclic Loading</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={TocycriteriaOptions}
									placeholder="Type of Cyclic Loading - Criteria"
									value={selectedTocycriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Corrective Action</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdcocriteriaOptions}
									placeholder="Adjustment Corrective Action - Criteria"
									value={selectedAdcocriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Pipe Complexity</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdpicriteriaOptions}
									placeholder="Adjustment Pipe Complexity - Criteria"
									value={selectedAdpicriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Condition of Pipe</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdcoicriteriaOptions}
									placeholder="Adjustment Condition of Pipe - Criteria"
									value={selectedAdcoicriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Joint Type or Branch Design</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdjotcriteriaOptions}
									placeholder="Adjustment Joint Type or Branch Design - Criteria"
									value={selectedAdjotcriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Branch Diameter</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdbrdicriteriaOptions}
									placeholder="Adjustment Branch Diameter - Criteria"
									value={selectedAdbrdicriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		
		{/*end DF Fatigue*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		<h5 style={{backgroundColor: '#d3d3d3', textAlign: 'center', width: '20%', margin: '20px 39% 50px 39%', padding: '15px'}}>COF</h5>
		
		{/*start COF*/}
		
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Hole Size</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								1
							</div>
										
							<div className="col-8">
							
								<Select
									options={Holesize1234Options}
									placeholder="Hole Size 1"
									value={selectedHolesize1}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								2
							</div>
										
							<div className="col-8">
							
								<Select
									options={Holesize1234Options}
									placeholder="Hole Size 2"
									value={selectedHolesize2}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								3
							</div>
										
							<div className="col-8">
							
								<Select
									options={Holesize1234Options}
									placeholder="Hole Size 3"
									value={selectedHolesize3}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								4
							</div>
										
							<div className="col-8">
							
								<Select
									options={Holesize1234Options}
									placeholder="Hole Size 4"
									value={selectedHolesize4}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Atmospheric Pressure</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Patm
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Atmosprepatm"
									value={formData2.Atmosprepatm}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Conversion Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								C2 (lbs)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Confac2"
									value={formData2.Confac2}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Detection System</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									options={DetsystyOptions}
									placeholder="Detection System - Type"
									value={selectedDetsysty}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Isolation System</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									options={IsosystypeOptions}
									placeholder="Isolation System - Type"
									value={selectedIsosysty}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Total Leak Durations</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-5">
								4 (minutes)
							</div>
										
							<div className="col-7">
							
								<input
									className="form-control"
									type="number"
									name="Toledur"
									value={formData2.Toledur}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Consequence Area Mitigation Reduction Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								factmit
							</div>
										
							<div className="col-8">
							
								<Select
									options={ConarmitOptions}
									placeholder="Consequence Area Mitigation Reduction Factor - factmit"
									value={selectedConarmit}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Environmental Costs</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								envcost (($/bbl))
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Encosen"
									value={formData2.Encosen}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Fluid Type</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									options={FluidtypecofOptions}
									placeholder="Fluid Type"
									value={selectedFluidtypecof}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Toxic Percentage of the Toxic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								mfractox
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Toxperthetoxcom"
									value={formData2.Toxperthetoxcom}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Toxic Fluid</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Toxic Fluid
							</div>
										
							<div className="col-8">
							
								<Select
									options={ToxflucovOptions}
									placeholder="Toxic Fluid"
									value={selectedToxflucov}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Acids or Caustics</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								5 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus5"
									value={formData2.Acidorcaus5}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								6 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus6"
									value={formData2.Acidorcaus6}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								7 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus7"
									value={formData2.Acidorcaus7}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								8 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus8"
									value={formData2.Acidorcaus8}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Material Cost Factors</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								matcost
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Matcosfacmat"
									value={formData2.Matcosfacmat}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Equipment Cost Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								equipcost ($/ft2)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Eqcosfac"
									value={formData2.Eqcosfac}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Equipment Outage Multiplier</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Equipment Outage Multiplier
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Eqoutmul"
									value={formData2.Eqoutmul}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Blending Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 1
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac1"
									value={formData2.Blenfac1}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 2
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac2"
									value={formData2.Blenfac2}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 3
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac3"
									value={formData2.Blenfac3}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 4
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac4"
									value={formData2.Blenfac4}
									required
								/>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CA for Steam or Acid Leaks Non-Flammablem Non-Toxic Personnel Injury</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 1
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon1"
									value={formData2.Caforstoracleaknon1}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 2
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon2"
									value={formData2.Caforstoracleaknon2}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 3
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon3"
									value={formData2.Caforstoracleaknon3}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 4
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon4"
									value={formData2.Caforstoracleaknon4}
									required
								/>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CA for Steam or Acid Leaks Non-Flammablem Non-Toxic Personnel Injury</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmdnfnt (ft2)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Finoflnotoxcon"
									value={formData2.Finoflnotoxcon}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Production Cost</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								prodcost ($/days)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Procoxprod"
									value={formData2.Procoxprod}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			</div>
		
		{/*start COF*/}
		
		<div style={{backgroundColor: '#d3d3d3', width: '100%', textAlign: 'center'}}>
			ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @RIM SHG 2024
		</div>
		
    </div>
  );
};

export default RBIprint;
