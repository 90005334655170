import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Listuser_lrv = () => {
  const [users, setUsers] = useState([]);

  // Fetch data pengguna dari API
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://alive.my.id/restapialive/api/users', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Pastikan token ada di localStorage
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Hapus pengguna berdasarkan ID
  const deleteUser = async (id) => {
    try {
      await axios.delete(`https://alive.my.id/restapialive/api/user/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Sertakan token autentikasi
        },
      });
      // Setelah menghapus, fetch ulang daftar pengguna
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <div>
      <h1>Daftar Pengguna</h1>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name} - {user.email}
            <button onClick={() => deleteUser(user.id)}>Hapus</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Listuser_lrv;
