import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const Editfirebasedata = () => {
  const [email, setEmail] = useState('');
  const [entitas, setEntitas] = useState('');
  const [message, setMessage] = useState('');

  const documentId = 'ariodw44@gmail.com';

  // Fetch data when the component is mounted
  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "alive_allowedemail", documentId);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setEmail(data.email);
          setEntitas(data.entitas);
        } else {
          setMessage('No such document!');
        }
      } catch (error) {
        setMessage('Error fetching data: ' + error.message);
      }
    };

    fetchData();
  }, [documentId]);

  const handleUpdate = async () => {
    const docRef = doc(db, "alive_allowedemail", documentId);

    try {
      await updateDoc(docRef, {
        email: email,
        entitas: entitas
      });
      setMessage('Data updated successfully');
    } catch (error) {
      setMessage('Error updating data: ' + error.message);
    }
  };

  return (
    <div>
      <h2>Edit Firebase Data</h2>
      <div>
        <label>Email:</label>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
      </div>
      <div>
        <label>Entitas:</label>
        <input 
          type="text" 
          value={entitas} 
          onChange={(e) => setEntitas(e.target.value)} 
        />
      </div>
      <button onClick={handleUpdate}>Update Data</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Editfirebasedata;
