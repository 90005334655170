import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'chosen-js/chosen.css';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Rimox = () => {
	
	const [userData, setUserData] = useState(null);
	const [token, setToken] = useState(null);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const isLargeScreen = window.matchMedia("(min-width: 1493px)").matches;

  useEffect(() => {
	 
	
	const rimox_prm = document.getElementById('prm_rimox').innerHTML;
	
	const fetchData = async () => {
      try {
       
		onAuthStateChanged(auth, async (user) => {
		  if (user) {
			const userEmail = user.email;
			const docRef = doc(db, 'alive_allowedemail', userEmail);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setUserData(docSnap.data());
			} else {
			  
				const storedToken = localStorage.getItem('token');
				setToken(storedToken);

				if (storedToken) {
					
					const response = await fetchUserData();
					setUserData(response.data);
					
				}else{
					window.location.href = "/alive_react/login";
				}
			  
			}
		  }
		});
		
	   
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();
	
	let stasiun_awal = "";
    
	if (rimox_prm === "rbi" || rimox_prm === "fmeca") {
		stasiun_awal = "Grissik";
	} else if (rimox_prm === "virtual") {
		stasiun_awal = "Tambak Lorok";
	}

	
    fetch(`https://script.google.com/macros/s/AKfycbzAom_qz_T_3beNWLXpxtbVXQLS2EddWDbLeHrx9pNyc7-liXJlpQYf4ozuvVChcvj7/exec?sn=${rimox_prm.toUpperCase()}&rj=yes&stasiun=${stasiun_awal}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            setData(data[0]);

            const elementIds = ["5A", "5B", "5C", "5D", "5E", "4A", "4B", "4C", "4D", "4E", "3A", "3B", "3C", "3D", "3E", "2A", "2B", "2C", "2D", "2E", "1A", "1B", "1C", "1D", "1E"];

            let samping1 = 0;
            let samping2 = 0;
            let samping3 = 0;
            let samping4 = 0;
            let samping5 = 0;

            elementIds.forEach((id) => {
                const element = document.getElementById(id);
                if (element) {
                    const cekdata = parseFloat(data[0][id]);
					
                    if (cekdata > 0) {
                        element.innerHTML = cekdata;
                    } else {
                        element.innerHTML = "<div></div>";
                    }

                    if (["5E", "5D", "5C", "4E", "4D", "3E"].includes(id)) {
                        samping1 += cekdata;
                    } else if (["5B", "4C", "3D", "2E"].includes(id)) {
                        samping2 += cekdata;
                    } else if (["5A", "4B", "3B", "3C", "2C", "2D", "1E"].includes(id)) {
                        samping3 += cekdata;
                    } else if (["2B", "1D"].includes(id)) {
                        samping4 += cekdata;
                    } else if (["3A", "2A", "1A", "1B", "1C"].includes(id)) {
                        samping5 += cekdata;
                    }
                }
            });

            if (document.getElementById("samping1")) {
                document.getElementById("samping1").innerHTML = samping1 > 0 ? samping1 : "<div></div>";
            }
            if (document.getElementById("samping2")) {
                document.getElementById("samping2").innerHTML = samping2 > 0 ? samping2 : "<div></div>";
            }
            if (document.getElementById("samping3")) {
                document.getElementById("samping3").innerHTML = samping3 > 0 ? samping3 : "<div></div>";
            }
            if (document.getElementById("samping4")) {
                document.getElementById("samping4").innerHTML = samping4 > 0 ? samping4 : "<div></div>";
            }
            if (document.getElementById("samping5")) {
                document.getElementById("samping5").innerHTML = samping5 > 0 ? samping5 : "<div></div>";
            }

            setLoading(false);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            setError(error);
            setLoading(false);
        });
		
		var xhr = new XMLHttpRequest();
		xhr.onreadystatechange = function() {
		  if (xhr.readyState === 4 && xhr.status === 200) {
			var jsonData = JSON.parse(xhr.responseText);
			var tbody = document.querySelector('#tbl_detail tbody');
			tbody.innerHTML = '';

			for (var i = 0; i < jsonData.length; i++) {
			  var row = tbody.insertRow();
			  var cellNo = row.insertCell(0);
			  var cellTagNumber = row.insertCell(1);
			  var cellEquipmentName = row.insertCell(2);
			  var cellRiskCategory = row.insertCell(3);
			  var cellRiskLevel = row.insertCell(4);
			  var cellRemainingLife = row.insertCell(5);
			  var cellNextInspection = row.insertCell(6);

			  cellNo.innerHTML = i + 1;
			  cellTagNumber.innerHTML = jsonData[i].tag_number;
			  cellEquipmentName.innerHTML = jsonData[i].equipment_name;
			  
			  cellRiskCategory.innerHTML = jsonData[i].risk_category;
			  cellRiskLevel.innerHTML = jsonData[i].risk_level;
			  cellRemainingLife.innerHTML = jsonData[i].remaining_life;
			  cellNextInspection.innerHTML = jsonData[i].next_inspection;
			}
		  }
		};
		xhr.open('GET', `https://script.google.com/macros/s/AKfycbzAom_qz_T_3beNWLXpxtbVXQLS2EddWDbLeHrx9pNyc7-liXJlpQYf4ozuvVChcvj7/exec?stasiun=${stasiun_awal}&sn=${rimox_prm.toUpperCase()}`, true);
		xhr.send();
		
		var xhr = new XMLHttpRequest();

		function checkAndUpdateTable() {
			var tbody = document.querySelector('#tbl_detail tbody');

			if (!tbody) {
				setTimeout(checkAndUpdateTable, 1000);
			} else {
				xhr.onreadystatechange = function() {
					if (xhr.readyState === 4 && xhr.status === 200) {
						var jsonData = JSON.parse(xhr.responseText);

						tbody.innerHTML = '';

						if (jsonData.length === 0) {
							setTimeout(checkAndUpdateTable, 1000);
						} else {
							for (var i = 0; i < jsonData.length; i++) {
								var row = tbody.insertRow();
								var cellNo = row.insertCell(0);
								var cellTagNumber = row.insertCell(1);
								var cellEquipmentName = row.insertCell(2);
								var cellRiskCategory = row.insertCell(3);
								var cellRiskLevel = row.insertCell(4);
								var cellRemainingLife = row.insertCell(5);
								var cellNextInspection = row.insertCell(6);

								cellNo.innerHTML = i + 1;
								cellTagNumber.innerHTML = jsonData[i].tag_number;
								cellEquipmentName.innerHTML = jsonData[i].equipment_name;
								cellRiskCategory.innerHTML = jsonData[i].risk_category;
								cellRiskLevel.innerHTML = jsonData[i].risk_level;
								cellRemainingLife.innerHTML = jsonData[i].remaining_life;
								cellNextInspection.innerHTML = jsonData[i].next_inspection;
							}
						}
					}
				};
				xhr.open('GET', `https://script.google.com/macros/s/AKfycbzAom_qz_T_3beNWLXpxtbVXQLS2EddWDbLeHrx9pNyc7-liXJlpQYf4ozuvVChcvj7/exec?stasiun=${stasiun_awal}&sn=${rimox_prm.toUpperCase()}`, true);
				xhr.send();
			}
		}

		checkAndUpdateTable();
		
		
		
}, []);

const handleSetUrl = () => {
    const urlAutodesk = document.getElementById('valcad').innerHTML;

    localStorage.setItem('Urlautodesk', urlAutodesk);

    const newWindow = window.open('/view3d', '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.focus();
    }
  };

  if (loading) {
    return '';
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }
	
	const styles = {
		
		styleifrcad2: {
		  //...(isLargeScreen && {
			
			width: '100%', 
			height: '100%', 
			marginTop: '-55px',
			
			
		  //})
		},
		
		styleifrcad3: {
		  //...(isLargeScreen && {
			
			width: '100%', 
			height: '100%', 
			marginTop: '-55px',
			
			
		  //})
		},
		
		gooddayfreeze_biar_gak_ngantuk: {
			position: 'sticky',
			top: '0', 
			background: 'black',
			color: 'white'
		 }
		
		
	};
	
	const detail_rbi = (status) => {
		
		if(status == "open"){
			document.getElementById('hlm2').style.display="block";
			document.getElementById('hlm1').style.display="none";
			document.getElementById('detailrbi').style.display="none";
		}else if(status == "close"){
			var valcad = document.getElementById('valcad').innerHTML;
			document.getElementById('iframecad2').src = valcad;
			document.getElementById('hlm2').style.display="none";
			document.getElementById('hlm1').style.display="block";
			document.getElementById('detailrbi').style.display="block";
		}
		
	};
	
	const fullcad = (status) => {
	
		if(status == "open"){
			var valcad = document.getElementById('valcad').innerHTML;
			document.getElementById('iframecad3').src = valcad;
			document.getElementById('hlm3').style.display="block";
			document.getElementById('hlm1').style.display="none";
		}else if(status == "close"){
			var valcad = document.getElementById('valcad').innerHTML;
			document.getElementById('iframecad2').src = valcad;
			document.getElementById('hlm3').style.display="none";
			document.getElementById('hlm1').style.display="block";
		}
		
	};
	
	return (
		
		<div style={{display: 'block', marginTop: '20px', minWidth: '900px'}}>
		<div id="valcad" style={{display: 'none'}}></div>
			<div id="hlm1">
				<div style={{float: 'left', width: '550px'}}>
					<table>
						
						<tbody>
						<tr>
							<td colSpan="7" align="center"><b><div id="judul_stasiun_matrix">GRISSIK</div></b></td>
						</tr>
						<tr>
							<td>
								<table className="table_matrix_all">
								  <tbody>
									<tr>
									  <td></td>
									  <td style={{fontSize: '10px'}}></td>
									  <td colSpan="2" valign="bottom" style={{fontSize: '12px', border: '1px solid white', height: '10px', width: '60px', textAlign: 'left'}}><b>Risk Matrix</b></td>
									  <td style={{fontSize: '10px', border: '1px solid white', height: '10px', width: '60px', textAlign: 'center'}}></td>
									  <td style={{fontSize: '10px', border: '1px solid white', height: '10px', width: '60px', textAlign: 'center'}}></td>
									  <td style={{fontSize: '10px', border: '1px solid white', height: '10px', width: '60px', textAlign: 'center'}}></td>
									</tr>
									<tr>
									 
									  <td rowSpan="5" style={{padding: '0' }}>
										<div className="rotated-text" style={{transform: 'rotate(-90deg)', whiteSpace: 'nowrap', width: '20px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
											<b>Probability of Failure (PoF)</b>
										</div>
									  </td>
									  <td align="right" style={{fontSize: '10px', width: '40px'}}><b>5</b></td>
									  <td style={{fontSize: '10px', background: '#ffff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="5A"></div></td>
									  <td style={{fontSize: '10px', background: '#ffa500', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="5B"></div></td>
									  <td style={{fontSize: '10px', background: '#ff1000', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="5C"></div></td>
									  <td style={{fontSize: '10px', background: '#ff1000', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="5D"></div></td>
									  <td style={{fontSize: '10px', background: '#ff1000', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="5E"></div></td>
									</tr>
									<tr>
									  <td align="right" style={{fontSize: '10px'}}><b>4</b></td>
									  <td style={{fontSize: '10px', background: '#55ff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="4A"></div></td>
									  <td style={{fontSize: '10px', background: '#ffff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="4B"></div></td>
									  <td style={{fontSize: '10px', background: '#ffa500', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="4C"></div></td>
									  <td style={{fontSize: '10px', background: '#ff1000', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="4D"></div></td>
									  <td style={{fontSize: '10px', background: '#ff1000', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="4E"></div></td>
									</tr>
									<tr>
									  <td align="right" style={{fontSize: '10px'}}><b>3</b></td>
									  <td style={{fontSize: '10px', background: '#27a227', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="3A"></div></td>
									  <td style={{fontSize: '10px', background: '#ffff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="3B"></div></td>
									  <td style={{fontSize: '10px', background: '#ffff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="3C"></div></td>
									  <td style={{fontSize: '10px', background: '#ffa500', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="3D"></div></td>
									  <td style={{fontSize: '10px', background: '#ff1000', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="3E"></div></td>
									</tr>
									<tr>
									  <td align="right" style={{fontSize: '10px'}}><b>2</b></td>
									  <td style={{fontSize: '10px', background: '#27a227', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="2A"></div></td>
									  <td style={{fontSize: '10px', background: '#55ff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="2B"></div></td>
									  <td style={{fontSize: '10px', background: '#ffff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="2C"></div></td>
									  <td style={{fontSize: '10px', background: '#ffff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="2D"></div></td>
									  <td style={{fontSize: '10px', background: '#ffa500', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="2E"></div></td>
									</tr>
									<tr>
									  <td align="right" style={{fontSize: '10px'}}><b>1</b></td>
									  <td style={{fontSize: '10px', background: '#27a227', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="1A"></div></td>
									  <td style={{fontSize: '10px', background: '#27a227', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="1B"></div></td>
									  <td style={{fontSize: '10px', background: '#27a227', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="1C"></div></td>
									  <td style={{fontSize: '10px', background: '#55ff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="1D"></div></td>
									  <td style={{fontSize: '10px', background: '#ffff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="1E"></div></td>
									</tr>
									<tr style={{border: 'none'}}>
									  <td></td>
									  <td></td>
									  <td style={{fontSize: '10px', border: 'none', width: '50px', height: '50px', textAlign: 'center'}}><b>A</b></td>
									  <td style={{fontSize: '10px', border: 'none', width: '50px', height: '50px', textAlign: 'center'}}><b>B</b></td>
									  <td style={{fontSize: '10px', border: 'none', width: '50px', height: '50px', textAlign: 'center'}}><b>C</b></td>
									  <td style={{fontSize: '10px', border: 'none', width: '50px', height: '50px', textAlign: 'center'}}><b>D</b></td>
									  <td style={{fontSize: '10px', border: 'none', width: '50px', height: '50px', textAlign: 'center'}}><b>E</b></td>
									</tr>
									<tr>
										<td style={{height: '50px'}} colSpan="7"><b style={{marginLeft: '120px'}}>Consequence of Failure (CoF)</b></td>
									</tr>
								  </tbody>
								</table>
							</td>
							
							<td>
								
								<table className="table_matrix_all">
								  <tbody>
									<tr>
									  <td valign="bottom" colSpan="2" style={{fontSize: '12px', border: '1px solid white', height: '10px', width: '60px', textAlign: 'left'}}><b>Risk Level</b></td>
									</tr>
									<tr>
									  <td style={{fontSize: '10px', background: '#ff1000', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="samping1"></div></td>
									  <td style={{fontSize: '10px', width: '90px'}}><b>High</b></td>
									</tr>
									<tr>
									  <td style={{fontSize: '10px', background: '#ffa500', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="samping2"></div></td>
									  <td style={{fontSize: '10px', width: '90px'}}><b>Moderate to High</b></td>
									</tr>
									<tr>
									  <td style={{fontSize: '10px', background: '#ffff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="samping3"></div></td>
									  <td style={{fontSize: '10px', width: '90px'}}><b>Moderate</b></td>
									</tr>
									<tr>
									  <td style={{fontSize: '10px', background: '#55ff00', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="samping4"></div></td>
									  <td style={{fontSize: '10px', width: '90px'}}><b>Low to Moderate</b></td>
									</tr>
									<tr>
									  <td style={{fontSize: '10px', background: '#27a227', border: '1px solid white', height: '60px', width: '60px', textAlign: 'center'}}><div id="samping5"></div></td>
									  <td style={{fontSize: '10px', width: '90px'}}><b>Low</b></td>
									</tr>
									<tr style={{border: 'none'}}>
									  <td style={{border: 'none', width: '50px', height: '50px', textAlign: 'center'}}></td>
									</tr>
									<tr>
										<td style={{height: '50px'}}></td>
									</tr>
								  </tbody>
								</table>
								
							</td>
							
						</tr>
						
						<tr>
							<td colSpan="7" style={{fontSize: '10px'}}>Reference :</td>
						</tr>
						
						<tr>
							<td colSpan="7" style={{fontSize: '10px'}}>
								<table>
									<tbody>
									<tr>
										<td style={{fontSize: '10px'}}>1.</td>
										<td style={{fontSize: '10px'}}><a target="_blank" style={{color: 'black'}} href="https://drive.google.com/file/d/1fB5B8nxKYLBnOEJwybrSeMK7a9ZCgNQX/view">API 580 2016</a></td>
									</tr>
									<tr>
										<td style={{fontSize: '10px'}}>2.</td>
										<td style={{fontSize: '10px'}}><a target="_blank" style={{color: 'black'}} href="https://drive.google.com/file/d/1MB0C2pO-_ZCBY3LUEBLn61N-4C8o3h16/view">P-004-A018-Pedoman Pengelolaan Resiko HSSE</a></td>
									</tr>
									<tr>
										<td colSpan="2">
											<button id="detailrbi" style={{background: '#000000', color: 'white', borderRadius: '5px'}} onClick={() => detail_rbi("open")}>Inspection Plan</button>
										</td>
									</tr>
									</tbody>
								</table>	
							</td>
						</tr>
						
						</tbody>
					</table>
				</div>
				
				<div style={{float: 'left', minWidth: '200px', width:'calc(100% - 550px)', height: '70vh', overflow: 'hidden'}} id="placehold2">
			
					<iframe style={styles.styleifrcad2} id="iframecad2" src="" title="Modelling" frameBorder="0"></iframe>
					
					<button style={{marginTop: '-125vh', marginLeft: 'calc(100% - 50px)'}} id="fulltam" className="btn" onClick={() => fullcad("open")}>
						<img style={{width: '25px', height: '25px'}} id="imgkosong" src="https://drive.google.com/thumbnail?id=1NiYLKiX70ZGHtvF_u1xUoumin2CPVl6Z&sz=w1000" />
					</button>
					
					<button style={{marginTop: '-131vh', marginLeft: 'calc(100% - 85px)'}} id="fulltam" className="btn" onClick={handleSetUrl}>
						<img style={{width: '25px', height: '25px'}} id="imgkosong" src={`${process.env.PUBLIC_URL}/gambar/panah4arah.png`} />
					</button>
					
					<img style={{ display: 'none', width:'100%', height: '65vh', overflow: 'hidden' }} id="imgkosong2" src="https://drive.google.com/thumbnail?id=10qzlFFbbgN7IiBkv-RFNiIgA3cf7hQHX&sz=w1000" />
					
					
				</div>
			</div>
			
			<div id="hlm2" style={{display: 'none', padding: '20px'}}>
				<button id="next2" style={{background: '#000000', color: 'white', borderRadius: '5px'}} onClick={() => detail_rbi("close")}>Back</button>
				
				<table id="tbl_detail" className="table table-bordered table-striped col-12 ms-auto" >
				  <thead style={styles.gooddayfreeze_biar_gak_ngantuk}>
					<tr>
					  <th>No</th>
					  <th>Tag Number</th>
					  <th>Equipment Name</th>
					  <th>Risk Category</th>
					  <th>Risk Level</th>
					  <th>Remaining Life</th>
					  <th>Next Inspection</th>
					</tr>
				  </thead>
				  <tbody>
					
				  </tbody>
				</table>
			</div>
			
			<div style={{display: 'none', width:'100%', height: '75vh', overflow: 'hidden'}} id="hlm3">
			
					<iframe style={styles.styleifrcad3} id="iframecad3" src="" title="Modelling" frameBorder="0"></iframe>
					
					<img style={{ display: 'none' }} id="imgkosong3" src="https://drive.google.com/thumbnail?id=10qzlFFbbgN7IiBkv-RFNiIgA3cf7hQHX&sz=w1000" />
					
					<button style={{marginTop: '-135vh', marginLeft: 'calc(100% - 50px)'}} id="fulltam" className="btn" onClick={() => fullcad("close")}>
						<i style={{fontSize: '20px'}} className="fa fa-window-close" aria-hidden="true"></i>
					</button>
					
				</div>
			
			
		
		</div>
		
	);
	
};

export default Rimox;