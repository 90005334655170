import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDJw394YW7jxmtN-rrqTUmePYqQzNR7cSs",
  authDomain: "my-alive.firebaseapp.com",
  projectId: "my-alive",
  storageBucket: "my-alive.appspot.com",
  messagingSenderId: "691540791457",
  appId: "1:691540791457:web:bf273f934b7994f28320e0"
};

// Inisialisasi Firebase
const app = initializeApp(firebaseConfig);

// Inisialisasi Auth dan Firestore
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
