import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from '../firebase'; // db seharusnya sudah diimpor dari konfigurasi firebase

const DataTable = () => {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");

  // Mendapatkan semua data dari Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "alive_allowedemail"));
        const userData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(userData);
      } catch (e) {
        setMessage(`Error fetching data: ${e.message}`);
      }
    };

    fetchData();
  }, []);

  // Fungsi untuk menghapus data berdasarkan ID dengan validasi konfirmasi
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Apakah Anda yakin ingin menghapus data ini?");
    
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "alive_allowedemail", id));
        setUsers(users.filter((user) => user.id !== id));
        setMessage(`Document with ID ${id} has been deleted.`);
      } catch (e) {
        setMessage(`Error deleting document: ${e.message}`);
      }
    }
  };

  return (
    <div>
      <h2>User Data</h2>
      {message && <p>{message}</p>}
      <table border="1">
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Entitas</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <tr key={user.id}>
                <td>{user.email}</td>
                <td>{user.name}</td>
                <td>{user.entitas}</td>
                <td>
                  <button onClick={() => handleDelete(user.id)}>Hapus</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
