import React, { useRef, useState, useEffect } from "react";
import Select from 'react-select';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";

const Muhlbaueronshoreprint = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [riskData, setRiskData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [jalurData, setJalurData] = useState([]);
  const [kpnameData, setKpnameData] = useState([]);
  const [selectedKpname, setSelectedKpname] = useState(null);
  const [selectedCrossing, setSelectedCrossing] = useState(null);
  const [selectedExposuretypethirdparty, setSelectedExposuretypethirdparty] = useState(null);
  const [selectedDepthofcovertype, setSelectedDepthofcovertype] = useState(null);
  const [selectedPipecasing, setSelectedPipecasing] = useState(null);
  const [selectedSignandmarkerrowcondition, setSelectedSignandmarkerrowcondition] = useState(null);
  const [selectedPatrolfrequency3rdparty, setSelectedPatrolfrequency3rdparty] = useState(null);
  const [selectedPubliceducation, setSelectedPubliceducation] = useState(null);
  const [selectedResistancetypethirdparty, setSelectedResistancetypethirdparty] = useState(null);
  const [selectedCleaningpigging, setSelectedCleaningpigging] = useState(null);
  const [selectedCoatingsystem, setSelectedCoatingsystem] = useState(null);
  const [selectedCathodicprotectionsystem, setSelectedCathodicprotectionsystem] = useState(null);
  const [selectedCrackpropagationpreventer, setSelectedCrackpropagationpreventer] = useState(null);
  const [selectedExposuretypegeohazard, setSelectedExposuretypegeohazard] = useState(null);
  const [selectedPipelinestabilization, setSelectedPipelinestabilization] = useState(null);
  const [selectedGroundreinforcement, setSelectedGroundreinforcement] = useState(null);
  const [selectedAvoidance, setSelectedAvoidance] = useState(null);
  const [selectedInspectionsurvey, setSelectedInspectionsurvey] = useState(null);
  const [selectedDitchmodification, setSelectedDitchmodification] = useState(null);
  const [selectedExposuretypeincorrectoperation, setSelectedExposuretypeincorrectoperation] = useState(null);
  const [selectedControlsafetysystem, setSelectedControlsafetysystem] = useState(null);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [selectedTrainingprogramonpipelineoperation, setSelectedTrainingprogramonpipelineoperation] = useState(null);
  const [selectedMechanicalpreventer, setSelectedMechanicalpreventer] = useState(null);
  const [selectedSubstanceabuse, setSelectedSubstanceabuse] = useState(null);
  const [selectedExposuretypesabotage, setSelectedExposuretypesabotage] = useState(null);
  const [selectedCommunitypartnering, setSelectedCommunitypartnering] = useState(null);
  const [selectedSecurity, setSelectedSecurity] = useState(null);
  const [selectedResolve, setSelectedResolve] = useState(null);
  const [selectedIndustrycooperation, setSelectedIndustrycooperation] = useState(null);
  const [selectedAccessability, setSelectedAccessability] = useState(null);
  const componentRef = useRef();

	const handleDownloadPDF = async () => {
  const input = componentRef.current;
  if (!input) return;

  try {
    // Tangkap elemen HTML sebagai gambar menggunakan html2canvas
    const canvas = await html2canvas(input, {
      scale: 2, // Meningkatkan kualitas gambar (opsional)
    });
    const imgData = canvas.toDataURL("image/png");

    // Dapatkan ukuran elemen yang akan dicetak
    const contentWidth = canvas.width;  // Lebar elemen dalam px
    const contentHeight = canvas.height; // Tinggi elemen dalam px

    // Buat PDF dengan dimensi sesuai elemen
    const pdf = new jsPDF({
      orientation: contentWidth > contentHeight ? "landscape" : "portrait", // Otomatis orientasi
      unit: "px",
      format: [contentWidth, contentHeight], // Dimensi PDF sesuai elemen
    });

    // Tambahkan gambar elemen ke PDF
    pdf.addImage(imgData, "PNG", 0, 0, contentWidth, contentHeight);

    // Simpan file PDF
    pdf.save("Muhlbauer_onshore_Result.pdf");
  } catch (error) {
    console.error("Gagal membuat PDF:", error);
  }
};

  const isPDFGenerated = useRef(false); // Gunakan useRef untuk menyimpan status

useEffect(() => {
  // Ambil data dari localStorage
  const storedData = JSON.parse(localStorage.getItem("printData"));

  if (storedData) {
    setRiskData(storedData.riskData);
    setFormData(storedData.formData);
    localStorage.removeItem("printData");

    // Pastikan PDF hanya dibuat satu kali
    if (!isPDFGenerated.current) {
      setTimeout(() => {
        handleDownloadPDF(); // Cetak PDF
        isPDFGenerated.current = true; // Tandai bahwa PDF sudah dibuat
		setTimeout(() => {
			
			window.close();
			
		}, 2000)
      }, 5000);
    }
  }
}, [navigate]); // Tambahkan navigate ke daftar dependensi untuk menghindari lint warning


  if (!riskData || !formData) return <div>Loading...</div>;

  // Ambil properti riskData
  const { cof, pof, risk_category } = riskData;
  const { 
		Jalur,
		Kpname, 
		Kpname2, 
		Namaasset, 
		Metalloss, 
		Asmeclasslocation, 
		Yearofconstruction, 
		Yearofinspection, 
		DesignlifeYear,
		Pipelinematerialgrade,
		Pipelineservice,
		Designpressure,
		Alignmentdepthofcover,
		Crossing,
		Exposuretypethirdparty,
		Depthofcovertype,
		Pipecasing,
		Signandmarkerrowcondition,
		Patrolfrequency3rdparty,
		Publiceducation,
		Resistancetypethirdparty,
		Cleaningpigging,
		Coatingsystem,
		Cathodicprotectionsystem,
		Crackpropagationpreventer,
		Exposuretypegeohazard,
		Pipelinestabilization,
		Groundreinforcement,
		Avoidance,
		Inspectionsurvey,
		Ditchmodification,
		Exposuretypeincorrectoperation,
		Controlsafetysystem,
		Procedure,
		Trainingprogramonpipelineoperation,
		Mechanicalpreventer,
		Substanceabuse,
		Exposuretypesabotage,
		Communitypartnering,
		Intelligence,
		Security,
		Resolve,
		Industrycooperation,
		Accessability,
		Gasunitprice,
		Heatcapacityratio,
		Dischargecoefficient,
		Molecularweightm,
		Idealgasconstant,
		Accelarationofgravity,
		Probabilityofignition,

  } = formData;
  
const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        cursor: 'not-allowed',
    },
};
	
  return (
    <div style={{marginTop: '30px',padding: '10px'}} ref={componentRef}>
		<div style={styles.overlay} ></div>
		
		<a href="./">
		  <img 
			src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
			alt="Alive Logo" 
			style={{ width: '150px', marginTop: '0px' }} 
		  />
		</a>
	  
      <img style={{ float: 'right', marginTop: '10px', marginRight: '15px' }} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
        
      <div style={{ clear: 'both' }}></div>
		
		<div class="row">
			<div class="card col-4" style={{margin: '20px 5px 5px 5px', border: 'none'}}>
				
				<div class="card-header" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
					<div style={{float: 'left'}}>
						<h5 style={{textAlign: 'left'}}>Result</h5>
					</div>
					
					
				</div>
						
				<div class="card-body" style={{backgroundColor: "#646464", color: 'white'}}>
						
					{riskData ? (
						<div>
							<p><strong>Risk Matrix:</strong> {pof}{cof}</p>
							<p><strong>Risk Level:</strong> {risk_category}</p>
						</div>
					) : (
						
						<p>Hasil Perhitungan dan Kalkulasi</p>
					)}
					
						
				</div>
				
			</div>
			
			
		</div>
    
	  
	  <div class="row">
		
		<div class="col-lg-4 col-md-4 mb-2">
				
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Parameter</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
							
						<div className="col-5">
							Jalur
						</div>
										
						<div className="col-7">
											
						 <Select
							placeholder="Pilih Jalur"
							value={{ value: Jalur, label: Jalur }}
						  />
											
						</div>
										
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							KP Name
						</div>
										
						<div className="col-7">
											
						<Select
							value={{ value: Kpname2, label: Kpname2 }}
						/>
											
						</div>
										
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Nama Asset
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Namaasset"
								value={formData.Namaasset}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Metal Loss
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Metalloss"
								value={formData.Metalloss}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Year of Construction
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Yearofconstruction"
								value={formData.Yearofconstruction}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Year of Inspection
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Yearofinspection"
								value={formData.Yearofinspection}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Design Life (Year)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="DesignlifeYear"
								value={formData.DesignlifeYear}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Pipeline Material Grade
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Pipelinematerialgrade"
								value={formData.Pipelinematerialgrade}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Pipeline Service
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Pipelineservice"
								value={formData.Pipelineservice}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Design Pressure
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Designpressure"
								value={formData.Designpressure}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Alignment Depth of Cover (m)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Alignmentdepthofcover"
								value={formData.Alignmentdepthofcover}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Crossing
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Crossing, label: Crossing }}
							  />
							
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Gas Unit Price (USD/MMBTU)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Gasunitprice"
								value={formData.Gasunitprice}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Heat Capacity Ratio (γ)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Heatcapacityratio"
								value={formData.Heatcapacityratio}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Discharge Coefficient (C0)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Dischargecoefficient"
								value={formData.Dischargecoefficient}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Molecular Weight M (lbm/lb-mol)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Molecularweightm"
								value={formData.Molecularweightm}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Ideal Gas Constant (ft lbf/lb-moloR)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Idealgasconstant"
								value={formData.Idealgasconstant}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Accelaration of gravity (ft lbm/lbf s2)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Accelarationofgravity"
								value={formData.Accelarationofgravity}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Probability of Ignition
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Probabilityofignition"
								value={formData.Probabilityofignition}
								required
							/>
								
						</div>
							
					</div>
					
				
				</div>
				
			
			</div>
		</div>
		
		<div class="col-lg-4 col-md-4 mb-2">
			
			<div>
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Third Party Damage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Exposure Type Third Party
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Exposuretypethirdparty, label: Exposuretypethirdparty }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Depth of Cover Type
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Depthofcovertype, label: Depthofcovertype }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Pipe Casing
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Pipecasing, label: Pipecasing }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Sign and Marker & ROW Condition
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Signandmarkerrowcondition, label: Signandmarkerrowcondition }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Patrol Frequency (3rd party)
						</div>
										
						<div className="col-7">
						
							<Select
								value={{ value: Patrolfrequency3rdparty, label: Patrolfrequency3rdparty }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Public Education
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Publiceducation, label: Publiceducation }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Resistance Type Third Party
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Resistancetypethirdparty, label: Resistancetypethirdparty }}
							/>
							
						</div>
							
					</div>
				
				</div>
			
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Time Dependent Failure Mechanism</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Cleaning Pigging
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Cleaningpigging, label: Cleaningpigging }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Coating System
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Coatingsystem, label: Coatingsystem }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Cathodic Protection System
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Cathodicprotectionsystem, label: Cathodicprotectionsystem }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Crack Propagation Preventer
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Crackpropagationpreventer, label: Crackpropagationpreventer }}
							/>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Geohazards Related Damage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Exposure Type Geohazard
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Exposuretypegeohazard, label: Exposuretypegeohazard }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Pipeline Stabilization
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Pipelinestabilization, label: Pipelinestabilization }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Ground Reinforcement
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Groundreinforcement, label: Groundreinforcement }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Avoidance
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Avoidance, label: Avoidance }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Inspection / Survey
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Inspectionsurvey, label: Inspectionsurvey }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Ditch Modification
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Ditchmodification, label: Ditchmodification }}
							/>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			</div>
		
		</div>
		
		<div class="col-lg-4 col-md-4 mb-2">
			
			<div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>In Correct Operations</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Exposure Type Incorrect Operation
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Exposuretypeincorrectoperation, label: Exposuretypeincorrectoperation }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Control & Safety System
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Controlsafetysystem, label: Controlsafetysystem }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Procedure
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Procedure, label: Procedure }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Training Program on Pipeline Operation
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Trainingprogramonpipelineoperation, label: Trainingprogramonpipelineoperation }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Mechanical Preventer
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Mechanicalpreventer, label: Mechanicalpreventer }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Substance Abuse
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Substanceabuse, label: Substanceabuse }}
							/>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Sabotage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							Exposure Type Sabotage
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Exposuretypesabotage, label: Exposuretypesabotage }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Community Partnering
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Communitypartnering, label: Communitypartnering }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Intelligent
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Intelligence"
								value={formData.Intelligence}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Security
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Security, label: Security }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Resolve
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Resolve, label: Resolve }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Industry Cooperation
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Industrycooperation, label: Industrycooperation }}
							/>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Accessability
						</div>
										
						<div className="col-7">
							
							<Select
								value={{ value: Accessability, label: Accessability }}
							/>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Consequence of Failure Calculation</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
							
						<div className="col-5">
							ASME Class Location
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="Asmeclasslocation"
								value={formData.Asmeclasslocation}
								required
							/>
								
						</div>
							
					</div>
					
					
				
				</div>
				
			</div>
			
			</div>
			
		</div>
		
		</div>
	  
	  <div style={{backgroundColor: '#d3d3d3', width: '100%', textAlign: 'center'}}>
			ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @RIM SHG 2024
		</div>
	  
    </div>
  );
};

export default Muhlbaueronshoreprint;
