// src/api.js
import axios from 'axios';

const API_URL = 'https://alive.my.id/restapialive3/api';

// Login user
//export const loginUser = (email, password) => {
  //return axios.post(`${API_URL}/login`, { email, password }, {
    //headers: { 'Content-Type': 'application/json' },
  //});
//};

// Fetch user data
export const fetchUserData = () => {
  const token = localStorage.getItem('token');
  return axios.post(`${API_URL}/user`, {}, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Logout user
export const logoutUser = async () => {
  const token = localStorage.getItem('token');
  return await axios.post(`${API_URL}/logout`, {}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
