import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Nativestock_sor3 = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	  var photostocklist = {
        
        GRESIK:{
            "1":"1MPZYu_BXP09vW1ezUVGwnhpQU4duYf5E",
            "2":"1Rp7Wm8YWbRXj6WJ-GuhIEsJ9F8aVXPzC",
            "3":"1zrruqLBIcafdW8a5G9BqekIWYy5fWaJ6",
            "4":"1iMw9lsydnwPiMQGVjCyDPU78j1PTrxSW",
            "5":"1dY4YQU80PNp0qxYJLmpSee7qJyE4au_m",
            "6":"1L9sZo3asQmCr9U_SQOkNXJqAt-yPl2KI",
        },
        
        TANDES:{
            "1":"1DWSds2TGPJAz2qG0KI2HzLt9PObYdi7C",
            "2":"1ZIeXVKiwcei-5enpno-HroPFHy3jjqgn",
            "3":"1mAINSmHTj2vgy5B6Mb01dL113l9UXTev",
            "4":"1p11--OIlp3qawvGle59sAKMf5i8h02yk",
            "5":"1ah0koHXJEBkgLCTaVk7oYZ3ObB383AL6",
            "6":"19lsJf2AvB_KN71gFGPzzEU-gw4-l8188",
            "7":"1Pil_7rmjrxqJfy5Jj2f_F3hFBoWc2mGB",
        },
        
        WARU:{
            "1":"1VNVq7e_HhTnm319FsOd5H-ZMvzQ_5k0p",
            "2":"1XeOLTI2kUiDWADRXdflOXKb4CV52BImm",
            "3":"1diEUrW1IsJY4p_0pe12hUxdFV9hSyWVv",
            "4":"1bP5qMmefv_U4krKwXU1UmBiIKZheoE1c",
            "5":"1CKrC7RVQCecsyUmtsP_Rx7LOvJMBHhXR",
        },
        
        KALISOGO:{
            "1":"1j5zJCCfeuyCadEIn-H7iU7uX771la3tC",
            "2":"1j5zJCCfeuyCadEIn-H7iU7uX771la3tC",
            "3":"1WC_MVLxEwtIcxY8ULGZ78iIBA1i-s6QC",
            "4":"1gahR86aA3_Yq9pDowO_D2lubcBoTuzAj",
            "5":"1jdNNsUQj-pLLn9YV3NIxs0Ap8cOQGWMj",
            "6":"1Do-kRCb0K28zW8KQh6GEjO-6sHlfPokK",
            "7":"1dS8iHeXE_ocPYwBIhWBJNdm8xh26EthH",
        },
        
        JAPANAN:{
            "1":"1UoS6oJlVtwhFqVYzz_HitJf1salio-3x",
            "2":"1UoS6oJlVtwhFqVYzz_HitJf1salio-3x",
            "3":"1furNM-y0aBU99psss_nlqR3gpx_tsmmX",
            "4":"1hR71cvB1a-6BbbuDGY_oNKeZiRk9-a0w",
            "5":"1jiWSzH9rh89osKg8_SA2GkjexMmi-G_Z",
            "6":"110iYO37y_R9geG7jqIBPk_Cx5yJyhubJ",
            "7":"1TsKKBJukyMiV5FmqkS31gUgy2OcEnF-D",
            "8":"14fdWuHk1-EsA40UTMncVvfEdBEAXLSRV",
        },
        
        NGORO:{
            "1":"11AA7CUnp-jpuZslFq6Xjz8RKPGltyDKC",
            "2":"15hzJpeUneb6BGZQ47fAOOMZLC0d1pW8w",
            "3":"16ix0ev9KKTsMXCasAD4kRGYZchwzbclr",
            "4":"1CuXrb1sZh_9v35fouHCBJpFVvmQYyPff",
            "5":"1E_h43lIsJYLHDlBZaAzfQaqFYiz_EfbX",
            "6":"1JilSIE8sGlKwUwY2-_X-m5EvDDyTFGN3",
            "7":"1KeserRpsLM3s85aPVfYGRRNRt4awSsDZ",
            "8":"1RqgWDJTg-G6zLlhkzrF5O_JvzXctGFi5",
            "9":"1TkZXTW265ZYTbkuaAMqu8MFk7fV49ven",
            "10":"1WK4CB_h3Kk-ZtwQMVKFuI97Y5Wt4Tykd",
            "11":"1frEcOaVY0H5jnhybXoUvaNJ5YaiaOm5i",
            "12":"1i5H6E7q0NVCeuesL4mB2iHXRQGkklJMW",
            "13":"1AcBOVSS6LDEJE9ap5QAh1LEs4YuMNdBZ",
            "14":"1GpHYLlSNaBn6HIGciRkEy5hPO_vrBvcI",
            "15":"1RuTINf5fzkVrXu6Gyi9sPqdUajtTctF2",
            "16":"1TIoqRAxwpPtcF_-WIcI1V780N0FwA51b",
            "17":"1TwEN9-WUtJlNRRbhrmAkyQeeFgX9hrs_",
            "18":"1WCwqYXc8u9UkzjtXDbBuuTvDZOy6Kxst",
            "19":"1YsnJ9PJsST8VyJx4iEDiSr0tqCHygAs2",
            "20":"1c7kHq-ZgyZTpN9VakGS5_oGaQC0xu5cN",
            "21":"1eOctjlKbLDK9fWB_PZJzYoS8kmi0N9JG",
            "22":"1eyOx2smKF6L_GhHnuIx-wy8clqBRgRuR",
            "23":"1ixJd4yVB0M8cey5KmgmEd1zVc54lIozX",
            "24":"11AFiSLmnJv7smREZMd3F3Uf3qKrxS4S9",
            "25":"1OhxsGEDCdKBH1UU4NLQn03_73eKy_Dv5",
            "26":"1SNZ1AyzFIklHEMl4tkY2pn1iQPwxVkZo",
            "27":"1Seum6BMnd0wJ-m1ZSIlsOmbjpA5yI0jP",
            "28":"1ThRQRXT4XPMAqFXNxiX72fEIJD1sofzh",
            "29":"1UKyq8Q0YHwudNpB_A8u_AnXdVLc8nbE1",
            "30":"1gpXxO95u4r1l5Qv6mIznH0RkbqrDtWd-",
            "31":"1zWzLX4ToGzNqHIuTWJM2mmtf_AEnS35i",
            "32":"1zmh_q0qQQ3cf60Xq-1n6XInjjxkaJUUe",
            "33":"1-frTLSyLP4uZZKXwTCispxIq28Gd1SVu",
            "34":"1QT6q2DCp1q71SAaeKbjCL3Iau4BmXSye",
            "35":"1EJYn4BHBJ0WYtnZENhSPMxq_ihOCFPXV",
        },
        
        PIER:{
            "1":"129OUIrANwRC5Zo951yBfCzdQ-iCAhMoF",
            "2":"132iSgp9wmSnoxbcuKgoCHDKk12C6VXPr",
            "3":"1JRACfapHOKAHodPY-DjTOmpXU_yY-f8g",
            "4":"14c1sfKUTAvDiA-AidkvgKitSFSm5sr-o",
            "5":"17KByagL3P2qQdbWOLJ3DtDSb4vhLPHss",
            "6":"18ksTaNXQCYqyMVg9WeJq1fc7afLYAdI5",
            "7":"1DAmJJn0Zz2OnnFTOTrFloq7vsALdqJyT",
            "8":"1IWniaIJYjqUPzzRCEd97ntN4WlqExRBq",
            "9":"1OXgeR7ZUOWT1DYyhRZlCHaIlYEhpNAmH",
            "10":"1XU7XXnJEpuhEdF-9u70S4V7vllxd3SrA",
            "11":"1db3Yyfdc_7diflHaeYIGSh2BAXuZIjtP",
            "12":"1eXScrygx_ZdU2gS_owcwZyrxMqo9SSUF",
            "13":"1lIFqGIq7Y8tHoV08fDKXU1JrBsVvgBE9",
            "14":"1nQLKldWD-nV72cPlMNPQDqiHEf5YTN0y",
            "15":"1oxhO9Fphkcg_v8NcStJ6jpH_zn_akqo4",
            "16":"1xbfYqZGURUOwL5wh0v_FnU3QYs6E2lH2",
            "17":"10TS5G3T6S_ODe5m15a-QRrbaOBA1jEaW",
            "18":"12t1Z5z5awXhSIsqImwTfIWsSs8n6FYpt",
            "19":"13G8VwtpHWZETKkPH1O7kL-IFoD6F2bxd",
            "20":"15QC9TwSyTPRAPrVboPrlGMShdkTXjOdY",
            "21":"16pf02Rzj_fjrpk5j2GwN_IvOPF-QdxcB",
            "22":"1C2RfQa7MpuDu7W3-gLXY2S0oX25loMPH",
            "23":"1C_ZXtjhEOzrOab2mBvqA-X4sP6PuVXU3",
            "24":"1ClEtIo26yLRzkCekEEsxvEyv6NrRVrHR",
            "25":"1DmJqbFP3ishyUsaa23czWk-Q2t5QDqlo",
            "26":"1RZCFzi-YvEHfAyJbGneP5FEmAxWohP_w",
            "27":"1TcLsClcGXG-Oo_c_Bxf5XzpYqhMAdDJP",
            "28":"1Vh54oqcWa0_V8IpeG1RTrwtggUjwEDcj",
            "29":"1dqRfCLHj9SDpvLmpjHe7OCcND-17Jwgw",
            "30":"1fdSF1q4Pnig7dMzm6nqUcx5lPYPo3RyO",
            "31":"1mQ-SBJprWT1PaTyja0OvySWIM6eNbhgD",
            "32":"1msVLj4KbBOLU8FqJeeIcRfY9_9UoIobj",
            "33":"1oaJIhCGsNkML59BiQ51pHU3Ne9veRnzK",
            "34":"1evRhzMRlQcz4hXjMWBkgGws7Z4-2ivjj",
            "35":"1FqugoFV_hSx-_8ym0DyMklTHvM7LOOGt",
        },
        
        SEMARE:{
            "1":"11ITOePfT6UEkre08zFV4TXMkRS3AHwbC",
            "2":"17UfCrjzp5bnJCPYm1_Ovf7I6l16z7UeJ",
            "3":"1HArQZg2hVTkUhP4ljxwl-igEBTL5zS6h",
            "4":"1IkIwYiCUKmTF0iLYpxcRq9QXuSRphk3m",
            "5":"1Joz2288FpFYhyiEQnzvQfQzZzHPOe40_",
            "6":"1KKcRnpVbEF_uD-O-HfF4rbrdSLtwbc62",
            "7":"1QroAK88ADDXaoyNI_iJdD6XooZ3is89U",
            "8":"1THrQOMbsstoYqt6MhlHBigSXjY2lzl1l",
            "9":"1gpjcGsUXICSbMOgw6nsJFU_uiJ-VRace",
            "10":"1kJ4vVP4r2oa9D_uPfndcXnVLQXtOUGlO",
            "11":"1mUPlUWiszFWMZH31uLqrupUlS-gIdJGm",
            "12":"1okN0XhuBe92grrEWw7PvlvzfSHqDPtk6",
            "13":"1uDWUL4XrJwrkF6EImRgrk5WRs2bYwflz",
            "14":"1ySlBRkvKDkn-kAE0NALtSdpVKNYNkqsd",
            "15":"10P0BO7n08oUN2aL-LyIWq1qEzK-84Bm_",
            "16":"1f2GMA4SBN7fV3uO1BRcL_GL8gZ52OvaL",
        },
        
    };
	
	 
 const photostockData = [
  { id: 'GRESIK', label: 'GRESIK' },
  { id: 'TANDES', label: 'TANDES' },
  { id: 'WARU', label: 'WARU' },
  { id: 'KALISOGO', label: 'KALISOGO' },
  { id: 'JAPANAN', label: 'JAPANAN' },
  { id: 'NGORO', label: 'NGORO' },
  { id: 'PIER', label: 'PIER' },
  { id: 'SEMARE', label: 'SEMARE' },
  { id: 'CIKANDE', label: 'CIKANDE' },
  { id: 'BITUNG', label: 'BITUNG' },
  { id: 'SERPONG', label: 'SERPONG' },
  { id: 'CIMANGGIS', label: 'CIMANGGIS' },
  { id: 'NAROGONG', label: 'NAROGONG' },
  { id: 'PONDOK_UNGU', label: 'PONDOK UNGU' },
  { id: 'TANGSI', label: 'TANGSI' },
  { id: 'DELTAMAS', label: 'DELTAMAS' },
  { id: 'BONGAS', label: 'BONGAS' }
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesNativeStock, setSelectedTablesNativeStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesNativeStockOptions, setTablesNativeStockOptions] = useState([
    { value: '', label: 'NativeStock' },
    
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'kosong', label: '2. Pertamina Gas' },
	{ value: 'nativestock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'nativestock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'nativestock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'nativestock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'nativestock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'nativestock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'nativestock_sor1', label: '16. PGN-SOR1' },
	{ value: 'nativestock_sor2', label: '17. PGN-SOR2' },
	{ value: 'nativestock_sor3', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesNativeStock) {
		
		if(selectedTablesNativeStock.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesNativeStock(null);
		}else{
			navigate("/"+selectedTablesNativeStock.value);
		}
	  
    }
  }, [selectedTablesNativeStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>	
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tnts" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesNativeStockOptions}
			  onChange={setSelectedTablesNativeStock}
			  placeholder="NativeStock"
			  value={selectedTablesNativeStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/homealive_home.png`}
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Nativestock_sor3;
