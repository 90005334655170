import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const STLViewer = () => {
  const mountRef = useRef(null);
  const cameraRef = useRef(null);
  const [tooltip, setTooltip] = useState("");
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [measurementPoints, setMeasurementPoints] = useState([]);
  const [helperLine, setHelperLine] = useState({ visible: false, x1: 0, y1: 0, x2: 0, y2: 0 });

  // Declare controls here so it's available in the entire component
  const controlsRef = useRef(null);

  useEffect(() => {
    // Setup scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 10000);
    cameraRef.current = camera;
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);

    scene.background = new THREE.Color(0xffffff);

    // Add light
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(1, 1, 1).normalize();
    scene.add(light);

    const ambientLight = new THREE.AmbientLight(0x404040, 2);
    scene.add(ambientLight);

    // Load objects
    const loader = new STLLoader();
    const objects = [];

    loader.load("stl/pipa_selongsong.stl", (geometry) => {
      const material = new THREE.MeshPhongMaterial({ color: 0xffff00 });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(0, 0, 0);
      mesh.name = "Pipa Selongsong";
      scene.add(mesh);
      objects.push(mesh);
    });

    loader.load("stl/elbo.stl", (geometry) => {
      const material = new THREE.MeshPhongMaterial({ color: 0xff0000 });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(200, 0, 0);
      mesh.name = "Elbo";
      scene.add(mesh);
      objects.push(mesh);
    });

    camera.position.z = 400;

    // Orbit controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableRotate = true;
    controls.enableZoom = true;
    controls.enablePan = true;

    // Store controls in the ref to access later
    controlsRef.current = controls;

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const onMouseClick = (event) => {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(objects);

      if (isMeasuring) {
        if (intersects.length > 0) {
          const point = intersects[0].point;

          setMeasurementPoints((prev) => {
            if (prev.length === 1) {
              const distance = prev[0].distanceTo(point);
              setTooltip(`Jarak: ${distance.toFixed(2)} meter`);

              setHelperLine({ visible: false, x1: 0, y1: 0, x2: 0, y2: 0 });
              return [];
            }

            const { clientX: x1, clientY: y1 } = event;
            setHelperLine({ visible: true, x1, y1, x2: x1, y2: y1 });

            return [point];
          });
        }
      }
    };

    const onMouseMove = (event) => {
      if (!isMeasuring || measurementPoints.length !== 1) return;

      const { clientX: x2, clientY: y2 } = event;
      setHelperLine((prev) => ({ ...prev, x2, y2 }));
    };

    window.addEventListener("click", onMouseClick);
    window.addEventListener("mousemove", onMouseMove);

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update(); // Update controls after changing camera position
      renderer.render(scene, camera);
    };
    animate();

    return () => {
      window.removeEventListener("click", onMouseClick);
      window.removeEventListener("mousemove", onMouseMove);

      if (renderer.domElement && mountRef.current?.contains(renderer.domElement)) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, [isMeasuring, measurementPoints]);

  // Fungsi untuk mengubah tampilan kamera ke sisi yang diinginkan
  const setCameraView = (view) => {
    const camera = cameraRef.current;

    switch (view) {
      case "top":
        camera.position.set(0, 400, 0);
        camera.lookAt(0, 0, 0);
        break;
      case "bottom":
        camera.position.set(0, -400, 0);
        camera.lookAt(0, 0, 0);
        break;
      case "left":
        camera.position.set(-400, 0, 0);
        camera.lookAt(0, 0, 0);
        break;
      case "right":
        camera.position.set(400, 0, 0);
        camera.lookAt(0, 0, 0);
        break;
      case "front":
        camera.position.set(0, 0, 400);
        camera.lookAt(0, 0, 0);
        break;
      case "back":
        camera.position.set(0, 0, -400);
        camera.lookAt(0, 0, 0);
        break;
      default:
        break;
    }

    // Ensure controls are updated after camera change
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div ref={mountRef} style={{ width: "100%", height: "100vh" }} />

      {/* Kubus kontrol untuk tampilan */}
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          zIndex: 1,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <button onClick={() => setCameraView("top")}>Top</button>
        <button onClick={() => setCameraView("bottom")}>Bottom</button>
        <button onClick={() => setCameraView("left")}>Left</button>
        <button onClick={() => setCameraView("right")}>Right</button>
        <button onClick={() => setCameraView("front")}>Front</button>
        <button onClick={() => setCameraView("back")}>Back</button>
      </div>

      {/* Tooltip untuk pengukuran */}
      {tooltip && (
        <div
          style={{
            position: "absolute",
            top: "150px",
            left: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            zIndex: 1,
          }}
        >
          {tooltip}
        </div>
      )}

      {/* Helper Line */}
      {helperLine.visible && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            pointerEvents: "none",
          }}
        >
          <svg
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <line
              x1={helperLine.x1}
              y1={helperLine.y1}
              x2={helperLine.x2}
              y2={helperLine.y2}
              stroke="blue"
              strokeWidth="2"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default STLViewer;
