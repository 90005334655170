import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_sswj = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var photostocklist = {
        
        kjg:{
            /**"1":"12WRnyXppNdLHKJ4fg5XycxmdEoBy6LFh",
            "2":"13gzKbeQFML_BeOXSHZK4cx72TwCuvTeQ",
            "3":"1H4A11rFZMg5FzO7fkmp3vsyJcOSONtUP",
            "4":"1Reqcakl-APaBxhvoJFtTxDtqhXYztLgW",
            "5":"1WIbbURgK8T7kvOnFgChCdF3RAOpRGDjA",
            "6":"1WNnyR-7FZHLYEAvsqd3D3L4VfxxFhdgF",
            "7":"1d3akIzt4n5gvTHAjr2TarTJfwqn6RU92",
            "8":"1mzQ4HG21YuVl2sIK_Mes4pWm4gqbzeuv",
            "9":"1vydCobJsP9UN-0PsYhy4_RzQ9aSq3gvM",
            "10":"1eiaUNqpBQ1OJOY32WF53U9Bb6Jolz6SO",
            "11":"1nE7mjOcQVjrbDjt9MGQ1iUB5ci29Uv3l",
            "12":"1tjQlIeiFpoFDxwXC97UglJifOIimHoia",
            "13":"14WUW6wbjYGLEKgXa3WplW07AvndqsxS6",
            "14":"1RgHawcKxDCvd8pGKdSURdJ4IQfEpF69I",
            "15":"1mbUZo2qVrfq7pbhuETEDGELa3GFR2TLQ",
            "16":"1pgSy-fvOOw9kqQ3N4LTyfBQKQO_8I8wH",
            "17":"19UnHcyGUfVCQaJCUNnQtHR9f8fkELW3B",
            "18":"1MqdFu_BA5sjS_5VOYJHR8y7zmAPsNcRY",
            "19":"1I3sZCy7qJydAx3hCGdcp0WOLTsxakO8E",
            "20":"13DKmUd_ccOUSrjyYRqLAhomYNv_st3R7",
            "21":"1Mpkqzb4sx5O1I7kCCOyA-kIR1Kidvq5X",
            "22":"1gKbHd5moo4M7w7AZJHDBF-sk5tvBDuks",
            "23":"19BP8GEGQJiksgHOyhiBWEPCIE4hDEnpC",
            "24":"1LTXySlTISzbzcp4aiELwRMn5sKcgPApt",
            "25":"1pWYPtIPTEbjAkPt26bxOqn5jJO97kJp-",
            "26":"1-FLU4IJQE07PcxC3ljztNgkyMWBo2mU2",
            "27":"1YDkbUkYdckoJFExDYNlBVXHamDtyZKqq",
            "28":"1CD77yJGt-gTdhwpsNjygQFVxumKIZJYy",
			*/
			"1":"1wGtJFQDmqIbA9rYRWUYYpYh2cPPL4UXr",
			"2":"1nAe91uQZrarFz8KzTNK7gbN1iiZXiygt",
			"3":"1X7EGtrJrL9yB2A3eF2ktHRzTTrW8bT7J",
			"4":"1q-B4bUl0BjFqj-7ajIzxRcWJYSDFqnPG",
			"5":"1fds0MqbjzQPV9xcTdKwm8o50OS4JfeL1",
			"6":"1IFo02lagupN-ZHlvFKQek_9wctkwdv7J",
			"7":"1FFBY4lBr4SWhgA9ODHxdEY7z3x0oPSBq",
			"8":"1gU2yTqZ9cgZJp-gcyBOiEAOmHUgpbauB",
			"9":"1PGXZUWdF4PpGR_44iApDOZHXe_7GHEkd",
			"10":"1yvdwfV952HaBp3wNo0IEhaaMjICsKznQ",
			"11":"1t38T5uzC0SqGGo90fIbhSqn5MGgydvsL",
			"12":"1mjGvTXY4Zfbl3kJXs_KhB3iv1cI1XB7c",
			"13":"1isg78TCkd7L4sLmva752c_fi6GcyZHYl",
			"14":"1Eph5xujqlnnNC98GmDzYOcTU7go_s3D3",
			"15":"1Wlu8Rcf3MS0jJ7mrLdYNvaepyz4iyzWk",
			"16":"1_sIXj_fQv-OYArf9WP16xFwQgjpcipZH",
			"17":"1EoHEGBWPwXL9ACw2E4rKYZx8PNNunq3x",
			"18":"16FlC4Taj8RHZ49YYcWZM14SwQ-NdOkms",
			"19":"1r76X8CEk4s692UInyWgmTWeNFJ-SPgV0",
			"20":"1YPyhQLXyx7gai4Aob4S0cKkmXOhYqGWc",
			"21":"1eMTMOtbD4jZ4GhmLWZp0I2-fwjf3ZtmY",
			"22":"1PL7WNAQ-xeIjTDQOf5y847hBj59spT2Z",
			"23":"1ZQ7MKSAcPphmo4pz9fN5sGZ-6qriWZ6X",
			"24":"1eQ3MwyopAgMYqGailQzb-tOojHeDgvJL",
			"25":"1LKJ2kx6iFS4g6qoLOW0N9BNeRcyFSsNt",
			"26":"1Sqj6a5XzPKtKUzC6KYl8vauJOZx207Td",
			"27":"1ZIJcfvkMFgEZa-Vx2uGuvDubruxhxY_2",
			"28":"1HItj5yu7U8HT3U1YcMiE04fJjeWn0jHC",
			"29":"18WeR0dPlzLB2b67TOr0bL6dLJ9CAOJ7G",
			"30":"1Rt1mBphyxHvrph68owBg3M3l8qY1OIh3",
			"31":"1adgPDDhZHK1207KTcml9w4-4Ff_H1R5b",
			"32":"1yfJ6-rayOYR9qLBX4mWiKawZn-d3SrtF",
			"33":"1YQZpn_7rBSaI1HOl-WlQRn_ZxxPe-DBX",
			"34":"1AyHiNq7bnZhz7iqZR99JGpLfYaTysw_8",
			"35":"1HCwgtGKi-0OFXn8H66REKzEYf8MZbaWf",
			"36":"19hhfeZmVMqb3IVWMA3QTW0rRAPB7v9AD",
			"37":"1EjymCivTzm4h5NYiK6kHKay8IxhzQLyf",
			"38":"1B1F_jiK8sMu6nVZRtxnMdrFgXqqh2jBM",
			"39":"1V9bACwDnXUNSYWWhbyv8cv5lNL6kHCPQ",
			"40":"1qMyq6YqwakHjMIb36LHmPWCOvnDUvlDU",
			"41":"1xGtMGmGc9m_dtpCoEOlD7wgkMXvAgW7f",
			"42":"1Pidrhgghgm5dl3wu5x-YNqUy1rTcHad1",
			"43":"1cX_7u77DBeq4k0jDMFEwTLFMnyUKCPpt",
			"44":"1SGY4ROMX4s71f3Jq38qMYywcsjJge75H",
			"45":"14weWHaCG3tZELIg8JpSZvfTqWetPoZc3",
			"46":"1_tVOXPRb7tv-WU2iuDQX_Pgx5FAE4hic",
			"47":"1DucZrYZa9xK-8DK22KFRwj70lLe1M81A",
			"48":"1G7Ym0jY_SIf-0WA8qyEu2ZhNFYr91K_1",
			"49":"1G-fUp7GsoyAObmpa5--P-6unR9X2zsRc",
			"50":"13uW7hsWvqfWHLPHevjkW5aJZLo9CqyNd",
			"51":"1j6kXg0N4gXf_MXR3zfKsrouzOR7PSglc",
			"52":"1tpfaINX_tx0lY6mL7zug8rtCnyV2_GlC",
			"53":"1DVTy8CGYYjCO58X0mjjUlE9Kx15gDYdX",
			"54":"1H4B3nFKgh0O1PY9NtMFbBJctg3EVKBNH",
			"55":"1SWCwTQ2yw00-6acumnako13ARwxXA7N_",
			"56":"1NbVYZwgyZMEUZ6lXaGdKzs4VLmizQySW",
			"57":"1F4JFUZtFSHKog0Id6kccs2EZcUww_FWY",
			"58":"10DQD4rBGNsZu6oMDzcSfIYFv_Mtrtj4u",
			"59":"1g_EoLUvOiFrewHaR3IiTsNl5tX_6QFWV",
			"60":"1IRU_CazXNzjeDeBjoIa-5XZ4ym-3-BU4",
			"61":"1Fq19N34jVPpyZBpWXGuK9VeBblmTuBiR",
			"62":"143ojQo-fktQssVnGqR2-NNcMys02zqz6",
			"63":"1YWD3hncN2m7vNZRglIKSUX-pXheNYD2-",
			"64":"1jMa66sgdw8-vBm4jTQ7bKFpMJDA4UuND",
			"65":"1YOJiuIt9EMESSAh8hGNldtEWnskEBO17",
			"66":"1170sAOxHZJp-L5pvQbszlFyF3rQZMJqe",
			"67":"1ZfUb7Zui1qhVHIZ1rsUNIvhlFGc9q0a7",
			"68":"1jTdp591me20UzKZBVQr1gjFmLHWt-F3v",
			"69":"1XZuFdfK3peNWFb0iFH6XvVXRPioOc586",
			"70":"1Lmbn8lxhEmV1JDfBWYEurjFqPmw-dNZX",
			"71":"1V6VUCw-6On9rw1u0LZ5VfafAOkQPt8DZ",
			"72":"1xm2Wajkte3DYVJZHqTIWhi2WFlwJvw2P",
			"73":"1A-9Y2CF28Zh7fIzoVzNSWuWAoIONBbAA",
			"74":"1vLcwX95_4txhpJiFEi5hS_sV62pWkqhh",
			"75":"1fvLr6RdOFpi85KXtL412M5YEBfyLqwfm",
			"76":"1MT7doWScjeliFfh3Z-iHs3OXpozrKiaF",
			"77":"1dcsouevMDQTGdkw5K_b_ONd3ldssF4kt",
			"78":"1PRvswnedIEFNUYRy3e4Mo5Lhb-QZs-eI",
			"79":"1kM42KoRKv_xGhc6MQXbq0M9e6Lfg1XRw",
			"80":"1d7--lRtL2TER-RFrr7rgAyvgAG7pIZFH",
			"81":"17JgQd34Ohi_4WmKmu_HzQEcXarx3A95_",
			"82":"1JhkYR7oIu5hmVIwXOhnztsEoHM2ztmSE",
			"83":"1JXi1e3eCP_4_HHLGQMrBRWLTGgWsBCHa",
			"84":"1YZ8gt6vDMwI8R4Kyhr3nAbRpnk_0OMQP",
			"85":"1cBq1Sl6aisMvOix9CDFGKhV7A8NclEU4",
			"86":"1Xkyucp2wSWAuexoma8wAc9SNNUM31HYN",
			"87":"1gnP9aJnxXvRpyNGgvNZBUDctQspwwW-F",
			"88":"1pQo3uMu_td9RUibHxHKNXDIVF--rqlYa",
			"89":"1KeNInOVOUTMyCyJGj6NWaRHemhmjXk_5",
			"90":"1FYOkWlCXmdkr8C2aOlMlCNSBYc3YEGMh",
			"91":"1H_M41VSP76P_mGUdcR0JKzxCPY1ZALq9",
			"92":"1BfVGxn-I7sMrp1x2JDTzGpUxUh9WDNAA",
			"93":"1wYLvQsVllONm-M4GejsVxIK9naLEd_7Z",
			"94":"1vZZ9JlEz8xt-SsRuyq6mcLGvfwPq2q2s",
			"95":"1LprmYI0dn16Bv_MtjSrkG02VzQ2DgKj3",
			"96":"15rbmOg9n-BXmBv4bmm56hu02crB4b0xA",
			"97":"1LEiIUR_5DqKUbE7hvdXur_pXaluDY-LN",
			"98":"1J-HtHM71kW_bI15oRhksI3WxTzK0dEMu",
			"99":"1wTrK-kbiGQHWhCnlFmfgveXt8LF6-nRg",
			"100":"1dW4FdolcOy2tOCwkp_GSoIV1rzj2smZY",
			"101":"117l12aghIktc0R4dIeD663EJEW2e7l4J",
			"102":"1Odr4gYgV6JocL27mI3ALL_pbBQ5G26XO",
			"103":"1xqedXCYPUijG72vSzspMXzeg0LN2BeKe",
			"104":"17CeX_tp3L_gqBh7FoGtHFgUY30rt48P7",
			"105":"1-207mP5TajakAC4wylM8rJ2EXkuNJKH4",
			"106":"1nThbfP0fJXsJqevBuzbGY44IQh2K-x1f",
			"107":"11cm7cB3a24UUDD19CwhIQT8PCeqHyHmx",
			"108":"1DLwXG0Kl24N4C5_WUdvaDOE7x0_wuEAB",
			"109":"1SPbbE2QcEZpXCwkhtu-iNlJYwV_iNnf7",
			"110":"1vsqpP3YFRXpOSMt3K4fJ8EubpH3zZuyc",
			"111":"1aI82dPifDt2EcUmhWl5vFLYawN5FzO8g",
			"112":"1_Q87Wj-4FBP8fZDx7ty-MvAgsvA58Z60",
			"113":"10-hVhXa5PUPnlQp2nJT7SqFjrS7zX-K_",
			"114":"1oWa-Za_0pHDriX95CfHuTApOog5bBjuS",
			"115":"17x-DbbtEn5LsIzFJzS7qO31AeCRn0gzB",
			"116":"1RSJqEO5VHt0wFr-qgp7Ld59MV2rvs1-O",
			"117":"153EEilniSbvKKMnlOLfWgqoSQmHCNd9r",
			"118":"1eJZ54YP1_aOlCy5Qjkn80QKxYB7yfK4m",
			"119":"1mlqjCI4tBHA58iTzLYiczsXHrhmaKo6D",
			"120":"1VhbkyJ2viBtynZwf8xJrpKXg7ujGDJmi",
			"121":"1XT2wLRQqgUjYY7Yzd1Twt3zgM2PfMHSK",
			"122":"1APcO4g2UJ8gE_01XFXs7o7PNLDzAeh3l",
			"123":"13to5fFVUvmO5Yn4j6K_JUFc1k7q0NjCE",
			"124":"1DmE-76H4IvEEAr2BAcqE1jI2cz4QwlBD",
			"125":"1LL7jCEwhVGLNXi6ftggeg_-akEjS6R6d",
			"126":"1W6pG0JGUcSXwAr3EbibvSqNLT-0oQN8D",
			"127":"1Yhh4wwv6ZWmy9btCHkwvNI2A1PZYv3yf",
			"128":"1typLDUK6Ol69_nrut9KNp9md7gY59scf",
			"129":"1-ev2Me95j9nFUD7Z4P5r3lDl-oHltlMj",
			"130":"1apwWBZZPHOD9m6g-jkoVw7mMov77_Z5k",
			"131":"1omwIEyf_hZxJXNToYIB2Us8br7icFM4V",
			"132":"1z_9sSYITMgVh73ACQeKq2TsmGhXt9KlK",
			"133":"1AlvOeNWLhaqycdNpi9tJ3dc96v-uFukg",
			"134":"1SXBam6oFtQy69LldcWV1d8VujvmfkjtO",
			"135":"1eSBwH6omx46avyLouRV7KARCRsOP3t5x",
			"136":"1zJjguQgri4z3J6KPlDEIwopPJ93IIroj",
			"137":"16u3K-cMeeSFNQ2TN9jDEldgBT4z-rY90",
			"138":"19YO2EF86HEUA-2Xaa5Rm9oWctJpaz5qJ",
			"139":"1fZmISwZglvnasJ2LNUwOGSLOOopOzy8g",
			"140":"1qLXFDp-8xvghcHwmcbedtMCovC6ZaL3f",
			"141":"1GFXAQP3amUH9bq6VF-yYPlhsplIAPU92",
			"142":"1b1DASsN_5r-37OylsDAU6x0G1wHxO8ey",
			"143":"11AlpLBDxTTq0RXCNZn5DpRVYuFz-v0Q2",
			"144":"1OJYAfWF3kZn5k7E211j6B_otp1C329PB",
			"145":"1F-iSSmAangpBpeA9rgIwlCasEuOqYQo6",
			"146":"1W_rsW2iJ7YCuDWHjAvSxqm5Oan9V3yNc",
			"147":"1ED7xX766XDS76KSVmWCZfXAxPDB6mwR4",
			"148":"10aG1RfviU5NG7DXum2FivZSZaarqWTPn",
			"149":"1FgeHrfwjBtAUY_sNBevvGgFmmAos4l2Q",
			"150":"1Rjn7mvEv-rKDfwBF1LHvMWTaiI6rTV7H",
			"151":"1qtP0yjneFmp8jOrPkgtA49UUw_zm5XEM",
			"152":"17H6o32hneX0i_kcPAlyw_HJJNBQk6SbC",
			"153":"1x_320VtgKoY1JBx5DRKpCxAtfGMkCz72",
			"154":"12UVGvNwNeJlFsv3IPG6M-4pUSBydgXfi",
			"155":"1WTxKADan1TXgLpFmHOj_yYQufwgaqETC",
			"156":"1daGB6ioEzb1taHE2XFdt5o3EJr41oFI5",
			"157":"1VZ8bfux1pQNVMLEdYQwEtJhlVdd4wIqJ",
			"158":"11huou1Pd5Gq5zdN0F3qA6NoSTon33-zZ",
			"159":"1jQW0nSd_FxBcibbjxvcctZfmgljUEHyp",
			"160":"14unPgez-YDaA-dlr5KUMlROKHu-dbHJj",
			"161":"17Cn024CbRpS-x6MQg2Gjxw88ySSPau-6",
			"162":"1_8IgLGZn3ar8vnYuPkQEFteIlDyIHqn8",
			"163":"1aljugwoOMXZ4Job1dxkuxD3NVK5I-gXi",
			"164":"1qhvvy3lDPsybWrha3M3Jb5zRv_J15lqa",
			"165":"1pEaKr1IVamfd2pz2BxyQ4C4m7fsspU_3",
			"166":"1A1kRj33Aje9Zer29YUU8EBlb2QZf0ROQ",
			"167":"16PIx2BV2ysQXr3HZ2xTnvVVbe1jBXZEl",
			"168":"1ojZpMJUAx1ittyfaT-MsUGaE0xqyA9Az",
			"169":"1Tx5uJAtlnvHHXwvhWXcHMa86LvgZTFOC",
			"170":"1Gck1CRhNBe5kcMxQ8-PeXO0MtRwT9gS2",
			"171":"1A1lY2Zfx6HUmJQMUCmSKo654X_cDDfm0",
			"172":"1i2S9z-4g_iEGRojKLK_FL14BmDPcs_Qs",
			"173":"1aWsxOgEHds9MbQaTCfBHoUKW3-QR8Q6e",
			"174":"1m6RN12pFjxRnjOVH345tGvJ06dSsB0mt",
			"175":"1rc80smqwt5FiY1w0jSfm9abR8WtG2qH4",
			"176":"1S3BVkv59vnayOKmiZDOCw5_Mgx2uvYjK",
			"177":"1NTRS_r7bm3XE_SJXnIgUCnE8BbjgMMK3",
			"178":"1fU6i6hxpIhIeeCxFuLZzRdNebmKOHS8S",
			"179":"1Ulfds-tfnB-b5XJ0FNyKf-cnZJZBe47V",
			"180":"1b0vA2Jw67FKH5Ucb9ejJ5ujUFg7EBcGy",
			"181":"1MAh91K-CqLRymE901g2MasYGcC0GAQAj",
			"182":"1t8NIsCCNtMUHEqh4aWzo3DxrBAqHXWWF",
			"183":"1_Vztj5YZXYbYRx4zCIY-lXMiw0UdvLqj",
			"184":"1bdS3RYH21rN30HsgGeoCtn-rqoWFuZE2",
			"185":"13rQ8Gjsi9VyO7QXKBjmSlxxlQV-b3kos",
			"186":"16XUEaXoRCT1KDFsBdcnfU8bg3uyJ7KpU",
			"187":"1dt-MsgftE-XkcVgOuw8PxmDCWDSSwyzQ",
			"188":"1jTGEDUcEKKsIaaLqE805Tjv_hdNhxvM7",
			"189":"1eDjWuGC_6XIOjD3nJaZmJ1pLnsUgyOSk",
			"190":"1emw-8__96eIUOjf7CZhAAFslG6x0mgJ-",
			"191":"17kPIT_u93yOCOzHrAhacWsrNDSuOi8Jc",
			"192":"1i-GPoOJyHxnEs3YaJHupsAArMUxwWcJJ",
			"193":"1h_b7qSAEn4tjP2ePE5BQVqNEPAx0wQo0",
			"194":"1CTL-mCkh9FHoow9XE1ePQ2UwraeJr94k",
			"195":"1Xrtb51iz9JBhCNSJdHzTJ5w8o2hd0dlj",
			"196":"1OwABL_kWG5BiYxUGXBfDj54JBUit8aA8",
			"197":"12uSlRRxR7hF0kLx_8y0fi8EM5Ppa-TMk",
			"198":"1PWbbn8of1z4fglpEPIT48oa5Mroc2SQR",
			"199":"1ZnVD1gvXyo35nIyDrudJQH6h-3dkq719",
			"200":"1icrq09kvvnqhdlx2sEPXLOqiX1DudLZ7",
			"201":"19xKo6T1S6fS9HDQ7nE_T7b8YwdtH0scT",
			"202":"1xZSb03KGxc9JxdK2585cMr4uwTPGkmjz",
			"203":"1Jd_IsZ5AWw5UtW6GfgvGgi2_IR05sfyQ",
			"204":"1M6J92ShE42T7XWFh0sEsTnRhSORe2cTb",
			"205":"1282xozlYwBKR2DZZtk7MX5YhbfPZY-NN",
			"206":"1dLCcvoug8fFe2JOSw22RtvjF-1HCYcZr",
			"207":"172dSJ74N0Cd_bcUT6KghlhM1BcavBpE6",
			"208":"1byrsJyGT7KUxBJHf97TnbVD3FQoR80N9",
			"209":"1cE0gVJh9J9iZ0X9T-c1GhyCtEC_rHN3v",
			"210":"1gF0KA4CoRdMGcBPMlJ_D9q2Tle90ulKG",
			"211":"1RPowAvX5DziBJNJ7MhTx9Lg9W-FjlqaD",
			"212":"1xz-wbqkIxV0qwyPecpgZR1d8w3TL2ea4",
			"213":"1gSsKSOP1WcxNsiVLeSjMJljxpCRDF47Q",
			"214":"141DF9P2tefczCUBp_njT0hwMn8tqU7bR",
			"215":"1ae0bBgoLieWxs2o2Ibc0M8E9Hcr2vLYy",
			"216":"1k6tDIBnj77nuzqZZq20YxO7KfkrSbYAL",
			"217":"14JtuB69S6wcKPibovGABN7bxReVhrY0g",
			"218":"1bJbNPS13WZXO6TfruqFeHAnbxzHmfZIm",
			"219":"1toi9cgfju51TPr-VyCNx_H-_qCR9CgwT",
			"220":"1o3U6ac3DDqZ8DBueQTiEbQ3c7YdMJr-g",
			"221":"13zLRKw5i0dHh5ZjVTTbWOZeNECfbkTKv",
			"222":"1bG-JODcAfaN4nWbVwGCaNz_ybu3xnYNG",
			"223":"15E7aRdNYF4desOpxoyvs_nBtbRetoeFi",
			"224":"1mqMWRZhl6xou8Kotaf_FZwWBEZT3-oOP",
			"225":"1kqAwCnZi2PO2VrzZUTUhdbSb8MAowsyI",
			"226":"1w8elfi2Wkv9om3KYPuj2sBMi6xOag2Mp",
			"227":"1aBdfW0jJhbJ6zfBDsBlcxKUx6QfRqr-Q",
			"228":"1airrppNd7MdH-oSzlzPqJRLRIE31-GJq",
			"229":"1RWrgo5rmUWoq6c00Xjpu3dMQJVVJC84l",
			"230":"1yXuEhv-wCyR0s4QUA8ulwLNs-QWSG8q-",
			"231":"17pJeAZ4lbVGEbqrPdS-9iGtQ-VuzKvNC",
			"232":"1YXL7XgBcLhMK5PfZyKVCc4b-p_jMLsJ3",
			"233":"1D85rLVlnF-Xe_NoB0wj8pR6C1YHd01zK",
			"234":"1TC4LWUxRBgOKBQa-TIz1uIG6jGiT-Fix",
			"235":"1nrNQJ_vlZCCIplCRinyyo4F_RSR0Ld34",
			"236":"1dVSjtd8NB5OQ5FhB2DUJp4f65_zTNC5c",
			"237":"1u17DbhlyRkkKlSh_gyOiVDvGLYvJXH20",
			"238":"1Chidjh_-rmK_7i4nBhzQGltpsAur5lZo",
			"239":"1GDxDYfiwVqXLjZnCMKjw77rfV9C1InKn",
			"240":"1GKLj8ENaUX92WIDJZvjuGnQ7jPuiXvrf",
			"241":"1HnvgCUR4OJSlHTAO_AX56AroBN6Gfd-J",
			"242":"1Rs_j0t2OlEMDwz9dRTFmwUeiJjejBARf",
			"243":"1_ecx9mcQ3OV0Prgib1Cs06bMTL0MKgum",
			"244":"1NOiIJ-TQPqnOyo7molryUWOPmDv_3FCj",
			"245":"1pwvsz91ERKCtgyagQKG8yqtNPLJHYQAM",
			"246":"1q5gGmvCO5_oZXl2vdFSWQJod8kIM6ENu",
			"247":"16NW_ZHUtWCIyQk7XHhm12m3rHKfkwCIC",
			"248":"1DqxgaZ-5QCgtvVjVAncZQgMyNhaoAI9r",
			"249":"1I2-PScJ9AR8H3BRMJYrKvcPG1B1Lm4Xl",
			"250":"135emrUwditT4drHBysBSt02jtwayxXnC",
			"251":"16h3MRXfEDmeCK0L-I1sEUkw3aapYjUIh",
			"252":"18TmcGCEck_8GrdS2e7yqpVapO0ouLVIr",
			"253":"1B5hAyRdtt7b3mR5wVqQ6MvBDcz0YaNuF",
			"254":"1JoWEhXIgRwma-Kc9XNstCGLVc_UH3c-l",
			"255":"1khoujNydzAe6JPqkAfuU0lWAVS04h6wG",
			"256":"1ePTQx4JBOFZZIqvs1HJ10FNSW9B4gJkk",
			"257":"1vaZ_991LX6sOVgkVJxInbjIXDfNmkLXZ",
			"258":"116vCULvnMadLWb93hahTflRqMj9S6P6X",
			"259":"1EZujQOQ0ks9Oi0ij3NSyEUoUDv8eUbEo",
			"260":"1EittRp0LlExMPRDraffeWhN_2tbokqhM",
			"261":"1heMK87_JGnQttXJmlcT5PtT74QPtLN94",
			"262":"10CPNk7RcttKEW2NhyteHjjjo8R1kY3SL",
			"263":"1P2Idz3E_9gcYphjTnnk_9gu775ycaT-b",
			"264":"1Q7xf2i2DEgeZ7FQMaTzdGJehcvZhl2qA",
			"265":"1Vu6mv9vo76c-UBoL11JOgwgehMILqBol",
			"266":"1BQyK614UqIXak0xVtjKC92UU6QHvizil",
			"267":"1VFWLtglpZTq2ws4oGEKVnEn0ZhBuOYpq",
			"268":"1PjwZuzBxa94FQueV29GhMd0MCsms2LKI",
			"269":"1y1gpAhTkBAYlcXVfCIyzG1P6i9ZplXE6",
			"270":"1pECYOPH-OrcsEgM_anfMAG9OIuuGyrI2",
			"271":"1UV8DmK_qDqn_237ACz0kv2y4r_4Z2KC7",
			"272":"15qvG3sMsAPsS3S5ZiCadYpmCQ_ZJoBiw",
			"273":"1AIV9QC7kUHJmNF0Xk1LWngy1nxkn24W6",
			"274":"1LXfGR72MazXQYFrFi2gg5sqgC2h8-mQf",
			"275":"1vO9nVPXArxFwxSKuT6cwcgySH2dalAFR",
			"276":"1zFbPZx7kEooU5jiGUJn8Sh4Q3bkqcKNz",
			"277":"1bnrLUxoUxV2e_fOjBbIUlAweyMpviaiJ",
			"278":"1i_uxU3zKqlhppF1EKjcdzToEJAB_EVN3",
			"279":"1v-5Ud2R6v7Y-TidLHY6LjzF3i-Ie-DEZ",
			"280":"1n3SdM_zIwKc-SI_jjqd1iiwWaZu6Cx08",
			"281":"1l1nvw6zHiPnEIKQg45duHpi7Rt9xiNmL",
			"282":"1F9OVfovAbLPGz4ZPhUndv-iErL77IvKX",
			"283":"1sOlTPNDN3i9UEEXZwz-TOkjo7OkDN_jT",
			"284":"1Xl97anaKPSNyXkE5nCONwhvUVIakxOxY",
			"285":"1xksjC1eRWWk6i3GijtUxAcQKrAeflaCt",
			"286":"1ruEFvfC8lvORWvh87KoJ_0vZWUJg3zK6",
			"287":"1wsp6qVCa3sLSIMb-UN2xa722vq5ZPPYz",
			"288":"116Qzr9hUnZKgbUN8lSxzikThFKvT1iFM",
			"289":"13DgxEoGjAtDarPeQHd81oTE1Wz7R9OrW",
			"290":"17rWjlG216jjT2cu_6wiwpDjAR83rFTmv",
			"291":"1bpEO5GOzTQvjtPXLTSkgG6xTf6idF3rb",
			"292":"1hPS1zOHzUs4kjQA5VZaHwxP9L9_LQ5KD",
			"293":"1jW0pxIgEuuNSoDO0kbmwgQRi4HW8FQx3",
			"294":"13tPT4rQgoGCf3ewsxFlrWuJXAsVqpyJb",
			"295":"17ykhrCYFGdZrXbs7H0IHzCIbqtc1VD8M",
			"296":"1GVCWi7BPMKFPfH9ehcU0_8xbdKn7xkWG",
			"297":"1MCc2NdNtlqroyAhJrKouaxBv3F66lSJx",
			"298":"1Z-woM3y8lCaPBTbSRP7Tibi-PdMPxHlW",
			"299":"1rPslbzbvD7A08D9JbXh_teTDprMAOZFJ",
			"300":"1dlZ_cJPqjzDZqPbqaA4hATev-mvJbABs",
			"301":"1ed5SBcvnZBidw6IUmv16sdmfW1-dPiLz",
			"302":"144Fz3JOwdT4T3_CsGg8GYr-4fiiliABp",
			"303":"17e6i46IG48gYxKav-M6CmKRw3TFkNitQ",
			"304":"1wnoBP1IEneMCqKbGWffrGDJhByORKLZG",
			"305":"1ve94rfykueGhpfg2swyGvVoAirJE4Y0E",
			"306":"1SWuyCm5eV9xRXVS2WdUj6mMH89d5Vybl",
			"307":"1C-odPCqeKGHstqESWJdIvx4EVd-vbrw2",
			"308":"10URqEMJ25-jyiiNN7aF5ImkYQc96srhs",
			"309":"1HkNMXi6Yzi75G8VN0AxKE2v67brSVyXX",
			"310":"14JPHIeHQBzftj3GGoUm20n3HDyUDRqCJ",
			"311":"1MlYbpMzi1us-0hgai9QZmbwY97Eno5eR",
			"312":"1IvSBOFZlsJo2ZmvGU66n9pO1BgsrBB3J",
			"313":"1mH343KJ-dWvN2uhQyf7cqrp56j6zclDV",
			"314":"1BXN52yiplYafkEwZZ9QQyJBH493NI-NE",
			"315":"1DHhj0lRR01eAoIWYAGljaJhKfYjV217N",
			"316":"1tuGV3UE61lDxYD8LvPme653KbT_3I9X4",
			"317":"1T35aBIm3IUBEtM15IUQiItrAR6iOp2h6",
			"318":"1CpfyrUtbg8Mw8kPkuNhvqAGI6KfZzvUU",
			"319":"1UJVjOqYIytuP56T6jdBKmKG-_GpePZF0",
			"320":"1QJfxWamahGplUBkjszv1XKT_YerezM0A",
			"321":"1TJZl231a3I68avQwsywDb-X7WN7DI7wM",
			"322":"1GFLj61qanA3BTb5jAb2i3n42AWIfIyz-",
			"323":"1ewmFCsfIjb9WKEMSW7yFkc633V22TFCJ",
			"324":"1KmDJC0XvmZkUeM34Isaa7pPYq390CyeK",
			"325":"1ZaGu7NXtsKooNBjaE32tt9OOPzPNqper",
			"326":"1039aULsGmc3LwsJoMXhzlEfb2YzklWqE",
			"327":"1EUdqsWRSj66iKA8--9JS2Lrkvl2XyWaD",
			"328":"1LWj9qBnK3E9W4m9OSbLQpQxlBqyXGQO7",
			"329":"1R3QrnZ3hoXeh1UzwrFBs-X1KKcVVSmIq",
			"330":"1WdG_MCh0tffI0Ys3P3fbczHzjsIINF1P",
			"331":"1ZSrWx37d3dwEh2xvDBl-PFAVz2LgBk13",
			"332":"1BUJm2MsI6uPPJVgS2RRzymSEaId6vfE8",
			"333":"1dfI9XnFesuEK26FBkLb2A44S3LhlaOJj",
			"334":"19rRjckEyBtM_zOwnVy0nsj1i7GTzwu-H",
			"335":"1TyHhBMCceqZTUKkLe17OIG97qeacq70Q",
			"336":"1SZfRT6dipwNGqX9RBZ3WMQm9iNCmAuO4",
			"337":"1pXGsl0vJmcaRMr9NWIZ5-yQzaUzasUQL",
			"338":"1NlOQ7XFm8LPT4BRVj9GCQYNt9ccasJR7",
			"339":"1S-M628OAxNR_pwZ_cFyUySr_Avlp_5DN",
			"340":"1otS_RsRXkALUPBYid3JwwQ5Awzm8i7DU",
			"341":"1XO1u8Ft3pS1JbBiPpPhFnxNLl3DbOHlK",
			"342":"1g2VMOQSPLkIEOPupXyK-Ouct5Bdiav2d",
			"343":"1_gD7s7FuS2ilcCKe-Lr82lew7_wSC83X",
			"344":"1SWYoJ_kwPafc0c1ZCwDwmpuel7_w3gOa",
			"345":"1o-3dz2FpaHlS4LySWFdK5irbvWPuYkiF",
			"346":"1iZk7eI0FgumI4n27ULkr2HPZY-KzqyKb",
			"347":"13s9XvGoprWenYiT02QIQbZ-3202EuNad",
			"348":"1-w-BRw90fF-XNyE_yiiimosG2jHRphmC",
			"349":"12Un0iVhEqNhp5LVXihC3JK4MemesPDPl",
			"350":"18NQ4USKNIlVoTfW_TKNuzeYycPGgAsLK",
			"351":"1YIClyFzQSnu6EFxVjYY42nBIb8DaBteZ",
			"352":"1flF-81rftonqrtp-SraBMiOb2RrNOyDM",
			"353":"1uM_ONK4yekbi7LmOq2Z0-7S10EdhQiDC",
			"354":"147MRFLED3OnKfSQA1vvYGmRH-fIvS-ev",
			"355":"1oy0ncmR2irWVeOBmrU-sK-hcNDUPlYAi",
			"356":"1wPZpchNilnJha4sdp_oz3J2ze4Wf6SMZ",
			"357":"1E-_1WUjtqLBANxVz_Sus6Ri06iE3FGHy",
			"358":"1uPcnSk_zpTrUFq1vOp1wl5dYa4V4M42H",
			"359":"1Py8vrwCsu-QcPjTfyPY3hxj_2gWM0vme",
			"360":"1ZprJZIXBav9Z4DT8jkxoyEgL3lgu__wD",
			"361":"1iQZgpYlHo8ZmxKX933mMc1jzVPTgBfk3",
			"362":"1iHEKkUE_2zytBVHMNMGWKu7KdO5kecsP",
			"363":"1uX40jjNb123NfcumCy8xeuSnqdfzOmTp",
			"364":"1fFz-dX4SqyRLRif5IIC4f8-yIq75b1ug",
			"365":"1IVNHkP4-8fg3R2zapW00EbGc5YGpvLMh",
			"366":"1d_nWFGNn8bA7bJZyHr9FwMlXIDZcSUhi",
			"367":"1xc9Xs2CnbXFGIHwnyJXq_dzybbyfSizt",
			"368":"126lbeOsLQvKGZIpeMcbJUOQn4kzxdQTJ",
			"369":"1zamsJBofsIoIOB1-RsLQrnA6hLIHtwz_",
			"370":"1qVHZvX2aBhrguL4S863Jv_M_Xo8cim57",
			"371":"1sDhT8Hci46qZhLiMijWi5qWjPWtigkpA",
			"372":"1KyhyNEpMWJozzg9EtJv9HRW6AAfHW9_M",
			"373":"1zIfVSwXcISLoDbKE3MFT5eXXg_jIfFsh",
			"374":"1QWkDQznSvm0XbWH7UIcv8L1hxUwwT02r",
			"375":"1wh_udtz_qkJO-SKswESm6dCXSurCKFst",
			"376":"13XM_6YO8q9NxF2Fk5F6XP1gVLQNit4jQ",
			"377":"1gVA4Wk7WP5Iv9nUJdXZtA3SgB4UrRPHI",
			"378":"1OfkJgjlSuDK1wvMxo1ibMBEfEEkuZ_-0",
			"379":"1AWZmZDlA6HrLSQVkCLuBC-kAcC4nkhJ8",
			"380":"1QHvRYEax__YRYei9QCfpa0QfAVqcbYP9",
			"381":"1UJHNUsFxxAhKuoQ1CHmYiNuRpNewkQ_C",
			"382":"1oFlm48i7t9px_Muvj_lAN2DBTj7V8dHW",
			"383":"1dWkqeKKjGBwnqyerN5gOM7ev_ywxveIx",
			"384":"1IhqsR0MryiiqpiMJGEYJ6t5ga36NjfUB",
			"385":"1rgS4KM8o4z06zs6VGE9v0-HUH4Ch2eLy",
			"386":"1r_MV3a19i6Ty6ORDdmXytWcSM5OkLUrP",
			"387":"1Bfigtgf4LsPQMaUYH4WOmDoEGs08B2fh",
			"388":"1id83Y9HJ7p2NWd4nv0ERpEkSH4NZ3qj1",
			"389":"1JX4-kvOjEEO0P20quEQsZ8mnqcMrm6-9",
			"390":"1Wqu7EjqFvWmYSSW1zn3BVuMn91wXm_E-",
			"391":"1rBJN6sbopCT_DlI_ywkgd86jNJB3Wec1",
			"392":"1MiElvmFgGPz1NSslrg06iUoZvo59XEW4",
			"393":"1lPiFqmPLiqYy6VM3z2dbrDLRWkn9ntTb",
			"394":"1x1rN8x58Y8TvN2vqIFjDopJJLaWygr2B",
			"395":"1pSDTQqASpzEVCqKbGTqryr4Viw0Hdu9w",
			"396":"1xUHAXPGF8FW1VUoRTjnqDYMvR8Q6dx3J",
			"397":"1huIZ82e-wqIyRVHab8BPZleESqbppNE6",
			"398":"1jniCc_n455fdXcPOwTPGMcbIwVs3biW4",
			"399":"1_BqWJWPQMSKtJaPZN9JNr_bKbnBtKLHI",
			"400":"1LRe3mgiMl9coc4wj-aKJX1GKao8uuefe",
			"401":"1hi9jSiQIhOFPu307_2sA9CeL1tLH-HKf",
			"402":"1k-dtlLCi3RTA-bkHqKpgQlxQbmffjiG8",
			"403":"1Q11KOS3ejuLJSBCmaDsknK6aEfSko2Mp",
			"404":"1G14-gKe8H4uo5i9t_iPoUybgb8YTtTH7",
			"405":"1xRG8XUCpzTTTVBNIrTRb8cF_o4Qqiwab",
			"406":"1slydzoRsiArtGwKv2SigZLLZC1Xzoz62",
			"407":"1CIAHFrs6u3tv15dmrSo0lKq0F1QWV50g",
			"408":"1tWD688q5aM3esJoopleQXw4W2As5QQT7",
			"409":"14XJM4PqODxbtL_FZdvXFwXZGRf0g-a6u",
			"410":"1u8G3045dhIdwX-7vk_ZQzDkXXdJmKJC_",
			"411":"1DBjx-qHidtmF7KSbTrABnxy1FvdsezeH",
			"412":"1PL-5kxaSLuT7Jw64-mZcp3uhnbSsotnV",
			"413":"1brYdVu0Uy_RBfn4dZsIpnpBGnFq3RXg1",
			"414":"1rItrbhoCjiabFraWspwoPA7UsQtubVok",
			"415":"1u5cxf2nHQBYJeTNJhph4prS1au4QRPd8",
			"416":"1-lc_mtYQcN2TCDBNeoNJE8qTJz5P7xZe",
			"417":"114rTOSDK8NSLSYgdAE2wklQXqSFBWvI8",
			"418":"1GpupixVb873XusTT4Qo32ocrrX-VEkt0",
			"419":"1SNI1aEB3QjO4RpCqqfPNfMMrXMYDcbl-",
			"420":"1f3L-u6iOWGCLTavhImumsrrQl0Hx7C-d",
			"421":"1mfmZHca0I8lzeLxTpo2vM4wx3KaKKpRg",
			"422":"1Daauqx9P4RtLnb2TZf6seHYx4W3zkpL5",
			"423":"1cYx18JFIr2yhcoqR2aXSpZKUleEA68Mj",
			"424":"1iNTNkwu1wfSEkeXigdePbNZ6sIznEGqK",
			"425":"1x3K_2YAF2URxBJWse4L_XF9w4FTr_cQ7",
			"426":"1BJYfKYo851FaQk39_LINcuhRZYHq6ket",
			"427":"1kfpzXEhdExMSQe9GrXZQoIar7m8yVNjf",
			"428":"12G0t5vkipHV4VcQLCkOXQ5uEM33_3aOP",
			"429":"1hF3h9lWW7vY-VyengtI2JssehD_wU17_",
			"430":"1qwuAHvPlcjwR0cX5l_GXi9GGHzXJglUp",
			"431":"1VEDRVLEDr4DNAOuYJDOoKGCdCSZRRWqR",
			"432":"1QB37j5qns-M7I68uAIrx1mGz7utp4mGQ",
			"433":"17Nf_WQK5sLdBVTmzhG6dvDv7rQEwH5zZ",
			"434":"19-OgsqHKNKxJ4AGg3HCNcTkdp1lE7i4f",
			"435":"1K7hnkMbG3pT7HB-N11f1uiq-dgGQC6Rn",
			"436":"1bXZdtmxdlZ2qrFsyAIYMVS0W99yz3bf6",
			"437":"1jot-HYkJuX7NeD7b-lS0FLSvXg9ws_rk",
			"438":"1kxOe3Q5w6YJdAsf9NDq1-eoQ0Mt4ezwb",
			"439":"1OckzMAXSoaSIGCJoEUH4G_vTEkfz5_nB",
			"440":"1UK0bqqCMEfK6mrqqIHM1GSLMHwZBbVCC",
			"441":"1eaOFqVtKrftIK5eglCpK1cliT6_cSpcR",
			"442":"1l0iEUZhioOadimcURHPJy7l3jFfRQBc4",
			"443":"1uNkPmybS_4thUiaCVPVThCDQbhAsFM2b",
			"444":"1M9qHLOBvKKQ05fGis5bRWJmVTdSrpJ2J",
			"445":"1WKN2xRHqM6DxWxRYFG9-9fvaUwckJSep",
			"446":"1u0XuPLV_KVj4HLSJ16XF4Rc_xuX_ewx8",
			"447":"1Ipo6XECNdVm6dhxzZgP3hKF4C2zEJTUp",
			"448":"1vy5J_4-sSJ16qccwRBQcXgqf_L4loMfb",
			"449":"17j0LcoYEv6QJNj374JVjgegca2pmDE1Q",
			"450":"1IGNI-LqIZpT6p52_18g8TYlXHNSMjGUY",
			"451":"1yKGeLvyWy0cSuXUlZkDRDmKCOwPKNkVS",
			"452":"1KjizdP-RZGpL8sIEuiimsM93_nQdBVil",
			"453":"1zqPgJuPiid5Z94oIKykJPqDtASy56atO",
			"454":"1EsY8iX0o8MlhcgHKqQkDEfyutOHjKdT4",
			"455":"15f9FZxWBi1Sz2qVuWEAWL6UqkbMuBkQl",
			"456":"1JN2c0OqCuRzI31h1U6U2XFkdf3DsD6B4",
			"457":"1zqapTocPwCvfGBH1fNq9SAplJkoDsjHV",
			"458":"1yfyAadRCeWTZNeXOIyzGfIwyYCZsBWRP",
			"459":"113YTwQcHNIoAQAys5okHBbNrBMqwWEKS",
			"460":"11wbhfREgoREPnwb92LKd2LDbQbGcXB97",
			"461":"1wY4my1Ezd07UDrlZQrXhCNTbW9EP7TMS",
			"462":"154tgwFtKf--pgcS1limhn0Y7BbZYM4_0",
			"463":"1DcmzQydwB6UKABufKM9aYAWS3bsFAe2n",
			"464":"1p-ZhOXLV8mdvZrHdAA6O_1xDp7DltXeL",
			"465":"1uLelTiVVqPGgAK6Zy_N40ExUQJGFb5VX",
			"466":"1pljSeSVQvn8EwOToMxRKjcol2SZRLFLB",
			"467":"12jBKxzyrEhEqpF2Okb_QGU9QajXRdjb3",
			"468":"1Vuvylkqt1ntFhsDWOtCvDYWCyO5Ojfqo",
			"469":"1pdv5tgIvUzTyk82CfjstkqJ00ufoAsrC",
			"470":"1IpbR26haqcIzix1W-rjGOgGCDWEbTRAK",
			"471":"1lQj3DFu_dMIQaHm5_lRA0RobsFenSHn3",
			"472":"1liRmhZmTCAflTMDVtq64leOnSnBE9hvU",
			"473":"1C2BUAUMeY_0868CuosisHcb34zT3wl0n",
			"474":"1gkx7zLXkHo4J9-g3njNVJXb9Ovrdgb2P",
			"475":"1viLjvGaVQp6tZZyjkoP2Kr0O3SzTxUsx",
			"476":"1yQheGl6spRdtnhIzUn5fiyzeMh5DdYby",
			"477":"1MA3WT_ckZiRU6npNd_XndCjjDcwY8f7H",
			"478":"1N_bPcVtzK_PV3mmcgoZNeopZjt438YXj",
			"479":"1jSHLan0cv0lyR3XL7zX5vj_qkvLmyeaG",
			"480":"1jcy4m93129s2W70oTWk8dC9REKfg92CJ",
			"481":"1lEpnHL5Sb61WQZ7Z87S8EgTiDR01x7cP",
			"482":"1FZLGme8039yhgcSbCuCWb8_EsIJR4uBw",
			"483":"1YP53xWmz6xMnUBvZyMX-Q7AanNWNQtND",
			"484":"1rkA852b8-KDViyNj4r6pN4Wtq98V5d5m",
			"485":"1LTRSetCC7F-q0NMIqJ5DpbpIIlIhcMVl",
			"486":"1nUduIv-6GMM3pJgVJYy4UfW1s4I_yvCx",
			"487":"10L4yw2fa_24HaUBkea0nJol_kMyNiFfI",
			"488":"1ZI9UeH5qhypL6H4rak0tZlJto00lXE5B",
			"489":"1f2HN5O3jDZ58epxeYYTkiQTvhMBN95Y_",
			"490":"1hZfNqOp4P4CdSUkymIIfBudFglOcfOas",
			"491":"1mGh12ElyU25gTkgTYzHpdv3vhyVflYHu",
			"492":"15yL6UxQk5W5kdXQM4CE8Y0dsTz1qjkQ9",
			"493":"1BTpFWkD5mZsL5Ygy_UwQRSbVf8RHGxMp",
			"494":"1VOCSZC16XlWC3QWVY88NIu2qLsseXgtb",
			"495":"1f_35RlyGc4GrlKdsjmiC-GNgaj-eRI6R",
			"496":"1m3VpnEMFWO04TrV0V-98U5-fJB2g3RuM",
			"497":"1O8mJd48sEulj5ba7Pgslde5gQ8clwPFy",
			"498":"176yf63_-6i7qLk_as1gdDTV9pT0sQVQ-",
			"499":"1w_w7FR3MouGOrkPkfsvCsd7EumW7WdVG",
			"500":"1cFcJN9nP41Nnd2Q0vQOoO9Y3rh41MPKr",
			"501":"1UkGto203qHf_haBZN_6-mXqHLrMMMKFc",
			"502":"1NIcpz6nmphOGPbWy_0UikvG7W6YE0HVL",
			"503":"1Jj8rUTokJwS3UfKUH4IBLqcyYqv2XYiC",
			"504":"1dol8H0_zxdHf1k5TBRWT4-Du8ser6ey2",
			"505":"1pnLCWZKkprQynPlpYMhMpcmR3lpiI9En",
			"506":"1iZLNR_syTXpSZ0rMVRIgasUi3Go7Tqxr",
			"507":"1sSkSL_-Qg5DzYC6JcYo1R5fnHbg3xuRY",
			"508":"1AYzdm-TnZPPNzoZABLIfgRhzSbT2I7K4",
			"509":"1gAz1sFagW_WChnxeh-ZsgBy1QxGsl6BH",
			"510":"1dYF5fLrkeSuWnndonUHxg122ofV8gRgW",
			"511":"1yc5y2baVVbuY0ZixbqG5THhSzZuVzLRd",
			"512":"1s2949BZ-Ms1LFDwkVxT1aNn5pAgxGyst",
			"513":"1JZikmSWNEKLshIvTaasVxvQGnVZv2xLg",
			"514":"14PStCwxhyiA9JqOpfGHNXe_gqvr84b-f",
			"515":"1lfwRFHewAIfBC7unUTGi-SaLDz2ik6Ln",
			"516":"1HbFSe3EoFTbC-gVK0fbIfysrcLNcrU4A",
			"517":"1_OqJ7LJa38-twdrvGHV0zW2vLYFEmpi0",
			"518":"17wB1ujyg3Irj9_oK47AdI_jXxWPgjdxQ",
			"519":"1ZVNZSTAE3bQmouJ6mppRf0jJpkNPRAn9",
			"520":"1woi4YuFCL-ZGdRID7Lx5xLHUTtf4IpMa",
			"521":"1XE6JLbFd8S9p0nBhAfDiALpcGp3qx6Tb",
			"522":"1fnfWd-5jeMTKTOnfWd_IjFb3doYqt70P",
			"523":"1l0y142EKNztXlkh8hxc-ywEkSiiNdCiu",
			"524":"1LeqXeM4rmqNCP9ASyMzZA86tKlTzfEXE",
			"525":"1Rysrt_DS1Rh7BwdNCVAMqJF3fAbhxrIW",
			"526":"1kUQnEmIowsCEczUp73UGAFtir51gEZbW",
			"527":"1I0qa3bcVAj0WxEhxjNh8n-31_lnymMRE",
			"528":"1GDfx-TkfciuMpUzUPNb07HW0uZXbnLqj",
			"529":"1KSUxm-z5934KCFO2-4yAf1fgGVMuO2ZU",
			"530":"1o3iApxCd9PAEWPXl3Csua-t8Zs5wUDll",
			"531":"1mlumb7Zi67317iGnuM2puwdDQvtI7KVP",
			"532":"1XgPSjEpBEtM46XdnyYYKCt2i3B1yQqMe",
			"533":"1bZEtmWQcZ-ducP4G8zbqg24I_GGD9mej",
			"534":"1FEYvtuIDM1p-F8i-O_9hkFgDNTvVaOLc",
			"535":"10JALk4iDOwhvvekCGNd3KI8lcvrLm8Hz",
			"536":"1_w9KrndOpjzOgA1yVEFqxpZbF95OjoAu",
			"537":"1QYP6XHhcx-aRO74Yr_38dno_s-91CFbw",
			"538":"1lEYHt863xIq1PQsK9Lzl7YmmtRNJBg1N",
			"539":"1F3A5AoZMDIrWnbb052m-gJt1FqFfSWEr",
			"540":"1DOgyWdU9nKdTBhwAKnNo1ldBXYLihVyE",
			"541":"11af_V7Bc9nsTg6gkXJ30c3d4tZ8AiTON",
			"542":"1RuOHB9AsRC1h3vLo9Z54e_qE1BOt8d60",
			"543":"1c8wprxL1T68sGfs0HzxwI2Xn-Ox9jwqc",
			"544":"1h42NM731iKzvtkFYvyeQPseNFTKTz6wn",
			"545":"1VUY2phPLB_pMuutZWGq9C9jMgvRUl4sM",
			"546":"1VHrnWJwOFAqt_P4DEpre6_rWkKa2QTsv",
			"547":"1MLvs_zWF7R9RQx87c-O0fgSPUZPq7XrV",
			"548":"165VvySDcyDp2JbeU_X3OMN2hiSG2oIae",
			"549":"1oo_CwpQ-nKVuXClRnHaiaInXpkZiVPmo",
			"550":"1syF7fJuZ2TYg1KLKbY7B0ZLvtREF_Fdy",
			"551":"198uOs7shD4waT--qA2j91Lj3J_FFhicw",
			"552":"1rGgQNEF863qUvG_xvbXRW9K1uZTeF-Nt",
			"553":"1Z92KY88GV8720X48E6shWDDa52n8C9lS",
			"554":"19VPla0WzHdhyRtQgh6Qau2Lf52xlPRdk",
			"555":"1Y0YfQr6vf3LjVauJYJ_7N6pZvSf3YC6S",
			"556":"1zxVPh-_yDo-Bt2URtzcRpMk2Y-j5vz93",
			"557":"1I3W1nmd2mEB0qBMVXxdhoFupUT0dUv1q",
			"558":"1VBCICPsq32iEm_1hmDb5AvKyiUByA5Bp",
			"559":"1rxJacvcvxW-eJkDK9npovubD2hd6xEkG",
			"560":"1xtbmzSV2yJPVZm58jVCjVzh8nzMFY9yU",
			"561":"1ZVuzdlH9ridaN_FcKMv-fguIKey4qS7u",
			"562":"1-EPd759fHV52PIVypIK9n6cLAxSsNsJM",
			"563":"1er3eIx5I1AuHZpYLaJY9e6QWB1P8Mtjr",
			"564":"1PIaQyZ-ejYDAgN3NwM8XetAt9EJBoRIs",
			"565":"137y1R_77ubi-Pdy5O01mqdiOz6gWDG21",
			"566":"1cArAv5K9rOj2brod536ZxhUYQ7uPs0JC",
			"567":"1Qy-UVpxHU9FOqjy74JLHM2p5Ih5XpQlT",
			"568":"1A9zz8YAdhvOB9UPEYMLnfD2FByqoucif",
			"569":"1Z651dx5goZIvqk5OUWagsVJJl2mDus6u",
			"570":"1rTDF8rvXfS-E2Zp7y3PovmtaZdKaL3OM",
			"571":"16M_aLp7SXxeKKKDfYYpBHcfICjxkn7XK",
			"572":"1gq4m4TkLIt387_slSLzBqUpREvtntkea",
			"573":"19ZARQ56zsDfLGg40xjXXEBltFm7uYzWr",
			"574":"1VUWn-gBki-RdZPTfG4ALXzd87xoo0CgS",
			"575":"11kijxN82epaHvC1svlXM5ywHIS0AAuDI",
			"576":"1ZEMBEShOs6qTNVXEgOVavH2-o-1FpTJm",
			"577":"1kPv-IB2a9INRxJgfkp9Sc5UeaUTzjvZl",
			"578":"1fHwTVvGXP6BpkVCH79afW0SnDJen3fL9",
			"579":"1jOEFwD875sxKvLD5RNIgSxVW-xwBVp6T",
			"580":"1RFD9MCcNbJaBM5J1qtUhtV-ylHlZv_Za",
			"581":"1LBEJRw0qDpDe6YNnQ6TCaGqaCXlrjYkX",
			"582":"1phhPUdLllLLMWEomZshQj2W8fUUZuTmt",
			"583":"1UlzRN7nZqMETMrCfAggJSjc6fFD6cK9E",
			"584":"1Fs9Kf-lEEcNgYvBN-nU6FtV9-OZ7fvqw",
			"585":"1_wtwU7wyuDSGroIfiFXW__ZSKAm9vFfq",
			"586":"1DDo3NFxbVodu_C0tHVRI7WM4PP5aFCBM",
			"587":"1qq4386Sh9hZSSxkQnK3dsFwHS0ihe6s3",
			"588":"1MH4Ig1ZiHTYIKxY2ZDB55P1ehw_0y_Yc",
			"589":"1fZE8mdhym3Srohcn0ztdEsooQWPGGc6w",
			"590":"1tPauQhZpbgg9pvp0z5Oyg-m5HSMrAnZS",
			"591":"1hOrqhcHLtLnoMfeUaWE9GVbR8pFva710",
			"592":"1iIOd1WnXwMaulA-U_Iju6PNJ1e_pQWAA",
			"593":"1RlEAUUQRr6hzZJdSRcRBlEnANjmNAHcC",
			"594":"1oCKq54S7zS7W2x3gxQN9DH5UVQ6JduUK",
			"595":"1noq_pnB4qh8MD5bwcHoDv3iKGzCDUAky",
			"596":"1taRqBFW_QAmx4HN3XfPY_sO6SkO7iwoE",
			"597":"11IrYKoI7aMb_ZF34_y8o-RF02gS9y8fJ",
			"598":"1_oo6BdeehGckxyXprmEAP8El_z9lrIn8",
			"599":"1s0LxlxTpDGa_syUq0lhwRZFkf8iYZnem",
			"600":"1x_fWkrPh489K99KWllCFCEV0R8qcYTLu",
			"601":"1yiuucBWTl8oVv_zy7QKDKQsBYH_6zkEw",
			"602":"130gFlqVo7f_dO4Ht3NmvMduUYlWSMaH8",
			"603":"1XNR_eounQQgu05E3sh_5M2a_QvZICeHC",
			"604":"1BzUutKq97eQ_ky7hiib6yl4_XwyVlTHm",
			"605":"1nQgGcjUZ7NFZWL1UZ_zd9snmuod5PI_V",
			"606":"1tx24_5daQw-iV8lnnxLEtWvPeoqz39kQ",
			"607":"1ufZxP8fZrQmgnv3bh4t48fqF4Ln4Tii3",
			"608":"1pkBSTxIWi2UJG0MGRY5qhGktRhfiLV9l",
			"609":"11mnk9gav_Ky5VFWnjp0OGLA8nB4XQqAp",
			"610":"1txUf_mKJkSpbV7FRPToS2nDZ3rz4xYVx",
			"611":"1QqNswO3VQu9Kp8hG8B7WOYczlmqdpYOC",
			"612":"12B_KXErAvW1uX2LufLMl82aACK_Lpxaz",
			"613":"13OE2oB8VD7FL24LZOfI2JnfJWOV2u-HV",
			"614":"1bRRKmfIaLmb7wRDNv0DJnTe0STQu7RrK",
			"615":"1Xiw5qGuTcoei2D2qsE3lneo3MOc8mVU7",
			"616":"1a5Cs1vVGVshKcbUSm7N3cXWr9hCsAl5M",
			"617":"1Rv1Jem-7uW56geK08eVz-MmF9_-amPal",
			"618":"1ur-BsqENvoOTg_Qq5VrhsZCCiUQG7jE-",
			"619":"1w7WZagCqnOqwqls0_H9waR6LAR_jTaZa",
			"620":"1BC8mxxJG8GeEq5j4BQJnfUq_8Q4PoPxx",
			"621":"1_EKTS6hS-Alr76blddE4rbzXqvrDqYch",
			"622":"1AVxgGzGleMgiKW_GxKtud2AAVJ8G48Ir",
			"623":"1id-CIgsi_BoVo4MPXXPB_2vEtKQ2RFQd",
			"624":"1ciVO-7FaXqGrl7Rg5S4E7aN627J5am6B",
			"625":"18WJI0Uyv5KG2s2psIdzl_mHZideVH05E",
			"626":"1EXW1G2QxInHyG2S4fPDjLK-CmbNI9vyV",
			"627":"1VaiH8ORKSH_FVgdkoPi0V-2y7-wDPSpD",
			"628":"1zU2MetG0k0uOTxicNkIIrgUk_y4syij9",
			"629":"11ZXm_iX-ahz5gPWTLzYKTOwmfjrxCF29",
			"630":"15_hoznr3MpNBeOQ1A1ohfTaKb4E6UDBm",
			"631":"1rO3r0ZJQnwcwBfzRcTOt1KTppTvG2fLc",
			"632":"1cuazSDAH974w47ctD3FkodyhBNood9hT",
        },
        
    };
	 
 const photostockData = [
   { id: 'kjg', label: 'Tambak Lorok' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock background new2.jpeg`}
						
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_sswj;
