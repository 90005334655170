import React, { useState } from 'react';

// Definisikan style di bagian atas
const styles = {
  container: {
    margin: '10px 0px 0px -30px',
    cursor: 'pointer',
    fontSize: '13px',
    position: 'relative',
  },
  card: {
    position: 'absolute',
    top: '100%',
    left: '0',
    marginTop: '10px',
    padding: '10px',
    width: '200px',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    zIndex: 1000,
  },
  text: {
    fontSize: '12px',
    color: '#333',
  },
  list: {
    fontSize: '12px',
  }
};

const HoverInfoCards = () => {
  // State untuk mengatur tampilan card saat hover
  const [hoveredItem, setHoveredItem] = useState(null);

  // Fungsi untuk mengubah state saat mouse hover
  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  // Fungsi untuk mengubah state saat mouse keluar
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div>
      {/* Lightning Protection System */}
      <div
        style={styles.container}
        onMouseEnter={() => handleMouseEnter('lightning_protection_system')}
        onMouseLeave={handleMouseLeave}
        onClick={() => toggleCollapse('lightning_protection_system')}
      >
        <ul>
          <li>
            <table>
              <tbody>
                <tr>
                  <td valign="top">
                    <span className="lbn" style={styles.list}></span>
                  </td>
                  <td>Lightning Protection System</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>

        {/* Card untuk penjelasan Lightning Protection System */}
        {hoveredItem === 'lightning_protection_system' && (
          <div style={styles.card}>
            <p style={styles.text}>
              Lightning Protection System is designed to protect buildings and structures from damage caused by lightning strikes.
            </p>
          </div>
        )}
      </div>

      {/* Quantitative Risk Assessment */}
      <div
        style={styles.container}
        onMouseEnter={() => handleMouseEnter('quantitative_risk_assessment')}
        onMouseLeave={handleMouseLeave}
        onClick={() => toggleCollapse('quantitative_risk_assessment')}
      >
        <ul>
          <li>
            <table>
              <tbody>
                <tr>
                  <td valign="top">
                    <span className="lbn" style={styles.list}></span>
                  </td>
                  <td>Quantitative Risk Assessment</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>

        {/* Card untuk penjelasan Quantitative Risk Assessment */}
        {hoveredItem === 'quantitative_risk_assessment' && (
          <div style={styles.card}>
            <p style={styles.text}>
              Quantitative Risk Assessment is a systematic approach to assessing and managing risk by quantifying potential losses and their probabilities.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

// Fungsi untuk menampilkan atau menyembunyikan konten
const toggleCollapse = (id) => {
  console.log(`Toggling collapse for ${id}`);
  // Tambahkan logika yang sesuai untuk mengubah tampilan elemen berdasarkan ID
};

export default HoverInfoCards;
