import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";

const RBIcalc = () => {

  const [openSection, setOpenSection] = useState('basicData');
  const [loading, setLoading] = useState(false);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  const navigate = useNavigate();
	
  const [materialData, setMaterialData] = useState([]);
  const [EquipmenttypeData, setEquipmenttypeData] = useState([]);
  const [Componenttype, setComponenttype] = useState([]);
  const [Geometrytype, setGeometrytype] = useState([]);
  const [Representativefluid, setRepresentativefluid] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedEquipmenttype, setSelectedEquipmenttype] = useState(null);
  const [selectedComponenttype, setSelectedComponenttype] = useState(null);
  const [selectedGeometrytype, setSelectedGeometrytype] = useState(null);
  const [selectedRepresentativefluid, setSelectedRepresentativefluid] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const defaultOptionDatainspeksi = false;
  const [selectedDatainspeksi, setSelectedDatainspeksi] = useState(defaultOptionDatainspeksi);
  const defaultOptionHeattracing = false;
  const [selectedHeattracing, setSelectedHeattracing] = useState(defaultOptionHeattracing);
  const defaultOptionCladding = false;
  const [selectedCladding, setSelectedCladding] = useState(defaultOptionCladding);
  const defaultOptionInsulation = false;
  const [selectedInsulation, setSelectedInsulation] = useState(defaultOptionInsulation);
  const defaultOptionPWHT = false;
  const [selectedPWHT, setSelectedPWHT] = useState(defaultOptionPWHT);
  const defaultOptionLining = false;
  const [selectedLining, setSelectedLining] = useState(defaultOptionLining);
  const defaultOptionCoating = true;
  const [selectedCoating, setSelectedCoating] = useState(defaultOptionCoating);
  const [selectedCausticpresen, setSelectedCausticpresen] = useState(null);
  const [selectedAlkalinewater, setSelectedAlkalinewater] = useState(null);
  const [selectedOilwithsulfurcompounds, setSelectedOilwithsulfurcompounds] = useState(null);
  const [selectedChloridesppm, setSelectedChloridesppm] = useState(null);
  const [selectedFreewater, setSelectedFreewater] = useState(null);
  const [selectedH2Sandhydrogen, setSelectedH2Sandhydrogen] = useState(null);
  const [selectedOxygen, setSelectedOxygen] = useState(null);
  const [selectedH2S, setSelectedH2S] = useState(null);
  const [selectedHCl, setSelectedHCl] = useState(null);
  const [selectedH2SO4, setSelectedH2SO4] = useState(null);
  const [selectedHF, setSelectedHF] = useState(null);
  const [selectedCO2, setSelectedCO2] = useState(null);
  const [selectedMistoverspray, setSelectedMistoverspray] = useState(null);
  const [selectedSteamvents, setSelectedSteamvents] = useState(null);
  const [selectedDelugesystems, setSelectedDelugesystems] = useState(null);
  const [selectedChloridecontaining, setSelectedChloridecontaining] = useState(null);
  const [selectedChlorideswndwater, setSelectedChlorideswndwater] = useState(null);
  const [selectedHydrofluoricacid, setSelectedHydrofluoricacid] = useState(null);
  const [selectedAcidgastreatingamines, setSelectedAcidgastreatingamines] = useState(null);
  const [selectedSoil, setSelectedSoil] = useState(null);
  const [selectedTF350, setSelectedTF350] = useState(null);
  const [selectedTF300, setSelectedTF300] = useState(null);
  const [selectedT100, setSelectedT100] = useState(null);
  const [selectedT350, setSelectedT350] = useState(null);
  const [selectedT1070, setSelectedT1070] = useState(null);
  const [selectedT1050, setSelectedT1050] = useState(null);
  const [selectedT1700, setSelectedT1700] = useState(null);
  const [selectedT400, setSelectedT400] = useState(null);
  const [selectedT900, setSelectedT900] = useState(null);
  const [selectedHydrogenpartial, setSelectedHydrogenpartial] = useState(null);
  const [selectedSubjecttoprocess, setSelectedSubjecttoprocess] = useState(null);
  const [selectedNotnormally, setSelectedNotnormally] = useState(null);
  const [selectedWithdeteriorated, setSelectedWithdeteriorated] = useState(null);
  const [selectedPenetrationsandvisually, setSelectedPenetrationsandvisually] = useState(null);
  const [selectedMDMTorMAT, setSelectedMDMTorMAT] = useState(null);
  const [selectedHavebeenpast, setSelectedHavebeenpast] = useState(null);
  const [selectedPh7, setSelectedPh7] = useState(null);
  const [selectedAllregular300, setSelectedAllregular300] = useState(null);
  const [selectedHGrade300, setSelectedHGrade300] = useState(null);
  const [selectedLGrade300, setSelectedLGrade300] = useState(null);
  const [selectedStainlesssteel321, setSelectedStainlesssteel321] = useState(null);
  const [selectedStainlesssteel347, setSelectedStainlesssteel347] = useState(null);
  
  //DF Thinning
  const [selectedApplicable, setSelectedApplicable] = useState(null);
  const [selectedInjectionmixpoints, setSelectedInjectionmixpoints] = useState(null);
  const [selectedDeadlegcontain, setSelectedDeadlegcontain] = useState(null);
  const [selectedWeldedconstructionwelded, setSelectedWeldedconstructionwelded] = useState(null);
  const [selectedInspectioneffectivenesscategory, setSelectedInspectioneffectivenesscategory] = useState(null);
  const [selectedMaintenanceAPISTD563Maintained, setSelectedMaintenanceAPISTD563Maintained] = useState(null);
  const [selectedSettlementcriteria, setSelectedSettlementcriteria] = useState(null);
  const [selectedOnlinemonitoringThinningmechanism, setSelectedOnlinemonitoringThinningmechanism] = useState(null);
  const [selectedOnlinemonitoringMonitoringtype, setSelectedOnlinemonitoringMonitoringtype] = useState(null);
  const [selectedLiningtype, setSelectedLiningtype] = useState(null);
  const [selectedAgeofLiningoryearage, setSelectedAgeofLiningoryearage] = useState(null);
  const [selectedInorganicliningstype, setSelectedInorganicliningstype] = useState(null);
  const [selectedOrgaliningstype, setSelectedOrgaliningstype] = useState(null);
  const [selectedMonitoringcontain, setSelectedMonitoringcontain] = useState(null);
  const [selectedLiningconditionqualitativecondition, setSelectedLiningconditionqualitativecondition] = useState(null);
  
  //DF External
  const [selectedExtDriver, setSelectedExtDriver] = useState(null);
  const [selectedEquipmentDesignorFabrication, setSelectedEquipmentDesignorFabrication] = useState(null);
  const [selectedInterface, setSelectedInterface] = useState(null);
  const [selectedCoatingQuality, setSelectedCoatingQuality] = useState(null);
  const [selectedCfcDriver, setSelectedCfcDriver] = useState(null);
  const [selectedInsulationType, setSelectedInsulationType] = useState(null);
  const [selectedComplexity, setSelectedComplexity] = useState(null);
  const [selectedInsulationCondition, setSelectedInsulationCondition] = useState(null);
  const [selectedCfcDesignorFabrication, setSelectedCfcDesignorFabrication] = useState(null);
  const [selectedInterfaceenterssoilorwater, setSelectedInterfaceenterssoilorwater] = useState(null);
  const [selectedEcfDrive, setSelectedEcfDrive] = useState(null);
  const [selectedEcfDrivecui, setSelectedEcfDrivecui] = useState(null);
  const [selectedEcfCracking, setSelectedEcfCracking] = useState(null);
  const [selectedEcfCrackingcui, setSelectedEcfCrackingcui] = useState(null);
  const [selectedChloridefreecui, setSelectedChloridefreecui] = useState(null);
  const [EcfInspectionPerformed, setEcfInspectionPerformed] = useState([]);
  const [selectedEcfInspectionPerformed, setSelectedEcfInspectionPerformed] = useState(null);
  const [InspectionPerformedcui, setInspectionPerformedcui] = useState([]);
  const [selectedInspectionPerformedcui, setSelectedInspectionPerformedcui] = useState(null);
  
  //DF SCC
  const [DetermineSusceptibility, setDetermineSusceptibility] = useState([]);
  const [selectedDetermineSusceptibility, setSelectedDetermineSusceptibility] = useState(null);
  const [ACDetermineSusceptibility, setACDetermineSusceptibility] = useState([]);
  const [selectedACDetermineSusceptibility, setSelectedACDetermineSusceptibility] = useState(null);
  const [CRNumberofInspections, setCRNumberofInspections] = useState([]);
  const [selectedCRNumberofInspections, setSelectedCRNumberofInspections] = useState(null);
  const [ACNumberofInspections, setACNumberofInspections] = useState([]);
  const [selectedACNumberofInspections, setSelectedACNumberofInspections] = useState(null);
  const [Sscphwater, setSscphwater] = useState([]);
  const [selectedSscphwater, setSelectedSscphwater] = useState(null);
  const [Sscfunctionh2swater, setSscfunctionh2swater] = useState([]);
  const [selectedSscfunctionh2swater, setSelectedSscfunctionh2swater] = useState(null);
  const [selectedSsccyanides, setSelectedSsccyanides] = useState(null);
  const [selectedSscfunctiontreatment, setSelectedSscfunctiontreatment] = useState(null);
  const [selectedSscmaxbrinnell, setSelectedSscmaxbrinnell] = useState(null);
  const [selectedSsccracking, setSelectedSsccracking] = useState(null);
  const [SCCNumberofInspections, setSCCNumberofInspections] = useState([]);
  const [selectedSCCNumberofInspections, setSelectedSCCNumberofInspections] = useState(null);
  const [Shicphwater, setShicphwater] = useState([]);
  const [selectedShicphwater, setSelectedShicphwater] = useState(null);
  const [Shicfunctionh2swater, setShicfunctionh2swater] = useState([]);
  const [selectedShicfunctionh2swater, setSelectedShicfunctionh2swater] = useState(null);
  const [selectedShiccyanides, setSelectedShiccyanides] = useState(null);
  const [selectedShiccracking, setSelectedShiccracking] = useState(null);
  const [selectedShicsulfurcontent, setSelectedShicsulfurcontent] = useState(null);
  const [ShicNumberofInspections, setShicNumberofInspections] = useState([]);
  const [selectedShicNumberofInspections, setSelectedShicNumberofInspections] = useState(null);
  const [selectedHicmonitoringmethod, setSelectedHicmonitoringmethod] = useState(null);
  const [Acephwater, setAcephwater] = useState([]);
  const [selectedAcephwater, setSelectedAcephwater] = useState(null);
  const [selectedAceCO3, setSelectedAceCO3] = useState(null);
  const [selectedAcecracking, setSelectedAcecracking] = useState(null);
  const [selectedPACracking, setSelectedPACracking] = useState(null);
  const [selectedCscccracking, setSelectedCscccracking] = useState(null);
  const [selectedHsccracking, setSelectedHsccracking] = useState(null);
  const [selectedHicsohiccracking, setSelectedHicsohiccracking] = useState(null);
  const [selectedHicsohicsulfurcontent, setSelectedHicsohicsulfurcontent] = useState(null);
  const [selectedHicsohicadjustment, setSelectedHicsohicadjustment] = useState(null);
  const [selectedPamaterial, setSelectedPamaterial] = useState(null);
  const [selectedCsccoperatingtemperature, setSelectedCsccoperatingtemperature] = useState(null);
  const [selectedCsccchloride, setSelectedCsccchloride] = useState(null);
  const [selectedAcefoht, setSelectedAcefoht] = useState(null);
  const [selectedShicfunctiontreatment, setSelectedShicfunctiontreatment] = useState(null);
  const [selectedHsccondition, setSelectedHsccondition] = useState(null);
  const [selectedHscmaxbrinnell, setSelectedHscmaxbrinnell] = useState(null);
  
  //DF Brittle
  const [selectedHthadamagecondition, setSelectedHthadamagecondition] = useState(null);
  const [selectedAdmprocon, setSelectedAdmprocon] = useState(null);
  const [selectedBfasmeexcurve, setSelectedBfasmeexcurve] = useState(null);
  const [selectedLaseaorprocon, setSelectedLaseaorprocon] = useState(null);
  const [selectedLasasmecurve, setSelectedLasasmecurve] = useState(null);
  const [selectedEmbaopc, setSelectedEmbaopc] = useState(null);
  const [selectedSigmaembcontent, setSelectedSigmaembcontent] = useState(null);
  
  //DF Fatigue
  const [selectedPrefacriteria, setSelectedPrefacriteria] = useState(null);
  const [selectedPrefadfbpf, setSelectedPrefadfbpf] = useState(null);
  const [selectedVisshcriteria, setSelectedVisshcriteria] = useState(null);
  const [selectedTocycriteria, setSelectedTocycriteria] = useState(null);
  const [selectedAdcocriteria, setSelectedAdcocriteria] = useState(null);
  const [selectedAdpicriteria, setSelectedAdpicriteria] = useState(null);
  const [selectedAdcoicriteria, setSelectedAdcoicriteria] = useState(null);
  const [selectedAdjotcriteria, setSelectedAdjotcriteria] = useState(null);
  const [selectedAdbrdicriteria, setSelectedAdbrdicriteria] = useState(null);
  
  //COF
  const [selectedHolesize1, setSelectedHolesize1] = useState(null);
  const [selectedHolesize2, setSelectedHolesize2] = useState(null);
  const [selectedHolesize3, setSelectedHolesize3] = useState(null);
  const [selectedHolesize4, setSelectedHolesize4] = useState(null);
  const [selectedDetsysty, setSelectedDetsysty] = useState(null);
  const [selectedIsosysty, setSelectedIsosysty] = useState(null);
  const [selectedConarmit, setSelectedConarmit] = useState(null);
  const [selectedFluidtypecof, setSelectedFluidtypecof] = useState(null);
  const [selectedToxflucov, setSelectedToxflucov] = useState(null);
  
  const [selectedSulfurbearing, setSelectedSulfurbearing] = useState(null);
  const [selectedyearoffabricationmaterial, setSelectedyearoffabricationmaterial] = useState(null);
  const [selectedFluidphase, setSelectedFluidphase] = useState(null);
  const [selectedFluidatnormaloperatingconditions, setSelectedFluidatnormaloperatingconditions] = useState(null);
  const [selectedFluidatambientconditions, setSelectedFluidatambientconditions] = useState(null);
  const [selectedDataconfidencedamagestate1, setSelectedDataconfidencedamagestate1] = useState(null);
  const [selectedDataconfidencedamagestate2, setSelectedDataconfidencedamagestate2] = useState(null);
  const [selectedDataconfidencedamagestate3, setSelectedDataconfidencedamagestate3] = useState(null);
  
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };
  
  const generateRandomNumber = () => {
    return Math.floor(100000000 + Math.random() * 900000000); // menghasilkan angka dari 100000000 hingga 999999999
  };

  // State untuk menyimpan nilai input
  const [formData, setFormData] = useState({
	  
	genrannum: generateRandomNumber(),
    tag_number: "14-PG-16000-EC2-N",
    piping_service: "PRODUCED GAS",
    location_from: "",
    location_to: "",
    installation_date: 2015,
    Thicknessactual: "",
    coating_installation_date: getTodayDate(),
    grade: "Schedule 80",
    material: "Carbon Steel", 
    Designcode: "",
    Outsidediameter: 14,
    Length: 39,
    Corrosionallowance: 0.125,
    Nominalthickness: 0.750,
    Inspectiondate: 2024,
    Inspectiondatecomplete: "2024-10-08",
    Remaininglifeofthecladding: "",
    Corrosionratecladding: "",
    Weldjointefficiency: 1,
    Yieldstrength: 35000,
    Tensilestrength: 60000,
    Allowablestress: 20000,
    Designpressure: 2115,
    Designtemperature: 140,
    Operatingpressure: 1277,
    Operatingtemperature: 84,
    Minimumdesigntemperature: 0,
    Upsettemperature: 32,
    Coatinginstallationdate: 2015,
    Previousinspectiondate: 2015,
    Previousthicknessreading: 0,
    Corrosionprobe: "",
    Corrosioncoupon: 0.0001271,
	PIDNumber: "",
	position: "",
	Heattracing: "No",
	Cladding: "No",
	Insulation: "No",
	PWHT: "No",
	Lining: "No",
	Coating: "Yes",
	
	//Screening
	yearoffabricationmaterial:"",
	Representativefluid:"",
	Fluidphase:"",
	Fluidatnormaloperatingconditions:"",
	Fluidatambientconditions:"",
	Areaft2:2000,
	Dataconfidencedamagestate1:"",
	Dataconfidencedamagestate2:"",
	Dataconfidencedamagestate3:"",
	Numberofpastinspection: 0,
	InspectionA: 0,
	InspectionB: 0,
	InspectionC: 0,
	InspectionD: 0,
	Causticpresen:"",
	Alkalinewater:"",
	Oilwithsulfurcompounds:"",
	Chloridesppm:"",
	Freewater:"",
	H2Sandhydrogen:"",
	Oxygen:"",
	H2S:"",
	HCl:"",
	H2SO4:"",
	HF:"",
	CO2:"",
	Mistoverspray:"",
	Steamvents:"",
	Delugesystems:"",
	Chloridecontaining:"",
	Sulfurbearing:"",
	Chlorideswndwater:"",
	Hydrofluoricacid:"",
	Acidgastreatingamines:"",
	Soil:"",
	TF350:"",
	TF300:"",
	T100:"",
	T350:"",
	T1070:"",
	T1050:"",
	T1700:"",
	T400:"",
	T900:"",
	Hydrogenpartial:"",
	Subjecttoprocess:"",
	Notnormally:"",
	Withdeteriorated:"",
	Penetrationsandvisually:"",
	MDMTorMAT:"",
	Havebeenpast:"",
	Ph7:"",
	Allregular300:"",
	HGrade300:"",
	LGrade300:"",
	Stainlesssteel321:"",
	Stainlesssteel347:"",
	
	//DF Thinning
	Applicable:"",
	ReliabilityindicesDS1:1,
	ReliabilityindicesDS2:2,
	ReliabilityindicesDS3:4,
	ReliabilityindicesCOVAT:0.2,
	ReliabilityindicesCOVSf:0.2,
	ReliabilityindicesCOVP:0.05,
	Inspectioneffectivenesscategory:"",
	Injectionmixpoints:"",
	Deadlegcontain:"",
	Weldedconstructionwelded:"",
	MaintenanceAPISTD563Maintained:"",
	Settlementcriteria:"",
	OnlinemonitoringThinningmechanism:"",
	OnlinemonitoringMonitoringtype:"",
	Liningtype:"",
	AgeofLiningoryearage:"",
	Inorganicliningstype:"",
	Orgaliningstype:"",
	Monitoringcontain:"",
	Liningconditionqualitativecondition:"",
	
	//DF External
	ExtDriver: "",
	EquipmentDesignorFabrication:"",
	Interface: "",
	CoatingQuality: "",
	CfcDriver: "",
	InsulationType: "",
	Complexity: "",
	InsulationCondition: "",
	CfcDesignorFabrication: "",
	Interfaceenterssoilorwater: "",
	EcfDrive: "",
	EcfCracking: "",
	EcfInspectionPerformed: "",
	EcfDrivecui: "",
	EcfCrackingcui: "",
	Chloridefreecui: "",
	InspectionPerformedcui: "",
	
	//DF SCC
	DetermineSusceptibility: "",
	CRNumberofInspections: "",
	ACDetermineSusceptibility: "",
	ACNumberofInspections: "",
	Sscphwater: "",
	Sscfunctionh2swater: "",
	Ssccyanides: "",
	Sscfunctiontreatment:"",
	Sscmaxbrinnell: "",
	Ssccracking: "",
	SCCNumberofInspections: "",
	Shicphwater: "",
	Shicfunctionh2swater: "",
	Shiccyanides: "",
	Shiccracking: "",
	Shicfunctiontreatment: "",
	Shicsulfurcontent: "",
	ShicNumberofInspections: "",
	Hicmonitoringmethod: "",
	Acephwater: "",
	AceCO3: "",
	Acecracking: "",
	Acefoht: "",
	PACracking: "",
	Pamaterial: "",
	Csccoperatingtemperature: "",
	Csccchloride: "",
	Cscccracking: "",
	Hsccondition: "",
	Hscmaxbrinnell: "",
	Hsccracking: "",
	Hicsohiccracking: "",
	Hicsohicsulfurcontent: "",
	Hicsohicadjustment: "",
	
	//DF Brittle
	Hthadamagecondition: "",
	Admprocon: "",
	Bfasmeexcurve: "",
	Laseaorprocon: "",
	Lasasmecurve: "",
	Embaopc: "",
	Embreftem:80,
	Sigmaembcontent: "",
	
	//DF Fatigue
	Prefacriteria: "",
	Prefadfbpf: "",
	Visshcriteria: "",
	Tocycriteria: "",
	Adcocriteria: "",
	Adpicriteria: "",
	Adcoicriteria: "",
	Adjotcriteria: "",
	Adbrdicriteria: "",
	
	//COF
	Holesize1: "",
	Holesize2: "",
	Holesize3: "",
	Holesize4: "",
	Atmosprepatm: 14.696,
	Confac2: 1,
	Detsysty: "",
	Isosysty: "",
	Toledur: 0,
	Conarmit: "",
	Fluidtypecof: "",
	Toxperthetoxcom: 1,
	Toxflucov: "",
	Acidorcaus5: 0,
	Acidorcaus6: 0,
	Acidorcaus7: 0,
	Acidorcaus8: 0,
	Blenfac1: 0,
	Blenfac2: 0,
	Blenfac3: 0,
	Blenfac4: 0,
	Caforstoracleaknon1: 0,
	Caforstoracleaknon2: 0,
	Caforstoracleaknon3: 0,
	Caforstoracleaknon4: 0,
	Finoflnotoxcon: 0,
	Matcosfacmat: 1,
	Eqcosfac: 5000,
	Eqoutmul: 3,
	Procoxprod: 10000,
	Encosen: 1000,
	
  });
  
  const handleOpenRBIprint = () => {
    navigate(`/rbiprint?genrannum=${formData.genrannum}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMaterialChange = (selectedOption) => {
    setSelectedMaterial(selectedOption); // Set nilai selected ke state
    setFormData(prevFormData => ({
      ...prevFormData,
      material: selectedOption ? selectedOption.value : ""
    }));
    console.log("Material Construction selected:", selectedOption);
  };
  
  const handleEquipmenttypeChange = (selectedOption) => {
    setSelectedEquipmenttype(selectedOption); // Set nilai selected ke state
    setFormData(prevFormData => ({
      ...prevFormData,
      Equipmenttype: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleComponenttypeChange = (selectedOption) => {
    setSelectedComponenttype(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Componenttype: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleGeometrytypeChange = (selectedOption) => {
    setSelectedGeometrytype(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Geometrytype: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleRepresentativefluidChange = (selectedOption) => {
    setSelectedRepresentativefluid(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Representativefluid: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePositionChange = (selectedOption) => {
    setSelectedPosition(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      position: selectedOption ? selectedOption.value : ""
	  
    }));
  };
  
  const handleDatainspeksiChange = (e) => {
    const isChecked = e.target.checked;
    setSelectedDatainspeksi(isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      Thicknessactual: isChecked ? prevFormData.Thicknessactual : "",
    }));
  };
  
  const handleHeattracingChange = (e) => {
    const isChecked = e.target.checked;
    setSelectedHeattracing(isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      Heattracing: isChecked ? "Yes" : "No"
    }));
  };
  
  const handleCladdingChange = (e) => {
    const isChecked = e.target.checked;
    setSelectedCladding(isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      Cladding: isChecked ? "Yes" : "No",
	  Remaininglifeofthecladding: isChecked ? prevFormData.Remaininglifeofthecladding : "",
	  Corrosionratecladding: isChecked ? prevFormData.Corrosionratecladding : ""
    }));
  };
  
  const handleInsulationChange = (e) => {
    const isChecked = e.target.checked;
	setSelectedInsulation(isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      Insulation: isChecked ? "Yes" : "No"
    }));
  };
  
  const handlePWHTChange = (e) => {
    const isChecked = e.target.checked;
    setSelectedPWHT(isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      PWHT: isChecked ? "Yes" : "No"
    }));
  };
  
  const handleLiningChange = (e) => {
    const isChecked = e.target.checked;
    setSelectedLining(isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      Lining: isChecked ? "Yes" : "No"
    }));
  };
  
  const handleCoatingChange = (e) => {
    const isChecked = e.target.checked;
    setSelectedCoating(isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      Coating: isChecked ? "Yes" : "No"
    }));
  };
  
  const handleCausticpresenChange = (selectedOption) => {
    setSelectedCausticpresen(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Causticpresen: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAlkalinewaterChange = (selectedOption) => {
    setSelectedAlkalinewater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Alkalinewater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleOilwithsulfurcompoundsChange = (selectedOption) => {
    setSelectedOilwithsulfurcompounds(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Oilwithsulfurcompounds: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleChloridesppmChange = (selectedOption) => {
    setSelectedChloridesppm(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Chloridesppm: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleFreewaterChange = (selectedOption) => {
    setSelectedFreewater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Freewater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleH2SandhydrogenChange = (selectedOption) => {
    setSelectedH2Sandhydrogen(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      H2Sandhydrogen: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleOxygennChange = (selectedOption) => {
    setSelectedOxygen(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Oxygen: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleH2SChange = (selectedOption) => {
    setSelectedH2S(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      H2S: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHClChange = (selectedOption) => {
    setSelectedHCl(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      HCl: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleH2SO4Change = (selectedOption) => {
    setSelectedH2SO4(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      H2SO4: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHFChange = (selectedOption) => {
    setSelectedHF(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      HF: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCO2Change = (selectedOption) => {
    setSelectedCO2(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      CO2: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleMistoversprayChange = (selectedOption) => {
    setSelectedMistoverspray(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Mistoverspray: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSteamventsChange = (selectedOption) => {
    setSelectedSteamvents(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Steamvents: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleDelugesystemsChange = (selectedOption) => {
    setSelectedDelugesystems(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Delugesystems: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleChloridecontainingChange = (selectedOption) => {
    setSelectedChloridecontaining(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Chloridecontaining: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSulfurbearingChange = (selectedOption) => {
    setSelectedSulfurbearing(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sulfurbearing: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleChlorideswndwaterChange = (selectedOption) => {
    setSelectedChlorideswndwater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Chlorideswndwater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHydrofluoricacidChange = (selectedOption) => {
    setSelectedHydrofluoricacid(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hydrofluoricacid: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAcidgastreatingaminesChange = (selectedOption) => {
    setSelectedAcidgastreatingamines(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Acidgastreatingamines: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSoilChange = (selectedOption) => {
    setSelectedSoil(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Soil: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleTF350Change = (selectedOption) => {
    setSelectedTF350(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      TF350: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleTF300Change = (selectedOption) => {
    setSelectedTF300(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      TF300: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleT100Change = (selectedOption) => {
    setSelectedT100(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      T100: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleT350Change = (selectedOption) => {
    setSelectedT350(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      T350: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleT1070Change = (selectedOption) => {
    setSelectedT1070(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      T1070: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleT1050Change = (selectedOption) => {
    setSelectedT1050(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      T1050: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleT1700Change = (selectedOption) => {
    setSelectedT1700(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      T1700: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleT400Change = (selectedOption) => {
    setSelectedT400(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      T400: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleT900Change = (selectedOption) => {
    setSelectedT900(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      T900: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHydrogenpartialChange = (selectedOption) => {
    setSelectedHydrogenpartial(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hydrogenpartial: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSubjecttoprocessChange = (selectedOption) => {
    setSelectedSubjecttoprocess(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Subjecttoprocess: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleNotnormallyChange = (selectedOption) => {
    setSelectedNotnormally(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Notnormally: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleWithdeterioratedChange = (selectedOption) => {
    setSelectedWithdeteriorated(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Withdeteriorated: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePenetrationsandvisuallyChange = (selectedOption) => {
    setSelectedPenetrationsandvisually(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Penetrationsandvisually: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleMDMTorMATChange = (selectedOption) => {
    setSelectedMDMTorMAT(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      MDMTorMAT: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHavebeenpastChange = (selectedOption) => {
    setSelectedHavebeenpast(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Havebeenpast: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePh7Change = (selectedOption) => {
    setSelectedPh7(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Ph7: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAllregular300Change = (selectedOption) => {
    setSelectedAllregular300(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Allregular300: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHGrade300Change = (selectedOption) => {
    setSelectedHGrade300(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      HGrade300: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleLGrade300Change = (selectedOption) => {
    setSelectedLGrade300(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      LGrade300: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleStainlesssteel321Change = (selectedOption) => {
    setSelectedStainlesssteel321(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Stainlesssteel321: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleStainlesssteel347Change = (selectedOption) => {
    setSelectedStainlesssteel347(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Stainlesssteel347: selectedOption ? selectedOption.value : ""
    }));
  };
  
  //DF Thinnning
  const handleApplicableChange = (selectedOption) => {
    setSelectedApplicable(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Applicable: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInjectionmixpointsChange = (selectedOption) => {
    setSelectedInjectionmixpoints(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Injectionmixpoints: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleDeadlegcontainChange = (selectedOption) => {
    setSelectedDeadlegcontain(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Deadlegcontain: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleWeldedconstructionweldedChange = (selectedOption) => {
    setSelectedWeldedconstructionwelded(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Weldedconstructionwelded: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInspectioneffectivenesscategoryChange = (selectedOption) => {
    setSelectedInspectioneffectivenesscategory(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Inspectioneffectivenesscategory: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleMaintenanceAPISTD563MaintainedChange = (selectedOption) => {
    setSelectedMaintenanceAPISTD563Maintained(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      MaintenanceAPISTD563Maintained: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSettlementcriteriaChange = (selectedOption) => {
    setSelectedSettlementcriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Settlementcriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleOnlinemonitoringThinningmechanismChange = (selectedOption) => {
    setSelectedOnlinemonitoringThinningmechanism(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      OnlinemonitoringThinningmechanism: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleOnlinemonitoringMonitoringtypeChange = (selectedOption) => {
    setSelectedOnlinemonitoringMonitoringtype(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      OnlinemonitoringMonitoringtype: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleLiningtypeChange = (selectedOption) => {
    setSelectedLiningtype(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Liningtype: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAgeofLiningoryearageChange = (selectedOption) => {
    setSelectedAgeofLiningoryearage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      AgeofLiningoryearage: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInorganicliningstypeChange = (selectedOption) => {
    setSelectedInorganicliningstype(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Inorganicliningstype: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleOrgaliningstypeChange = (selectedOption) => {
    setSelectedOrgaliningstype(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Orgaliningstype: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleMonitoringcontainChange = (selectedOption) => {
    setSelectedMonitoringcontain(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Monitoringcontain: selectedOption ? selectedOption.value : ""
    }));
  };
  
  //DF External
  const handleExtDriverChange = (selectedOption) => {
    setSelectedExtDriver(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      ExtDriver: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleEquipmentDesignorFabricationChange = (selectedOption) => {
    setSelectedEquipmentDesignorFabrication(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      EquipmentDesignorFabrication: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInterfaceChange = (selectedOption) => {
    setSelectedInterface(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Interface: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCoatingQualityChange = (selectedOption) => {
    setSelectedCoatingQuality(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      CoatingQuality: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCfcDriverChange = (selectedOption) => {
    setSelectedCfcDriver(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      CfcDriver: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInsulationTypeChange = (selectedOption) => {
    setSelectedInsulationType(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      InsulationType: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleComplexityChange = (selectedOption) => {
    setSelectedComplexity(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Complexity: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInsulationConditionChange = (selectedOption) => {
    setSelectedInsulationCondition(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      InsulationCondition: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCfcDesignorFabricationChange = (selectedOption) => {
    setSelectedCfcDesignorFabrication(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      CfcDesignorFabrication: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInterfaceenterssoilorwaterChange = (selectedOption) => {
    setSelectedInterfaceenterssoilorwater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Interfaceenterssoilorwater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleEcfDriveChange = (selectedOption) => {
    setSelectedEcfDrive(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      EcfDrive: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleEcfDrivecuiChange = (selectedOption) => {
    setSelectedEcfDrivecui(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      EcfDrivecui: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleEcfCrackingChange = (selectedOption) => {
    setSelectedEcfCracking(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      EcfCracking: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleEcfCrackingcuiChange = (selectedOption) => {
    setSelectedEcfCrackingcui(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      EcfCrackingcui: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleChloridefreecuiChange = (selectedOption) => {
    setSelectedChloridefreecui(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Chloridefreecui: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleEcfInspectionPerformedChange = (selectedOption) => {
    setSelectedEcfInspectionPerformed(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      EcfInspectionPerformed: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleInspectionPerformedcuiChange = (selectedOption) => {
    setSelectedInspectionPerformedcui(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      InspectionPerformedcui: selectedOption ? selectedOption.value : ""
    }));
  };
  
  
  
  //DF SCC
  const handleDetermineSusceptibilityChange = (selectedOption) => {
    setSelectedDetermineSusceptibility(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      DetermineSusceptibility: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleACDetermineSusceptibilityChange = (selectedOption) => {
    setSelectedACDetermineSusceptibility(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      ACDetermineSusceptibility: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCRNumberofInspectionsChange = (selectedOption) => {
    setSelectedCRNumberofInspections(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      CRNumberofInspections: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleACNumberofInspectionsChange = (selectedOption) => {
    setSelectedACNumberofInspections(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      ACNumberofInspections: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSscphwaterChange = (selectedOption) => {
    setSelectedSscphwater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sscphwater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSscfunctionh2swaterChange = (selectedOption) => {
    setSelectedSscfunctionh2swater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sscfunctionh2swater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSsccyanidesChange = (selectedOption) => {
    setSelectedSsccyanides(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Ssccyanides: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSscfunctiontreatmentChange = (selectedOption) => {
    setSelectedSscfunctiontreatment(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sscfunctiontreatment: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHscconditionChange = (selectedOption) => {
    setSelectedHsccondition(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hsccondition: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSscmaxbrinnellChange = (selectedOption) => {
    setSelectedSscmaxbrinnell(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sscmaxbrinnell: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHscmaxbrinnellChange = (selectedOption) => {
    setSelectedHscmaxbrinnell(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hscmaxbrinnell: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSsccrackingChange = (selectedOption) => {
    setSelectedSsccracking(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Ssccracking: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSCCNumberofInspectionsChange = (selectedOption) => {
    setSelectedSCCNumberofInspections(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      SCCNumberofInspections: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleShicphwaterChange = (selectedOption) => {
    setSelectedShicphwater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Shicphwater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleShicfunctionh2swaterChange = (selectedOption) => {
    setSelectedShicfunctionh2swater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Shicfunctionh2swater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleShiccyanidesChange = (selectedOption) => {
    setSelectedShiccyanides(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Shiccyanides: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleShiccrackingChange = (selectedOption) => {
    setSelectedShiccracking(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Shiccracking: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleShicsulfurcontentChange = (selectedOption) => {
    setSelectedShicsulfurcontent(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Shicsulfurcontent: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleShicNumberofInspectionsChange = (selectedOption) => {
    setSelectedShicNumberofInspections(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      ShicNumberofInspections: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHicmonitoringmethodChange = (selectedOption) => {
    setSelectedHicmonitoringmethod(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hicmonitoringmethod: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAcephwaterChange = (selectedOption) => {
    setSelectedAcephwater(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Acephwater: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAceCO3Change = (selectedOption) => {
    setSelectedAceCO3(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      AceCO3: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAcecrackingChange = (selectedOption) => {
    setSelectedAcecracking(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Acecracking: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePACrackingChange = (selectedOption) => {
    setSelectedPACracking(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      PACracking: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCscccrackingChange = (selectedOption) => {
    setSelectedCscccracking(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Cscccracking: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHsccrackingChange = (selectedOption) => {
    setSelectedHsccracking(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hsccracking: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHicsohiccrackingChange = (selectedOption) => {
    setSelectedHicsohiccracking(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hicsohiccracking: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHicsohicsulfurcontentChange = (selectedOption) => {
    setSelectedHicsohicsulfurcontent(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hicsohicsulfurcontent: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHicsohicadjustmentChange = (selectedOption) => {
    setSelectedHicsohicadjustment(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hicsohicadjustment: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePamaterialChange = (selectedOption) => {
    setSelectedPamaterial(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Pamaterial: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCsccoperatingtemperatureChange = (selectedOption) => {
    setSelectedCsccoperatingtemperature(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Csccoperatingtemperature: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleCsccchlorideChange = (selectedOption) => {
    setSelectedCsccchloride(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Csccchloride: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAcefohtChange = (selectedOption) => {
    setSelectedAcefoht(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Acefoht: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleShicfunctiontreatmentChange = (selectedOption) => {
    setSelectedShicfunctiontreatment(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Shicfunctiontreatment: selectedOption ? selectedOption.value : ""
    }));
  };
  
  
  //DF Brittle
  const handleHthadamageconditionChange = (selectedOption) => {
    setSelectedHthadamagecondition(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Hthadamagecondition: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAdmproconChange = (selectedOption) => {
    setSelectedAdmprocon(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Admprocon: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleBfasmeexcurveChange = (selectedOption) => {
    setSelectedBfasmeexcurve(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Bfasmeexcurve: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleLaseaorproconChange = (selectedOption) => {
    setSelectedLaseaorprocon(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Laseaorprocon: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleLasasmecurveChange = (selectedOption) => {
    setSelectedLasasmecurve(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Lasasmecurve: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleEmbaopcChange = (selectedOption) => {
    setSelectedEmbaopc(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Embaopc: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleSigmaembcontentChange = (selectedOption) => {
    setSelectedSigmaembcontent(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sigmaembcontent: selectedOption ? selectedOption.value : ""
    }));
  };
  
  //DF Fatigue
  const handlePrefacriteriaChange = (selectedOption) => {
    setSelectedPrefacriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Prefacriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handlePrefadfbpfChange = (selectedOption) => {
    setSelectedPrefadfbpf(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Prefadfbpf: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleVisshcriteriaChange = (selectedOption) => {
    setSelectedVisshcriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Visshcriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleTocycriteriaChange = (selectedOption) => {
    setSelectedTocycriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Tocycriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAdcocriteriaChange = (selectedOption) => {
    setSelectedAdcocriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Adcocriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAdpicriteriaChange = (selectedOption) => {
    setSelectedAdpicriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Adpicriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAdcoicriteriaChange = (selectedOption) => {
    setSelectedAdcoicriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Adcoicriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAdjotcriteriaChange = (selectedOption) => {
    setSelectedAdjotcriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Adjotcriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleAdbrdicriteriaChange = (selectedOption) => {
    setSelectedAdbrdicriteria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Adbrdicriteria: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHolesize1Change = (selectedOption) => {
    setSelectedHolesize1(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Holesize1: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHolesize2Change = (selectedOption) => {
    setSelectedHolesize2(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Holesize2: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHolesize3Change = (selectedOption) => {
    setSelectedHolesize3(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Holesize3: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleHolesize4Change = (selectedOption) => {
    setSelectedHolesize4(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Holesize4: selectedOption ? selectedOption.value : ""
    }));
  };
  
  //COF
  const handleDetsystyChange = (selectedOption) => {
    setSelectedDetsysty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Detsysty: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleIsosystyChange = (selectedOption) => {
    setSelectedIsosysty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Isosysty: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleConarmitChange = (selectedOption) => {
    setSelectedConarmit(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Conarmit: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleFluidtypecofChange = (selectedOption) => {
    setSelectedFluidtypecof(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Fluidtypecof: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleToxflucovChange = (selectedOption) => {
    setSelectedToxflucov(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Toxflucov: selectedOption ? selectedOption.value : ""
    }));
  };
  
  //
  const handleLiningconditionqualitativeconditionChange = (selectedOption) => {
    setSelectedLiningconditionqualitativecondition(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Liningconditionqualitativecondition: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleyearoffabricationmaterialChange = (selectedOption) => {
    setSelectedyearoffabricationmaterial(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      yearoffabricationmaterial: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleFluidphaseChange = (selectedOption) => {
    setSelectedFluidphase(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Fluidphase: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleFluidatnormaloperatingconditionsChange = (selectedOption) => {
    setSelectedFluidatnormaloperatingconditions(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Fluidatnormaloperatingconditions: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleFluidatambientconditionsChange = (selectedOption) => {
    setSelectedFluidatambientconditions(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Fluidatambientconditions: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleDataconfidencedamagestate1Change = (selectedOption) => {
    setSelectedDataconfidencedamagestate1(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Dataconfidencedamagestate1: selectedOption ? selectedOption.value : ""
    }));
  };
  
  const handleDataconfidencedamagestate2Change = (selectedOption) => {
    setSelectedDataconfidencedamagestate2(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Dataconfidencedamagestate2: selectedOption ? selectedOption.value : ""
    }));
  };

  const handleDataconfidencedamagestate3Change = (selectedOption) => {
    setSelectedDataconfidencedamagestate3(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Dataconfidencedamagestate3: selectedOption ? selectedOption.value : ""
    }));
  };

const [riskData, setRiskData] = useState(null);

const handleSubmit = async (e) => {
    e.preventDefault(); // Mencegah refresh halaman setelah form submit
    console.log("Data yang dikirim: ", formData); // Menampilkan data yang akan dikirim
	setLoading(true);

    try {
        // Melakukan request POST ke Google Apps Script
        await fetch('https://script.google.com/macros/s/AKfycbyOfnVnieLqBeOz6rV8PxafT-HEfLT3d_c2ritchTVzEI1SgFPxGnKoZNmcHjF0GvDlUg/exec', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData) // Pastikan ini adalah data yang benar
        });

        // Melakukan request GET untuk mendapatkan hasil dari fungsi doGet
        const id = formData.genrannum; // ID yang akan dikirim sebagai parameter
        const getResultUrl = `https://script.google.com/macros/s/AKfycbyOfnVnieLqBeOz6rV8PxafT-HEfLT3d_c2ritchTVzEI1SgFPxGnKoZNmcHjF0GvDlUg/exec?id=${id}&print=no`;
        
        const getResultResponse = await fetch(getResultUrl);
        const result = await getResultResponse.json(); // Membaca hasil respons dari server
        console.log("Hasil dari server:", result); // Menampilkan hasil respons dari server

		if (result && result.length > 0) {
                setRiskData(result[0]); // Asumsi result[0] berisi objek dengan Risk_matrix dan Risk_level
            } else {
                setRiskData(null); // Mengatur ulang jika tidak ada hasil yang ditemukan
            }
		
    } catch (error) {
        console.error('Error:', error); // Menangani jika ada error dalam request
    }finally {
      setLoading(false); // Menonaktifkan loading setelah selesai
	  document.getElementById('prm_getrannum').innerHTML=formData.genrannum;
	  
	  setTimeout(() => {
          document.getElementById('printicon').style.display="block";
        }, 5000);
	  
    }
};



  useEffect(() => {
    const fetchData = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=tiga`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMaterialData(data);

        const defaultMaterial = data.find(material => material === "Carbon Steel");
        if (defaultMaterial) {
          const defaultOption = { value: defaultMaterial, label: defaultMaterial };
          setSelectedMaterial(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            material: defaultMaterial
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
	
	const fetchData2 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=empat`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEquipmenttypeData(data);
		const defaultEquipmenttype = data.find(Equipmenttype => Equipmenttype === "Pipe");
        if (defaultEquipmenttype) {
          const defaultOption = { value: defaultEquipmenttype, label: defaultEquipmenttype };
          setSelectedEquipmenttype(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Equipmenttype: defaultEquipmenttype
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
	
	fetchData2();
	
	const fetchData3 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=lima`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setComponenttype(data);
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
	
	fetchData3();
	
	const fetchData4 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=enam`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setGeometrytype(data);
		const defaultGeometrytype = data.find(Geometrytype => Geometrytype === "Cylindrical Shell");
        if (defaultGeometrytype) {
          const defaultOption = { value: defaultGeometrytype, label: defaultGeometrytype };
          setSelectedGeometrytype(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Geometrytype: defaultGeometrytype
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
	
	fetchData4();
	
	const fetchData5 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=dua`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setRepresentativefluid(data);
		const defaultRepresentativefluid = data.find(Representativefluid => Representativefluid === "C1 - C2");
        if (defaultRepresentativefluid) {
          const defaultOption = { value: defaultRepresentativefluid, label: defaultRepresentativefluid };
          setSelectedRepresentativefluid(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Representativefluid: defaultRepresentativefluid
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
	
	fetchData5();
	
	const fetchData6 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=delapan`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEcfInspectionPerformed(data);
		const defaultEcfInspectionPerformed = data.find(EcfInspectionPerformed => EcfInspectionPerformed === "Inspection E");
        if (defaultEcfInspectionPerformed) {
          const defaultOption = { value: defaultEcfInspectionPerformed, label: defaultEcfInspectionPerformed };
          setSelectedEcfInspectionPerformed(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            EcfInspectionPerformed: defaultEcfInspectionPerformed
          }));
        }
		
		setInspectionPerformedcui(data);
		const defaultInspectionPerformedcui = data.find(InspectionPerformedcui => InspectionPerformedcui === "Inspection E");
        if (defaultInspectionPerformedcui) {
          const defaultOption2 = { value: defaultInspectionPerformedcui, label: defaultInspectionPerformedcui };
          setSelectedInspectionPerformedcui(defaultOption2);
          setFormData(prevFormData => ({
            ...prevFormData,
            InspectionPerformedcui: defaultInspectionPerformedcui
          }));
        }
		
		setCRNumberofInspections(data);
		const defaultCRNumberofInspections = data.find(CRNumberofInspections => CRNumberofInspections === "Inspection E");
        if (defaultCRNumberofInspections) {
          const defaultOption2 = { value: defaultCRNumberofInspections, label: defaultCRNumberofInspections };
          setSelectedCRNumberofInspections(defaultOption2);
          setFormData(prevFormData => ({
            ...prevFormData,
            CRNumberofInspections: defaultCRNumberofInspections
          }));
        }
		
		setACNumberofInspections(data);
		const defaultACNumberofInspections = data.find(ACNumberofInspections => ACNumberofInspections === "Inspection E");
        if (defaultACNumberofInspections) {
          const defaultOption2 = { value: defaultACNumberofInspections, label: defaultACNumberofInspections };
          setSelectedACNumberofInspections(defaultOption2);
          setFormData(prevFormData => ({
            ...prevFormData,
            ACNumberofInspections: defaultACNumberofInspections
          }));
        }
		
		setSCCNumberofInspections(data);
		const defaultSCCNumberofInspections = data.find(SCCNumberofInspections => SCCNumberofInspections === "Inspection E");
        if (defaultSCCNumberofInspections) {
          const defaultOption2 = { value: defaultSCCNumberofInspections, label: defaultSCCNumberofInspections };
          setSelectedSCCNumberofInspections(defaultOption2);
          setFormData(prevFormData => ({
            ...prevFormData,
            SCCNumberofInspections: defaultSCCNumberofInspections
          }));
        }
		
		setShicNumberofInspections(data);
		const defaultShicNumberofInspections = data.find(ShicNumberofInspections => ShicNumberofInspections === "Inspection E");
        if (defaultShicNumberofInspections) {
          const defaultOption2 = { value: defaultShicNumberofInspections, label: defaultShicNumberofInspections };
          setSelectedShicNumberofInspections(defaultOption2);
          setFormData(prevFormData => ({
            ...prevFormData,
            ShicNumberofInspections: defaultShicNumberofInspections
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
	
	fetchData6();
	
	const fetchData7 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=sembilan`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSscphwater(data);
		const defaultSscphwater = data.find(Sscphwater => Sscphwater === "7,6 to 8,3");
        if (defaultSscphwater) {
          const defaultOption = { value: defaultSscphwater, label: defaultSscphwater };
          setSelectedSscphwater(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Sscphwater: defaultSscphwater
          }));
        }
		
		setShicphwater(data);
		const defaultShicphwater = data.find(Shicphwater => Shicphwater === "<5,5");
        if (defaultShicphwater) {
          const defaultOption = { value: defaultShicphwater, label: defaultShicphwater };
          setSelectedShicphwater(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Shicphwater: defaultShicphwater
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
	
	fetchData7();
	
	const fetchData8 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=sepuluh`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSscfunctionh2swater(data);
		const defaultSscfunctionh2swater = data.find(Sscfunctionh2swater => Sscfunctionh2swater === "<50 ppm");
        if (defaultSscfunctionh2swater) {
          const defaultOption = { value: defaultSscfunctionh2swater, label: defaultSscfunctionh2swater };
          setSelectedSscfunctionh2swater(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Sscfunctionh2swater: defaultSscfunctionh2swater
          }));
        }
		
		setShicfunctionh2swater(data);
		const defaultShicfunctionh2swater = data.find(Shicfunctionh2swater => Shicfunctionh2swater === "<50 ppm");
        if (defaultSscfunctionh2swater) {
          const defaultOption = { value: defaultShicfunctionh2swater, label: defaultShicfunctionh2swater };
          setSelectedShicfunctionh2swater(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Shicfunctionh2swater: defaultShicfunctionh2swater
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
	
	fetchData8();
	
	const fetchData9 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbzFXB4_bvTt0B73NSnZOuo5QGDbIb4PhBIV4Z6_28cWatMjpwFzrNMBZPh6d-QGpglFUQ/exec?sn=sebelas`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
		
		setAcephwater(data);
		const defaultAcephwater = data.find(Acephwater => Acephwater === "<7,5");
        if (defaultAcephwater) {
          const defaultOption = { value: defaultAcephwater, label: defaultAcephwater };
          setSelectedAcephwater(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Acephwater: defaultAcephwater
          }));
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
	
	fetchData9();
	
	const defaultOptionComponenttype = { value: "PIPE-16", label: "PIPE-16" };
    setSelectedComponenttype(defaultOptionComponenttype);
    handleComponenttypeChange(defaultOptionComponenttype);
	
	const defaultOptionPosition = { value: "Aboveground", label: "Aboveground" };
    setSelectedPosition(defaultOptionPosition);
    handlePositionChange(defaultOptionPosition);
	
	const defaultOptionCausticpresen = { value: "No", label: "No" };
    setSelectedCausticpresen(defaultOptionCausticpresen);
	handleCausticpresenChange(defaultOptionCausticpresen);
	
	const defaultOptionAlkalinewater = { value: "No", label: "No" };
    setSelectedAlkalinewater(defaultOptionAlkalinewater);
	handleAlkalinewaterChange(defaultOptionAlkalinewater);
	
	const defaultOptionOilwithsulfurcompounds = { value: "No", label: "No" };
    setSelectedOilwithsulfurcompounds(defaultOptionOilwithsulfurcompounds);
	handleOilwithsulfurcompoundsChange(defaultOptionOilwithsulfurcompounds);
	
	const defaultOptionChloridesppm = { value: "No", label: "No" };
    setSelectedChloridesppm(defaultOptionChloridesppm);
	handleChloridesppmChange(defaultOptionChloridesppm);
	
	const defaultOptionFreewater = { value: "No", label: "No" };
    setSelectedFreewater(defaultOptionFreewater);
	handleFreewaterChange(defaultOptionFreewater);
	
	const defaultOptionH2Sandhydrogen = { value: "No", label: "No" };
    setSelectedH2Sandhydrogen(defaultOptionH2Sandhydrogen);
	handleH2SandhydrogenChange(defaultOptionH2Sandhydrogen);
	
	const defaultOptionOxygen = { value: "No", label: "No" };
    setSelectedOxygen(defaultOptionOxygen);
	handleOxygennChange(defaultOptionOxygen);
	
	const defaultOptionH2S = { value: "No", label: "No" };
    setSelectedH2S(defaultOptionH2S);
	handleH2SChange(defaultOptionH2S);
	
	const defaultOptionHCl = { value: "No", label: "No" };
    setSelectedHCl(defaultOptionHCl);
	handleHClChange(defaultOptionHCl);
	
	const defaultOptionH2SO4 = { value: "No", label: "No" };
    setSelectedH2SO4(defaultOptionH2SO4);
	handleH2SO4Change(defaultOptionH2SO4);
	
	const defaultOptionHF = { value: "No", label: "No" };
    setSelectedHF(defaultOptionHF);
	handleHFChange(defaultOptionHF);
	
	const defaultOptionCO2 = { value: "Yes", label: "Yes" };
    setSelectedCO2(defaultOptionCO2);
	handleCO2Change(defaultOptionCO2);
	
	const defaultOptionMistoverspray = { value: "No", label: "No" };
    setSelectedMistoverspray(defaultOptionMistoverspray);
	handleMistoversprayChange(defaultOptionMistoverspray);
	
	const defaultOptionSteamvents = { value: "No", label: "No" };
    setSelectedSteamvents(defaultOptionSteamvents);
	handleSteamventsChange(defaultOptionSteamvents);
	
	const defaultOptionDelugesystems = { value: "No", label: "No" };
    setSelectedDelugesystems(defaultOptionDelugesystems);
	handleDelugesystemsChange(defaultOptionDelugesystems);
	
	const defaultOptionChloridecontaining = { value: "No", label: "No" };
    setSelectedChloridecontaining(defaultOptionChloridecontaining);
	handleChloridecontainingChange(defaultOptionChloridecontaining);
	
	const defaultOptionChlorideswndwater = { value: "No", label: "No" };
    setSelectedChlorideswndwater(defaultOptionChlorideswndwater);
	handleChlorideswndwaterChange(defaultOptionChlorideswndwater);
	
	const defaultOptionSulfurbearing = { value: "No", label: "No" };
    setSelectedSulfurbearing(defaultOptionSulfurbearing);
	handleSulfurbearingChange(defaultOptionSulfurbearing);
	
	const defaultOptionHydrofluoricacid = { value: "No", label: "No" };
    setSelectedHydrofluoricacid(defaultOptionHydrofluoricacid);
	handleHydrofluoricacidChange(defaultOptionHydrofluoricacid);
	
	const defaultOptionAcidgastreatingamines = { value: "No", label: "No" };
    setSelectedAcidgastreatingamines(defaultOptionAcidgastreatingamines);
	handleAcidgastreatingaminesChange(defaultOptionAcidgastreatingamines);
	
	const defaultOptionSoil = { value: "No", label: "No" };
    setSelectedSoil(defaultOptionSoil);
	handleSoilChange(defaultOptionSoil);
	
	const defaultOptionTF350 = { value: "No", label: "No" };
    setSelectedTF350(defaultOptionTF350);
	handleTF350Change(defaultOptionTF350);
	
	const defaultOptionTF300 = { value: "Yes", label: "Yes" };
    setSelectedTF300(defaultOptionTF300);
	handleTF300Change(defaultOptionTF300);
	
	const defaultOptionT100 = { value: "No", label: "No" };
    setSelectedT100(defaultOptionT100);
	handleT100Change(defaultOptionT100);
	
	const defaultOptionT350 = { value: "No", label: "No" };
    setSelectedT350(defaultOptionT350);
	handleT350Change(defaultOptionT350);
	
	const defaultOptionT1070 = { value: "No", label: "No" };
    setSelectedT1070(defaultOptionT1070);
	handleT1070Change(defaultOptionT1070);
	
	const defaultOptionT1050 = { value: "No", label: "No" };
    setSelectedT1050(defaultOptionT1050);
	handleT1050Change(defaultOptionT1050);
	
	const defaultOptionT1700 = { value: "No", label: "No" };
    setSelectedT1700(defaultOptionT1700);
	handleT1700Change(defaultOptionT1700);
	
	const defaultOptionT400 = { value: "No", label: "No" };
    setSelectedT400(defaultOptionT400);
	handleT400Change(defaultOptionT400);
	
	const defaultOptionT900 = { value: "No", label: "No" };
    setSelectedT900(defaultOptionT900);
	handleT900Change(defaultOptionT900);
	
	const defaultOptionHydrogenpartial = { value: "No", label: "No" };
    setSelectedHydrogenpartial(defaultOptionHydrogenpartial);
	handleHydrogenpartialChange(defaultOptionHydrogenpartial);
	
	const defaultOptionSubjecttoprocess = { value: "No", label: "No" };
    setSelectedSubjecttoprocess(defaultOptionSubjecttoprocess);
	handleSubjecttoprocessChange(defaultOptionSubjecttoprocess);
	
	const defaultOptionNotnormally = { value: "No", label: "No" };
    setSelectedNotnormally(defaultOptionNotnormally);
	handleNotnormallyChange(defaultOptionNotnormally);
	
	const defaultOptionWithdeteriorated = { value: "No", label: "No" };
    setSelectedWithdeteriorated(defaultOptionWithdeteriorated);
	handleWithdeterioratedChange(defaultOptionWithdeteriorated);
	
	const defaultOptionPenetrationsandvisually = { value: "No", label: "No" };
    setSelectedPenetrationsandvisually(defaultOptionPenetrationsandvisually);
	handlePenetrationsandvisuallyChange(defaultOptionPenetrationsandvisually);
	
	const defaultOptionMDMTorMAT = { value: "No", label: "No" };
    setSelectedMDMTorMAT(defaultOptionMDMTorMAT);
	handleMDMTorMATChange(defaultOptionMDMTorMAT);
	
	const defaultOptionHavebeenpast = { value: "No", label: "No" };
    setSelectedHavebeenpast(defaultOptionHavebeenpast);
	handleHavebeenpastChange(defaultOptionHavebeenpast);
	
	const defaultOptionPh7 = { value: "Yes", label: "Yes" };
    setSelectedPh7(defaultOptionPh7);
	handlePh7Change(defaultOptionPh7);
	
	const defaultOptionAllregular300 = { value: "No", label: "No" };
    setSelectedAllregular300(defaultOptionAllregular300);
	handleAllregular300Change(defaultOptionAllregular300);
	
	const defaultOptionHGrade300 = { value: "No", label: "No" };
    setSelectedHGrade300(defaultOptionHGrade300);
	handleHGrade300Change(defaultOptionHGrade300);
	
	const defaultOptionLGrade300 = { value: "No", label: "No" };
    setSelectedLGrade300(defaultOptionLGrade300);
	handleLGrade300Change(defaultOptionLGrade300);
	
	const defaultOptionStainlesssteel321 = { value: "No", label: "No" };
    setSelectedStainlesssteel321(defaultOptionStainlesssteel321);
	handleStainlesssteel321Change(defaultOptionStainlesssteel321);
	
	const defaultOptionStainlesssteel347 = { value: "No", label: "No" };
    setSelectedStainlesssteel347(defaultOptionStainlesssteel347);
	handleStainlesssteel347Change(defaultOptionStainlesssteel347);
	
	//DF Thinning
	const defaultOptionApplicable = { value: "Yes", label: "Yes" };
    setSelectedApplicable(defaultOptionApplicable);
	handleApplicableChange(defaultOptionApplicable);
	
	const defaultOptionInjectionmixpoints = { value: "No", label: "No" };
    setSelectedInjectionmixpoints(defaultOptionInjectionmixpoints);
	handleInjectionmixpointsChange(defaultOptionInjectionmixpoints);
	
	const defaultOptionDeadlegcontain = { value: "No", label: "No" };
    setSelectedDeadlegcontain(defaultOptionDeadlegcontain);
	handleDeadlegcontainChange(defaultOptionDeadlegcontain);
	
	const defaultOptionWeldedconstructionwelded = { value: "N/A", label: "N/A" };
    setSelectedWeldedconstructionwelded(defaultOptionWeldedconstructionwelded);
	handleWeldedconstructionweldedChange(defaultOptionWeldedconstructionwelded);
	
	const defaultOptionMaintenanceAPISTD563Maintained = { value: "N/A", label: "N/A" };
    setSelectedMaintenanceAPISTD563Maintained(defaultOptionMaintenanceAPISTD563Maintained);
	handleMaintenanceAPISTD563MaintainedChange(defaultOptionMaintenanceAPISTD563Maintained);
	
	const defaultOptionSettlementcriteria = { value: "N/A", label: "N/A" };
    setSelectedSettlementcriteria(defaultOptionSettlementcriteria);
	handleSettlementcriteriaChange(defaultOptionSettlementcriteria);
	
	const defaultOptionOnlinemonitoringThinningmechanism = { value: "Other Corrosion Mechanism", label: "Other Corrosion Mechanism" };
    setSelectedOnlinemonitoringThinningmechanism(defaultOptionOnlinemonitoringThinningmechanism);
	handleOnlinemonitoringThinningmechanismChange(defaultOptionOnlinemonitoringThinningmechanism);
	
	const defaultOptionOnlinemonitoringMonitoringtype = { value: "Corrosion Coupon", label: "Corrosion Coupon" };
    setSelectedOnlinemonitoringMonitoringtype(defaultOptionOnlinemonitoringMonitoringtype);
	handleOnlinemonitoringMonitoringtypeChange(defaultOptionOnlinemonitoringMonitoringtype);
	
	const defaultOptionLiningtype = { value: "Organic", label: "Organic" };
    setSelectedLiningtype(defaultOptionLiningtype);
	handleLiningtypeChange(defaultOptionLiningtype);
	
	const defaultOptionAgeofLiningoryearage = { value: "3", label: "3" };
    setSelectedAgeofLiningoryearage(defaultOptionAgeofLiningoryearage);
	handleAgeofLiningoryearageChange(defaultOptionAgeofLiningoryearage);
	
	const defaultOptionInorganicliningstype = { value: "Fiberglass", label: "Fiberglass" };
    setSelectedInorganicliningstype(defaultOptionInorganicliningstype);
	handleInorganicliningstypeChange(defaultOptionInorganicliningstype);
	
	const defaultOptionOrgaliningstype = { value: "High Quality (Reinforced)", label: "High Quality (Reinforced)" };
    setSelectedOrgaliningstype(defaultOptionOrgaliningstype);
	handleOrgaliningstypeChange(defaultOptionOrgaliningstype);
	
	const defaultOptionMonitoringcontain = { value: "No", label: "No" };
    setSelectedMonitoringcontain(defaultOptionMonitoringcontain);
	handleMonitoringcontainChange(defaultOptionMonitoringcontain);
	
	const defaultOptionExtDriver = { value: "Arid / Dry", label: "Arid / Dry" };
    setSelectedExtDriver(defaultOptionExtDriver);
	handleExtDriverChange(defaultOptionExtDriver);
	
	const defaultOptionEquipmentDesignorFabrication = { value: "No", label: "No" };
    setSelectedEquipmentDesignorFabrication(defaultOptionEquipmentDesignorFabrication);
	handleEquipmentDesignorFabricationChange(defaultOptionEquipmentDesignorFabrication);
	
	const defaultOptionInterface = { value: "No", label: "No" };
    setSelectedInterface(defaultOptionInterface);
	handleInterfaceChange(defaultOptionInterface);
	
	const defaultOptionCoatingQuality = { value: "High", label: "High" };
    setSelectedCoatingQuality(defaultOptionCoatingQuality);
	handleCoatingQualityChange(defaultOptionCoatingQuality);
	
	const defaultOptionCfcDriver = { value: "Marine", label: "Marine" };
    setSelectedCfcDriver(defaultOptionCfcDriver);
	handleCfcDriverChange(defaultOptionCfcDriver);
	
	const defaultOptionInsulationType = { value: "Pearlite", label: "Pearlite" };
    setSelectedInsulationType(defaultOptionInsulationType);
	handleInsulationTypeChange(defaultOptionInsulationType);
	
	const defaultOptionComplexity = { value: "Above average", label: "Above average" };
    setSelectedComplexity(defaultOptionComplexity);
	handleComplexityChange(defaultOptionComplexity);
	
	const defaultOptionInsulationCondition = { value: "Average", label: "Average" };
    setSelectedInsulationCondition(defaultOptionInsulationCondition);
	handleInsulationConditionChange(defaultOptionInsulationCondition);
	
	const defaultOptionCfcDesignorFabrication = { value: "Not allow water", label: "Not allow water" };
    setSelectedCfcDesignorFabrication(defaultOptionCfcDesignorFabrication);
	handleCfcDesignorFabricationChange(defaultOptionCfcDesignorFabrication);
	
	const defaultOptionInterfaceenterssoilorwater = { value: "Not enter", label: "Not enter" };
    setSelectedInterfaceenterssoilorwater(defaultOptionInterfaceenterssoilorwater);
	handleInterfaceenterssoilorwaterChange(defaultOptionInterfaceenterssoilorwater);
	
	const defaultOptionEcfDrive = { value: "Temperate", label: "Temperate" };
    setSelectedEcfDrive(defaultOptionEcfDrive);
	handleEcfDriveChange(defaultOptionEcfDrive);
	
	const defaultOptionEcfDrivecui = { value: "Temperate", label: "Temperate" };
    setSelectedEcfDrivecui(defaultOptionEcfDrivecui);
	handleEcfDrivecuiChange(defaultOptionEcfDrivecui);
	
	const defaultOptionEcfCracking = { value: "Not Present", label: "Not Present" };
    setSelectedEcfCracking(defaultOptionEcfCracking);
	handleEcfCrackingChange(defaultOptionEcfCracking);
	
	const defaultOptionEcfCrackingcui = { value: "Not Present", label: "Not Present" };
    setSelectedEcfCrackingcui(defaultOptionEcfCrackingcui);
	handleEcfCrackingcuiChange(defaultOptionEcfCrackingcui);
	
	const defaultOptionChloridefreecui = { value: "Contains", label: "Contains" };
    setSelectedChloridefreecui(defaultOptionChloridefreecui);
	handleChloridefreecuiChange(defaultOptionChloridefreecui);
	
	const defaultOptionLiningconditionqualitativecondition = { value: "Good", label: "Good" };
    setSelectedLiningconditionqualitativecondition(defaultOptionLiningconditionqualitativecondition);
	handleLiningconditionqualitativeconditionChange(defaultOptionLiningconditionqualitativecondition);
	
	const defaultOptionInspectioneffectivenesscategory = { value: "N/A", label: "N/A" };
    setSelectedInspectioneffectivenesscategory(defaultOptionInspectioneffectivenesscategory);
	handleInspectioneffectivenesscategoryChange(defaultOptionInspectioneffectivenesscategory);
	
	const defaultOptionyearoffabricationmaterial = { value: "After 1988", label: "After 1988" };
    setSelectedyearoffabricationmaterial(defaultOptionyearoffabricationmaterial);
	handleyearoffabricationmaterialChange(defaultOptionyearoffabricationmaterial);
	
	const defaultOptionFluidphase = { value: "Vapor or Gas", label: "Vapor or Gas" };
    setSelectedFluidphase(defaultOptionFluidphase);
	handleFluidphaseChange(defaultOptionFluidphase);
	
	const defaultOptionFluidatnormaloperatingconditions = { value: "Gas", label: "Gas" };
    setSelectedFluidatnormaloperatingconditions(defaultOptionFluidatnormaloperatingconditions);
	handleFluidatnormaloperatingconditionsChange(defaultOptionFluidatnormaloperatingconditions);
	
	const defaultOptionFluidatambientconditions = { value: "Gas", label: "Gas" };
    setSelectedFluidatambientconditions(defaultOptionFluidatnormaloperatingconditions);
	handleFluidatambientconditionsChange(defaultOptionFluidatnormaloperatingconditions);
	
	const defaultOptionDataconfidencedamagestate1 = { value: "Low", label: "Low" };
    setSelectedDataconfidencedamagestate1(defaultOptionDataconfidencedamagestate1);
	handleDataconfidencedamagestate1Change(defaultOptionDataconfidencedamagestate1);
	
	const defaultOptionDataconfidencedamagestate2 = { value: "Low", label: "Low" };
    setSelectedDataconfidencedamagestate2(defaultOptionDataconfidencedamagestate2);
	handleDataconfidencedamagestate2Change(defaultOptionDataconfidencedamagestate2);
	
	const defaultOptionDataconfidencedamagestate3 = { value: "Low", label: "Low" };
    setSelectedDataconfidencedamagestate3(defaultOptionDataconfidencedamagestate3);
	handleDataconfidencedamagestate3Change(defaultOptionDataconfidencedamagestate3);
	
	//DF SCC
	const defaultOptionDetermineSusceptibility = { value: "None", label: "None" };
    setSelectedDetermineSusceptibility(defaultOptionDetermineSusceptibility);
	handleDetermineSusceptibilityChange(defaultOptionDetermineSusceptibility);
	
	const defaultOptionACDetermineSusceptibility = { value: "None", label: "None" };
    setSelectedACDetermineSusceptibility(defaultOptionACDetermineSusceptibility);
	handleACDetermineSusceptibilityChange(defaultOptionACDetermineSusceptibility);
	
	const defaultOptionSsccyanides = { value: "Not Present", label: "Not Present" };
    setSelectedSsccyanides(defaultOptionSsccyanides);
	handleSsccyanidesChange(defaultOptionSsccyanides);
	
	const defaultOptionSscfunctiontreatment = { value: "As-Welded", label: "As-Welded" };
    setSelectedSscfunctiontreatment(defaultOptionSscfunctiontreatment);
	handleSscfunctiontreatmentChange(defaultOptionSscfunctiontreatment);
	
	const defaultOptionHsccondition = { value: "As-Welded", label: "As-Welded" };
    setSelectedHsccondition(defaultOptionHsccondition);
	handleHscconditionChange(defaultOptionHsccondition);
	
	const defaultOptionSscmaxbrinnell = { value: "200-237", label: "200-237" };
    setSelectedSscmaxbrinnell(defaultOptionSscmaxbrinnell);
	handleSscmaxbrinnellChange(defaultOptionSscmaxbrinnell);
	
	const defaultOptionHscmaxbrinnell = { value: "<200", label: "<200" };
    setSelectedHscmaxbrinnell(defaultOptionHscmaxbrinnell);
	handleHscmaxbrinnellChange(defaultOptionHscmaxbrinnell);
	
	const defaultOptionSsccracking = { value: "Not Present", label: "Not Present" };
    setSelectedSsccracking(defaultOptionSsccracking);
	handleSsccrackingChange(defaultOptionSsccracking);
	
	const defaultOptionShiccyanides = { value: "Not Present", label: "Not Present" };
    setSelectedShiccyanides(defaultOptionSsccyanides);
	handleShiccyanidesChange(defaultOptionShiccyanides);
	
	const defaultOptionShiccracking = { value: "Not Present", label: "Not Present" };
    setSelectedShiccracking(defaultOptionShiccracking);
	handleShiccrackingChange(defaultOptionShiccracking);
	
	const defaultOptionShicsulfurcontent = { value: "Product Form - Seamless/Extruded Pipe", label: "Product Form - Seamless/Extruded Pipe" };
    setSelectedShicsulfurcontent(defaultOptionShicsulfurcontent);
	handleShicsulfurcontentChange(defaultOptionShicsulfurcontent);
	
	const defaultOptionHicmonitoringmethod = { value: "Hydrogen Probes", label: "Hydrogen Probes" };
    setSelectedHicmonitoringmethod(defaultOptionHicmonitoringmethod);
	handleHicmonitoringmethodChange(defaultOptionHicmonitoringmethod);
	
	const defaultOptionAceCO3 = { value: "CO3 All Concentrations", label: "CO3 All Concentrations" };
    setSelectedAceCO3(defaultOptionAceCO3);
	handleAceCO3Change(defaultOptionAceCO3);
	
	const defaultOptionAcecracking = { value: "Not Present", label: "Not Present" };
    setSelectedAcecracking(defaultOptionAcecracking);
	handleAcecrackingChange(defaultOptionAcecracking);
	
	const defaultOptionPACracking = { value: "Not Present", label: "Not Present" };
    setSelectedPACracking(defaultOptionPACracking);
	handlePACrackingChange(defaultOptionPACracking);
	
	const defaultOptionCscccracking = { value: "Not Present", label: "Not Present" };
    setSelectedCscccracking(defaultOptionCscccracking);
	handleCscccrackingChange(defaultOptionCscccracking);
	
	const defaultOptionHsccracking = { value: "Not Present", label: "Not Present" };
    setSelectedHsccracking(defaultOptionHsccracking);
	handleHsccrackingChange(defaultOptionHsccracking);
	
	const defaultOptionHicsohiccracking = { value: "Not Present", label: "Not Present" };
    setSelectedHicsohiccracking(defaultOptionHicsohiccracking);
	handleHicsohiccrackingChange(defaultOptionHicsohiccracking);
	
	const defaultOptionHicsohicsulfurcontent = { value: "Product Form – Seamless/Extruded Pipe", label: "Product Form – Seamless/Extruded Pipe" };
    setSelectedHicsohicsulfurcontent(defaultOptionHicsohicsulfurcontent);
	handleHicsohicsulfurcontentChange(defaultOptionHicsohicsulfurcontent);
	
	const defaultOptionHicsohicadjustment = { value: "Key Process Variables and Hydrogen Probes", label: "Key Process Variables and Hydrogen Probes" };
    setSelectedHicsohicadjustment(defaultOptionHicsohicadjustment);
	handleHicsohicadjustmentChange(defaultOptionHicsohicadjustment);
	
	const defaultOptionPamaterial = { value: "All regular 300 series Stainless Steels and Alloys 600 and 800 ", label: "All regular 300 series Stainless Steels and Alloys 600 and 800 " };
    setSelectedPamaterial(defaultOptionPamaterial);
	handlePamaterialChange(defaultOptionPamaterial);
	
	//DF Brittle
	const defaultOptionHthadamagecondition = { value: "Component has beed replaced with an upgrade in the material construction", label: "Component has beed replaced with an upgrade in the material construction" };
    setSelectedHthadamagecondition(defaultOptionHthadamagecondition);
	handleHthadamageconditionChange(defaultOptionHthadamagecondition);
	
	const defaultOptionAdmprocon = { value: "No", label: "No" };
    setSelectedAdmprocon(defaultOptionAdmprocon);
	handleAdmproconChange(defaultOptionAdmprocon);
	
	const defaultOptionBfasmeexcurve = { value: "A", label: "A" };
    setSelectedBfasmeexcurve(defaultOptionBfasmeexcurve);
	handleBfasmeexcurveChange(defaultOptionBfasmeexcurve);
	
	const defaultOptionLaseaorprocon = { value: "Exist", label: "Exist" };
    setSelectedLaseaorprocon(defaultOptionLaseaorprocon);
	handleLaseaorproconChange(defaultOptionLaseaorprocon);
	
	const defaultOptionLasasmecurve = { value: "A", label: "A" };
    setSelectedLasasmecurve(defaultOptionLasasmecurve);
	handleLasasmecurveChange(defaultOptionLasasmecurve);
	
	const defaultOptionEmbaopc = { value: "Exist", label: "Exist" };
    setSelectedEmbaopc(defaultOptionEmbaopc);
	handleEmbaopcChange(defaultOptionEmbaopc);
	
	const defaultOptionSigmaembcontent = { value: "Low", label: "Low" };
    setSelectedSigmaembcontent(defaultOptionSigmaembcontent);
	handleSigmaembcontentChange(defaultOptionSigmaembcontent);
	
	//DF Fatigue
	const defaultOptionPrefacriteria = { value: "None", label: "None" };
    setSelectedPrefacriteria(defaultOptionPrefacriteria);
	handlePrefacriteriaChange(defaultOptionPrefacriteria);
	
	const defaultOptionPrefadfbpf = { value: "Minor", label: "Minor" };
    setSelectedPrefadfbpf(defaultOptionPrefadfbpf);
	handlePrefadfbpfChange(defaultOptionPrefadfbpf);
	
	const defaultOptionVisshcriteria = { value: "Shaking less than 2 weeks", label: "Shaking less than 2 weeks" };
    setSelectedVisshcriteria(defaultOptionVisshcriteria);
	handleVisshcriteriaChange(defaultOptionVisshcriteria);
	
	const defaultOptionTocycriteria = { value: "None", label: "None" };
    setSelectedTocycriteria(defaultOptionTocycriteria);
	handleTocycriteriaChange(defaultOptionTocycriteria);
	
	const defaultOptionAdcocriteria = { value: "No modification", label: "No modification" };
    setSelectedAdcocriteria(defaultOptionAdcocriteria);
	handleAdcocriteriaChange(defaultOptionAdcocriteria);
	
	const defaultOptionAdpicriteria = { value: "0 to 5 total pipe fittings", label: "0 to 5 total pipe fittings" };
    setSelectedAdpicriteria(defaultOptionAdpicriteria);
	handleAdpicriteriaChange(defaultOptionAdpicriteria);
	
	const defaultOptionAdcoicriteria = { value: "Good", label: "Good" };
    setSelectedAdcoicriteria(defaultOptionAdcoicriteria);
	handleAdcoicriteriaChange(defaultOptionAdcoicriteria);
	
	const defaultOptionAdjotcriteria = { value: "Sweepolets", label: "Sweepolets" };
    setSelectedAdjotcriteria(defaultOptionAdjotcriteria);
	handleAdjotcriteriaChange(defaultOptionAdjotcriteria);
	
	const defaultOptionAdbrdicriteria = { value: "Less than or equal to 2 NPS", label: "Less than or equal to 2 NPS" };
    setSelectedAdbrdicriteria(defaultOptionAdbrdicriteria);
	handleAdbrdicriteriaChange(defaultOptionAdbrdicriteria);
	
	//COF
	const defaultOptionHolesize1 = { value: "Small", label: "Small" };
    setSelectedHolesize1(defaultOptionHolesize1);
	handleHolesize1Change(defaultOptionHolesize1);
	
	const defaultOptionHolesize2 = { value: "Medium", label: "Medium" };
    setSelectedHolesize2(defaultOptionHolesize2);
	handleHolesize2Change(defaultOptionHolesize2);
	
	const defaultOptionHolesize3 = { value: "Large", label: "Large" };
    setSelectedHolesize3(defaultOptionHolesize3);
	handleHolesize3Change(defaultOptionHolesize3);
	
	const defaultOptionHolesize4 = { value: "-", label: "-" };
    setSelectedHolesize4(defaultOptionHolesize4);
	handleHolesize4Change(defaultOptionHolesize4);
	
	//COF
	const defaultOptionDetsysty = { value: "Suitably located detectors to detemine when the material is present outside the pressure-containing envelope.", label: "Suitably located detectors to detemine when the material is present outside the pressure-containing envelope." };
    setSelectedDetsysty(defaultOptionDetsysty);
	handleDetsystyChange(defaultOptionDetsysty);
	
	const defaultOptionIsosysty = { value: "Isolation or shutdown systems activated by operators in the control room or other suitable locations remote from the leak. ", label: "Isolation or shutdown systems activated by operators in the control room or other suitable locations remote from the leak. " };
    setSelectedIsosysty(defaultOptionIsosysty);
	handleIsosystyChange(defaultOptionIsosysty);
	
	const defaultOptionConarmit = { value: "Inventory blowdown, coupled with isolation system classification B or higher ", label: "Inventory blowdown, coupled with isolation system classification B or higher " };
    setSelectedConarmit(defaultOptionConarmit);
	handleConarmitChange(defaultOptionConarmit);
	
	const defaultOptionFluidtypecof = { value: "TYPE 1", label: "TYPE 1" };
    setSelectedFluidtypecof(defaultOptionFluidtypecof);
	handleFluidtypecofChange(defaultOptionFluidtypecof);
	
	const defaultOptionToxflucov = { value: "None", label: "None" };
    setSelectedToxflucov(defaultOptionToxflucov);
	handleToxflucovChange(defaultOptionToxflucov);
	
	//
	const defaultOptionCsccoperatingtemperature = { value: ">100 - 200", label: ">100 - 200" };
    setSelectedCsccoperatingtemperature(defaultOptionCsccoperatingtemperature);
	handleCsccoperatingtemperatureChange(defaultOptionCsccoperatingtemperature);
	
	const defaultOptionCsccchloride = { value: "'1-10", label: "'1-10" };
    setSelectedCsccchloride(defaultOptionCsccchloride);
	handleCsccchlorideChange(defaultOptionCsccchloride);
	
	const defaultOptionAcefoht = { value: "Stabilized After Welding", label: "Stabilized After Welding" };
    setSelectedAcefoht(defaultOptionAcefoht);
	handleAcefohtChange(defaultOptionAcefoht);
	
	const defaultOptionShicfunctiontreatment = { value: "As-Welded", label: "As-Welded" };
    setSelectedShicfunctiontreatment(defaultOptionShicfunctiontreatment);
	handleShicfunctiontreatmentChange(defaultOptionShicfunctiontreatment);
	
  }, []);

  const materialOptions = materialData.map(materialObj => {
    return {
      value: materialObj,
      label: materialObj,
    };
  });
  
  const EquipmenttypeOptions = EquipmenttypeData.map(EquipmenttypeDataObj => {
    return {
      value: EquipmenttypeDataObj,
      label: EquipmenttypeDataObj,
    };
  });
  
  const ComponenttypeOptions = Componenttype.map(ComponenttypeDataObj => {
    return {
      value: ComponenttypeDataObj,
      label: ComponenttypeDataObj,
    };
  });
  
  const GeometrytypeOptions = Geometrytype.map(GeometrytypeDataObj => {
    return {
      value: GeometrytypeDataObj,
      label: GeometrytypeDataObj,
    };
  });
  
  const RepresentativefluidOptions = Representativefluid.map(RepresentativefluidDataObj => {
    return {
      value: RepresentativefluidDataObj,
      label: RepresentativefluidDataObj,
    };
  });
  
  const EcfPerformedOptions = EcfInspectionPerformed.map(EcfInspectionPerformedDataObj => {
    return {
      value: EcfInspectionPerformedDataObj,
      label: EcfInspectionPerformedDataObj,
    };
  });
  
  const PerformedcuiOptions = InspectionPerformedcui.map(InspectionPerformedcuiDataObj => {
    return {
      value: InspectionPerformedcuiDataObj,
      label: InspectionPerformedcuiDataObj,
    };
  });
  
  const CRInspectionsOptions = CRNumberofInspections.map(CRNumberofInspectionsDataObj => {
    return {
      value: CRNumberofInspectionsDataObj,
      label: CRNumberofInspectionsDataObj,
    };
  });
  
  const SscphwaterOptions = Sscphwater.map(SscphwaterDataObj => {
    return {
      value: SscphwaterDataObj,
      label: SscphwaterDataObj,
    };
  });
  
  const SscfunctionhwaterOptions = Sscfunctionh2swater.map(Sscfunctionh2swaterDataObj => {
    return {
      value: Sscfunctionh2swaterDataObj,
      label: Sscfunctionh2swaterDataObj,
    };
  });
  
  const ACInspectionsOptions = ACNumberofInspections.map(ACNumberofInspectionsDataObj => {
    return {
      value: ACNumberofInspectionsDataObj,
      label: ACNumberofInspectionsDataObj,
    };
  });
  
  const SCCInspectionsOptions = SCCNumberofInspections.map(SCCNumberofInspectionsDataObj => {
    return {
      value: SCCNumberofInspectionsDataObj,
      label: SCCNumberofInspectionsDataObj,
    };
  });
  
   const ShicphwaterOptions = Shicphwater.map(ShicphwaterDataObj => {
    return {
      value: ShicphwaterDataObj,
      label: ShicphwaterDataObj,
    };
  });
  
  const ShicfunctionwaterOptions = Shicfunctionh2swater.map(Shicfunctionh2swaterDataObj => {
    return {
      value: Shicfunctionh2swaterDataObj,
      label: Shicfunctionh2swaterDataObj,
    };
  });
  
  const ShicInspectionsOptions = ShicNumberofInspections.map(ShicNumberofInspectionsDataObj => {
    return {
      value: ShicNumberofInspectionsDataObj,
      label: ShicNumberofInspectionsDataObj,
    };
  });
  
  const AcephwaterOptions = Acephwater.map(AcephwaterDataObj => {
    return {
      value: AcephwaterDataObj,
      label: AcephwaterDataObj,
    };
  });
  
  
  
  const PositionOptions = [
  { value: 'Aboveground', label: 'Aboveground' },
  { value: 'Underground', label: 'Underground' },
  { value: 'Partially', label: 'Partially' },
];

const YesnoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const YesnowithnaOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'N/A', label: 'N/A' },
];

const InspectioneffectivenesscategoryOptions = [
  { value: 'N/A', label: 'N/A' },
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
];

const SettlementcriteriaOptions = [
  { value: 'Exceeds', label: 'Exceeds' },
  { value: 'Meets', label: 'Meets' },
  { value: 'Never evaluated', label: 'Never evaluated' },
  { value: 'No settlement', label: 'No settlement' },
  { value: 'N/A', label: 'N/A' },
];

const OnlinemonitoringThinningmechanismOptions = [
  { value: 'Hydrochloric Acid (HCl) Corrosion', label: 'Hydrochloric Acid (HCl) Corrosion' },
  { value: 'High Temp Sulfidic/Naphthenic Acid Corrosion', label: 'High Temp Sulfidic/Naphthenic Acid Corrosion' },
  { value: 'High Temp H2S/H2 Corrosion', label: 'High Temp H2S/H2 Corrosion' },
  { value: 'Sulfuric Acid (H2S/H2) Corrosion Low Velocity', label: 'Sulfuric Acid (H2S/H2) Corrosion Low Velocity' },
  { value: 'Sulfuric Acid (H2S/H2) Corrosion High Velocity', label: 'Sulfuric Acid (H2S/H2) Corrosion High Velocity' },
  { value: 'Hydrofluoric Acid (HF) Corrosion', label: 'Hydrofluoric Acid (HF) Corrosion' },
  { value: 'Sour Water Corrosion Low Velocity', label: 'Sour Water Corrosion Low Velocity' },
  { value: 'Sour Water Corrosion High Velocity', label: 'Sour Water Corrosion High Velocity' },
  { value: 'Amine Low Velocity', label: 'Amine Low Velocity' },
  { value: 'Amine High Velocity', label: 'Amine High Velocity' },
  { value: 'Other Corrosion Mechanism', label: 'Other Corrosion Mechanism' },
];

const OnlinemonitoringMonitoringtypeOptions = [
  { value: 'Key Process Variable', label: 'Key Process Variable' },
  { value: 'Electrical Resistance Probes', label: 'Electrical Resistance Probes' },
  { value: 'Corrosion Coupon', label: 'Corrosion Coupon' },
  { value: 'On-Line Monitoring Absent', label: 'On-Line Monitoring Absent' },
];

const LiningtypeOptions = [
  { value: 'Organic', label: 'Organic' },
  { value: 'Inorganic', label: 'Inorganic' },
];

const AgeofLiningoryearageOptions = Array.from({ length: 25 }, (v, i) => ({
  value: i + 1,
  label: i + 1
}));

const InorganicliningstypeOptions = [
  { value: 'Strip Lined Alloy', label: 'Strip Lined Alloy' },
  { value: 'Castable Refractory', label: 'Castable Refractory' },
  { value: 'Castable Refractory Severe Conditions', label: 'Castable Refractory Severe Conditions' },
  { value: 'Glass Lined', label: 'Glass Lined' },
  { value: 'Acid Brick', label: 'Acid Brick' },
  { value: 'Fiberglass', label: 'Fiberglass' },
];

const OrgaliningstypeOptions = [
  { value: 'Low Quality (Spray)', label: 'Low Quality (Spray)' },
  { value: 'Medium Quality (Filled)', label: 'Medium Quality (Filled)' },
  { value: 'High Quality (Reinforced)', label: 'High Quality (Reinforced)' },
];

const LiningconditionqualitativeconditionOptions = [
  { value: 'Poor', label: 'Poor' },
  { value: 'Average', label: 'Average' },
  { value: 'Good', label: 'Good' },
];

const yearoffabricationmaterialOptions = [
  { value: 'After 1988', label: 'After 1988' },
  { value: '1981 to 1987', label: '1981 to 1987' },
  { value: '1973 to 1980', label: '1973 to 1980' },
  { value: '1965 to 1972', label: '1965 to 1972' },
];

const FluidphaseOptions = [
  { value: 'Vapor or Gas', label: 'Vapor or Gas' },
  { value: 'Liquid', label: 'Liquid' },
];

const GasliquidOptions = [
  { value: 'Gas', label: 'Gas' },
  { value: 'Liquid', label: 'Liquid' },
];

const Dataconfidencedamagestate123Options = [
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
];

const ExtDriverOptions = [
  { value: 'Marine', label: 'Marine' },
  { value: 'Temperate', label: 'Temperate' },
  { value: 'Arid / Dry', label: 'Arid / Dry' },
  { value: 'Severe', label: 'Severe' },
];

const CfcDriverOptions = [
  { value: 'Marine', label: 'Marine' },
  { value: 'Temperate', label: 'Temperate' },
  { value: 'Arid / Dry', label: 'Arid / Dry' },
  { value: 'Severe', label: 'Severe' },
];

const EcfDriveOptions = [
  { value: 'Marine', label: 'Marine' },
  { value: 'Temperate', label: 'Temperate' },
  { value: 'Arid / Dry', label: 'Arid / Dry' },
  { value: 'Severe', label: 'Severe' },
];

const EcfDrivecuiOptions = [
  { value: 'Marine', label: 'Marine' },
  { value: 'Temperate', label: 'Temperate' },
  { value: 'Arid / Dry', label: 'Arid / Dry' },
  { value: 'Severe', label: 'Severe' },
];

const EcfCrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const CrackingcuiOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const ChloridefreecuiOptions = [
  { value: 'Contains', label: 'Contains' },
  { value: 'Not contain', label: 'Not contain' },
];

const CfcInsulationTypeOptions = [
  { value: 'Unknown', label: 'Unknown' },
  { value: 'Foamglass', label: 'Foamglass' },
  { value: 'Pearlite', label: 'Pearlite' },
  { value: 'Fiberglass', label: 'Fiberglass' },
  { value: 'Mineral Wool', label: 'Mineral Wool' },
  { value: 'Calcium Silicate', label: 'Calcium Silicate' },
  { value: 'Asbestos', label: 'Asbestos' },
];

const ComplexityOptions = [
  { value: 'Below average', label: 'Below average' },
  { value: 'Average', label: 'Average' },
  { value: 'Above average', label: 'Above average' },
];

const InsulationConditionOptions = [
  { value: 'Below average', label: 'Below average' },
  { value: 'Average', label: 'Average' },
  { value: 'Above average', label: 'Above average' },
];

const CfcDesignorFabricationOptions = [
  { value: 'Allow water', label: 'Allow water' },
  { value: 'Not allow water', label: 'Not allow water' },
];

const CfcInterfacewaterOptions = [
  { value: 'Enter', label: 'Enter' },
  { value: 'Not enter', label: 'Not enter' },
];

const CoatingQualityOptions = [
  { value: 'Poor', label: 'Poor' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
];

//DF SCC
const DetermineSusceptibilityOptions = [
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Low', label: 'Low' },
  { value: 'None', label: 'None' },
];

const ACDetermineSusceptibilityOptions = [
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Low', label: 'Low' },
  { value: 'None', label: 'None' },
];

const SsccyanidesOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const SscfunctiontreatmentOptions = [
  { value: 'As-Welded', label: 'As-Welded' },
  { value: 'PWHT', label: 'PWHT' },
];

const HscconditionOptions = [
  { value: 'As-Welded', label: 'As-Welded' },
  { value: 'PWHT', label: 'PWHT' },
];

const SscmaxbrinnellOptions = [
  { value: '< 200', label: '< 200' },
  { value: '200-237', label: '200-237' },
  { value: '> 237', label: '> 237' },
];

const HscmaxbrinnellOptions = [
  { value: '<200', label: '<200' },
  { value: '200-237', label: '200-237' },
  { value: '>237', label: '>237' },
];

const SsccrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const ShiccyanidesOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const ShiccrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const ShicsulfurcontentOptions = [
  { value: 'High Sulfur Steel', label: 'High Sulfur Steel' },
  { value: 'Low Sulfur Steel', label: 'Low Sulfur Steel' },
  { value: 'Product Form - Seamless/Extruded Pipe', label: 'Product Form - Seamless/Extruded Pipe' },
];

const HicmonitoringmethodOptions = [
  { value: 'Key Process Variables', label: 'Key Process Variables' },
  { value: 'Hydrogen Probes', label: 'Hydrogen Probes' },
  { value: 'Key Process Variables and Hydrogen Probes', label: 'Key Process Variables and Hydrogen Probes' },
];

const AceCO3Options = [
  { value: 'CO3 All Concentrations', label: 'CO3 All Concentrations' },
  { value: 'CO3 < 100 ppm', label: 'CO3 < 100 ppm' },
  { value: 'CO3 > 100 ppm', label: 'CO3 > 100 ppm' },
];

const AcecrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const PacrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const CscrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const HsccrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const HicsohiccrackingOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Not Present', label: 'Not Present' },
];

const HicsohicsulfurcontentOptions = [
  { value: 'High Sulfur Steel  > 0.01% S', label: 'High Sulfur Steel  > 0.01% S' },
  { value: 'Low Sulfur Steel  < 0.01% S', label: 'Low Sulfur Steel  < 0.01% S' },
  { value: 'Product Form – Seamless/Extruded Pipe', label: 'Product Form – Seamless/Extruded Pipe' },
];

const HicsohicadjustmentOptions = [
  { value: 'Key Process Variables', label: 'Key Process Variables' },
  { value: 'Hydrogen Probes', label: 'Hydrogen Probes' },
  { value: 'Key Process Variables and Hydrogen Probes', label: 'Key Process Variables and Hydrogen Probes' },
];

const PamaterialOptions = [
  { value: 'All regular 300 series Stainless Steels and Alloys 600 and 800 ', label: 'All regular 300 series Stainless Steels and Alloys 600 and 800 ' },
  { value: 'H Grade 300 series SS', label: 'H Grade 300 series SS' },
  { value: 'L Grade 300 series SS ', label: 'L Grade 300 series SS ' },
  { value: '321 Stainless Steel ', label: '321 Stainless Steel ' },
  { value: '347 Stainless Steel, Alloy ', label: '347 Stainless Steel, Alloy ' },
];

const CsccoperatingtemperatureOptions = [
  { value: '<100', label: '<100' },
  { value: '>100 - 150', label: '>100 - 150' },
  { value: '>100 - 200', label: '>100 - 200' },
  { value: '>150 - 200', label: '>150 - 200' },
  { value: '>200 - 300', label: '>200 - 300' },
  { value: '>300', label: '>300' },
];

const CsccchlorideOptions = [
  { value: "'1-10", label: "'1-10" },
  { value: "11-100", label: "11-100" },
  { value: "101-1000", label: "101-1000" },
  { value: ">1000", label: ">1000" },
];

const AcefohtOptions = [
  { value: 'Solution Annealed', label: 'Solution Annealed' },
  { value: 'Stabilized Before Welding', label: 'Stabilized Before Welding' },
  { value: 'Stabilized After Welding', label: 'Stabilized After Welding' },
];

const ShicfunctiontreatmentOptions = [
  { value: 'As-Welded', label: 'As-Welded' },
  { value: 'PWHT', label: 'PWHT' },
];

//DF Brittle
const HthadamageconditionOptions = [
  { value: 'Yes and  component has not been replaced', label: 'Yes and  component has not been replaced' },
  { value: 'Yes and the component has been replaced in kind', label: 'Yes and the component has been replaced in kind' },
  { value: 'Component has beed replaced with an upgrade in the material construction', label: 'Component has beed replaced with an upgrade in the material construction' },
];

const BfasmeexcurveOptions = [
	{ value: 'A', label: 'A'},
	{ value: 'B', label: 'B'},
	{ value: 'C', label: 'C'},
	{ value: 'D', label: 'D'},
];

const LasasmecurveOptions = [
	{ value: 'A', label: 'A'},
	{ value: 'B', label: 'B'},
	{ value: 'C', label: 'C'},
	{ value: 'D', label: 'D'},
];

const LaseaorproconOptions = [
	{ value: 'Exist', label: 'Exist'},
	{ value: 'No', label: 'No'},
];

const EmbaopcOptions = [
	{ value: 'Exist', label: 'Exist'},
	{ value: 'No', label: 'No'},
];

const SigmaembcontentOptions = [
	{ value: 'Low', label: 'Low'},
	{ value: 'Medium', label: 'Medium'},
	{ value: 'High', label: 'High'},
];

const PrefacriteriaOptions = [
	{ value: 'None', label: 'None'},
	{ value: 'One', label: 'One'},
	{ value: 'Greater than one', label: 'Greater than one'},
];

const PrefadfbpffatigueOptions = [
	{ value: 'Minor', label: 'Minor'},
	{ value: 'Moderate', label: 'Moderate'},
	{ value: 'Severe', label: 'Severe'},
];

const VisshcriteriaOptions = [
	{ value: 'Shaking less than 2 weeks', label: 'Shaking less than 2 weeks'},
	{ value: 'Shaking between 2 and 13 weeks', label: 'Shaking between 2 and 13 weeks'},
	{ value: 'Shaking between 13 and 52 weeks', label: 'Shaking between 13 and 52 weeks'},
];

const TocycriteriaOptions = [
	{ value: 'Reciprocating Machinery', label: 'Reciprocating Machinery'},
	{ value: 'PRV Chatter', label: 'PRV Chatter'},
	{ value: 'Valve with high pressure drop', label: 'Valve with high pressure drop'},
	{ value: 'None', label: 'None'},
];

const AdcocriteriaOptions = [
	{ value: 'No modification', label: 'No modification'},
	{ value: 'Modification based on experience', label: 'Modification based on experience'},
	{ value: 'Modification based on complete engineering analysis', label: 'Modification based on complete engineering analysis'},
];

const AdpicriteriaOptions = [
	{ value: '0 to 5 total pipe fittings', label: '0 to 5 total pipe fittings'},
	{ value: '6 to 10 total pipe fittings', label: '6 to 10 total pipe fittings'},
	{ value: 'Greater than 10 total pipe fittings', label: 'Greater than 10 total pipe fittings'},
];

const AdcoicriteriaOptions = [
	{ value: 'Missing', label: 'Missing'},
	{ value: 'Broken', label: 'Broken'},
	{ value: 'Good', label: 'Good'},
];

const AdjotcriteriaOptions = [
	{ value: 'Threaded', label: 'Threaded'},
	{ value: 'socketweld', label: 'socketweld'},
	{ value: 'saddle on', label: 'saddle on'},
	{ value: 'Saddle in fittings', label: 'Saddle in fittings'},
	{ value: 'Piping tee', label: 'Piping tee'},
	{ value: 'Weldolets', label: 'Weldolets'},
	{ value: 'Sweepolets', label: 'Sweepolets'},
];

const AdbrdicriteriaOptions = [
	{ value: 'Less than or equal to 2 NPS', label: 'Less than or equal to 2 NPS'},
	{ value: 'Greater than 2 NPS', label: 'Greater than 2 NPS'},
];

const Holesize1234Options = [
	{ value: 'Small', label: 'Small'},
	{ value: 'Medium', label: 'Medium'},
	{ value: 'Large', label: 'Large'},
	{ value: 'Rupture', label: 'Rupture'},
	{ value: '-', label: '-'},
];

const DetsystyOptions = [
	{ value: 'Instrument designed specifically to detect material losses by changes in operating conditions (i.e., loss of pressure or flow) in the system.', label: 'Instrument designed specifically to detect material losses by changes in operating conditions (i.e., loss of pressure or flow) in the system.'},
	{ value: 'Suitably located detectors to detemine when the material is present outside the pressure-containing envelope.', label: 'Suitably located detectors to detemine when the material is present outside the pressure-containing envelope.'},
	{ value: 'Visual detection, cameras, or detectors with marginal coverage', label: 'Visual detection, cameras, or detectors with marginal coverage'},
];

const IsosystypeOptions = [
	{ value: 'Isolation or shutdown systems activated directly from process instrumentation or detectors, with no operator intervention. ', label: 'Isolation or shutdown systems activated directly from process instrumentation or detectors, with no operator intervention. '},
	{ value: 'Isolation or shutdown systems activated by operators in the control room or other suitable locations remote from the leak. ', label: 'Isolation or shutdown systems activated by operators in the control room or other suitable locations remote from the leak. '},
	{ value: 'Isolation dependent on manually-operated valves. ', label: 'Isolation dependent on manually-operated valves. '},
];

const ConarmitOptions = [
	{ value: 'Inventory blowdown, coupled with isolation system classification B or higher ', label: 'Inventory blowdown, coupled with isolation system classification B or higher '},
	{ value: 'Fire water deluge system and monitors', label: 'Fire water deluge system and monitors'},
	{ value: 'Fire water monitors only ', label: 'Fire water monitors only '},
	{ value: 'Foam spray system ', label: 'Foam spray system '},
];

const FluidtypecofOptions = [
	{ value: 'TYPE 0', label: 'TYPE 0'},
	{ value: 'TYPE 1', label: 'TYPE 1'},
];

const ToxflucovOptions = [
	{ value: 'HF acid', label: 'HF acid'},
	{ value: 'H2S', label: 'H2S'},
	{ value: 'Ammonia', label: 'Ammonia'},
	{ value: 'Chlorine', label: 'Chlorine'},
	{ value: 'Aluminium chloride', label: 'Aluminium chloride'},
	{ value: 'Carbon monoxide', label: 'Carbon monoxide'},
	{ value: 'Hydrogen chloride', label: 'Hydrogen chloride'},
	{ value: 'Nitric acid', label: 'Nitric acid'},
	{ value: 'Nitrogen dioxide', label: 'Nitrogen dioxide'},
	{ value: 'Phosgene', label: 'Phosgene'},
	{ value: 'TDI', label: 'TDI'},
	{ value: 'EE', label: 'EE'},
	{ value: 'Propylene oxide', label: 'Propylene oxide'},
	{ value: 'None', label: 'None'},
];



  return (
    <div style={{ margin: '5px' }}>
	<div id="prm_getrannum" style={{display: 'none'}}></div>
      <u><h2>RBI Calculator</h2></u>
	  <h5 style={{marginTop: '-10px'}}>Based API 580</h5>
		
		<div class="row">
		
			<div class="card col-4" style={{margin: '20px 5px 5px 5px', border: 'none'}}>
				
				<div class="card-header" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
					<div style={{float: 'left'}}>
						<h5 style={{textAlign: 'left'}}>Result</h5>
					</div>
					
					<div style={{float: 'right'}}>
						<div style={{float: 'left', margin: "5px"}}>
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/Manual Book 2.png`} 
								alt="Alive home" 
								style={{ width: '20px', height: '20px', borderRadius: '5px'}} 
							/>
						</div>
						<div id="printicon" style={{float: 'left', margin: '5px', display: 'none'}}>
							
							<a 
							onClick={handleOpenRBIprint}
							style={{fontSize: '20px'}}
							>
								<i class="fa fa-print" aria-hidden="true"></i>
							</a>
						</div>
					</div>
					
				</div>
						
				<div class="card-body" style={{backgroundColor: "#646464", color: 'white'}}>
						
					{riskData ? (
						<div>
							<p><strong>Risk Matrix:</strong> {riskData.Risk_matrix}</p>
							<p><strong>Risk Level:</strong> {riskData.Risk_level}</p>
						</div>
					) : (
						
						<p>Hasil Perhitungan dan Kalkulasi</p>
					)}
					
					{loading && (
					  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						
						<div>Sedang memproses...</div>
					  </div>
					)}
						
				</div>
				
			</div>
			
			<div className="card col-4" style={{ margin: "20px 5px 5px 5px", border: 'none' }}>
				
				{riskData ? (
				  <>
					{riskData.Risk_matrix === "1A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "1B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "1C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskData.Risk_matrix === "1D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "1E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "2A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "2B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "2C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskData.Risk_matrix === "2D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "2E" && (
					  
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "3A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "3B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "3C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskData.Risk_matrix === "3D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "3E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "4A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "4B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "4C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskData.Risk_matrix === "4D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "4E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "5A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "5B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{riskData.Risk_matrix === "5C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{riskData.Risk_matrix === "5D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{riskData.Risk_matrix === "5E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
				  </>
				) : (
				  
				<a href="./">
					<img 
						src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/awal risk.png`}
						style={{ width: '150px', marginLeft: '-10px' }} 
					/>
				</a>
				  
				)}
				
			</div>
		</div>
		
      <form onSubmit={handleSubmit}>
	  
		<div class="row">
		
			<div style={{float: 'left', width: '90%'}}>
				
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: openSection === 'basicData' ? '#d3d3d3' : '#dc3545', color: openSection === 'basicData' ? 'black' : 'white' }}
				className="btn"
				type="button"
				onClick={() => toggleSection('basicData')}
				aria-expanded={openSection === 'basicData'}
				aria-controls="collapsebasicdata"
			>
				Basic Data
			</button>

			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: openSection === 'screening' ? '#d3d3d3' : '#dc3545', color: openSection === 'screening' ? 'black' : 'white' }}
				className="btn"
				type="button"
				onClick={() => toggleSection('screening')}
				aria-expanded={openSection === 'screening'}
				aria-controls="collapsescreening"
			>
				Screening
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: openSection === 'DFthinninglining' ? '#d3d3d3' : '#dc3545', color: openSection === 'DFthinninglining' ? 'black' : 'white' }}
				className="btn"
				type="button"
				onClick={() => toggleSection('DFthinninglining')}
				aria-expanded={openSection === 'DFthinninglining'}
				aria-controls="collapseDFthinninglining"
			>
				DF Thinning Lining
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: openSection === 'DFexternal' ? '#d3d3d3' : '#dc3545', color: openSection === 'DFexternal' ? 'black' : 'white' }}
				className="btn"
				type="button"
				onClick={() => toggleSection('DFexternal')}
				aria-expanded={openSection === 'DFexternal'}
				aria-controls="collapseDFexternal"
			>
				DF External
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: openSection === 'DFSCC' ? '#d3d3d3' : '#dc3545', color: openSection === 'DFSCC' ? 'black' : 'white' }}
				className="btn"
				type="button"
				onClick={() => toggleSection('DFSCC')}
				aria-expanded={openSection === 'DFSCC'}
				aria-controls="collapseDFSCC"
			>
				DF SCC
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: openSection === 'DFBrittleFracture' ? '#d3d3d3' : '#dc3545', color: openSection === 'DFBrittleFracture' ? 'black' : 'white' }}
				className="btn"
				type="button"
				onClick={() => toggleSection('DFBrittleFracture')}
				aria-expanded={openSection === 'DFBrittleFracture'}
				aria-controls="collapseDFBrittleFracture"
			>
				DF Brittle Fracture
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: openSection === 'DFFatigue' ? '#d3d3d3' : '#dc3545', color: openSection === 'DFFatigue' ? 'black' : 'white' }}
				className="btn"
				type="button"
				onClick={() => toggleSection('DFFatigue')}
				aria-expanded={openSection === 'DFFatigue'}
				aria-controls="collapseDFFatigue"
			>
				DF Fatigue
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: openSection === 'cof' ? '#d3d3d3' : '#dc3545', color: openSection === 'cof' ? 'black' : 'white' }}
				className="btn"
				type="button"
				onClick={() => toggleSection('cof')}
				aria-expanded={openSection === 'cof'}
				aria-controls="collapsecof"
			>
				COF
			</button>
				
			</div>
			
			<div style={{float: 'left', width: '10%'}}>
			
				<div className="col-12">
					<button style={{ marginTop: '10px', float: 'right' }} className="btn btn-success" type="submit" disabled={loading}>Submit</button>
				</div>
			
			</div>
			
		</div>
	  
	  
		<div className={`collapse ${openSection === 'basicData' ? 'show' : ''}`} id="collapsebasicdata">
			

			<div class="card" style={{margin: '20px 5px 5px 5px', width: '650px' }}>
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Inisialisasi</h5>
				</div>
						
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2" style={{textAlign: 'center'}}>
								
							
						<div className="col-12">
									
							<input
								style={{marginRight: '2px'}}
								type="checkbox"
								checked={selectedPWHT}
								onChange={handlePWHTChange}
								
							/>
							PWHT
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedLining}
								onChange={handleLiningChange}
								
							/>
							Lining
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedCoating}
								onChange={handleCoatingChange}
								
							/>
							Coating
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedCladding}
								onChange={handleCladdingChange}
								
							/>
							Cladding
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedHeattracing}
								onChange={handleHeattracingChange}
								
							/>
							Heat Tracing
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedInsulation}
								onChange={handleInsulationChange}
								
							/>
							Insulation
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedDatainspeksi}
								onChange={handleDatainspeksiChange}
								
							/>
							Data Inspeksi
							
						</div>
							
								
					</div>
				
				</div>
				
			</div>
			
			{/*<div class="row justify-content-center g-3">*/}
			<div class="row g-3">
			
			{/*start identifier*/}
			<div class="col-lg-4 col-md-3 mb-2">
				<div style={{margin: '5px'}} class="card">
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Identifier</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Tag Number
							</div>
							
							<div className="col-7">
								
								<input
										className="form-control"
										type="text"
										name="tag_number"
										value={formData.tag_number}
										onChange={handleChange}
										required
									/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Piping Service
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="piping_service"
									value={formData.piping_service}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Location From
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="location_from"
									value={formData.location_from}
									onChange={handleChange}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Location To
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="location_to"
									value={formData.location_to}
									onChange={handleChange}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="installation_date"
									value={formData.installation_date}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Inspection Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Inspectiondate"
									value={formData.Inspectiondate}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Inspection Date (complete)
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="date"
									name="Inspectiondatecomplete"
									value={formData.Inspectiondatecomplete}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Position
							</div>
							
							<div className="col-7">
								
								<Select
									options={PositionOptions}
									onChange={handlePositionChange}
									placeholder="Position"
									value={selectedPosition}
									required
								>
								</Select>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Equipment Type
							</div>
							
							<div className="col-7">
								
								<Select
									options={EquipmenttypeOptions}
									onChange={handleEquipmenttypeChange}
									placeholder="Equipment Type"
									value={selectedEquipmenttype}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Component Type
							</div>
							
							<div className="col-7">
								
								<Select
									options={ComponenttypeOptions}
									onChange={handleComponenttypeChange}
									placeholder="Component Type"
									value={selectedComponenttype}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Geometry Type
							</div>
							
							<div className="col-7">
								
								<Select
									options={GeometrytypeOptions}
									onChange={handleGeometrytypeChange}
									placeholder="Geometry Type"
									value={selectedGeometrytype}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								P&ID Number
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="PIDNumber"
									value={formData.PIDNumber}
									onChange={handleChange}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Thickness Actual
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Thicknessactual"
									value={formData.Thicknessactual}
									onChange={handleChange}
									disabled={!selectedDatainspeksi}
								/>
								
							</div>
							
						</div>
						
					</div>
					
				</div>
				
				{/*start Corrosion Data*/}
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Corrosion Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Allowance
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosionallowance"
									value={formData.Corrosionallowance}
									onChange={handleChange}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Probe
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosionprobe"
									value={formData.Corrosionprobe}
									onChange={handleChange}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Coupon
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosioncoupon"
									value={formData.Corrosioncoupon}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			{/*end Corrosion Data*/}
				
			</div>
			{/*end identifier*/}
		
			{/*start Construction Design Data*/}
			<div class="col-lg-4 col-md-4 mb-2">
				<div style={{margin: '5px', height: '100%'}} class="card">
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Construction Design Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Coating Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="date"
									name="coating_installation_date"
									value={formData.coating_installation_date}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Material Construction
							</div>
							
							<div className="col-7">
								
								<Select
									options={materialOptions}
									onChange={handleMaterialChange}
									placeholder="Material Construction"
									value={selectedMaterial}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Grade
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="grade"
									value={formData.grade}
									onChange={handleChange}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Design Code
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Designcode"
									value={formData.Designcode}
									onChange={handleChange}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Outside Diameter
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Outsidediameter"
									value={formData.Outsidediameter}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Length
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Length"
									value={formData.Length}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Nominal Thickness
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Nominalthickness"
									value={formData.Nominalthickness}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Remaining Life of the Cladding
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Remaininglifeofthecladding"
									value={formData.Remaininglifeofthecladding}
									onChange={handleChange}
									disabled={!selectedCladding}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Rate Cladding
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Corrosionratecladding"
									value={formData.Corrosionratecladding}
									onChange={handleChange}
									disabled={!selectedCladding}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Weld Joint Efficiency
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Weldjointefficiency"
									value={formData.Weldjointefficiency}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Yield Strength
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Yieldstrength"
									value={formData.Yieldstrength}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Tensile Strength
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Tensilestrength"
									value={formData.Tensilestrength}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Allowable Stress
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Allowablestress"
									value={formData.Allowablestress}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Coating Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Coatinginstallationdate"
									value={formData.Coatinginstallationdate}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Previous Inspection Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Previousinspectiondate"
									value={formData.Previousinspectiondate}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Previous Thickness Reading
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Previousthicknessreading"
									value={formData.Previousthicknessreading}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			</div>
			{/*end Construction Design Data*/}
			
			{/*start Operation Data*/}
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Operation Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Design Pressure
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Designpressure"
									value={formData.Designpressure}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Design Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Designtemperature"
									value={formData.Designtemperature}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Operating Pressure
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Operatingpressure"
									value={formData.Operatingpressure}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Operating Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Operatingtemperature"
									value={formData.Operatingtemperature}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Minimum Design Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Minimumdesigntemperature"
									value={formData.Minimumdesigntemperature}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Upset Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Upsettemperature"
									value={formData.Upsettemperature}
									onChange={handleChange}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			</div>
			{/*end Operation Data*/}
			
			</div>
		  
        </div>
	  
		<div className={`collapse ${openSection === 'screening' ? 'show' : ''}`} id="collapsescreening">
			
			<div class="col-lg-5 col-md-5 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Year of Fabrication Material
							</div>
										
							<div className="col-7">
											
								<Select
									options={yearoffabricationmaterialOptions}
									onChange={handleyearoffabricationmaterialChange}
									placeholder="Year of Fabrication Material"
									value={selectedyearoffabricationmaterial}
									required
								>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Representative Fluid
							</div>
										
							<div className="col-7">
											
								<Select
									options={RepresentativefluidOptions}
									onChange={handleRepresentativefluidChange}
									placeholder="Representative Fluid"
									value={selectedRepresentativefluid}
									required
								/>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid Phase
							</div>
										
							<div className="col-7">
											
								<Select
									options={FluidphaseOptions}
									onChange={handleFluidphaseChange}
									placeholder="Fluid Phase"
									value={selectedFluidphase}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid at Normal Operating Conditions
							</div>
										
							<div className="col-7">
											
								<Select
									options={GasliquidOptions}
									onChange={handleFluidatnormaloperatingconditionsChange}
									placeholder="Fluid at Normal Operating Conditions"
									value={selectedFluidatnormaloperatingconditions}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid at Ambient Conditions
							</div>
										
							<div className="col-7">
											
								<Select
									options={GasliquidOptions}
									onChange={handleFluidatambientconditionsChange}
									placeholder="Fluid at Ambient Conditions"
									value={selectedFluidatambientconditions}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Number of Past Inspection
							</div>
										
							<div className="col-7">
											
								<input
									className="form-control"
									type="number"
									name="Numberofpastinspection"
									value={formData.Numberofpastinspection}
									onChange={handleChange}
									required
								/>
											
							</div>
							
						</div>
						
						
					</div>
				</div>
			</div>
			
			<div class="row">
			
			{/* What Is Contained the process ? */}
			
   
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px', height: '900px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What Is Contained the process ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Caustic (Presence of NaOH at Elevated Temperature)
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleCausticpresenChange}
									placeholder="Caustic (Presence of NaOH at Elevated Temperature)"
									value={selectedCausticpresen}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Alkaline Water (pH > 7.5)
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleAlkalinewaterChange}
									placeholder="Alkaline Water (pH > 7.5)"
									value={selectedAlkalinewater}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Oil With Sulfur Compounds
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleOilwithsulfurcompoundsChange}
									placeholder="Oil With Sulfur Compounds"
									value={selectedOilwithsulfurcompounds}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chlorides {"<"}50 ppm
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleChloridesppmChange}
									placeholder="Chlorides <50 ppm"
									value={selectedChloridesppm}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Free Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleFreewaterChange}
									placeholder="Free Water"
									value={selectedFreewater}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂S and Hydrogen
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleH2SandhydrogenChange}
									placeholder="H₂S and Hydrogen"
									value={selectedH2Sandhydrogen}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Oxygen
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleOxygennChange}
									placeholder="Oxygen"
									value={selectedOxygen}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂S
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleH2SChange}
									placeholder="H₂S"
									value={selectedH2S}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								HCl
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleHClChange}
									placeholder="HCl"
									value={selectedHCl}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂SO₄
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleH2SO4Change}
									placeholder="H₂SO₄"
									value={selectedH2SO4}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								HF
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleHFChange}
									placeholder="HF"
									value={selectedHF}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2 div">
										
							<div className="col-7">
								CO₂
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleCO2Change}
									placeholder="CO₂"
									value={selectedCO2}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
			</div>
			
			{/* */}
			
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				
				{/*Area */}
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Area</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-5">
								Ft2
							</div>
										
							<div className="col-7">
											
								<input
									className="form-control"
									type="number"
									name="Areaft2"
									value={formData.Areaft2}
									onChange={handleChange}
									required
								/>
											
							</div>
							
						</div>
					
					</div>
				</div>
				{/*end Area*/}
				
				{/*Data Confidence Damage State*/}
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Data Confidence Damage State</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-3">
								State 1
							</div>
										
							<div className="col-9">
											
								<Select
									options={Dataconfidencedamagestate123Options}
									onChange={handleDataconfidencedamagestate1Change}
									placeholder="Data Confidence Damage State 1"
									value={selectedDataconfidencedamagestate1}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								State 2
							</div>
										
							<div className="col-9">
											
								<Select
									options={Dataconfidencedamagestate123Options}
									onChange={handleDataconfidencedamagestate2Change}
									placeholder="Data Confidence Damage State 2"
									value={selectedDataconfidencedamagestate2}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								State 3
							</div>
										
							<div className="col-9">
											
								<Select
									options={Dataconfidencedamagestate123Options}
									onChange={handleDataconfidencedamagestate3Change}
									placeholder="Data Confidence Damage State 3"
									value={selectedDataconfidencedamagestate3}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
					
					</div>
				</div>
				
				{/*end Data Confidence Damage State*/}
				
				{/*start Material Grade Alloy (For  DF Polythionic Acid)*/}
				
				<div class="card" style={{margin: '5px', height: '545px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Material Grade Alloy (For  DF Polythionic Acid)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								All regular 300 series Stainless Steels and Alloys 600 and 800 
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleAllregular300Change}
									placeholder="All regular 300 series Stainless Steels and Alloys 600 and 800 "
									value={selectedAllregular300}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H Grade 300 series SS
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleHGrade300Change}
									placeholder="H Grade 300 series SS"
									value={selectedHGrade300}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								L Grade 300 series SS 
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleLGrade300Change}
									placeholder="L Grade 300 series SS"
									value={selectedLGrade300}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								321 Stainless Steel 
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleStainlesssteel321Change}
									placeholder="321 Stainless Steel"
									value={selectedStainlesssteel321}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								347 Stainless Steel, Alloy 20, Alloy 625, All austenitic weld overlay 
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleStainlesssteel347Change}
									placeholder="347 Stainless Steel, Alloy 20, Alloy 625, All austenitic weld overlay"
									value={selectedStainlesssteel347}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				{/*end Material Grade Alloy (For  DF Polythionic Acid)*/}
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Inspection</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection A ( NAThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionA"
									value={formData.InspectionA}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection B ( NBThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionB"
									value={formData.InspectionB}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection C ( NCThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionC"
									value={formData.InspectionC}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection D ( NDThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionD"
									value={formData.InspectionD}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
					
					</div>
				</div>
				
				{/*start What is The Operating Temparature ?*/}
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What is The Operating Temparature ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								10 {"<"} T {"<"} 350 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleTF350Change}
									placeholder="10 < T < 350 °F"
									value={selectedTF350}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								120 {"<"} T {"<"} 300 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleTF300Change}
									placeholder="120 < T < 300 °F"
									value={selectedTF300}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 100 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleT100Change}
									placeholder="T > 100 °F"
									value={selectedT100}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 350 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleT350Change}
									placeholder="T > 350 °F"
									value={selectedT350}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								650{"<"} T {"<"} 1070 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleT1070Change}
									placeholder="650< T < 1070 °F"
									value={selectedT1070}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								700 {"<"} T {"<"} 1050 °C
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleT1050Change}
									placeholder="700 < T < 1050 °C"
									value={selectedT1050}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								1100 {"<"} T {"<"} 1700 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleT1700Change}
									placeholder="1100 < T < 1700 °F"
									value={selectedT1700}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 400 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleT400Change}
									placeholder="T > 400 °F"
									value={selectedT400}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T ≥ 900 °F
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleT900Change}
									placeholder="T ≥ 900 °F"
									value={selectedT900}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				{/*end What is The Operating Temparature ?*/}
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px', height: '690px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What Is Exposed To ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Mist Overspray from Cooling Towers
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleMistoversprayChange}
									placeholder="Mist Overspray from Cooling Towers"
									value={selectedMistoverspray}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Steam Vents
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleSteamventsChange}
									placeholder="Steam Vents"
									value={selectedSteamvents}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Deluge Systems
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleDelugesystemsChange}
									placeholder="Deluge Systems"
									value={selectedDelugesystems}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Containing Fluids, Mists, and Solids
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleChloridecontainingChange}
									placeholder="Chloride Containing Fluids, Mists, and Solids"
									value={selectedChloridecontaining}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Bearing Compounds
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleSulfurbearingChange}
									placeholder="Sulfur Bearing Compounds"
									value={selectedSulfurbearing}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chlorides And Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleChlorideswndwaterChange}
									placeholder="Chlorides And Water"
									value={selectedChlorideswndwater}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Hydrofluoric Acid
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleHydrofluoricacidChange}
									placeholder="Hydrofluoric Acid"
									value={selectedHydrofluoricacid}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Acid Gas Treating Amines
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleAcidgastreatingaminesChange}
									placeholder="Acid Gas Treating Amines"
									value={selectedAcidgastreatingamines}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Soil
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleSoilChange}
									placeholder="Soil"
									value={selectedSoil}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Other Conditions ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Hydrogen Partial Pressure P > 50 psia
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleHydrogenpartialChange}
									placeholder="Hydrogen Partial Pressure P > 50 psia"
									value={selectedHydrogenpartial}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Subject to Process Spills, Ingress of Moisture, or Acid Vapors
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleSubjecttoprocessChange}
									placeholder="Subject to Process Spills, Ingress of Moisture, or Acid Vapors"
									value={selectedSubjecttoprocess}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Not Normally Operate Between 10 {"<"} TO {"<"} 350 °F, but Cool or Heat
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleNotnormallyChange}
									placeholder="Not Normally Operate Between 10 < TO < 350 °F, but Cool or Heat"
									value={selectedNotnormally}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								With Deteriorated Coating and/or Wrapping
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleWithdeterioratedChange}
									placeholder="With Deteriorated Coating and/or Wrapping"
									value={selectedWithdeteriorated}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Penetrations and Visually Damage Insulation Areas
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handlePenetrationsandvisuallyChange}
									placeholder="Penetrations and Visually Damage Insulation Areas"
									value={selectedPenetrationsandvisually}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								MDMT or MAT is Unknown or The Component is Known to Operate at or Below or Under Normal or Upset Conditions
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleMDMTorMATChange}
									placeholder="MDMT or MAT is Unknown or The Component is Known to Operate at or Below or Under Normal or Upset Conditions"
									value={selectedMDMTorMAT}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Have Been Past Fatigue Failures or There is Visible/Audible Shaking in This Piping
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleHavebeenpastChange}
									placeholder="Have Been Past Fatigue Failures or There is Visible/Audible Shaking in This Piping"
									value={selectedHavebeenpast}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								pH {"<"} 7.0
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handlePh7Change}
									placeholder="pH < 7.0"
									value={selectedPh7}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
			</div>
					
			
			</div>
			
		</div>
		
		
		<div className={`collapse ${openSection === 'DFthinninglining' ? 'show' : ''}`} id="collapseDFthinninglining">
			
			<div class="col-lg-5 col-md-5 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Applicable
							</div>
										
							<div className="col-7">
											
								<Select
									options={YesnoOptions}
									onChange={handleApplicableChange}
									placeholder="Applicable"
									value={selectedApplicable}
									required
								>
							</Select>
											
							</div>
							
						</div>
					
					</div>
				</div>
			</div>
			
			
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Thinning</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 1)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS1"
									value={formData.ReliabilityindicesDS1}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 2)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS2"
									value={formData.ReliabilityindicesDS2}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 3)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS3"
									value={formData.ReliabilityindicesDS3}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVΔt)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVAT"
									value={formData.ReliabilityindicesCOVAT}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVSf)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVSf"
									value={formData.ReliabilityindicesCOVSf}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVP)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVP"
									value={formData.ReliabilityindicesCOVP}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Inspection Effectiveness (For Tank Bottom)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Category
							</div>
										
							<div className="col-5">
							
								<Select
									options={InspectioneffectivenesscategoryOptions}
									onChange={handleInspectioneffectivenesscategoryChange}
									placeholder="Injection/Mix Points - Contain"
									value={selectedInspectioneffectivenesscategory}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Injection/Mix Points</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Contain
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleInjectionmixpointsChange}
									placeholder="Injection/Mix Points - Contain"
									value={selectedInjectionmixpoints}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Deadleg</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Contain
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									onChange={handleDeadlegcontainChange}
									placeholder="Deadleg - Contain"
									value={selectedDeadlegcontain}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Welded Construction (Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Welded
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnowithnaOptions}
									onChange={handleWeldedconstructionweldedChange}
									placeholder="Welded Construction (Applicable only for AST)"
									value={selectedWeldedconstructionwelded}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Maintenance API STD 563 (Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Maintained
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnowithnaOptions}
									onChange={handleMaintenanceAPISTD563MaintainedChange}
									placeholder="Maintenance API STD 563 (Applicable only for AST) - Maintained"
									value={selectedMaintenanceAPISTD563Maintained}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Settlement(Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={SettlementcriteriaOptions}
									onChange={handleSettlementcriteriaChange}
									placeholder="Settlement(Applicable only for AST) - Criteria"
									value={selectedSettlementcriteria}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>On-Line Monitoring</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Thinning Mechanism
							</div>
										
							<div className="col-5">
							
								<Select
									options={OnlinemonitoringThinningmechanismOptions}
									onChange={handleOnlinemonitoringThinningmechanismChange}
									placeholder="On-Line Monitoring - Thinning Mechanism"
									value={selectedOnlinemonitoringThinningmechanism}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Monitoring Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={OnlinemonitoringMonitoringtypeOptions}
									onChange={handleOnlinemonitoringThinningmechanismChange}
									placeholder="On-Line Monitoring - Monitoring Type"
									value={selectedOnlinemonitoringMonitoringtype}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>COMPONENT LINING</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Lining Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={LiningtypeOptions}
									onChange={handleLiningtypeChange}
									placeholder="Lining Type"
									value={selectedLiningtype}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Age of Lining or Years Since Last Thorough Visual Inspection
							</div>
										
							<div className="col-5">
							
								<Select
									options={AgeofLiningoryearageOptions}
									onChange={handleAgeofLiningoryearageChange}
									placeholder="Age of Lining or Years Since Last Thorough Visual Inspection"
									value={selectedAgeofLiningoryearage}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Inorganic Linings - Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={InorganicliningstypeOptions}
									onChange={handleInorganicliningstypeChange}
									placeholder="Inorganic Linings - Type"
									value={selectedInorganicliningstype}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Organic Linings - Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={OrgaliningstypeOptions}
									placeholder="Organic Linings - Type"
									onChange={handleOrgaliningstypeChange}
									value={selectedOrgaliningstype}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Lining Condition - Qualitative Condition
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Lining Condition - Qualitative Condition"
									onChange={handleLiningconditionqualitativeconditionChange}
									value={selectedLiningconditionqualitativecondition}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On-Line Monitoring  - Contain
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="On-Line Monitoring - Contain"
									onChange={handleMonitoringcontainChange}
									value={selectedMonitoringcontain}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			</div>
			
		</div>
	  
		
		<div className={`collapse ${openSection === 'DFexternal' ? 'show' : ''}`} id="collapseDFexternal">
		
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External Ferritic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver - Arid / Dry
							</div>
										
							<div className="col-5">
							
								<Select
									options={ExtDriverOptions}
									placeholder="Driver"
									onChange={handleExtDriverChange}
									value={selectedExtDriver}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Equipment Design or Fabrication
							</div>
										
							<div className="col-5">
							
								<Select
								options={YesnoOptions}
								placeholder="Equipment Design or Fabrication"
								onChange={handleEquipmentDesignorFabricationChange}
								value={selectedEquipmentDesignorFabrication}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Interface
							</div>
										
							<div className="col-5">
							
								<Select
								options={YesnoOptions}
								placeholder="Interface"
								onChange={handleInterfaceChange}
								value={selectedInterface}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Coating Quality
							</div>
										
							<div className="col-5">
							
								<Select
								options={CoatingQualityOptions}
								placeholder="Coating Quality"
								onChange={handleCoatingQualityChange}
								value={selectedCoatingQuality}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External CLSCC Ferritic</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									options={EcfDriveOptions}
									placeholder="External CLSCC Ferritic - Drive"
									onChange={handleEcfDriveChange}
									value={selectedEcfDrive}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={EcfCrackingOptions}
									placeholder="External CLSCC Ferritic - Cracking"
									onChange={handleEcfCrackingChange}
									value={selectedEcfCracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspection Performed
							</div>
										
							<div className="col-5">
							
								<Select
									options={EcfPerformedOptions}
									onChange={handleEcfInspectionPerformedChange}
									value={selectedEcfInspectionPerformed}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
			
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CUI Ferritic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									options={CfcDriverOptions}
									placeholder="Driver"
									onChange={handleCfcDriverChange}
									value={selectedCfcDriver}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Insulation Type
							</div>
										
							<div className="col-5">
							
								<Select
									options={CfcInsulationTypeOptions}
									placeholder="Insulation Type"
									onChange={handleInsulationTypeChange}
									value={selectedInsulationType}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Complexity
							</div>
										
							<div className="col-5">
							
								<Select
									options={ComplexityOptions}
									placeholder="Complexity"
									onChange={handleComplexityChange}
									value={selectedComplexity}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Insulation Condition
							</div>
										
							<div className="col-5">
							
								<Select
									options={InsulationConditionOptions}
									placeholder="Insulation Condition"
									onChange={handleInsulationConditionChange}
									value={selectedInsulationCondition}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Design or Fabrication
							</div>
										
							<div className="col-5">
							
								<Select
									options={CfcDesignorFabricationOptions}
									placeholder="CUI Ferritic Component - Design or Fabrication"
									onChange={handleCfcDesignorFabricationChange}
									value={selectedCfcDesignorFabrication}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Interface Enters Soil or Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={CfcInterfacewaterOptions}
									placeholder="Interface Enters Soil or Water"
									onChange={handleInterfaceenterssoilorwaterChange}
									value={selectedInterfaceenterssoilorwater}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External CLSCC CUI</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									options={EcfDrivecuiOptions}
									placeholder="External CLSCC CUI - Drive"
									onChange={handleEcfDrivecuiChange}
									value={selectedEcfDrivecui}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={CrackingcuiOptions}
									placeholder="External CLSCC CUI - Cracking"
									onChange={handleEcfCrackingcuiChange}
									value={selectedEcfCrackingcui}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Free Insulation
							</div>
										
							<div className="col-5">
							
								<Select
									options={ChloridefreecuiOptions}
									placeholder="External CLSCC CUI - Chloride Free Insulation"
									onChange={handleChloridefreecuiChange}
									value={selectedChloridefreecui}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspection Performed
							</div>
										
							<div className="col-5">
							
								<Select
									options={PerformedcuiOptions}
									onChange={handleInspectionPerformedcuiChange}
									value={selectedInspectionPerformedcui}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
			
				
			</div>
			
			
			</div>
			
		
		
		</div>
		
		 <div className={`collapse ${openSection === 'DFSCC' ? 'show' : ''}`} id="collapseDFSCC">
			
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Caustic Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Determine Susceptibility for Cracking
							</div>
										
							<div className="col-5">
							
								<Select
								options={DetermineSusceptibilityOptions}
								onChange={handleDetermineSusceptibilityChange}
								value={selectedDetermineSusceptibility}
								required
							>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									options={CRInspectionsOptions}
									onChange={handleCRNumberofInspectionsChange}
									value={selectedCRNumberofInspections}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Amine Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Determine Susceptibility for Cracking
							</div>
										
							<div className="col-5">
							
								<Select
								options={ACDetermineSusceptibilityOptions}
								onChange={handleACDetermineSusceptibilityChange}
								value={selectedACDetermineSusceptibility}
								required
							>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									options={ACInspectionsOptions}
									onChange={handleACNumberofInspectionsChange}
									value={selectedACNumberofInspections}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Alkaline Carbonate</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={AcephwaterOptions}
									onChange={handleAcephwaterChange}
									value={selectedAcephwater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								CO3 Concentration
							</div>
										
							<div className="col-5">
							
								<Select
									options={AceCO3Options}
									placeholder="Alkaline Carbonate - CO3 Concentration"
									onChange={handleAceCO3Change}
									value={selectedAceCO3}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={AcecrackingOptions}
									placeholder="ALkaline Carbonate - Cracking"
									onChange={handleAcecrackingChange}
									value={selectedAcecracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									options={AcefohtOptions}
									placeholder="ALkaline Carbonate - Function of Heat Treatment"
									onChange={handleAcefohtChange}
									value={selectedAcefoht}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
			
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Polythionic Acid</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={PacrackingOptions}
									placeholder="Polythionic Acid - Cracking"
									onChange={handlePACrackingChange}
									value={selectedPACracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Material
							</div>
										
							<div className="col-5">
							
								<Select
									options={PamaterialOptions}
									placeholder="Polythionic Acid - Material"
									onChange={handlePamaterialChange}
									value={selectedPamaterial}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Sulfide Stress Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={SscphwaterOptions}
									onChange={handleSscphwaterChange}
									value={selectedSscphwater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of H2S Content of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={SscfunctionhwaterOptions}
									onChange={handleSscfunctionh2swaterChange}
									value={selectedSscfunctionh2swater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cyanides
							</div>
										
							<div className="col-5">
							
								<Select
									options={SsccyanidesOptions}
									placeholder="Sulfide Stress Cracking - Cyanides"
									onChange={handleSsccyanidesChange}
									value={selectedSsccyanides}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									options={SscfunctiontreatmentOptions}
									placeholder="Sulfide Stress Cracking - Function of Heat Treatment"
									onChange={handleSscfunctiontreatmentChange}
									value={selectedSscfunctiontreatment}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Max Brinnell Hardness
							</div>
										
							<div className="col-5">
							
								<Select
									options={SscmaxbrinnellOptions}
									placeholder="Sulfide Stress Cracking - Max Brinnell Hardness"
									onChange={handleSscmaxbrinnellChange}
									value={selectedSscmaxbrinnell}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={SsccrackingOptions}
									placeholder="Sulfide Stress Cracking - Cracking"
									onChange={handleSsccrackingChange}
									value={selectedSsccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									options={SCCInspectionsOptions}
									onChange={handleSCCNumberofInspectionsChange}
									value={selectedSCCNumberofInspections}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Chloride SCC</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Operating Temperature (°F)
							</div>
										
							<div className="col-5">
							
								<Select
									options={CsccoperatingtemperatureOptions}
									placeholder="Chloride SCC - Operating Temperature (°F)"
									onChange={handleCsccoperatingtemperatureChange}
									value={selectedCsccoperatingtemperature}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Ion
							</div>
										
							<div className="col-5">
							
								<Select
									options={CsccchlorideOptions}
									placeholder="Chloride SCC - Chloride Ion"
									onChange={handleCsccchlorideChange}
									value={selectedCsccchloride}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={CscrackingOptions}
									placeholder="Chloride SCC - Cracking"
									onChange={handleCscccrackingChange}
									value={selectedCscccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Hydrogen Stress Cracking HF</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Condition
							</div>
										
							<div className="col-5">
							
								<Select
									options={HscconditionOptions}
									placeholder="Hydrogen Stress Cracking HF - Condition"
									onChange={handleHscconditionChange}
									value={selectedHsccondition}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Max Brinnell Hardness
							</div>
										
							<div className="col-5">
							
								<Select
									options={HscmaxbrinnellOptions}
									placeholder="Hydrogen Stress Cracking HF - Max Brinnell Hardness"
									onChange={handleHscmaxbrinnellChange}
									value={selectedHscmaxbrinnell}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={HsccrackingOptions}
									placeholder="Hydrogen Stress Cracking HF - Cracking"
									onChange={handleHsccrackingChange}
									value={selectedHsccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
						
						
					</div>
				</div>
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>HIC/SOHIC-H2S</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicphwaterOptions}
									onChange={handleShicphwaterChange}
									value={selectedShicphwater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of H2S Content of Water
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicfunctionwaterOptions}
									onChange={handleShicfunctionh2swaterChange}
									value={selectedShicfunctionh2swater}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cyanides
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShiccyanidesOptions}
									placeholder="HIC/SOHIC-H2S - Cyanides"
									onChange={handleShiccyanidesChange}
									value={selectedShiccyanides}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShiccrackingOptions}
									placeholder="HIC/SOHIC-H2S - Cracking"
									onChange={handleShiccrackingChange}
									value={selectedShiccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicfunctiontreatmentOptions}
									placeholder="HIC/SOHIC-H2S - Function of Heat Treatment"
									onChange={handleShicfunctiontreatmentChange}
									value={selectedShicfunctiontreatment}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Content
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicsulfurcontentOptions}
									placeholder="HIC/SOHIC-H2S - Sulfur Content"
									onChange={handleShicsulfurcontentChange}
									value={selectedShicsulfurcontent}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									options={ShicInspectionsOptions}
									onChange={handleShicNumberofInspectionsChange}
									value={selectedShicNumberofInspections}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On-Line Monitoring - Monitoring Method
							</div>
										
							<div className="col-5">
							
								<Select
									options={HicmonitoringmethodOptions}
									placeholder="HIC/SOHIC-H2S - On-Line Monitoring - Monitoring Method"
									onChange={handleHicmonitoringmethodChange}
									value={selectedHicmonitoringmethod}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>HIC/SOHIC HF</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									options={HicsohiccrackingOptions}
									placeholder="HIC/SOHIC HF - Cracking"
									onChange={handleHicsohiccrackingChange}
									value={selectedHicsohiccracking}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Content
							</div>
										
							<div className="col-5">
							
								<Select
									options={HicsohicsulfurcontentOptions}
									placeholder="HIC/SOHIC HF - Sulfur Content"
									onChange={handleHicsohicsulfurcontentChange}
									value={selectedHicsohicsulfurcontent}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On Line Monitoring - Adjustment
							</div>
										
							<div className="col-5">
							
								<Select
									options={HicsohicadjustmentOptions}
									placeholder="HIC/SOHIC HF - Sulfur Content"
									onChange={handleHicsohicadjustmentChange}
									value={selectedHicsohicadjustment}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
						
						
					</div>
				</div>
			
			</div>
			
			</div>
						
		
		</div>
	  
		<div className={`collapse ${openSection === 'DFBrittleFracture' ? 'show' : ''}`} id="collapseDFBrittleFracture">
			
		<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>High Temperature Hydrogen Attack</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								HTHA Damage - Condition
							</div>
										
							<div className="col-5">
							
								<Select
									options={HthadamageconditionOptions}
									placeholder="HIGH TEMPERATURE HYDROGEN ATTACK - HTHA Damage - Condition"
									onChange={handleHthadamageconditionChange}
									value={selectedHthadamagecondition}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Brittle Fracture</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									options={YesnoOptions}
									placeholder="Administrative or Process Control"
									onChange={handleAdmproconChange}
									value={selectedAdmprocon}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								ASME Exemption - Curve
							</div>
										
							<div className="col-5">
							
								<Select
									options={BfasmeexcurveOptions}
									placeholder="Brittle Fracture - ASME Exemption - Curve"
									onChange={handleBfasmeexcurveChange}
									value={selectedBfasmeexcurve}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Low Alloy Steel Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									options={LaseaorproconOptions}
									placeholder="LOW ALLOY STEEL EMBRITTLEMENT - Administrative or Process Control"
									onChange={handleLaseaorproconChange}
									value={selectedLaseaorprocon}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								ASME Exemption - Curve
							</div>
										
							<div className="col-5">
							
								<Select
									options={LasasmecurveOptions}
									placeholder="LOW ALLOY STEEL EMBRITTLEMENT - ASME Exemption - Curve"
									onChange={handleLasasmecurveChange}
									value={selectedLasasmecurve}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Sigma Phase Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Sigma Content
							</div>
										
							<div className="col-5">
							
								<Select
									options={SigmaembcontentOptions}
									placeholder="Sigma Phase Embrittlement - Sigma Content"
									onChange={handleSigmaembcontentChange}
									value={selectedSigmaembcontent}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>885  °F Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									options={EmbaopcOptions}
									placeholder="885  °F Embrittlement - Administrative or Process Control"
									onChange={handleEmbaopcChange}
									value={selectedEmbaopc}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reference Temperature - Tref
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="Embreftem"
									value={formData.Embreftem}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
			</div>
			
		</div>
			
			
		</div>
	  
		<div className={`collapse ${openSection === 'DFFatigue' ? 'show' : ''}`} id="collapseDFFatigue">
		
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Previous Failure</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={PrefacriteriaOptions}
									placeholder="Previous Failure - Criteria"
									onChange={handlePrefacriteriaChange}
									value={selectedPrefacriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Amount of Visible/Audible Shaking ot Audible Noise</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={PrefadfbpffatigueOptions}
									placeholder="Amount of Visible/Audible Shaking ot Audible Noise - Criteria"
									onChange={handlePrefadfbpfChange}
									value={selectedPrefadfbpf}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Visible/Audible Shaking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={VisshcriteriaOptions}
									placeholder="Previous Failure - Criteria"
									onChange={handleVisshcriteriaChange}
									value={selectedVisshcriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Type of Cyclic Loading</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={TocycriteriaOptions}
									placeholder="Type of Cyclic Loading - Criteria"
									onChange={handleTocycriteriaChange}
									value={selectedTocycriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Corrective Action</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdcocriteriaOptions}
									placeholder="Adjustment Corrective Action - Criteria"
									onChange={handleAdcocriteriaChange}
									value={selectedAdcocriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Pipe Complexity</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdpicriteriaOptions}
									placeholder="Adjustment Pipe Complexity - Criteria"
									onChange={handleAdpicriteriaChange}
									value={selectedAdpicriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Condition of Pipe</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdcoicriteriaOptions}
									placeholder="Adjustment Condition of Pipe - Criteria"
									onChange={handleAdcoicriteriaChange}
									value={selectedAdcoicriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Joint Type or Branch Design</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdjotcriteriaOptions}
									placeholder="Adjustment Joint Type or Branch Design - Criteria"
									onChange={handleAdjotcriteriaChange}
									value={selectedAdjotcriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Branch Diameter</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									options={AdbrdicriteriaOptions}
									placeholder="Adjustment Branch Diameter - Criteria"
									onChange={handleAdbrdicriteriaChange}
									value={selectedAdbrdicriteria}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		
		</div>
		
		<div className={`collapse ${openSection === 'cof' ? 'show' : ''}`} id="collapsecof">
			
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Hole Size</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								1
							</div>
										
							<div className="col-8">
							
								<Select
									options={Holesize1234Options}
									placeholder="Hole Size 1"
									onChange={handleHolesize1Change}
									value={selectedHolesize1}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								2
							</div>
										
							<div className="col-8">
							
								<Select
									options={Holesize1234Options}
									placeholder="Hole Size 2"
									onChange={handleHolesize2Change}
									value={selectedHolesize2}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								3
							</div>
										
							<div className="col-8">
							
								<Select
									options={Holesize1234Options}
									placeholder="Hole Size 3"
									onChange={handleHolesize3Change}
									value={selectedHolesize3}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								4
							</div>
										
							<div className="col-8">
							
								<Select
									options={Holesize1234Options}
									placeholder="Hole Size 4"
									onChange={handleHolesize4Change}
									value={selectedHolesize4}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Atmospheric Pressure</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Patm
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Atmosprepatm"
									value={formData.Atmosprepatm}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Conversion Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								C2 (lbs)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Confac2"
									value={formData.Confac2}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Detection System</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									options={DetsystyOptions}
									placeholder="Detection System - Type"
									onChange={handleDetsystyChange}
									value={selectedDetsysty}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Isolation System</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									options={IsosystypeOptions}
									placeholder="Isolation System - Type"
									onChange={handleIsosystyChange}
									value={selectedIsosysty}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Total Leak Durations</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-5">
								4 (minutes)
							</div>
										
							<div className="col-7">
							
								<input
									className="form-control"
									type="number"
									name="Toledur"
									value={formData.Toledur}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Consequence Area Mitigation Reduction Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								factmit
							</div>
										
							<div className="col-8">
							
								<Select
									options={ConarmitOptions}
									placeholder="Consequence Area Mitigation Reduction Factor - factmit"
									onChange={handleConarmitChange}
									value={selectedConarmit}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Environmental Costs</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								envcost (($/bbl))
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Encosen"
									value={formData.Encosen}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Fluid Type</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									options={FluidtypecofOptions}
									placeholder="Fluid Type"
									onChange={handleFluidtypecofChange}
									value={selectedFluidtypecof}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Toxic Percentage of the Toxic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								mfractox
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Toxperthetoxcom"
									value={formData.Toxperthetoxcom}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Toxic Fluid</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Toxic Fluid
							</div>
										
							<div className="col-8">
							
								<Select
									options={ToxflucovOptions}
									placeholder="Toxic Fluid"
									onChange={handleToxflucovChange}
									value={selectedToxflucov}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Acids or Caustics</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								5 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus5"
									value={formData.Acidorcaus5}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								6 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus6"
									value={formData.Acidorcaus6}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								7 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus7"
									value={formData.Acidorcaus7}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								8 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus8"
									value={formData.Acidorcaus8}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Material Cost Factors</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								matcost
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Matcosfacmat"
									value={formData.Matcosfacmat}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Equipment Cost Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								equipcost ($/ft2)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Eqcosfac"
									value={formData.Eqcosfac}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Equipment Outage Multiplier</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Equipment Outage Multiplier
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Eqoutmul"
									value={formData.Eqoutmul}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Blending Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 1
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac1"
									value={formData.Blenfac1}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 2
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac2"
									value={formData.Blenfac2}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 3
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac3"
									value={formData.Blenfac3}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 4
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac4"
									value={formData.Blenfac4}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CA for Steam or Acid Leaks Non-Flammablem Non-Toxic Personnel Injury</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 1
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon1"
									value={formData.Caforstoracleaknon1}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 2
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon2"
									value={formData.Caforstoracleaknon2}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 3
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon3"
									value={formData.Caforstoracleaknon3}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 4
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon4"
									value={formData.Caforstoracleaknon4}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CA for Steam or Acid Leaks Non-Flammablem Non-Toxic Personnel Injury</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmdnfnt (ft2)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Finoflnotoxcon"
									value={formData.Finoflnotoxcon}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Production Cost</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								prodcost ($/days)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Procoxprod"
									value={formData.Procoxprod}
									onChange={handleChange}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			</div>
			
			
		</div>
		
      </form>
    </div>
  );
};

export default RBIcalc;
