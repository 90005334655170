import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_sor1 = () => {
	const [isHovered, setIsHovered] = useState(false);

var photostocklist = {
        
        DEB2: {
            "1":"16ASgOoq4RzpEx7cfyNPQAEJFSpXxNVR5",
            "2":"18OwhaTY2aTu7oCRYTvjx7Mrbmzuj-ov2",
            "3":"19ZzBTt9I3osnRJ8e24fkpoJD9mfJlnll",
            "4":"1DHQAmY7rCdHlXXvW_wuFjr0XWslXHr2C",
            "5":"1M-unafB5ZfXU7TzsTKfQWxcDbnopUfZY",
            "6":"1XmGfyUA1HhJrJ4scBAuYFRehfnCaqGO2",
            "7":"1aiH9WWBfnkRL_2cp1O3A8nhqZyf5Opk1",
            "8":"1ayOvhi85NRIZa-yor5VlfADbvY-8Y4Io",
            "9":"1eBJ3LwkRS3tELzldFEmtn8W8Bt0r2aP4",
            "10":"1eZiDKkoMAMp-35lDL_OMTwL86gzu6Cwg",
            "11":"1yIAQAPRibhJw9CG6SvEO4Y13niOFpoWp",
            "12":"1-IyTLK2jv7rHNZHCRwlPpeZ3uNiEhb_Q",
            "13":"1-fNJRbHro98Z5xoLFBqQAYqsebnSsCJK",
            "14":"10uSdFgg2iLsQVHjep2BEYDzoqmPBMPiB",
            "15":"11-1fOEpwxg1_ha8rEhmzS4PuYzyZjEWX",
            "16":"1127dkYhw1-WgH564H5gr49f43B-thHfw",
            "17":"11a1IqNAQN0GEMfWVH1ldCXH-h4KeH5mT",
            "18":"123nypo-NZtUlSaF3E6eF0WjRX-caGFhZ",
            "19":"12ceUfmQIvriXqF7MVEann-B7p28tRRr-",
            "20":"16llcTbpCJSwkObE01KwDW2-BPklP04Rq",
            "21":"1AeY6rc7cFCaVnjIV6qrBa3nVJG-r6upw",
            "22":"1CoFNleHFQhf6Eu-wEOP7Lew9aXCMb-jR",
            "23":"1Dji_yP8aCQxMECezN-mdReQxb4R2Roim",
            "24":"1G10_sx3jERVQ0AVdhz__MQyhh2zAnX-Q",
            "25":"1HelVwsS6mRNWiTpxiK419FrliUe0rszO",
            "26":"1IBEh8lXoZOK6LD7h1YmX70pRMImXUOQx",
            "27":"1IWdw2y5eVgnSPamnIhPBjiZSdIAdfPNF",
            "28":"1JqicW0E_GrKetV5MrAAoZLeHl0JxP7Rn",
            "29":"1O8hbQpDwDl86ZKtd05WrUWjfkqgTvx7L",
            "30":"1PG1aq6hJighUKK3lV7wmJbLyqhyR5U0F",
            "31":"1QUQ8Y6Y2cINFxspZ8z2Gm3vXXbDvZMiZ",
            "32":"1QWWbZzm5NCDCt2Z-39miJZftPnnO3lch",
            "33":"1RtHQnC9qNPldo4cFoOuwwnYYD5sI_yzw",
            "34":"1STKgv6wG_CkapvDMX4rG4bj1M-K2E4WG",
            "35":"1UGx3mndTAA3ZwKtU7zO5VvfLsYab4uSw",
            "36":"1V9ZiUz4cZVoKLmRMUN5e_gliAAKzfKtm",
            "37":"1WCdUCUMg8mOuy9fCYVftMRLHoEQLiOBI",
            "38":"1WH0O0X7Q0nJE0KE2kr1mjZhUFFV28yYA",
            "39":"1WIn7wlfkL-ca6Rv_kUMqiiKUH0bueSjZ",
            "40":"1WNPHzskn57gMbNKShhV6NdwAlcdUjCMV",
            "41":"1aBUPd8g3TJB0TqOt66tV_FKPVRI-9W1Y",
            "42":"1buv4Lk4gpEiB4otDGp9CQdAycGU1TtqP",
            "43":"1cEYWLNMRP-Mlx7nWqcI4LNP8bSRoIMom",
            "44":"1cFOv0qXSkntkv0YYHtMVsSDbj9S9HlkZ",
            "45":"1crjjSZoD7yY2HFCIZSPhepkmyPTuTQrr",
            "46":"1h1r5PXqjuyko9wFwxqtzchRTRKUV7acY",
            "47":"1k7Qn1-85KK1AS5N6__scJlMLGf9PZhwo",
            "48":"1ktcYnjPMZ7NTvz8HBsW892Fis74OmR_z",
            "49":"1lBO3zZoPDumuad2DZb3ysB9WVX5ihLnj",
            "50":"1m_jb-Q5vrdqf_ASvNe6nRmfeDIGPTVxq",
            "51":"1n9iFFQcRVqAfGxfNut0YoBf3neVkJFQI",
            "52":"1nMZe9Q9LAqz5uooZDJpRog9WVr4_1j-z",
            "53":"1obnPH0HAQydnpi3lbBdY9PgO_DoKyOXm",
            "54":"1p7mZbVcrn8XU09GmPExM7cr96_F6DyNT",
            "55":"1pVj1K9u6DLfk8pzBcSgVMCWxTTrVrcwO",
            "56":"1r5UPDyET9MLWaR8kLJyron_MHxUxm3YZ",
            "57":"1saz4p9FVgb_BmTUKVoitjmw6b8fOkwDI",
            "58":"1uq-QEdp58IdjY_gbr2dU44R9wNlGaLDe",
            "59":"1wINwpNJ4D7m5ePS9DxGoL0srmmI9GzMl",
            "60":"1xe9JuxFSa8lEERJ4cFAN6Cosne48wFCj",
            "61":"1ymLtMcc2dG-Xq-qm1fvABnupSFQXjTJ2",
            "62":"1zD1RR-TVMPeohamTo55P9JLAZBVbIwj5",
            "63":"1zub8szxX4KBViWVIWXWdv0qDrWU4nD68",
        },
        
        BELAWAN:{
            "1":"1aZgJBECvkKncy7c1sDwyHE8guobinFWt",
            "2":"1c_z-lWBNs5_emkaguAD9MkoG093lpxXL",
            "3":"1dsL69uwgsyaIjr_jdkFzYoEUA7Zv8PPX",
            "4":"1n-nIwhWw_qkvBRgoyerVKDcmyOhqsVBn",
            "5":"1oHuKACwJ2D3HU27VFVyGV2TShGkV8AJo",
            "6":"1zg3l0iCmKFzHEIk8eZVYsdAXDXnT6-0Q",
            "7":"10_A7YzoQWwK0kTENdZyy8bOAASqYbvg7",
            "8":"1Ba0l9xn5_fG85UUwfeL78M6t_6Ef5KQ-",
            "9":"1US5RIGbsTSIxbF3gXz7QsrhzxJ6yp2gf",
            "10":"1VpraJIt8DNr9g033jkPOJQa7iMfnEw_0",
            "11":"1lXPtiwGi4_LqFGDcOuydftErQ50lbe-e",
            "12":"1nzN6mlKNesgSz2QobH7dcF438Qp1jKkU",
            "13":"1pv9eS9SCs1K4zV-9UAQqwPmf-oVqseVr",
            "14":"1qG3ScVWTjNHVb_pL2gFKmkT7YcochyJy",
            "15":"1qS8Oz1vCQia5oVJk1dX1jE4p1szm1RBZ",
            "16":"11l0zo2NwX_3LRy9U1-vvAzb6Zeo2lJTs",
            "17":"15_jZjGpPPYQOcMcJdz0G3uP1PHwIoPbV",
            "18":"17DDdDAWqi2MExA1fVrjIZ6ioksFSMP3I",
            "19":"1BnVHbjZD1PbwZ5i0YtO3f4Ait-hXu2zr",
            "20":"1CWxVxaCDOV0PY-MpgvIwjEycq7_NDa0-",
            "21":"1H5E5FbYnBfaiFS55tzFKIrWGUOn74147",
            "22":"1HTw6Lf2pJgv_AbnwLWUYveILIg3M5tE3",
            "23":"1HYl1QtlO1OseFhCvdZid-xXLRf_KQSEM",
            "24":"1LHOIDk6yCCvro7m-qogTvsAIYQhGj9w8",
            "25":"1cwGmKREdPzNwSy_EPN7CubbjzEPFQZTr",
            "26":"1hLihMx9iPsHpZl93iMkyzeDlKgwkT0kn",
            "27":"1lI9SMBWz74dlPrOzIExdavDGQx9Cknzk",
            "28":"1ldYs5ZlNuHX8O1a_jo7BxcB7hxaZIH3u",
            "29":"1vRgpZtcIC2azn1qB9AMYri6gRRc5y08F",
            "30":"1xkqVxpvREFfD0EYlAxltNyIFzwIjZbeE",
            "31":"16XA37GPn8PX6y3MCf9wdkAwZrbdHOor4",
            "32":"1AG6wDHXzpbKjBJO95KQ4C53md2QEH6Va",
            "33":"1BU0QCqWBvG-WmBmF8nYCPwTmd4E02Yr6",
            "34":"1NwwDEun-_ZKg30qSpYgIY28a5aqLV62x",
            "35":"1Ohb38V-mEFO20MWqGIJRSR3tvy9YLGTp",
            "36":"1QAnqtokV3pCk1HTV9fSL9qPStDbhXTGx",
            "37":"1XIvRQNBqsEUZxvMLJ_1AyI9AQu8U0jwh",
            "38":"1XiUFWYMsiFQ8pM8WeGs9YmzQjfd8Ddze",
            "39":"1bOdxfa4dZfdcdXAiTHgkT_VVc-GWKi97",
            "40":"1cmMCNrHCqPWhfatB9cJvQv1nUBYd8JBs",
            "41":"1laOn8qxzpTmw8mlMjLrNRg3T_aHmmnpJ",
            "42":"1pCY7hufkzODucNlfVBdwX03wadEF2DWE",
            "43":"1qOqUl_-jYEC8v9OPSFTcDK1bKHdvtS1k",
            "44":"1tlYqzkwL7uJnEN_rsTeQC211gXs5PPSm",
            "45":"1-aZ5KUl4JSezs3lLRTmomjVqJXWVDbrH",
            "46":"10EYJKMEmdRn1S1rGRNVdGrrbPpkWhd_X",
            "47":"12DvJCbRB599xf8Ugrr5en2zFlNqChSTF",
            "48":"1DWF2ptbkzs7SaER7GO5nOQCGURCZmbmx",
            "49":"1Dfyn_1gJHhxDZx2MgVlOEhx_ZKI7jMlr",
            "50":"1KmJsC6nlzxZy0I-v2dCgtzBHr6K7WJJv",
            "51":"1OLR4bIUw1oXBO630O8qpu8PgTrtMWXTC",
            "52":"1aPDXU_a7vcHK2zhfwYA4vbSmkfV1O2x_",
            "53":"1aX7Ek44cB_6xQ3K9c4UjSlUePCUlP6cy",
            "54":"1hrQkhEsx2EmK32RxhRERbRrVCbh0-kFk",
            "55":"1i-j313lb4OY8xPb-nlx7lAY2MVkQg8L0",
            "56":"1irE5uY75G2y3v8lJ62gGvwxYlLYShtK7",
            "57":"1m5dN_yYnCF8s26ChiE_dMKeKkVghMyxY",
            "58":"1nTKIrIgb94trzRcRju1C1o_TaYSdXQbr",
            "59":"1vYKC8xEVVDgAhH5bpl9RhyBlbsNamaR_",
            "60":"1CCdajh0Oq4fydvY1pei7-UCNPHD667ES",
            "61":"1G6ssKELijHWSCqRrG39XdTzi_w0kex2H",
            "62":"1ISyQAyHvIW7ekD4Kpcg8I0lAttZy3mKm",
            "63":"1JSVG9umAb3O4TczOLqh2gTQvTmMHwg3F",
            "64":"1WVwEgNX10P_AagHAejsmbYvkUQMRStnV",
            "65":"1ff18HtCZt7YTkOPQQqTzZyRsdRtPWbIG",
            "66":"1pro3oYlHR1TRZcgp4tmAR9_gQS7bTjwc",
            "67":"1rl2QhPkO2A2ZyTgR2BPGYRHRLv07mwUk",
            "68":"1uQRHp-4-lWlKRPhNimAezbdaK-Fv52Gk",
            "69":"1xSUs3QKbiYNbnsp76_LAwUoBxQV04XEf",
            "70":"18O1kGoN-EacPWnVcfG0Q30fqzvKzo47-",
            "71":"14TdJauGnU-Ptp2ah25RPRFj33BHoum-r",
            "72":"1G9Xj2Mzktfl05dRk-RwqkRgCdc2-C0a7",
            "73":"1Jo5F8P7pjl2HND2HKMz81ojv0cf8wvV-",
            "74":"1hCjnM3YQmTp13ovqFZ739rfcqgYqM7HG",
            "75":"1jZ921gqNVcFmABaod-WuvykuKf2giWiC",
            "76":"1rQrhPF8-bkpmOjuP5y3FcbzR1dnQtx-9",
            "77":"1uIy8tkZtMCER__MH7mXCmIgmcTBICP4E",
            "78":"1ziYzs_JISd2cuMN4VVz20MLh8mZpP1kF",
            "79":"10QOxHKuaSXChIL5PnVbUIWaB9bPRtEFL",
            "80":"15Kmr097_YgbtVmscMVbvYt9NeNsvCoVS",
            "81":"19ITooAuQhdlFkYrvmoS_Rp4d9MK7iGq5",
            "82":"1IZXXxyyA3bbVaOPWYg8ppv2jvT00B7Of",
            "83":"1K1GBdZHJb5ziX4JwpYUBn8OxxpAXuOdV",
            "84":"1MUpDBDYprIN8JJayBsBvNREo_wd1d-eC",
            "85":"1S3IGyJaMQq9KNDq8AEd3n2hADfKrqyGU",
            "86":"1SUKARxpaPoyrAGqtk8E7Sm7ekFRvKUw2",
            "87":"1YST9Sc1JK_bPMERS07c2282_4Bm0qrga",
            "88":"1YWPLg9ifUNIlicPnRRar7rgsp0VwIJKA",
            "89":"1aa9de7DISWTxFgSw2ts3sr2zFFUpnmGF",
            "90":"1dAroqTFGZVlum55GsdXZEt_k3qvZUh4I",
            "91":"1lUfuyDP-GnYZxILDo55G4qZUoo_GlP6U",
            "92":"1z76JjEnreBaEoki7NaueSwPTvfe-K17O",
            "93":"1zYuiInXz7QH5c8iItx3gIBn8uIO7RStH",
            "94":"18iKbHMZPAKFLX9488RsYHwKWD75SIsGG",
            "95":"19wDjgT9zl2vfQsPr6o_3dwiV2fr4jgMe",
            "96":"1B4LpvPNh9kzNTO0kCSOnOSbJgX-nhQ4z",
            "97":"1CBpolA8iPyOz9jOC6a3-EyEKBqw2vsvG",
            "98":"1QjbPt1MzzWTJ9YAZyP0kZWpqxsZGb82C",
            "99":"1ZpWoo0D7fxkr0vOG3DoKfi_15SEcr3Uc",
            "100":"1_OxRbGBFB9fRERq7jeb-WrIm2dvmrFrt",
            "101":"1fTGnuPqXW6wLUBgN3UKmdi4SWPGspfc0",
            "102":"1jL1jwvEWg7FZrblTcQygaAORbxut8lRy",
            "103":"1R3XQndC-BQ_aDMH9asacR99o60bD1sJ1",
            "104":"1XxciMwFTcV1zQ01dP_yg5J2xzPGvqAol",
            "105":"1ld3-W-67nfMYGsZt_0SVqbQAKXZxaTqV",
            "106":"1sP0UTAjV-UdbkLLTq8XRayy_wDO3zc8Q",
            "107":"1-4dDOBbmPKN1MeEyQVD8NBO_2CQutPsI",
            "108":"10AaicFUupLNiDiAO5f0ljTYlcI2Z8nRQ",
            "109":"182ujuK2509lqIvrpdu2uwYFYmRdfJiYo",
            "110":"1D_jRv9Kg4v3uWw2Y6p3LZWcIbz5CEkLm",
            "111":"1ETEspPwCsnRRJ5nKblFe7iC9-rJA6k0F",
            "112":"1LAIVzYWfddlHRsdaH6yN3ERe6ZttROwO",
            "113":"1OgkpDykPPF9U1e3b_vbsnDmtSkgADxnE",
            "114":"1PteeP68mUD9PkoqSH0Q32itZS9lAD0kh",
            "115":"1XOrx_BR6lrZxpzb9cYyLeHa6emU4IJCv",
            "116":"1kA5tsLDVLJe85HTUsxHVGre-cL0OsLC7",
            "117":"1l7jLhlWNn7YBeJI-mEp5gOLmPr-v0QJs",
            "118":"1sgOMXqaAPWT3UA7m2mGnkGGTOBm9hxy8",
            "119":"1vH0N-IkfczPTiVJaXjDWniHLKatF4bL1",
            "120":"1wfC8IDi5UvJ4QN_6h1d5RZPGhzhW35WB",
            "121":"16_Kea2FaALAth2jWY5stA5pw_MsJ9jf-",
            "122":"1EN38WzkRfQebmQkGd8rKE1TBZpSeH_rb",
            "123":"1EwHzAuMbzwZxcrGjNT59opixmEUFne33",
            "124":"1UQoLyA1nQRyZ2VMhe9W7uajQfQdsgq_T",
            "125":"1Y6atGzHtHu2HajdUsvefDN079X7eblz9",
            "126":"1etkvFKCymxLJXSArfzgrZqcN55NZPPTK",
            "127":"1iE1AUs8qD6EYt6lj_OihfqJsya3juDFu",
            "128":"1-SwSPFmGuvDJWsyTKMPVwYe9F-hGHjmE",
            "129":"1D6tkbQxG0kxn5ESj_n3Pb-iMl36TeOqp",
            "130":"1U-bRHL_ccDnVGgLNG9gAFaOUwPcQDG9z",
            "131":"1iDYzFwbUzLjuwqRmQH660kpfMCnxS3US",
            "132":"1iNzT2Z0i5sEJm8tu0cOB_M34g_7F3XLI",
            "133":"1kd84iohPmTviVLRLJId7AMA1wbOuaa1T",
            "134":"1CM2LOIzDIhAL_BXVt9vqzv6S9PbegK59",
            "135":"1LcpeNI_e1TXrs0BuIq8n9gNfDE1RvCWP",
            "136":"1QvGjc_rgYSIhNacOJkra38C69vRJqrgX",
            "137":"1TUmhDnoK3nDRRbJNNn2mlF2ahzh3fA1E",
        },
        
        SICANANG:{
            "1":"1c5u3w6aTK2jOoef6l_D27aT9J4Br0eHw",
            "2":"1bODJfc6bcykhlHGqrcKc2BWMIulXCnP9",
            "3":"1QGC1GXjsNm3t2BHhj3NaDUvHq8niTX-d",
            "4":"16stQG5u4EXjkD6zTmeVdRh1sz-sqBvOB",
            "5":"1X-LLmSuuzvurtTKT8Ki-V0IP0KY_Qf03",
            "6":"1z4Xg5h3qGvUAtJ5fqt9FzqgDUry3Xgbt",
            "7":"1dp6TTZxQ1ANmKaKS1XR3W7KYXdCvjAQW",
            "8":"1_B3GPJ2ePffiLAIKz3nuw4yTRkhbGQfR",
            "9":"1NVE3piy08DO6LhFzGoV2fgf49VrMISB_",
            "10":"1RjX0LEECxyu08sCNfQmD2IkJlz_mH2xt",
            "11":"1FNpbOl5w06tlljZkvmqh2C1zz-w856hV",
            "12":"1YRK2MzDAYXfwYJNre-_TIBud_EDA1Lmk",
            "13":"1AWN4fR34G44W40UduiLr1_5hxwsYJAct",
            "14":"1b_WwbHWr2-eKqK3yL8q0ig3AIVMnS15h",
            "15":"1RbM-uZfJGQS7bMzcKs_LuqmJuK_HRRxm",
            "16":"108TdSS3Qy4rKO5oPU8f09n_5_tGZISb_",
            "17":"1m8mjhdRhXGGZrdbcJvRQFS3Qce3H4mOH",
            "18":"1jh9ZmUVY7yOsFCam46mkkxcR6X1eWHX2",
            "19":"1cOul_1Pauspb-Q-rwqtyxLEE4XYrrptQ",
            "20":"1HNcDODdJhhd7DmbGo03lInAtm7KBSZaN",
            "21":"1GtYOiZCs780EEO8mXLgyzNoUD-jMRvGb",
            "22":"1yk-Pob7WDqJeRBJRsOWK05Pz3WzHulln",
            "23":"1dUMPZOW9V67wehlT_fJH6LiZ40QxsjVA",
            "24":"11OPfKSBgGW_mf_gPKbVLzOXYYH8utDVO",
            "25":"1P73GgpedZkTfDKB9I5wNAnhR53WBYAKn",
            "26":"1QM_KUrD1g_12S9pHuoJSt6ZC23gj2lcq",
            "27":"1SeZO7TZImd0SABuFriy221EV9_2Nm_1p",
            "28":"1HySz_i1pPO6S5H_E5LYIgnJD63IuQqy8",
            "29":"1agXKutp1sgziAPRCk2F9jV2LzaF0TNId",
            "30":"1n-d54wgH8f-nkGcSMyHqb33nWXslCSDV",
            "31":"1ovY91n5B3GKojDNBFcuTAnxSRKOyM_R8",
            "32":"1fcQWxtyA-IRu5bE_vMUWLYm2moVMV2J7",
            "33":"1TBl10JFVk4_8xc5MFV-n70lBu5xejUsF",
            "34":"1Xs9R9PvBu73o-H9Ciq7N1RykTbJZDKMO",
            "35":"1hM9HY_BR48hFh9uYITAuVDkKZeCagHZx",
            "36":"1Lr5J7Emyl1T8sQiUGjpWvN53rYSJCBd7",
            "37":"17T6TUWXkiXXj9srKKi6OdgGMAixwNajY",
            "38":"1c6Qbwy-4Ht2svQipTYZ_h9SSAU6QsjpH",
            "39":"14lIm1RfzrMe7Vg02Qg3jAwl42vHbt3uU",
            "40":"18nYkFCZmR8SVb8grf_bylxO_TLGilNOR",
            "41":"1_dICd17JacbYNrdu0RB7joKAoRc8BeZT",
            "42":"1z3YQDcY3N_7Fss6nXXCcelJhuuYL2HwF",
            "43":"1A_Tr801Zh0VaUoeUlmwUjtrlBXWhURP9",
            "44":"1crV16TW665sLtXxSIm7D-0mMo3XezKlr",
            "45":"1m9WCqIcXXmmB2kboY7d1Ec2vV-xB0wS1",
            "46":"1yF-LQ6uS_JZZJQNLCDyiKzOxzgu8rOpi",
            "47":"1gDZdvRyG-MMUwL5nDQyv96KfE3AsDV7R",
            "48":"1lmfv8LwoApKX8GpEwKn1VDMNXK-aGl4z",
            "49":"19JoxHvIEO0--nLiVgPsaisc6W2CnNSyC",
            "50":"1oyM88e9zKR2C61wRcVPYGBv8niOP4uSO",
            "51":"1tyZDlvv5JseFhWFhuc_6Hsm0XVp4sJRd",
            "52":"12Sz1JalUfFtjRddSLAjtvLB0nZOmoVkR",
            "53":"1YaQd43ABP4I8MqCXOV5A9PSmbH7WYPCp",
            "54":"11unPqkOdQna-C-S8m_U_fuH-3FI55NZ5",
            "55":"1l6t0S-f558RrYGfpymFPMJ1jSpvH9mm2",
            "56":"1bgGiFwGtfaOthcAGgk4_yp3IG1-nwdoB",
            "57":"1YbGZjDb_rjs6Zu5iJjcCHZb1dn1BJ6sZ",
            "58":"14HiSkZOm07exndwFGDcSsfkMiCXHU6zv",
            "59":"1xhtVjAdlXDKuNbmk9_CjRlE1VIH1dyiK",
            "60":"1JUCzSG8818UFHItrDQSfYvx3ysbBPmtp",
            "61":"1ESw-UGQ6GwqlvJAfb9qdcjJF2Y5i_jqS",
            "62":"1NDAZdBQysTgVxsm17_diPNdt7YDXNVNV",
            "63":"1qsRjIM8kaFAN-ACS--h2piJBLho9yGGK",
            "64":"1XEJSkPZoFYEyWi2PJM-lPwH6rhubY8xK",
            "65":"1_9ka-mkJwxli_XeL9okujionKVAwLS0a",
            "66":"1GECBYBPW3oA2PR5BKCGxEdmqjTIXLyiN",
            "67":"1men5UpdLm3SiL09qi5VLYkoWhswcyfIp",
            "68":"1hWI6MJHB4H5HXxySvWVLVQ90Dr24GUm6",
            "69":"1YF90_kNiNwK4gWDXfSNj6XOoi6BEd-cO",
            "70":"1On4BssL1ib-wLPh9Lic5IsAPIqunZXV3",
            "71":"1HWDmqrpSm4CdlGi2ZswP809OPK62l2ou",
            "72":"1jg2jdbRG3yoQi2gICzYIn-Sx2whuXfYw",
            "73":"1zyVgvd-NWpkFWTS654jLe_FfjyltNYJR",
            "74":"1lzUPEqvZdlIIEpyC-acPwlMAJcNBn-AN",
            "75":"1Sv7crqaKfKAQysatuPpp5cFzGNoXlV6P",
            "76":"1gDh8E8hMVQ49pE_qEWJixG_m-u_oi3O0",
            "77":"10OhShIQ0S0JOyq1-X-mkJy0gYAmnMTqO",
            "78":"1McfkP_aVbc90jCb06PSC0TXCVTg3HMJR",
            "79":"1li0W_NbsnEu-4ORiYVPctzBjNiNgKKOn",
            "80":"1B8wYKr6TzElZq9CD3Dtty6zLblxa55BU",
            "81":"10I6IS6D8LWmqhhVws0CA_q5TvcHRJgn4",
            "82":"1KpUiFQenFuj5M9zshXGDD3DrYBj8fTVU",
            "83":"17LDPLPv0eah9b07j9LSbMtoAO5KeIxmk",
            "84":"1DppXUJlY7_-CSsDxLp-ha5eqTBZ5lGI3",
            "85":"111mXhqR7blUfR9TQc4obN-VeNTFfwFPj",
            "86":"1ZnHKGs9g96ns_Hj8ERKEs8zUWYQ9RAzs",
            "87":"148TxuKtY9UyIbS-RLyEaWONeEMNs1RJu",
            "88":"1Cfoh42psIUQCZY21j0w_r3gaerRkKDiC",
            "89":"1Vna1VyTFeO0LAHtwkK_UHCjxj1eQuqE1",
            "90":"13oyzNYuj6b6toE0AvPtvZZGxyMEq0ndh",
            "91":"1KAvC0GCwZ_xbJScuUymLGFLJ0-RTY66z",
            "92":"1iWfGNuJG6DbZeZGbgdPzNvXhuEJEGgPV",
            "93":"1Q1L89TDqNEj2p-dtLPlLq80NB1T0jlXF",
            "94":"1hdHiKXR_FRrT59otYki37Vj8yu_LC89U",
            "95":"1CDsEVbK7pAX8Z3sW8-dxBTlcR0qucyc4",
            "96":"1cXM3pyCr1Dt_5cv1-IKTViASSXjuRBZ3",
            "97":"1OWM4GRSXTwvVLsbTpjxQBxLprDw3zbEH",
            "98":"12dzqbRzvzahwDiHJzVHxleLhGot2kBns",
            "99":"1fNTAg0qo-Dl_ui1Qlu280dU0ANOEiE_T",
            "100":"11-uTeilR_V9Iw6p-oqMY3ifAvoW5bpdE",
            "101":"1b99HBm0TCPtgz9sKcQjGtZfbvcsg4DZh",
            "102":"10cZs_d6sinE1R21WnCy4cW7-pW2Bw23r",
            "103":"1TYfPssuGPMynyqI4QoVMyuV2akcCnMJR",
            "104":"1NdGJSRc_MbsbTfMtEuBjpRL6q2roOF1L",
            "105":"1m0ZYza68uEPeVtGS43oAmi4EZEtkqrKJ",
            "106":"1PcSfNR7lWnk6EgKumZyoPRzCujeXtWHM",
            "107":"1qTdk9Z_EhtZojJB0IiMSL93lIKZJOTK1",
            "108":"1CRkv1PuOwS6zDvFJtnq--jfpCNJVlR3v",
            "109":"1QCRIkS66iHRVEngSaeBP_MW_rtpuwGp_",
            "110":"13i4GX4GJzr9Sgx0Ut7Z5l1A3IKnhtB4i",
            "111":"11iJ3moSL_KQmGkSaDujL4kW5q1WASVYA",
            "112":"1lhWYI9b9y1pxX8CHH21hkbX3Ar2nbuEV",
            "113":"1-__cBLPu_9t9btHWrr47iwtDYDBqmQqs",
            "114":"1-TPyJvdAV-nWefpCNL2HpwakNP0hWfOP",
            "115":"1AJcwN9_ZbGW5SZYsrKuhJCbsgC6i03V9",
            "116":"12KjCZNdXjNn_-soGm1UDRuwknvHvV-_t",
            "117":"1xmDH39Kiv6QxWUk6CC3ETsmf_isaBmNl",
            "118":"1qPjAb44BV2vqVNL5lWGWgWINdSSC7QSQ",
            "119":"1sXkllb65_1tZzCUWijvMqA4yFkeeLyxz",
            "120":"1ZLCDfn7nazMp1IX8RkodHnyQnDQkW1qh",
            "121":"1J0TMHQ4u4cOPYdv_D9rVgfx33Ijybvg7",
            "122":"1R6UcbNsMwfpPY5dryLuPlIzdC5zkwlIx",
            "123":"1BL9cKSWFCO8Tn7VbmW-Yy6QYOr-BhmiL",
            "124":"1RwATQHZCSlDTlZ3uyssvaKX0Zly2-x7w",
            "125":"1rGzxqvrP8S6la9j_MikDtPcihQq7hJVA",
            "126":"1O13X6PihkSuMdU6EC_fOYTvOYFlDz3ne",
            "127":"1pggAoC6VxPHWfenFv0Gzr8cqUnRxRsXM",
            "128":"1bw7XzATpJNw2rpzvrsDTdxMZ54TROCCt",
            "129":"1nX6kyi_kDolD2Lw2PkAZaKSDMGaXgTEw",
            "130":"1gGvy3FTncT_tsG7jACKVJYRK9s5aK4eo",
            "131":"1F03Da4rwFIoiNFbxj3WB8CTvfek9aGw4",
            "132":"1W0gE1TYd1NnBkkQOaKuLOpXDKb3DEfJR",
            "133":"1-AYzcsxlXdQKetqwN-Y0leykPTQKbfbC",
            "134":"13BSZZegaCXEjSOFcOxt5chVJunCc-dyv",
            "135":"16GBo6jGYOc_H5_dnD-Tvvp7imfQAAwc6",
            "136":"16QHiD_TlLq4_-2Beqf-sb2V8azYgVkWH",
            "137":"176_jf7b1J-DLrNM3lUIiCXxJqNH4k5Qe",
            "138":"17moRkaTQqQV83fssdtsnkeSEKFP3MGiX",
            "139":"191FDFCFKJVVlsq8eMJ80P0eFdnnKH3pJ",
            "140":"1BSrSFuiCgc7ffoIT1QYWcF8DAAxtJ5U3",
            "141":"1EwOq7uHhbmvmiAj3DC_Jv6uEdL0TM4Od",
            "142":"1LeEocm6OMtnhxTwsl704iQNbTcZnr_dO",
            "143":"1ORDZa7sF6RSFL0f9jJf2W1RTgz1u2X7e",
            "144":"1T6Z009v_1O5SvRRXmqOUs1vBKuXbwBNQ",
            "145":"1XF0e6dcaBcKKb3qr1EwFpIgVilC83DfV",
            "146":"1XNHkAlLpXit1Q-HXox4lwQXVs2res9Ux",
            "147":"1ecE3LolwhFXWEZJgXb9tqRBbb-xv67la",
            "148":"1zK_ZgmozjOWvUVs9ZDro6zi1uLMXoeoZ",
            "149":"10g3JCHpz4pg9A84wcICYmjJzPG9H48jb",
            "150":"15t5ds-vmHYPBBqNokyAYPh6Xp4_2bz0C",
            "151":"1CGcNcO6QGCp9XAP4Zei3ip1QLF22lO3c",
            "152":"1NDdJdA9LWEZGa4kipp_m-MUAiud6bzUs",
            "153":"1PijMGpYWVNGjreeJCbyNyx6qbVpZTJpO",
            "154":"13OSEutb9gGa931pGYonjUqFouk6VBB-f",
            "155":"1DfzvmiC9BGulaA78FpwrbUpjqIvz0c4-",
            "156":"1EyajBdjmDLsQYTZmF-P2pQVBqJYnk1ri",
            "157":"1MYhkLejNP-xDlnkLzKzotzbTGeBIGxB2",
            "158":"1QTkVAxcItl60ujc1-kF6EA75fsROGOMM",
            "159":"1UkYZbcPSUm8dPoVKutAHBrcsdW2uIH5D",
            "160":"1hVSRVj_WOQru8JyHSAkTkCHptxip4mYi",
            "161":"1nJLGEeBraoJvNs6vCW2QQUXwkaPQKhjj",
            "162":"1qZKvqx9qnXqjVqNvdjxC7pIBuOyB3u5B",
            "163":"1sgd9EDdX3iXNtyJTpeHdc05IYG2V50xy",
            "164":"1zsMBBQ5_sB35YxP33Gyp_UhHRWSqYmfE",
            "165":"144tR_KVq1fTNfL9Z8p0bm9VXYqyh5elw",
            "166":"17QlFg64c1b4LYyDHdm49ZXyuE-laXpKq",
            "167":"1GqH328Nvnb_dhlcnHP6HMvkwz4D4vJVf",
            "168":"1K1vyFg1DHPvi5ReCWbs2Ftu-aFEiXWzm",
            "169":"1PjweiSnJJYVth9doTOC498SUhA-8iW3E",
            "170":"1SsHCmNv8Lswv4jkxojIec-x570TAmIhW",
            "171":"1emd3ExXvdnZCskfG0NPKtTLEK2tmaJ_L",
            "172":"1phXg-gl254sGfJvM-qhLqz7d8HVVS71D",
            "173":"1pmsh3WCADcnYEAV39-5NI5votSChM3F_",
            "174":"10JVBTNJtk3NMZKUpNIlccGJOy_Nrue4m",
            "175":"10P05b4GZEQ91AS0c3yYaA0SJQal_gvB6",
            "176":"13UahU91GIIGjPMzBA-afKVE74JME0LoL",
            "177":"1EI_XxTfmulGCLvJYQwhcdKwJBYtsnXF8",
            "178":"1JdPZXIZTHeA_EIM2kJiQwLcqU92hzrir",
            "179":"1UuZnH-YeYZVvOFaPzZcP0oivXlklRdXl",
            "180":"1bwKVg0fDAtdNEK2wmbGgBZ1PKQUgQDp7",
            "181":"1cEV8CDLgYY_dGQ7YnU_O9OA2bfkUQC2K",
            "182":"1izOe-prBPH1UndvU2A3bk6eJBD8T8DVc",
            "183":"1rrJQRRpaNifxNcx1laObbRyUPihtXh49",
            "184":"1yFynLTrOAYglNgQ-FuUgSe-Yi9y-ZxHp",
            "185":"1yPS7RymFc4KZD2n9xVL4SKJc9galsBZR",
            "186":"10fj08QaTXVmLMIXGQUN1cqnVm5DAf2OL",
            "187":"13Lrijxyc9E--1AIEbdW99_tA1lzqWE4r",
            "188":"15jjpBXH5Rgut8FEksysE8eQDdJZkjdr4",
            "189":"16wY9c1xnAkPUa-AnhkUyhoofP9HaOsZo",
            "190":"19ERtu5A_oQ3RMRXcGaCyCDa8ON1QTx8U",
            "191":"1HQQIwhlyRC5v_U1YLGDauh7f4n3Sgt2I",
            "192":"1J1yqyy25V5g2yKtkNNcRuCZI5y3QFF-1",
            "193":"1JWLjijGswrwI98ON8xP1jEtaNhqBqd98",
            "194":"1PqhIc0iplzcVpabkc4pnl5R5TUzV2f1E",
            "195":"1Ut7z6c8p7Af0MLkI5CZ4zur8wargBTs6",
            "196":"1ZlyIUBykNh5jnKZAHoeVuPZpyMgQm8jm",
            "197":"1bb_ewnt4rcutycvyhBQ5oqCN_mZzSDpo",
            "198":"1fEX_XWwaJmXy8vEsvQNQbNq3j7kliQGN",
            "199":"1g0hvjTnxYrwZDQZnu3Zp-mk514O7HkgO",
            "200":"1lEfL7_1ZiujdK8UOTh5uUuBvkPCJDcqb",
            "201":"1pNjKTxr-jnDai0PezTYYs280dPB-4gV0",
            "202":"10GIi4fZhxY1a7ywbFrH21DQwILuYWTxx",
            "203":"13XnOKa52bghbfDhWtcpPC6pdnvK38lBV",
            "204":"14cEhyhihLvN677CAuUVpb7qZTDqQ3adQ",
            "205":"16kAl-rqrzwNaeBfPRslczI0o0yvGkg9e",
            "206":"1Bq5tfAUfI1JqKAMchP9drFYFx373Qbbu",
            "207":"1EKyh-V97IXqxTi-uGn4K0K9mF-vusuBn",
            "208":"1JsOJfQK11QPoxpLH2k8aTFa_4FTCf67Z",
            "209":"1XLm4elFR0AIWVmkgOdVnmC8gk4O9TfX_",
            "210":"1_Rlp6s9aeyojoWsG7vUo15JWOWsaHbkk",
            "211":"1_a8JwrrlgSvkVL7WLAic2cJHYj6dof1j",
            "212":"1d3zZDUuZzafUKAjEg8lY-JihM2uCfJsm",
            "213":"1k_UEl33_i_dwFkdH7s840b3lMQsA3VDM",
            "214":"1l4a87bPCyV1tN2AGadLkpaYnipBoC0bg",
            "215":"1lB1PXOMWMl8CNFwPYh8cFi-_OgKLRPHm",
            "216":"1mUVaeQDfR6esO7mWNg2yX3Xv8cXrrLuV",
            "217":"1p7-Jc-Wt9Wn5b3Y-9KO1XYmVAeSwKwcF",
            "218":"15ulXK2b_urqFnVaqjEVWT2u49iIRvnfI",
            "219":"1HLiGRRpHQAN1Rmjt0311qdRhSLak2Mv3",
            "220":"1QYY2GggIriyJ9rbhvcX3UBUZAXoX7Vdr",
            "221":"1VXj0eM1YIgqARByJUcHtPuGd584RjnaW",
            "222":"1ZEgxLiv1LKwBAx1IZaSWhjwxPEU1rKYk",
            "223":"1aICRvqTFEIEeAiGM77wjZfnzBeYoA5ZJ",
            "224":"1b5GX1Qc2ft27KKld0-O5oYWI1UAG9EGJ",
            "225":"1dM5d8yLoEMHOnCTeFmbOOB-0WI-T3y5_",
            "226":"1grKOrap-Qljc5Mso86qWTVYewP_Q2M9d",
            "227":"1hatkOCaBMUgqCE-HWJrL-AJLanC681B3",
            "228":"1jBpt5YlvnFVHR_1VyLPoXSs9tdH7ZUQX",
            "229":"1k__5Yy2QQEhmiENmbzvyLdhmNzdkbZEY",
            "230":"1nfZpLctxXsrk2H5kkx692T_by8oD8nbi",
            "231":"1qbwikBTp4h3PweBRmO1TjBXHgYMSLzI6",
            "232":"1s5YBzD0DGxO8kBrqE5siIQ_kfg5Lh0Wo",
            "233":"1xfN1WzVIYVJ_mE3__86vlUPoxQQoRigf",
            "234":"1xfkL2EQi6b4TNtmQpfHldFsKu3tp8gxU",
            "235":"161yHAvzssMz79uSDUuMfkWLfbtaL43DW",
            "236":"16y2S7e9L2e9lOpG5tzNiSh2RUTi3KVmL",
            "237":"1BBfyw-9FwtUVlttmniAeEbXF1f4jktqD",
            "238":"1DB3feGHHkUR84RwyRGCMsv9DeX25Ra4a",
            "239":"1E4GTsQ6M6NELW9jS4XIK976crFJFtN_5",
            "240":"1SB4mkhuPETCpSvQ7g47nZ4nxHIBVeodd",
            "241":"1U_PICKzYXHIbozUAYO1-xPVdFYA9_mM2",
            "242":"1XBZ0fN0N2PkvmbeHmgWP9mkhIHZ2Qvsj",
            "243":"1YIdgecPc9EzrkKf1oXCrSk0DjAJpNKAs",
            "244":"1aczJ6ReVc3OfUgUN026UVqsRb8F0zo61",
            "245":"1cZDRJQTX9tLeO2buQ1WQZGLSQP8uG9iX",
            "246":"1dURBe4LpiXFy3rSi3wd5HePeigld9hAK",
            "247":"1jW1mr5XVSqqUwjxSlOAN1JLc1CE36Chx",
            "248":"1mrmikLkGyAmMzB8FTZtKP8IuXcA1Z9Du",
            "249":"1sE1FQSEuRXPE3jgFNDLJON7kHBDNIQBW",
            "250":"1t_HJVo3K-Sx3SIL2mgSxqL1jL_k4ZIW0",
            "251":"18QlwR0PbgsAgttuFUtnOsoH3LpwqV1Rf",
            "252":"1K85PSi90mFruesYnGIU9G2AMGBxCjr34",
            "253":"1dgB3hogwzhwFAXySDwT2HEU3tlykqxas",
            "254":"12J9v6Q2sgymUQGQPsXWgFUrzN7GRyxrw",
            "255":"19ZyfEuF6HWeN2uSLB99WfBuTnC1a5fpp",
            "256":"1CnG7DCt3VFEPBgogt5r797LZRz_rsth8",
            "257":"1Mf6Ahtt2gITK3s5OJ0LSgviQC6KmxlDd",
            "258":"1TKanv6B7JnPiig2-KYWTNW9DEqPVN1fd",
            "259":"1TuIhAdQhB7XmO4So0FyoUOQVTNxE9q7y",
            "260":"1Uqn05r7v45V60dcIwYoFN70Ebi18K-fe",
            "261":"1_yc6pSmlbIVJOFoArYxrGhlPVAc7vBkm",
            "262":"1cqa_TKBCPNvnjLjsHmfET-l8lc-qU068",
            "263":"1oOOO-oqnDqf6BFLhttFRjOluGpXmhgk0",
            "264":"1q088vMDAGY041FlZACcCZBizNGj8_IRU",
            "265":"1s-hEvlI1I4aFUcIJOCqZfQfGCbMcSbAT",
            "266":"1xWHAspg7EV68XYsXczm5QaIFXEdvbFJW",
            "267":"13Ax4m9p7W8Do8vGgTUudW0puFE72wQoB",
            "268":"14DUAPEGllv_ReACzpKRZcAv1Gw_Imwlt",
            "269":"16ZUUBqD2gYLus1DcfETjtXA5x-J_LnGC",
            "270":"1CI6sJ2vZiMHTsUn4qm4xG7xns7_uksmk",
            "271":"1L5kXuPlntyJmDDesbqXxHKxuAWcjAPaI",
            "272":"1N40WcigSwoxD_Wwd-YAOYlci2GvoBulD",
            "273":"1O0gfl10I3kgTJbAac9-f2Ek99YPpiifS",
            "274":"1QR2_ZnvE2USrHcJg2U5bOwdHlt2EOSJs",
            "275":"1RrBpmNdl4Tj8fSTsk6-P-j6H7YOA5nVM",
            "276":"1XkQsJdEP81a4LW3QLDAjwjf36WhoGmpq",
            "277":"1Z1FeerUX0HBdGgx_ICvgGgShLmArngTe",
            "278":"1ohCXLcGd1s-SAiPh3WaCL4HfwiyredVZ",
            "279":"1q9_BT3naAqXKtVYgXaQbnoJ2ESNtXOJo",
            "280":"1qmXsB_azTSYYoX8b5mOR_Yhbs7_6EYLS",
            "281":"1scOzkgqTloNJtKLIQKo7Zpa2v5BqlimO",
            "282":"1wi-D8izUTMeUiE7tOBRHnYx1z3beMONW",
            "283":"1yiLIK9NVDjuxSKWrRgP75sl5MNvT_Pga",
            "284":"1rmfB7odaS4PZWb-_pGzD7zvcRyffbyPG",
            "285":"1Z5_FyW8oD6ZbHGi2LpFOEp3rLrk3dLFr",
            "286":"1812Dt0eZDQ0ktdx1rJ6ycZTDBCRzd3IG",
            "287":"1LOZBrYhLPCiO4aOT-YT5dduYNT4B9NQj",
            "288":"1WwEOwrfQhrr27e-t4S0xBpYTqbiiasHZ",
        },
        
        PAYA_PASIR:{
            "1":"1guoJTv5CHnTwwlDALia3iaM3GGqMg3Zt",
            "2":"1lzcXP-mSxPAHJKZD7sYbiZsKa-4CiDvp",
            "3":"1RI74xXd468UnnfvzRSmbuNVla-JnvP6S",
            "4":"1BpHJpjg_IfzH7BAplDczBgRAPAGyH5QR",
            "5":"1V-5CXCGf4MgrD4sQm0n24NaH0G3vR1wz",
            "6":"1q7JOJ9tpciEFZwmR8GhUtPus8pxXZj5A",
            "7":"1be0suBIc9bsMag6RYEjOcZfLdKmZqZZA",
            "8":"1YHv4vRQom-5C4w3X3sxEeOt5NNUBe2Dx",
            "9":"1l1VuG8GM0avhdgUsVMhCW6duvbBHfM9V",
            "10":"1H12jm-dRcE1rN73DiVhxZx-gHDCigUYf",
            "11":"1n_obA4QKodQpEWM0ziqxARY_39geBelq",
            "12":"1MagKDsS3oW7dVk6Aa6Fe--KBTlxuNwVy",
            "13":"1AeP9KLR55Jzj4LUnTHM-sivv7K0qQi_t",
            "14":"1PAFaGiuuAfESbKUZK7Y9n2cAfFRkYP3U",
            "15":"1GfQj6faD74vZhsmQNfnLJ_iSr-9Y-8OF",
            "16":"1pwGIeS5UtcM_EQ2-s4RQ38w3gg5vrbe2",
            "17":"1qf7keBIIeECPBBNZWLsSl_PGFrMiI63J",
            "18":"1PwhVXHoSzDqk2PTO0gJvo9afLmEY7SNr",
            "19":"1KI9L2CcKPFP8qQ4J52MBPcpHWuuHExYL",
            "20":"1K6x-8VQBj_0k16g3HDKczq7yGxjKMyXa",
            "21":"1axZykNKAZqSJ-HOOdqJjMdiNWTWrGFDO",
            "22":"1leCML0OQMwFZ_b4MR5WgdgMKTMJR3aOe",
            "23":"1agdFPx4GEoSen8GCjs6J3zGMjfDmHqjH",
            "24":"1CG17MGZWA-Bb4-JNTThd6bAnrCQR65w-",
            "25":"1TbHTkFkTdvmVIGC5LlOWbNbnLLCjrav5",
            "26":"1F3jqU7TzgWbDWKnNoidnWs7ca4Ot9QOT",
            "27":"1VuTvMskXmIxf-4ve_e0VKRPvjFYQ3moo",
            "28":"1zJ0-RVAt7Zjkp98MmUtYoQ2g2HyR2zvQ",
            "29":"1s6sLkFD57GCbKmJDrlbrhMj2TOZeiA5s",
            "30":"1ChNLmsEd0rcxskyeFbs_us1pTZ7XaDXJ",
            "31":"1CCsc53buQ_nTtSFJoISMF1IffEPchjjG",
            "32":"1pta4WJoG55hRFJji4-QGGsSVyIzMmz1p",
            "33":"1wuhHCc9aoMhPGnfYVSzy6ClAjlWDqIIR",
            "34":"1y3CaTuxMqtdVrarHC95h2uNhi0aszqHs",
            "35":"1OMLempgrCvTAHxveq7UqoXH9jSZKOXFv",
            "36":"1tvZYNvKw-GmTs0gKp1ZRe-xwYGDp0xnf",
            "37":"1yvMs6rMV5_ZxdEdXF7TL206ScK9BPRWs",
            "38":"1XaZc8FbMhUizKeyvB9mKIWxcGT16YCPi",
            "39":"1ouNi5_anaSV32Oordq8odYyy1FBYklCY",
            "40":"10A6y3Av3Z0C4JwcMjpVQ-WFtvvo_n98C",
            "41":"15GlQUA4hx2zv3_4X7U-fLygvfFDgXUUS",
            "42":"1nGKDJ0CNsI_T0Nq8NPi9nskMgv0mJyRY",
            "43":"1cHkyUoYYtMGVhpeeqRkJ-p8HgGBvgSrC",
            "44":"1UEvOmD_QBMIh9zHkDR52avcjfAjgbDhY",
            "45":"100TnXFFsL_BoXEY5O5OzLYvfWW4pvCBz",
            "46":"1e9Xj_8W0R_EVaIFtcvqZbzUj30O6fY9h",
            "47":"1KqACleozQQiKiS9HR39mWiTJLOR7Ug_A",
            "48":"1JH96DepbRHhp0Ylk_rwoO-a0R36nswLF",
            "49":"1kzDrMzXagj7b1FNsiSTuoXjW1CwlxTl2",
            "50":"10FQ9r8q5XPOTBjVX3LBpgUMglvyPjgqn",
            "51":"1HxlCyMBBjBXoA6W67WwxorxIHAIR1JlK",
            "52":"18adR6LgL2G1KkOX-J_18bYJPrtnZBld1",
            "53":"1JTLQfdw_wncglMGFzDZNZB3W1Wmo8qf5",
            "54":"1HFmsE62SypDfWHmktyNLNsRVVEken_HA",
            "55":"1kLXCGVs_V1eHiCU0k-TKnyzHMZmnbJQ3",
            "56":"1S-4NRLCbJw0RiQS9ZPovDFhdjBhb12cG",
            "57":"1EPepZxOOvoSBWJhUS6KqAhBABKjvmkzG",
            "58":"1Y_MJlIr-kAnHAoLFN5cV55qxZK7QCegO",
            "59":"1Nha3YRlofv5qGjT86kG6rVhCV5d8vLEV",
            "60":"1C3JugJjLnPG3LXnEKozvETBvcp45b0nG",
            "61":"1pnAk3Oem1IWHOD1oyOEE2RM7b7gHINnT",
            "62":"11AfU876nXFApNd5OKLSuY1rKlB4AEKBD",
            "63":"1xIh6iar7HTQF_rSKELXwzNYbo2gKGjLh",
            "64":"11fFYayIHSMlf7N79HvBDN18XP2-QCtGY",
            "65":"1wOAU0fdSpX4CosYMWHgsEwxCi-eCLbJl",
            "66":"176rzYUT6ojgzf77jq3bFkQBXsUTpWkDQ",
            "67":"1sJHmpDY32I7EEvnr8upwdGrWOV9Hs66M",
            "68":"1sAuvpf4NdE2KFWIi3LQqIOHcye3jb6xQ",
            "69":"1y2F1wRakNxhPCUgAiFiV27wloW18kDDT",
            "70":"1c3Ua_CE4ON744rEB6OUMktFqFOgn7ycZ",
            "71":"1vIOLtaGLXZ5_S9lipo4zeNM8QJMRz4ff",
            "72":"1YvbjNB5zjJC9eVXi6BHhHYaIPLFwLK-e",
            "73":"1Um2bweA1plV5LRcBV3IIsuqEGKzmP5Z4",
            "74":"1hbOXZfedZ1yD54iUa3qiFAvTBHwgVNJI",
            "75":"12N9w0io7vkZBf2tQ8uubVwEzSAbtHX5h",
            "76":"1iojUS_7am1kZTMahGUNv98A8yJox6Sfl",
            "77":"1TquXSB6bRzPHSGVAi4v_ULfyoyT6GcUV",
            "78":"15hfx2lbewH5H4iMcsBi7X3om_gRE_I9E",
            "79":"1y1M_8dROf4facEy-ZYBaAcCFuwBE5ixS",
            "80":"1bXO_z2hO7bgiqateSd9bcwgezw4Nt4r0",
            "81":"138hUqaQ1PstL6hSGkf12m28hY7A1XMal",
            "82":"1F_wRQdn9NDWvK5RUY7nZerd-22LYTTkt",
            "83":"1bmFV12yvEZcRRuZRjJoMlqu4xdY-HLaT",
            "84":"19hi8ezKBLvBvx8sIQrnNO-_r1HomoXfJ",
            "85":"1r8d1FgNBjdHJ1v7EBZ9gcdSEr7rpmdzG",
            "86":"15YlIjFKamg66tE08Iov3XcuqX7OjVcQ2",
            "87":"1MVRfblyEJ0wyCdimx2pKSmYQwkOVpcEy",
            "88":"1FFwTs2ZaNGO2yRHVqo5AjlSZcWDqt8C2",
            "89":"1KfAalwue0m3MLu4ALtAXleV4OKviX-CQ",
            "90":"1U6arXpuvHgxb45oTF6U7jBVlLc8rqZk_",
            "91":"1sTB0aloPCHUg3dEheyQOiPwpVKPBwB_H",
            "92":"1c0pSpvaPljXuvZLkWhtrSgdOJbsrCxgg",
            "93":"1ZOPmNT481AW9w916m6WZF2kAB858JN5D",
            "94":"1cR7K500zHXXPnjJl7NI94zBHjQpw37-6",
            "95":"1tE2sy9-vgPSjDrQqX7-n-NWVl5IYnp-s",
            "96":"17zpCrDOJlFWZB7bQlmB4twxlE4l4N-EQ",
            "97":"1hXXKqGF2aIMhTmuT6OoYG3W6ZsljBmOi",
            "98":"1D5Hjdn_JqqQKsAiTLm9_lhAvsa0E5XKk",
            "99":"1_sIXER_5lq8o_JIFGnaNrWx5n-MzrkAS",
            "100":"1Slm6XyPm5-YYytGv5A1WObegteF8jCxe",
            "101":"1YOllYdi_0HwOVVUrrOok0mO77d7angvM",
            "102":"19ffoTzYqFzYN61NHRWdPsS3X_ImgC3YK",
            "103":"14OVSMtnmwMiYpPVUNYHYO_cR-Ogi18KA",
            "104":"1bpW7VrJv0AqhV1nBXl02aKTq3H-2DATr",
            "105":"1SXH0QWN0su2fv83Ph25Y8fHd_DXdoS_U",
            "106":"1R0tTmVvKyh9Byd2eRIg1T9vxsDmyeO2N",
            "107":"1OKQGHryQ6scxoE37ymrLKP6qiNLJsb6X",
            "108":"1L_7DjAQWtHOPZiHG-MHfS0sFXJbpeBGU",
            "109":"1MxS0QDSDXtuBeQQhVFWTQ7UBu73b4IQO",
            "110":"1yt0LMrKZXmpriz0ortT19ckeQdMh9p_B",
            "111":"1Os4DSR94Pw7Ugf3eyt7uC3f8un_-pXMA",
            "112":"1w1kaR_x59vjesq7fyrZGMovC6cNt8Vto",
            "113":"1yp2KeJcxUBm-eIZ1eP-dW6_tErC7ijLr",
            "114":"1nURZwspu1857zvhQTMBSFGmqoFkBL-P-",
            "115":"1rzVBeoxZeCyB-mBNscxydqa5a5myqNCD",
            "116":"1K66ww8PUlXVX4nOAeJta_bO9LaWcK7EM",
            "117":"1aQA7c3wIS2s6osM5ggP4HvaM4tBgtM-8",
            "118":"1m7nzdaOGnbHy4n_cQyCN4oqpb-1P36LT",
            "119":"1RusqDquR7lVMOUz8u2fctX46VwqFwm0f",
            "120":"1WYHA0hIxMpHdcNVqrno54n_zhud17oEw",
            "121":"1miB_lFuzb2btxnf1sFPHuv06zvVBLaXY",
            "122":"1vR9WKgKOg7EXemra7rMQP7W8Gtw7LEdH",
            "123":"1bSq9YYwADe5xIXMa05w9Z4MkhJXXCPuS",
            "124":"1FfxZaJ1gnhmbyRhX0Evzbr7INlAEqTS8",
            "125":"1016tF0-Ku79p3-OQHDQXfQFO_1SLJcrp",
            "126":"1L5FrwtaamALmojf80XfqOELu_p0F86By",
            "127":"1JeREXidIH59pUfO8ZwCuhz-Tl9MS1qUK",
            "128":"1TB_VQ3covS8SutltU8XLLxeZ71xn53Ub",
            "129":"1r660bmjx7A2R_HUSpq7TPemmUhAkShVB",
            "130":"16GcBQ432YPWfjVV9BlT_YSWNPD_uan39",
            "131":"19F4zC4K8cDzJUGQUWupFlllHweQDcSyG",
            "132":"1qNI1g9WHEaDrdV5VZQjJQAq6N_aewVpE",
            "133":"1QLmz3AhWYUUZPLR9KZwFh_6IhvYd5a8v",
            "134":"1o-jsJHtT3Pdv-JFu7Oh59dVIOIU1G8zg",
            "135":"1NVMGxrvju5ddHcLbM7GEOiYHy9EQulfB",
            "136":"1npge7Fw-jjmvZROl0JEWjnvObEh2L0q2",
            "137":"1NcsXDbh4cCez6nkTQ6aOtxpMw_RrrdIE",
            "138":"1EYGMtS1osKTocjMSNaIEdHNvh1quB5aQ",
            "139":"1kydWbiDAAqa_bkQ7DAa15InHmfCEyq8U",
            "140":"15iEYUjr4yFav-fIvbxDd20r2LWJ1lEjS",
            "141":"1IPdC3wvFi4_f40x39B0FnfMNIPJ6nWrv",
            "142":"1ufl-wdwPRozXvXKhtlDhLBGRy-8I6pFf",
            "143":"1CxQwOvBoCD9DT4CfToXkZgp-rTk-t2-H",
            "144":"1VOz24IQ6DHy0R5YKaH2Ac2tVsB7_FVxI",
            "145":"1OcmgxPP-IjxoriW43MJCRrhrYflB39To",
            "146":"1OJZJ-QzdeY8OHs9iFX4qAOtk_bFc3q71",
            "147":"197K2JWTb7Bmdkp-NAPQyfSQrdmt5euq4",
            "148":"1oqaBXDcCj9OvG8NvRAM7zRdCQZxRbOiJ",
            "149":"1h07XDDbooVA01bvOfijmeNJaJaE69U8l",
            "150":"110ICa4lBfs3i2QpdFOMX9wjlV7s_PV1I",
            "151":"1OGz9vHJW5e047-_Z-BgQGwfo6sS5B0en",
            "152":"1OHUpVRtR5PoACLTPQbYdVe95hvqTD8-w",
            "153":"1ptOZzh3101-In3YtmG_a1STjFZk6BXUc",
            "154":"1Z6NEq1j9uHANgDaoUkA4t1dFG04RXWJE",
            "155":"1tPyfavCIdBzU42IzZCEOH4nEz9KGE8KW",
            "156":"1zcSmkM_gqgp4JSSk3dNcOO-OipMloOn3",
            "157":"1xqd1TCD4o_W3dS2DM2bd8rgtfFse_qKr",
            "158":"1VgKODJTUxKRkXj_sdNt8Y_Vn81h2iomZ",
            "159":"1RUfRWUk-PU7-EVgjhz_bSuLAal2zYuTC",
            "160":"17iY-3AwKzSnUaqOodGtrwAPpw2VKp7tS",
            "161":"1H8a2algiFtXmvoZ8jSmU3ihPAVQHslJ4",
            "162":"1I7PmfzYfX78LcBqABFbytWDO42GZzGWm",
            "163":"1ITuXHOsJY3Vw3ShjAXVuwiJR-BLS43nB",
            "164":"1IoZa_rg03ae8UzB4ldpS0iEToIJi5IyZ",
            "165":"1KlyfT7PiwISQpiTSfOmtYbPdWOLEuFT_",
            "166":"1N5mLoK3UEfT1Mum_Pow-dQu96NCvngIY",
            "167":"1W0JdkkMWk41myA20jV50wd8eh6R3ku7o",
            "168":"1Y1mOm0QlOaIevB6GDpyD02rnq4JCNjyZ",
            "169":"1_FisjvYLIt4-mbI7oQJpkk9BvWtqK9yf",
            "170":"1hqfP45Lv5P5XHR4dboi5sPmkk-qlYR6I",
            "171":"1nLm_ix6ng4XCZXloylAmBZrCVIUcmOhT",
            "172":"1nvCgp1a5zavvu8DVZCjgYWp6QB_Pbw5N",
            "173":"1pws08LhpI6Fjn1gZebWgJBHnESNwEj2e",
            "174":"1rchWKb4nmLoWhOLLGvN5AspUY9g3vBZv",
            "175":"1siMxpv5KfzHRivKoobNpE4T1_wHvaiM_",
            "176":"1t0xooQCI04hsA8DQvkUVcBURIqfmyRIa",
            "177":"1vZzYWyAKSZWF-2CRnfsVfM2pVeL7iyCi",
            "178":"1wjHv64VHlGmIa8Dk9CbeDd8HDXnHGrcN",
            "179":"19qzWlVJW3uZdYDJx_Y6uxJiSAD-RQPz0",
            "180":"1B54lBqR3Jq5NU2vRA4uo6Sl2uQNPMVgg",
            "181":"1B6zdiMXjpUJCMOmW0Al9mm1X34X8P-z5",
            "182":"1DrruL6wBWB2HxT0LjJuOhDNaPHHzmpRf",
            "183":"1IepHWKClApCdeHvLNDUzaePhyWNwC6vU",
            "184":"1K8iXdBTeNQvR_NHVYKayYq78CFqcar-p",
            "185":"1T_spC8EReeXx9qnq7Oe-IzIalb99Gofr",
            "186":"1UxgkT__0PzJqaLoH2v19JD8hWAOinav1",
            "187":"1XRStow-FTdRh2zoTJK3za6bQsgNFZ6yZ",
            "188":"1atNWE_Novv0gpQCS21o5kNzl_baCACHz",
            "189":"1bNudbZtwrnuDgm8EhlsIsMurXPGGyK0k",
            "190":"1hHungNmwi8QGFQkEY5gvVw054IhN64ek",
            "191":"1kHilNB3LIfvC2EWjn32X8OthYMOWzjCU",
            "192":"1pd2k4Wlf1uPr5cktZlB0ysnVJCJN61Uj",
            "193":"1tLzuGLPWOGmyBYf6v9ZaQ7CbTv2TplyI",
            "194":"1-xfD_pkqfhexX1ZaIwh_RSQ_GMoUP73b",
            "195":"1Eml35zFPzkKXNz1Xl6HSiKGw3_C9axSK",
            "196":"1FlZVsuQCaGVZEueHp4eKoSNPZRnPLyHG",
            "197":"1GmtwNZ7n1vqrZgCARAxBXk9389jeX_Fy",
            "198":"1MnElj_WZGEvVJVAn8XNCZibU6eyPOT5F",
            "199":"1OgR-wThSBrLES92q5HU7X3pC8iHKcurH",
            "200":"1PED9tx2NjtAi2r1baAT71ANcMEeazFUb",
            "201":"1RuRuQkRdh3CGnvZKo0sB4TKLLcT79Xnz",
            "202":"1V0ZQmFo5uNOm1iMf3NWxZBeOND6wb28l",
            "203":"1aMXVxK8Et8X_jXkT40xlbTzEs2lZxaqH",
            "204":"1cH9uQ_11ujB18YTGEBlYsQQMIiDjZI7L",
            "205":"1gSW5xMvfUhSPwl1wiBxDddvyMuEUVWUr",
            "206":"1iv8OcoiOaVjRNXaUJII3_Z3vTBpjXZsz",
            "207":"1kBFfJ2v47FfgasTldzS1hZijn177JbhU",
            "208":"1rb6bnz08w9jyW4JS8eVnHRsBXKMjkgc9",
            "209":"1roVpWHb7oLc37V4ILVdQ7EQDdjoiDv1j",
            "210":"1tSxtty6yRgvd-xA1fY4i-eX8Nblyw-Jl",
            "211":"1ySGweUIm43AOoncZcMr7LA47BMGkXlCX",
            "212":"12lTtv8mxzH9TvgOBlUO2XWbZ6gDx9rGA",
            "213":"1VL8rM7Usx7a0xEGwl1yk1EwP6LeTc6VW",
            "214":"1YmJfiU3EJ7HnruDvYs8HQJH-aFJk2oyb",
            "215":"158g53almGnq5my80DzNdP2ehUile_S9Y",
            "216":"1EexIAfNKxt3D399A8v-EVUC2ZR9rhp6O",
            "217":"1FYiAvNgSkXQx31Lp-hbSUzngUi_0AMu6",
            "218":"1Fy-i0hPKagYhFboXN3iDbDWY-86TNrtz",
            "219":"1LEmM3Xn08nGUD_61QhxPwsmdBC1WOh4c",
            "220":"1NDBZKari76gYTY7YCD06wzwZBjF2K9xe",
            "221":"1OZnyAciS-Cd2AZmYh1QwJ9MObxol4V1v",
            "222":"1_Kkev1WTECQmwBFNEDVGBO1Zuq1UIBqd",
            "223":"1dTWPEYbrV0-Jq0pxIf-JLKPOBGWnwOfU",
            "224":"1rCHzgD14klQXqDT4CfZljJg9nkBdg9W6",
            "225":"1sTBRP2puEbhUoiUzgefOoah_AJgZ9TOD",
            "226":"16wX7b3SukB8Y5i4O0-oELhZadDH4LyJP",
            "227":"17QdJkv6GKKeN94Xw44T7yLaxNcFyeJye",
            "228":"1Dmk8RRRWk27xDJeIFcHm6-64xNIkubT-",
            "229":"1V_bqw-EIpmwmqOT_9yhHA1oXp21PDE4u",
            "230":"1aDXgQ0exvj6heHNODHrK3-_scP7VdM1s",
            "231":"1epmT8tHvwJKl4C8_oUcD588uHTLal3kU",
            "232":"1fsyW4TbAwT7U1rdimQasM-mHwGs0lAzV",
            "233":"1gY69WQVM7gujfoh-Dq1F2mg5NdKDskP6",
            "234":"1ibKZGbmOrhkG939y8oFIZ5H44I3mtZWC",
            "235":"1ljYqtEzAgE53iqTJU9SjIil-MODVP1I3",
            "236":"1sez270RmZu_u7sGQgnwBbwuR7WBmxonu",
            "237":"19bM-6vslSTo_G3MvA8aM2dk32fkjJWgD",
            "238":"1EAH8k0UHk4LcTq37fvMg8ql6YpZjfMhf",
            "239":"1EpnQiuJPvIzx5Rd26CggpslCUB2xBiE7",
            "240":"1FMnI9qGLvrGpd6G2Pm9sFpJltWqQcCMl",
            "241":"1GLuZU_D317fwoYmnB54claGsH58U7Ydi",
            "242":"1N40mtkh9bfl_x0oByLCIogbGZ3C7huuF",
            "243":"1OqQPJEtFcfPDVHLbtvVHJLpu4x0Szk3_",
            "244":"1UVvRQPV81xDj1nruwRm4SrduwxcnYh88",
            "245":"1WRlThc0it5HITUMjOqdpEy1NcTYzffbx",
            "246":"1ZMrFGPUeue58CDbW5rhFAYe7mKXpZigp",
            "247":"1dUuMtDA_6OxPFr-sSIQQkqSnvyPEzAHe",
            "248":"1fP4iczDpINRiUktB4mTXyeelwOQeL2hM",
            "249":"1fkMXhfUqwm27MLyxbGNA4GH8vq4YNhKJ",
            "250":"1lij-xWdH-Wn_D_80aWeCfUJAuTVTWiDC",
            "251":"1p_DhyLlhjNzqhWXnlBY38KzQP43RuDQa",
            "252":"1r-aUiUT4rfTA3n9ne5izXgy3abLw5utc",
            "253":"1rpoZx3ozDFYIdHBA19g1oaPJNeMeCL13",
            "254":"1uQszRl8uW5H6iVUMvPcKew_ZRCubxk5-",
            "255":"1usJy5X0OpJWj_wvCQttjTdeDgeGEwU2k",
            "256":"1ynoob80RYMSG_n3cdtb4VqXX_-KBCYEE",
            "257":"11sH1X5govkXTiqke1ZNSqwAEX4KsVXge",
            "258":"122OJroY2oya9bOP5SDWdPqn7eExBh1LK",
            "259":"12QKaR--PgyyZR9ZUK0TqNzzlLnK1LyAq",
            "260":"17VcvI9-MA2WFs5s23KfKxgusRn-59HQ9",
            "261":"1FHBKohv3t6QUZF9__ToDUNNDYukrkXW3",
            "262":"1Pn67TgmB9WPQiZeBIm2i0SmKtD9bksNv",
            "263":"1QychBWVVsWM9nM0zayVuzDjDSqWgvXJQ",
            "264":"1Rb3G_sif91o9tcL8LPfsnkIc1CccU3Hw",
            "265":"1SNjSGv8lD8TTSrCaDvWcn8ms_9PgHcjn",
            "266":"1TIUJDUk3GXaOcqag_WEVn-IQkz5qrRgf",
            "267":"1V6lz3VMO-CGY5Rpo0gQ5MQzIUDnY9CRx",
            "268":"1b40y8iy4NlmOdu6ZU1dwdZU3W-DABhI_",
            "269":"1djyPXP0EMF1fKN9CqtFvLIfuFPE3xXjH",
            "270":"1iZyC9vzIE0kqL2IA00AjXyOK9Qu2bcBE",
            "271":"1jQs-4XNmhpcsMFK3LDZGHcan0Eju7uk3",
            "272":"1mm_UJFPFwInl9wnW6bwhLCdhXL0kFHa8",
            "273":"1qXZh-Xa6246X48it-2jC7tNie-qTiA96",
            "274":"1qpCRgQJjS7jWXiNKv6nwlqZFHns8GNwF",
            "275":"1rEJ_JuLhFvT3wIAbN7b_paxM80l9WrRm",
            "276":"1vTrNVLGoc3Dj79f6ZHFM4oZeDhggXcV7",
            "277":"1xYOJj97bIJmW1aNlBAz96mPMktfsmB6u",
            "278":"14cRoF_bR5hMAnvGQNBSnLywxleRZUu9B",
            "279":"18TVJX0jNsA6d6ROm0iGFiNU0FFCmhv_3",
            "280":"19Tj-2iNJM0Eu2Fxbc1GZD5gRviqqoUZO",
            "281":"19TxZpUmAPZUXaVdSaTCH_Fqcm6BZw4p2",
            "282":"1AV_slD31ZMdyIF2WCjFbUFtVzQo1d1hA",
            "283":"1C3G33J8NDzRiQXYzGi4JoZtlselnm_ZE",
            "284":"1CE4UxUeSvgVQI1BvIVlL0oLCOSxItUZ5",
            "285":"1DG-zfbEZKDrw3L3JWlQE_G3gNmjAyfrf",
            "286":"1G_9Yoy0Z3db9tgHASkWhnzUM34zUmceV",
            "287":"1NKs9L6T3b_ZbfyGyqHT6K2fCmaJv7YvZ",
            "288":"1U5o8tNMl8wk3uBFpSWvkI489f_P8bZ9E",
            "289":"1VDkPZaJGg8kl_Rhr9JdHVSOZeYRe5qZS",
            "290":"1bYTKC1sD8wEjKoJiTXAyldifvqu2OBqm",
            "291":"1dLyOI7jR41vrhBTwdfHTAceWmAvuGaTP",
            "292":"1eb3F7RLExDgr1Xs_riWTKCSAnEuC2RRV",
            "293":"1g1ktFnYMlzG6ZwT-MSeqqw1pKXchDVjQ",
            "294":"1gQ21fmUlLhh8kDLMGbh1UIYGAg4xDNXy",
            "295":"1i0lI6pVvQOnGdIgUHCpR8yRaKU1A-yJo",
            "296":"1j5dFV7nXB6QC726-831EaGEix3O3ThSy",
            "297":"1rThK7lNHVBMBrHP8I_PsqJ50mS7sz61u",
            "298":"1yTRR6qJNf-Vtfogs6AEDkAn6Nx7k8K9Q",
            "299":"11uZvV5nefddKe6Oabw9iyG-k-jbTASeq",
            "300":"136cjvp3EcqNnKYT9l6289AMKRGePH6VK",
            "301":"150ytW7ON-2yVmIVHvn5iSvEBvOETsgjJ",
            "302":"1ABtX4XGpQKOleOa-_BKX49UrtpVmEdYp",
            "303":"1Hflptha56djCsizCR9V7UPP_pRs6NZ85",
            "304":"1HrQVv-xUxUKfy6TB3sB9xM9KJM08FW-x",
            "305":"1NStAagMc_DMhsjA5ZDXBlwrMvRVbqiJo",
            "306":"1Ow_O6nXNRpKWao3iuycWYyQXNJ_gxc7h",
            "307":"1QuuwK2dGXY6dOj2qprwoaMeKrGErdve3",
            "308":"1R1xROZLpk7qDNR-wx8_5d6B_1v0FFDVJ",
            "309":"1TIRKLMqmOFLDNTySuuGljdqEsZjSJDN-",
            "310":"1WUpVjHdjBmx2asITDMUtPZbidInKI9T0",
            "311":"1Xl3weGJsRG67IxNeCK4ARSlweWUb5Oz2",
            "312":"1_-I3m-tRtHHEm65xz_Aw7h-Lf_catNns",
            "313":"1b4-to2UH03JOjZIt6VyIIeDWVOejP0rN",
            "314":"1iQnFRpqdFavlV0C8howe2F42M9ucSjlg",
            "315":"1jodW3wnqmXVNiLgla2Qsj0R97SJALQm_",
            "316":"1ljgrC6j_LRzGeHZoXkJLw9DDFR_qWRDA",
            "317":"1o9JiMmIqugwTI9kvZM7Owd--wmp1Hv3k",
            "318":"1pFFklerwAcGHU-Ddf6TA_jd53_Q3kHu_",
            "319":"1prC0YEWff4j1silWPTDt6KTOp3z_O9v-",
            "320":"1JKq6yPa8P0cqkrkmiDzSDKiKSam9ak-0",
            "321":"1Lh_5AzxFSK-FfzuXoDOdc69RRwhqkAfu",
            "322":"1bHVeWERs5eyyzyFvQ3WzXkYSrj4C9CXz",
            "323":"1hq1Mgn-wAUyRAV-95fC_feIiUd_SQrwy",
            "324":"1o9L-OJEG8J_gMena-LxG1qnx_Q8z5U8X",
            "325":"1s5j_FSEtcbRcYm5rG9x6A2Pj9gU2ueOI",
            "326":"12OF1zzcmE8cIwaM1XJV5e7YaCRA57jwz",
            "327":"12bkZkh9YaHqHvKoDe_Fa98CdKTuneyL2",
            "328":"13bZyukw5CfnPu3TvbKV9n1qBHSGWHRmw",
            "329":"1CHJ4m02KM10O3ncHjqJZHlNEX3t8JTvE",
            "330":"1DD5PWPDhzT8aRUM9RNEty_y5OX95QCxN",
            "331":"1Di1DKho-sUdkhW2sZSvJYGLzoFEG8IrE",
            "332":"1DpfLHWfxCk4McJtngtpgKF4tDKcH7TvK",
            "333":"1G1nJiX1q2pYVH9TfYKMDczGn5OieJ1RZ",
            "334":"1OUV3FSDYy893z802IR_bnDRwlmgy-tuO",
            "335":"1TzZLBPiQGGcUT6ctuRBpnNCg475aXWnX",
            "336":"1Ul-Ijvkcq7wTY0KXYkLMadhs6-TSSXjz",
            "337":"1VfpQactjLDmMT1JdjErtTw3Fq-52kyJL",
            "338":"1ZQYGKQakM7TzWB0YPDQBpIwC_AKRcLaS",
            "339":"1_TVId_KQos1v5UPxZvdg5FSMIwPPx4jr",
            "340":"1bK_EKTdw-u_YuLdpgMsRpIYzhwj4kXMS",
            "341":"1eZ8XmxSwYhFU8wzgght89bQvfiA3otiM",
            "342":"1sj5EnI0cV5E6cjh6FdcOTwarhMSiRVs-",
            "343":"1uIe0kKjn4CK0sW4Y6AOnwOFPLaXkhYtE",
            "344":"1zSJR-wlIP_nIKfLyC-_yR4UqkMB_TdVj",
            "345":"10Ldsh0Q3YED9c7ZVebUSHMCve538nBnI",
            "346":"12pOPmDsumCseWnB9pCL-cXhMKsvx7HxF",
            "347":"15BJJ5NieJlwdu3L8m2kBv6bBHFQEu_bz",
            "348":"18WRR4cJAKFgGZk3U6dorja45VOsK9pRu",
            "349":"19oY5qTCFRboH1FzRLK1mj-YyrrJM94wv",
            "350":"1BV5ZUQzvDudBUsyNWKnAK8Me57TeOaDv",
            "351":"1DoH0nwtPwchELF5nY16kwVXEtivfrCL4",
            "352":"1EhKYrSj7ZL1q3lC5RiTvJLKjYvjZQxVt",
            "353":"1FOg4zbf1u9T3t6Qsq_6MYWEgGYMJbz-f",
            "354":"1O8AVk0Zy-hsT2YwTQfBaTSL_nLemmmkW",
            "355":"1PTNhQmQIWrrtDXsRH9OWgWjCwcPNKrDX",
            "356":"1SKwrU4DSMNM55kDx0Z3Ai9r7izFGZjoF",
            "357":"1T0h-s2Dlsl98csLikic3EB909D5oxilW",
            "358":"1Y5QqIS5hghLxdp07ENG-dZlydvh_Je7s",
            "359":"1ZFNwfI5I4LB2W3D4purCnYQpWEGWuMRS",
            "360":"1ZL9MT0bL4k0Fr6jD3K60SrdgIh_Nm_gs",
            "361":"1cj6jv-sx3vSEzx8F-lia4HwZm6vGdKgm",
            "362":"1cp_EyhLaJV80UOfu3xNL_S0VSzx-fS8w",
            "363":"1gC-hl-YENuKua_IHjjB-pf6CTOA4tj5E",
            "364":"1hG0xxa6VUAu_nKkAsJdWUqvjxu6-egPS",
            "365":"1hG2H7FAk-ba1dcjAybAI83rAgCcplCF4",
            "366":"1iHLTBZJSRaPBVnGnEVz8nM59SiTg6FCR",
            "367":"1jBvZDd6SYlw1G4Td4JB73eAkYRr7WQZk",
            "368":"1kFSrGQM3ro5157yVdeNoP7YTJMCQPmwZ",
            "369":"1lkhG59psiNKrZw_Wr17gcD2ylAsgpddF",
            "370":"1o6GkHBCpHDe82GEH3fTZpUZTWNTUwuos",
            "371":"1ylvV5ZYSmAJzWb1V2ByNdAaJvLCrZSEP",
            "372":"1C5M6e_WgHEhC4DqmB5FxWBujJa6W9jhI",
            "373":"1C8pRYanXkYQgmEWnzx6H3a-L-O-S8mJ7",
            "374":"1MVfOyjehfXqMM7nk6curlIHbrhPwoxrz",
            "375":"1P79QwZ238pgZcXZNZY_JVhR_rYPLurp_",
            "376":"1SuXI5yEVw2YIUda96CdKkfPyPrNjCyU0",
            "377":"1_RS7M34J7n7pkk-jFbYug-YLYVPXGxbC",
            "378":"1fcxZsIHWlXHAplLyRlJwNL9UPgl9F6GV",
            "379":"1lPbXLyNZpCwBzSLvtDQqWbMPbUIHrGSR",
            "380":"1xyM8te99L1u3kRtUMCe2Fu3jLY3D-dfX",
            "381":"1zJygdC9L37kpgT2_DlDFxhGgDPcn6dVQ",
            "382":"1UBPP39H_tjzUkEpsEeTfJNddjakGOX3D",
            "383":"1fG-gpferuSa2kD0JV9iWt2g5srjCcwmx",
            "384":"1lIqQm-cKZ7ZZsMxFDRlczljQWgfsnK08",
            "385":"1lZrzXRijUFuyKlSorMhc9ApZsg9-8Lqd",
            "386":"1lmu3ar-EpS2TDyb_PC2dNC8_CIBmfQkl",
            "387":"1m6NnH9AbHzntfDqcpVlP_0hqWD7VxpYy",
            "388":"1xCn8B54M7goTtIbhV-HWZ5Xhh9Vn-nJb",
            "389":"1EmKNUPiWSTruTdh6hSYER61pwXsMY1Rf",
            "390":"1f5QalrAqV0KOMpnIQTPsj1Jq37Zqb4-I",
            "391":"1Dpvd9-yXw8xDXssmbjLbC-k3d4ykWB2s",
            "392":"16TvFBXgMpeXqkFEcw3BJIzV10l4SFWFc",
            "393":"187nlnNth9jpipGCpUt4zygABj2A_YPbY",
            "394":"1GRL8WS2JRdaRfpgWji9YWS0XvMT4i5EJ",
            "395":"1QDFtZuhvPQ1UGFlz2oxy9zeAbItcos3g",
            "396":"1XaGDAdI-PjpNjJU09437jH9I1l4gdxV4",
            "397":"1cMtnck-xozZCH9EIaqfG1QNJ_V4DTahk",
            "398":"1inHwERbDh9nvzlEMWh5Xjdvjt-zwNRZn",
        },
        
        PASAR_IX:{
            "1":"1BdQ2hdwcVv0y6NlcrVDHDULTxx9QVse_",
            "2":"1ggufugRmKt_xFpzNskli04lVjDhU1qqs",
            "3":"1X7nX7hZ3KcgI-cF9EsCgQ5UG59IuP4RL",
            "4":"1VdN641dYMKhJBW1fuwyhUYTfilqnrGnn",
            "5":"1sqbnK9ORIic3Oeit7EF_kVYBmSX208sf",
            "6":"1FKxQ2KOXCrHOnokIZ1lbLCC7NRq5jxna",
            "7":"1BGYXJIOEESzo5kMDbbgm-ajEoixcmAHj",
            "8":"1YliUvKhw2mbDfW7vThF7YPFjINltssIq",
            "9":"1ba6R4rvk52e7rlo4p1dtnf5r0sQ2ce2r",
            "10":"1DOkMYOSCI4YXmzUagAzcT2v36LIdf6N4",
            "11":"1L_2ZIIiKkkpVhj00_rzDYWlmly1VTuDr",
            "12":"1gdLwGRuVVG3tIA47hRw9pFONpAEyfeJq",
            "13":"1FifhexJgROMseZhVlLUIJebdU-cb5JUC",
            "14":"1hVccBDNXiCvXJduI7O8k_4sVbVdMgKNh",
            "15":"13HtL2qfYBGWV0axEs1ZuC_9ybSGQJ38H",
            "16":"1Y5I2MaPpAfHHwxU8TP-82xiuNiQ80Z73",
            "17":"15e6jkmptOJPQr3NiJQUQWMypOa8J_gnj",
            "18":"1Ktk9wcEvnz3wsSngVo0VqcwC4NbC3nR3",
            "19":"1fkURC29G4EB1Dw8rIIiLQers5yDv5oZA",
            "20":"1oLOGOVthFAb4GyfRnddw1nGWlV1vmhae",
            "21":"18TfShsSMeF3ZxznVqMZM9HKYxw9i7ZEa",
            "22":"1pCJinXNKkOtjdJM4_Q4ognOqgtpY8CNA",
            "23":"1C5Zd7f0xzpVkjTuT-NHBCv1lb284ZHeo",
            "24":"1CBDvKiDA0Aqr3G43td1JJggP28e5ByOa",
            "25":"1k-K3ZC9ppQwhq7A-_-NuEinaS6a205Ey",
            "26":"1jHHJQjsXj5TQTmooToJPCWBOLvWZ2k51",
            "27":"1sc9CKbhzB71DUHB1McX5u88XU-GH89Ai",
            "28":"1AK8OTbC1QaLMGvVmPwp2VEXwB99jbCGS",
            "29":"1wd2IwriI6j0h5181c1mc1FiuypYCGg5a",
            "30":"1LBjTkdyIfJZq9yTk6p6UtqXX62Za73hw",
            "31":"1bBfO-9kWwm5inxOM3NeCj1XNRx5LtyUf",
            "32":"1UwnD1qdljYZnUNZsK4IhCE84d-eD_hik",
            "33":"1JshW1Zc3MejYzbo-RBdQWASjcvCXx1js",
            "34":"1nD3VudpawJFXkzUKukFBwqdq1FE6fdiX",
            "35":"1SaxS9GWYJd0YYGRKuLd4r1zqn3r5Zp2Y",
            "36":"1Ev-mRNDyIcmcuzt82V7APJ96arAFv1Vv",
            "37":"1byx7-Gmde2nE2jW-vmoyh1w14vgzZvoE",
            "38":"1rlJ1wcu_iXeG4HWxOdfAMsHo18j-yWGh",
            "39":"1MeWjxzXipEV8BJTwVKDQnscHaTIR2tG4",
            "40":"1PTJd7QJWJ_mVRHku3tMla0-__4nAwX7g",
            "41":"1s774Lgs6T0wm_siEUfXJ1Gr-9vWFol9T",
            "42":"14ypdguSl9mxQkPBqrgd4ICknVVQrG1gu",
            "43":"1OCRF_3Z4YPOVpYZub_NsZeyPsZy6qrra",
            "44":"1uij-7tTGbDeOc1ZnIxlS1XzTFOP_Jetn",
            "45":"1TLP8pXEHZ9C8AqcmU-k1xYczi3kAtzub",
            "46":"1dnP8HHQw1BOMEfQub-ZT9ecCfVnauDpJ",
            "47":"1WzrR3X41JglGYtugXS-lvjrK4xfIV_Iu",
            "48":"1PSy2OCUcVeWjZeAarUwjQKwMBXJB400M",
            "49":"17sP4vyU0L0AZMHzdRk9RidT2ogoQsO47",
            "50":"1oIZGBrYnw_EP-MBW7-3nPJjqvbzHduNb",
            "51":"1yb0zYsc9-BMVEyotFaoPklzCTtqYDDae",
            "52":"1n5wOKy8lbxHMlGHcuTvlAxnQiUJuzBVk",
            "53":"1OKGG4yoAHYBMTwo5aJq4odQ0Z89XOc5T",
            "54":"14Eb4NAui-MMBNVye2hUNA070JC88nKbL",
            "55":"1sTgpuOPdVGY9XJbCTwQxxatvsv6twhrR",
            "56":"19XXVViketEN0iyeEgm28VGJIClJvXXGT",
            "57":"1CKaMzVzKIfwUUp4Vz2HeT7_Hgr_ouDwz",
            "58":"1iojeQGdwuiuQ8cDSvsmOd8tS42mc1R1v",
            "59":"1Bvrnc4VesY7Pb4GjxMRh4TiKKTNa3d0a",
            "60":"1l0dmJX1dE1sHhGiePEedeWfBRQelXxgy",
            "61":"1trz-_TJtw3JkruVN_-VgD95gxW7rOi9g",
            "62":"1rlGExnMubjuHhFbujFEe1mCUvtomcMOA",
            "63":"1YuFKC6rZWRFA8rxkA1e4LzFshXPGnrXW",
            "64":"1JDlbMwgnatd5rMG5RzOM6dk9avI8CENN",
            "65":"1StgmXbCcmtAsLzUrIukY_aqmVCO8u7qm",
            "66":"1zOXViG7P0mn1cyQ3ozYChBjxoJh9Lugo",
            "67":"1YG2A2ZZyz9FHuerI13TXbwqwh2TcYBl0",
            "68":"1VsOu2UYcH3LMx94TXUGdYTozyN9Q2AYl",
            "69":"1W2lRXXlhL6MHIGeFhODWnQ4G7csMQhme",
            "70":"1tBdAO2IdBbnNQYV9ua5rr683RtIOYq33",
            "71":"1blU7SkBn-CiNFJ3q6Fn_a8S_HiN6EeXT",
            "72":"1MD7aLlsZK_sOdI_CzNDRlwDgXJwCGUrm",
            "73":"1rHJ-ZzcTgrpHtaBbg5qiRQ7TfC5Hj6IQ",
            "74":"1LpDa3ElrTGya18RIq3sfonBrwRh6ddeP",
            "75":"1qC2FyZfxW8g2-emeuiP5tFDeks_NxJCb",
            "76":"1Ik2VMXVwbysZ_P6jznq2AVtOoc0duMLN",
            "77":"1wWOOVukxlPD0EpVeffxs4VWJnyjzQjJD",
            "78":"1kYBWX7ludz8NJEUMARDSwhvI_AVbTnf_",
            "79":"1wCihop1rhtguB3q3N6cnYQO6lS81aJlx",
            "80":"1a8opogiXrenG9r77VAnma3u0m6YACbZ0",
            "81":"1k23F0yk9iTuy1so1iPSKg9UF_PDmMNVS",
            "82":"1ef0jqhDoVCycKgeu8Q0CrTg5cfDPQMys",
            "83":"1GhPWiRkxlySKY99uMnBkG27tTPnaE008",
            "84":"1mbQR8fsRxpRwbmjqU14RU-fsi2ezxWFe",
            "85":"1ycy2_Fe4oJoggpTh9UrNZUsrb7ctbJAO",
            "86":"1drZRia2hUEm_X810YpG0fc4NMpTSzrCw",
            "87":"160rteQqPHNh1bmT9qokZrdDqtm4jnMx3",
            "88":"1_w0aA1jrVowm0jsWZb2XusW1ZFiv8rRu",
            "89":"1zIMEp-MuzVXUmElnVgPI4-taEYYV1KOk",
            "90":"1YqeZQgS5uCwPZve03MY_hVLAJt3xmlxa",
            "91":"1d37xLHlK4ElF6q3Z_mKWElrWklaXx-VE",
            "92":"1dref-ObVfvfOUQ2Zp1-Ke8OR8lmnm8-R",
            "93":"19j89RCPVSqsk_kM870QbUDCFlowAqZsd",
            "94":"1DUBgVb6ml4CrTGDnAhup0GDJ4VTEvcP7",
            "95":"1zWWW96SjyBsrD5_tWr8JvhZLhpBTyY2D",
            "96":"1Yk8eizi0gqDsnyqSAoZB-4cKuoINfHrz",
            "97":"1IzdueRqvPMo5cSi4a2e9hNKViIWbBWGx",
            "98":"1i7k9iTRtM6sAs0SOhQTYNsLjN2VuNFrR",
            "99":"1YHPBpgQYR_FHnAKo8oJQWbMS0NWmSZHE",
            "100":"1c4XflDWGsEqIi3mRlZ3YNh-XgkgRmsO0",
            "101":"1YQ4wtijVUuw2L0WHJa43xKxqFJZAryyQ",
            "102":"1bifT0MQ9G8o52_dLyifBWd76FedeouAx",
            "103":"1tHSgrVFLne9rgWjfZFfT8dlrrQjf4EeJ",
            "104":"1ZNJ1v5zb7Y2mRBqBkCxfzQ8fsi37Psn9",
            "105":"1jUtJbnlF8D-o-u4z1Vewks6Ny_rbdNHG",
            "106":"1dt34lPxelPglFSPyiFcF3MZihOGRasnV",
            "107":"1gts_KqGvB_qWQf4LE4nfj9eEotYIQy7i",
            "108":"1gkGumUSpbcxcnQAKiuZt2nZea7lyGgi4",
            "109":"12mr1zdtW43qxwmxtsu_edCAOz9blXsSa",
            "110":"1KAVWkPorkkoaEzKVhHiyDoJ_XJr9wm7G",
            "111":"1_rKuiq-1g78zT44ddpy85V8NGyqLZjLY",
            "112":"1AH22pko03ggkSF5Fc_-hSqxmkKaVDxm4",
            "113":"1ZN2TO8plqPtLOAc_z2uFstikaBULokwP",
            "114":"1jBWsFiZ9gdTdUl6HjgXSqwNPswaiYTvB",
            "115":"1CPKe8EZPS-QPbpzR_mDQLrWSA1AbHEua",
            "116":"11MfWVKgBMonXdMmz6jYrIZELYX80LKws",
            "117":"1VmS3EQgnAuxJJr9ppQOmd-0v7__vpx69",
            "118":"18qFKOvhQJ5Gb1aUQxegASCM9Z7MsUQEe",
            "119":"1JakKXPN-OCNMWTL-dl_Dnal2nMH0iNtN",
            "120":"1jJZs66R18swYkKH7z6wrbDBDhLpIBv0B",
            "121":"1gjq1ZA-oPdeVgjg6_tWzMoXubzTQUXPg",
            "122":"1H1GhO22ZUQfIRyIDTf1ScQn3tkXDPTLC",
            "123":"11Mh1jEESVCl0Y2Y_Spn2g0WW0SK-WNUw",
            "124":"1eSUI6QRAHGuHeLDNLAQtJCjgS5xjtOiR",
            "125":"1K4AH2vKKl0dtVpxzqw_jjoY_MdPrrCjf",
            "126":"10mUW7UChkSuqemeSw81glMyhKfwcbI8p",
            "127":"1mkKx34uHkQsEimVxGMWZRiM_Pld82d6K",
            "128":"1Z2RSpamIfVVzyTa0GyIi8wTRPeuMCZw4",
            "129":"1lICM914P-9AUCZwwUn5UwmqyCYQWyoCI",
            "130":"126Nz9358x12UbXyQyp_5kQ3kcRkJStB0",
            "131":"1uFZkdwiqPZm8LlQnsjOyy8wf2qaOZUmh",
            "132":"1n-BzmMj2MHBWKblqugUsl7Yi6cyEpfKW",
            "133":"19X47RWAic5_qpeySX_31vWdwxwYUckmq",
            "134":"1Vaeq8qEMMovhjta0JdPPUnB2ejyaGgip",
            "135":"1904pgRjCN2UyM2LwMWR8DkJVataLgH0_",
            "136":"1yw_pno9qYsvcW6cjswQSHwH8EwSyp-rB",
            "137":"1IAoaFM7es8Lm7kcZXjkpC1Z40f7jH5Cl",
            "138":"1Clp3DZZjLbsj-mfRejRVYGm7T-aPdpP-",
            "139":"1IF5vZEMV3QsRLuNx08rCPlxFhtv7GEue",
            "140":"1TiSuAGZwW-RiQEWeiu6s9eNNxkY816Gk",
            "141":"11xCHh1RgQKwD1kxgvs5tHNO0NhTf823j",
            "142":"1gBQ0toiTUATWJVorw9Evz7lgAWJIC6kQ",
            "143":"1BfPxE5_fDkawP9ufvyoifHWc57CqrZ-g",
            "144":"1CDVBNHMDRZVLzX4JuiSPs-Q3FAc0SxKF",
            "145":"1RgMo-lJB3NMG7Gf0uMmUH4QCbCRIns1g",
            "146":"1nnaq12EyMykJ5_yDze3WNddCYSSLVcP3",
            "147":"1OCbFiVLTpGczL_JYKdgUF9BS4qurADC_",
            "148":"1IznHYPoN1Pb30YQfEiGVcqKzKn1EFZGS",
            "149":"1B31P3XGn7INV-SckHJyvtbDrfjQU0y46",
            "150":"1v0-iQkzqXxC2-_iRM9lSFhkfX3C09mSi",
            "151":"1scb-ZdJcWERoF5s1UDYXQGdbGsWoKJJL",
            "152":"1QV_ZYONiNJ5HekIx3rsE4Tmbe8BM3N2x",
            "153":"1suJxsPN75KLwaWgB9Nbp4_OSVE1L1WXf",
            "154":"1WIguSXhf4bWkw8V6OjtnSAd4bx0TIhhB",
            "155":"1BkSID4djusr1YDTFHCTfDeJ6WjfDDsXM",
            "156":"1ZyYP1dlTIgot9ed3R078q4-TXc1LMN8W",
            "157":"1swAnODTfA5T0NQzfVPBeWo_YRI9-gpVc",
            "158":"1AIsAk_03D57LxyyQuEcGohfeO-96sb1Q",
            "159":"1eA3GLzaxB22Kzh2bHE0RqRioGkrJT5NY",
            "160":"19NOBXLZPole1j_qusAA1r0coYEaxk0FS",
            "161":"1e5au9hWurVaBYc_6SXvUHMT6XwzvjykP",
            "162":"1w6F5FYNTTwArSwSSbGIsKHfMjl7Yvywo",
            "163":"1Q8zX9ZDjpu8dA_c5B2f_jSt7-J5Krm7a",
            "164":"1SkIMqwYBdA4RadSyU52OyuAlamxt4nhV",
            "165":"1v4SpjnJaPm6vZzsgy8Yk-v6nlCbYKQKe",
            "166":"1QBpqWEAtyuK1SGSgoOsG_9N78ql_CmhR",
            "167":"1LL12kG0qbFghyl8uluKwAL3xVlIkR6af",
            "168":"11Rp6CZkceg4yBwRrTqK3jXS8oZekRFIs",
            "169":"1DCAj79grB3-UrEaXlXPW60O1anKNUYF9",
            "170":"1hqTixeVVBUngWvnhDQdlE_BMTmi4hbD0",
            "171":"1EWF-IVQroFxDAJBhDMGuF4cPUsfdibyf",
            "172":"1uAyyS4J6ZTgqDTs9scAXx-GD--A9q5Ul",
            "173":"1vwxudGgOr5rWg9QEjk-LiYafMIh12tq3",
            "174":"19kiKtSpToyTXU76f_f5tcLHe_NDkCkgw",
            "175":"1QvMlcEfrgYda3bPkBMYp5M6qdCr6eBnH",
            "176":"1avj9ggb8VkSJn7HElPGsNsQECbZThrda",
            "177":"1wTm8n-PhyC4Z9NZRV12NI9mSXZ9XOrBB",
            "178":"1Vk7gUeFr_5ZIDE00Qsiq4P6RyIqxMdWS",
            "179":"156_C279y0FDXpC-2ACaedFB1fhx1S3sH",
            "180":"1U4aQ3OVEezQ6DtbW45Fy0nreHWsJkD4d",
            "181":"1qggZa-8WstnvkngWC5Uc5Y9P37GeEc_Y",
            "182":"1-5l5e-Jq01uIp79vJBaV-yYJdFk5QPfA",
            "183":"10S27Pmhz0jsmxgjJMTL5usFgU6B3-dSa",
            "184":"1WSM2i0nrjEp89Zn2oNzFXto0KQhjIVaQ",
            "185":"1Lx76N-7Tz4_WL36wEV9f-BTZJ8MSQO-Z",
            "186":"1yfuo591wk4sAEOyeTMbwfOtolBtDPhbP",
            "187":"1ci_2_0M74xjcOSPXyBcOZE5qN5DeFjrx",
            "188":"163sxJI14xqQ7lJPI3BfYvYvo6ulH1r9t",
            "189":"1h-tpOxfvfC6UNt4W-YIAuqfv4xNRjvSs",
            "190":"1SCqy8E4GAhCFzYi7VvLYw3VbIVIQ_DC8",
            "191":"1eaq253TTsNHaQGl51T0f_dnzGZWTX_fL",
            "192":"1pucsxeChcZLjywlM3LhsBuCKdm8dX2u8",
            "193":"1GZULj91d5Y0ldVklAhM1QJz7dfCepJI_",
            "194":"1N9n1jgM9wyYJs9TL3HTkDE2vZBof9fRv",
            "195":"1Ph0UDuPX43nN-ZZ6EX2CPMSiQKvD6tZA",
            "196":"1HsEWp3MV5rjkJetNyiE2OcCY61b7htGP",
            "197":"1fOmA-8TQci9PLlm8ElIqQKwJtxqKXQTJ",
            "198":"13r_PHswlSendQyODtIlsy8T9muzt2118",
            "199":"1Uwl0wgRqMmp_vR1Qh70gi0RYtg2WCXi6",
            "200":"14oYaUfFy4I7goiiv1s2zqCv4gSFul-gl",
            "201":"14Ql0kY2w6kvsPYe0YKl6Va1uVndBT25b",
            "202":"1H7GLF9FNziERU8H9Z7ZXQjrDxaT3cN0T",
            "203":"1MSTVffB5hYmRjjxLETj2oDtQWTv61kSb",
            "204":"1PyaHSc6XMzLRUtXuB7IcFbZEiHZrDCrO",
            "205":"19b6-1TqxzY-b1FltMi7KiulLqP9u0Po4",
            "206":"1AdmRikNSZH-qNb5e0vWjUI-BBIxeOFNv",
            "207":"1o6dcnFj0zX94UPCr3UcsVjoET8lGxTBC",
            "208":"1BIvWTJs0uaGTpxcnen3Mt7QF6_VNKSYE",
            "209":"13OV6lYQG4I1yEJ7xqREW5DsqIhe-RRT5",
            "210":"1A7d3hAHChE8-nCB34xy7fUOVLSydRtHk",
            "211":"1FQMwcu-ztBitcIY_Ta_mv187KrPZv80t",
            "212":"1nryNTVZOYGZAu8moAqiAADEN51zzQ1EU",
            "213":"1xKK5R7r8vmjbIvQ9BSd4TBV5vZfh4Sjn",
            "214":"1XbMoTrxK5R9GlZeu6z9h3PU5Hzqj3S76",
            "215":"19b1Lk7MnH2cT8rKuv4BvTTvcSqS6KnpR",
            "216":"1r2UK5QN4sSx0Eg4tkg_J4a1ZRp8PKfZL",
            "217":"1J0v4MftSip0A0H1g7NjA0dxEUYVcABCO",
            "218":"1-oSAZCKdHpu5wv2UJhPPfaCpRK0qqLgg",
            "219":"1j1qfSmCXxMD2BSkKVRumFkh7s8Ct5H5M",
            "220":"1a2OM9UWvF6BJ_GkgLV8_11dxHecQw8jv",
            "221":"1mWYgjpQ7UTNDwUbFRghVjkblK-_Hgg3y",
            "222":"1B2oS4cqmKBYdx97J2rHyH5cf-b5G6d1w",
            "223":"1FfZDAklf2ceK85mdBYZgh9huPkEbck9X",
            "224":"1obo3OyRZaF7G6KKXOtElKipHva7nFdUF",
            "225":"1JOmta0d5_01JRF7OgOpm8VqmuywDr9N7",
            "226":"1bRBH8bZlqoIqOiN5hxGIbEQDnrzRN4Rf",
            "227":"17QEXmqB0Owah0em6mf3yNsZiiibBVj9M",
            "228":"1FgJ1c0oARzTRAUiuSbUiNI3YfX_qTkLj",
            "229":"1GN82lxjL_hVV8wDzlR4zs_9SJf3qzEuN",
            "230":"1O8OXx7fDa3hso-5rGgM1gDm7nEdg2uET",
            "231":"1isuybQwo3J08mQ-I-gjiALyXIWO2O4hY",
            "232":"1pbhu-tcY2vTzS7HiheicmQma2_VGSwff",
            "233":"1rT3Kq8oNEU-FHaxqEbdhMKiOTrLvfv74",
            "234":"1rfvaQy4hZTF0oOkUSm5gjFWVMO4cDmvb",
            "235":"1yuRA1I7UBf9ag6EQ_QHkE5lderTpgQhL",
            "236":"10uSMUj9cnsUqjNnsT-QCTO8ONWKTYLzj",
            "237":"15CbVJTJ9ZN3kHD2_aMMrxnxcXw13TkTE",
            "238":"1Gziez5DYicUtWzSbjiWPDBW6Wy5AHhRU",
            "239":"1L9bnVsj2S8XMIhnMnYf_uEVcXeeu6hbN",
            "240":"1Ob-xCZc36JMlab5nTq7cx8GvGiTRFF20",
            "241":"1_KfeCraj8TTrIICUHD22EqZnT5hVs1_b",
            "242":"1e9ds_kbVsbLg9ebGIZm4zu1jk8N5A1fk",
            "243":"1B1bJX2cM13ZSaY17D4gNUdvZH9AKtJGY",
            "244":"1egVRS53hRJ8NIakq3gd21-XOmL_pYxnb",
            "245":"1DTYMJ3V56Feo9No1w_7_HsK-gSdadwoe",
            "246":"1_dv0SotpWQH7AxUBspLCOCYJi-CPa4xS",
            "247":"1q1PHJeMVT_kdUWDhMx4x6C7Hlfi8ZwFB",
        },
        
        PANARAN:{
            "1":"1T8YGfzwgQjWuhHA0wix_OAJecCtUqIvz",
            "2":"1hD9fGgLmml8RGvlqBQgKFcUJXT2myr9a",
            "3":"1MOZPcAub8S9eJrv_u5zBb9p2n2dQnMtY",
            "4":"1-atGjy5hF8s8teCTNSjXL3k0QbLx67mQ",
            "5":"1JvwV-SbdTcdyVwQ8Fb6Uc6tmdwt9ZhCf",
            "6":"1KQzimDIXfQmpifh6oFNxlRuTj1gIkeRJ",
            "7":"1OT0Ne_pQcBDs8aiFKNgsxtbd6jzxbhp0",
            "8":"1VFfasQogV2X5I82mY1Dy-A7XoVrU9BRc",
            "9":"1bDtISklxwgDZg42r3SBKG2e-cOOrxdLD",
            "10":"1grggk2pGrjDJwnp4AI238QMOHO321Wcx",
            "11":"1l1Ob8rkqHkc6DmhQVvRpzNFUXQriahI0",
            "12":"1mRLL_CEnkESvXQZzrUvDLGk5Mtc5owSp",
            "13":"1mWPPddOQC8YokuppB_78W9Sb2rT_88_g",
            "14":"1oWh58cqE2KQORMARaBeAC1XOEagZ_DUG",
            "15":"1qBWvXIc9Wa_CuAgXvB2nrzeiwxXJt1Ap",
            "16":"1vtuMWQC3q0Kz7c5fuITX789uS521oG6i",
            "17":"1yXJV7Riin4cIAeF4qpLSdHN3XjXrZJwN",
            "18":"12Ugo4JHeQ-txncbHBOqStB2_9baR_0lD",
            "19":"18-UQFViwFX6Ct7YRnTbcdL8Blsl8HqjZ",
            "20":"197VtsYJU_MFH6lFfVYRGpN6WyGjxaPo2",
            "21":"1A8-OTSAtLCqWcL3DYqwPo009w7ZSG9Xn",
            "22":"1AyGduECypepam6XocPcmjNz6LqFeWw6K",
            "23":"1CZnPRPW0YWek19azrYQYlyEN4dUIPjKq",
            "24":"1Gsx3HVNYQuV52BCz4QM9NRrbad2Zi0OO",
            "25":"1NZYVLLXiXgo8pHIRkYliQAG7L0Tcs-AH",
            "26":"1U1KuicT3IA9ZqPkCKTR8CfTtQBYVJQSG",
            "27":"1eQ_Fr07QN8JhxqHwbw4kbcgaxHJggPZs",
            "28":"1jIPkIt_6xm0uG8xEUf3fQi2Ki21KMbn_",
            "29":"1r4Jk2w2TEiLa2ci-l8CGixREPvgRRUY5",
            "30":"1s0PCbEJ9ZrV4gJLGeQAADUYut6a4M0Ja",
            "31":"1u3fZVQyKiA7wpwLjH5xE43tAh-SC3Gr-",
            "32":"1uA3tj-nmPfVnJZlmt1vbnWKJsvCIfN7E",
            "33":"1vK3b64Oyrj_gEM3cRSx565AIdd7ab8Jw",
            "34":"1zfRbXoYYXnMk1KxbilM4niXkQo9PDn3L",
            "35":"11fqdB3XGSnHrFCzMIO9E8iPvwkQ1S2y6",
            "36":"196twv9elJdUpmNih3TtEM6ECuTey-mYY",
            "37":"1A6aoLjEjxQySQbJ6Q_MXpeA2rMJu1Jn_",
            "38":"1IhaucvmDwyjP7I_Nc6WgfD7As3nouWrk",
            "39":"1JIBoIGD1PwXRM0ofgHlJKJ35pSeZXQNM",
            "40":"1JaAYg7RCHKW8zLnXjPFHDUSINulK0H_C",
            "41":"1Jfzl-ZEWBbrcqiAZRsS8L4jHdjF5NZnW",
            "42":"1K9ukMGddj8g6Vkcl-CGBXqVlloeVc3l8",
            "43":"1MZ9KWixi-8YTpAk0B7nrNtyLXU9R1Bma",
            "44":"1NERkX-Td-NFWv1MevfQhWHc7wEh0WkOB",
            "45":"1NFo4YG1GDh5vJbDV23FyCNZFec7932I9",
            "46":"1W8b3EJfbyvxnszWSXjl_urxFtD_VIGGI",
            "47":"1Z4yMX2FY6t9jMByrfg6O3DrWm7AkmBfq",
            "48":"1aZqaAsEk67fsho751KIEIfvN4T4le9e0",
            "49":"1dcNo79hVW_oNou_ebfADqnZ9_PkGbnYg",
            "50":"1jcjYXdzlx_d28JzJva85td8Zx2CZ_tt0",
            "51":"1sXwkO4mpQ39WexXrz-snfshgH0Gg0B8u",
            "52":"1wqMx6kv1p7Az02g1buQE2QTa3pJnPL_5",
            "53":"1yccuFaMIA-O8cLg79hIHk8d_RuDi6k0g",
            "54":"1-uXGOL7AXEPvbi-q9tBvFSb_H5XT1yec",
            "55":"11YPRuh8yf_aSVBBBXtK1lgyXynr8Evss",
            "56":"13DJisbAqmIORViWO20Uim_KdT8ie7iph",
            "57":"13O3MChF1HPKtYU-fjbOdSf1PKEJbqi9n",
            "58":"14ZeUMuu5YLGETcmzPk5LnK2h8GdpSKmQ",
            "59":"19PD-iY09jYqDscxadA5Mglh6zmd9g7Zm",
            "60":"1ADurqP69EhuTOQmYxTGOyV3bc20o7KAN",
            "61":"1H7zPmKeY-0NZNjcuCMQpQpQeUodhnz1b",
            "62":"1MFq_ustJyRvmSO7PlHO29UkI6UxK4mhV",
            "63":"1SMKRLAJ3Ff7BSTuQAf0NxDdQqWxUad-r",
            "64":"1VoalVF4mcwF_RoY8kmIg5COA5YU3clUs",
            "65":"1iQTBaVz10_dIARZvwotYz1lb8ZM4rKdu",
            "66":"1jn9auuEBAie5smbIsVBbcCU9_cu46UL0",
            "67":"1n2bYGGSw68oj7nl0t3PhN52ij9NU-EJb",
            "68":"1oU5SdpPJ3uHj0iCYYhxpnQI_Rz-MchIU",
            "69":"1szARQ0w3unCTP3gy2d6H_O-1FkuD8Hpt",
            "70":"1wkiCGfUQ7U0edN1SyEOYAT9dn09vHXUK",
            "71":"1xYeFJQtu0GkaLIbXbtzw6WQgnRSChK7o",
            "72":"1-Go56S5aJxRN3l90VU4o57ZcsZQ4975I",
            "73":"13RTOSBP8SmPsLXaU__vtty9Gy44yrMtL",
            "74":"14ytRTV5dhpI7OFxSpgdlV6X86arv3Y4s",
            "75":"15O0DV7eEN-eY-mmZdl2iQ_E2fvu_TAOS",
            "76":"1BatGGQXt4SAvVBej7jVgSkAIcBKOurMl",
            "77":"1FfwbghvZIy0xga75Gcg91hdUdwuMBhi1",
            "78":"1I1ONNi5J_YjS0FbzmqS8d6FVIbQdhvdm",
            "79":"1IxR4UoHLpeuFI8m9tVwggklOVpR1ZlSe",
            "80":"1dQXL8UO-480Eu5BSTURgzaCCya_CO2Sc",
            "81":"1gBdBPSrImu9Rofj6gGmGGc5fEc4C0HVr",
            "82":"1gVy42djouO5pwQ7CoyjrWEnbWZMSjHsH",
            "83":"1j9yCgbqyop6vCxx-NNjohqRHeHtqLmZ3",
            "84":"1jnixAUl0XRiXALhhnMCHZt-yDnYrZpay",
            "85":"1pX8Sldp__kHWe-eDESB9ONFTNZGtw0tG",
            "86":"1rHI8O_-slekjHxfC7W25XSyz49quhneL",
            "87":"1wUAoOMiN69N6RtjfBjHzmo40FStmQL0G",
            "88":"1wcKk2QzlvOj7neCSkEioz2EzyXtdlbxk",
            "89":"1yum7fNUUZuuBfhJuDMSNInoM0Gk2AQq8",
            "90":"12yMMKUB4C_7Gb2athH9GoxOfPIYaBr3L",
            "91":"17BoGWJ73A2-oAFUjWyB1vzTKLwXXFVKP",
            "92":"1BrBQB9QPqnGuAWR0aSrwzCcB810yu_op",
            "93":"1HW2bd_mO_XDFKEG3EMfQEVrCH6t3is1b",
            "94":"1KilH8a1i1wvPif36QYPGz288oobLV5xy",
            "95":"1MI5q_HF928xs8esuHN3NvS0iWS_8dNXh",
            "96":"1WJZknIqjIOy4u7sLmEzrznKnTqEg0guy",
            "97":"1Z1r1IBwMR4WJWELBHFnAUyk4Obs7RKl1",
            "98":"1ZgnTURxQfIP9pnw90WDIrGU8-5w7oTnX",
            "99":"1oQmQRZPIhhW0tM0Ijzl-xnpSEUpkHWCD",
            "100":"1qnOcVo2mN9dM8suiofVNZAQdJc_GS_q9",
            "101":"1AaSaHuNo2W14QTh11RCdag3BHQS4j0FE",
            "102":"1Aw0_qY4wlt1J-twQyj_aBIsB1au_6uH-",
            "103":"1Diqv2EeQBYbrdZkCYPC9XZ3GVg69R2IO",
            "104":"1E3gIEkYd-xLjijKdnToJDpIh1099m1XR",
            "105":"1Fk8fl_8yC8Ix_-7r6w_WWqznVuOt-VFh",
            "106":"1LLDbjbhx_LuqFJAkBIyDQDLFHRljFIk1",
            "107":"1UI8QDeVAeKEj8JH8Oi5dvojf-A6v-381",
            "108":"1gNmWIr3J6TVmfNwsWXCqOH22OUXPOhUn",
            "109":"1kxtprjK6o2AGca2QrdpUJIPQINCHkzO2",
            "110":"1-CXJjr-RWbv8g6H1hg1WgesK7Cr6xLZ9",
            "111":"10MnTWXLSIbvcAtOBatqqmpJR8r130r_i",
            "112":"14l1sO4FGh3O5mneehMCf8qnfF3xBDwbg",
            "113":"15vZqY9_VJyM4wIGzQqKjn5AWUu9-hrmj",
            "114":"16ovZxV1Cf4WU1o_9IWnsnnrWI3ZLOTWc",
            "115":"19T7oiIBITdief2_XVx6jGo6v_yTIUWfX",
            "116":"1FOKhnJWfN6zBPjZW3wnKn18yySErBQIo",
            "117":"1KS0kENmdVaoJwTvGjQty8xBOrXvdUzdZ",
            "118":"1KplV-vtogmOyT1qjZKp5uL60xtccrJAT",
            "119":"1NOzGONkJ5KrHYTn9SeUagjZEfnu3yaPt",
            "120":"1PXxxI1h8HwysqonTGK5t7a1QDBgTM-0l",
            "121":"1RNiQINgkLz3ydaQmwpdazpoiqoy977o1",
            "122":"1UAVHqCXHQHR7S8KFtfmm9_WXlK26P_9l",
            "123":"1aEMw8PzqNV0rsFn9SExtFBOS5c4R-Z5I",
            "124":"1iSprOlgXZe9Qt0IvC-i8sJX8zr7LxFFG",
            "125":"1kkAU1D5MFAIR4WF8PUucFaLiRYEKovGr",
            "126":"1mE8UpE0-jXapwFD6gwtjD4IqJs3cbABC",
            "127":"1v-JlQ3AcHO9tEpn4ZDI3Qg7oRhkGd6hn",
            "128":"1wmamjPqYMOkJZPtxxLTFbZJK0DjA6aZ1",
            "129":"1-Zb1RTKnCtzMMKNW6Ks-UkV_GDrXi5E-",
            "130":"10mb0dQjkrZGev7NI0XfEp_a51NjrTqNu",
            "131":"14RYOcFwDZ9gRJA7TBSIX0Hbu3TcOQL0n",
            "132":"1AgCudmt4uTJ-yUJspufOYJufOJIuxV2y",
            "133":"1CQ_Br5n6ICOtoBDfaESeKpa_jgvXG93G",
            "134":"1FHeyRGqp8tuIxqLqfeBPdLfobUG81Eos",
            "135":"1G305i08hWcP7PCGFBZRUug2j58m4YzVB",
            "136":"1KwxGJS3FRbnmGE3WSefqy-faid9E2ZLT",
            "137":"1TTeOf82tsQATCdzdWXvVM93Kqg1R57nu",
            "138":"1_SOFLc946nUVJk2OSBor9NiSMXDKgr_R",
            "139":"1_dX_HBLvUiJGpCSOB3mtnhCEj9aB3Kdb",
            "140":"1eAguAi7rMPcSOernrBAtBWN7kJwgGrw4",
            "141":"1et4yQ7DygxvYNiiJZSaxQ_SboO2Vkv6S",
            "142":"1ibSeaiPtsZp64_DIvq607aKsBDndT2cq",
            "143":"1ppMSs7RAcpPL-zi86MEs2ZF6KGeaUmu2",
            "144":"1tjUAhnMJpo9j3VmjDZrDgjJfjw4XijV1",
            "145":"1vw7O55xSoY5c_keReTKK3RakAgqKYyEm",
            "146":"11q7BEfXLGXIGQ-DKnLN8rSyleiUjf7Lg",
            "147":"12tQedZIMjyfVWgepgMRkoX95ftnmzdP_",
            "148":"14s4hzBccrqE8CK5UZzxMc3KBOO-O8Hg6",
            "149":"1Bq1WGO7Iy8r889TI8QL-uu_I188GNHDQ",
            "150":"1DRGmX8DJUI42AUA-vW7BhDZAa96bUTpe",
            "151":"1JHnphH1_qmvTea-XhFkuQZE49HCXEnnJ",
            "152":"1Ku079V-shfBJw0T2EUrxQyvHd74f-72E",
            "153":"1P5qE-PjJHMw5ctxyRaeTqO-r142UXTnI",
            "154":"1Pc_TUlCQ-ltVUI3dBOzljbeQaNhRZasq",
            "155":"1Rt5GQHAZ9wKmjbu_2QeCbTV5u05CPR8a",
            "156":"1V_XkckpAFmgqsuWYV8eWw4GL4oWzF9bL",
            "157":"1aW7FXgC4mzz-h5izXxVfZWJnqzOPSFpj",
            "158":"1eEHHHP90DlkUAPQ66MEWqeq-laS6DgVD",
            "159":"1gfMvuCe19w178YNT4luR0d4AZ20DObgs",
            "160":"1nB7WR2gw-AiY24mfhSouh36p7QUK5INJ",
            "161":"1r22BDa2U5RkgurmydL1ciGRGY8PfDgAP",
            "162":"1t4b2OuIBXBAqAcYGi8XNU39xdMSLlPdj",
            "163":"1Iq5kHAEvUM7HtA5ZXMKlqiL8siPXd26H",
            "164":"1XB4rq8j7LuzRiwj3fLDQoX8ikKX5Xgop",
            "165":"1jcHgxePvzNiy8BLlakH4k6IbpaIXvG3S",
            "166":"1zk3mAOg30d3DMTH2lwDWPrX3Ef1lMFO2",
            "167":"1s7Ypv7bLTEgn4H6KDFZn2U_2xYJUMzNS",
            "168":"1HCotY7n5G8AWDArvfwovHzu_g052_0SN",
            "169":"1Xr0yJ5znrX4LkiV7WRMHsOwNTaffGXrT",
        },
        
        DUMAI:{
           "1":"1Oecw4AI8RV5A-nYBpfiFALO3m_yG4YhE",
            "2":"1nWH9oNYhOBLa9xB4_jyy6-i51Yt05Ywt",
            "3":"1QmRoRK3pwyLACqMMVNQG6NKeEl3YRRDT",
            "4":"12cHYwkXCSKeteXdUBdWb4skVliTf2vRT",
            "5":"1WdcbMYZVd79NrSShL30-C392CAgo6iYk",
            "6":"1Y0qqun2nwtM_7WaQeKtjSC5EpBoRN7tk",
            "7":"1FoOjOV44WqLI4msJNS0YU_hKJ8j5dtFN",
            "8":"1hJYgK1ICqc-R-XT6jo1YiTT_qP1Dueu8",
            "9":"1uNAZszLKJ122bik_5qFC2EXdft4NGTvv",
            "10":"1ek9MsVuk7rc5DXQCQHCDYJyxwT_mlSQC",
            "11":"1H3JZX4SemD-oVBuodG8G59qaZiNb6GAi",
            "12":"1YxpVVK2FHhyLCGDAbS4Fckhf5VVUFDLn",
            "13":"1pF-m_5FCGWeVGVRXSEMxID_4FCrc2eRc",
            "14":"1SXp24ym5texcBIhqQn71dqFisCmq_8er",
            "15":"145QekkXB1R1waVMSBthIx5h5YPNZdkNa",
            "16":"1ws9QOEqNFJLSscJHZiRkHwjUzLcMwgGX",
            "17":"1EnNwJr3e2Vbug2X32rdRKtckf5ees6kg",
            "18":"1opcS_gN8CYeAya5NP7se9sGsPgMxlVFb",
            "19":"1PacrWwKaKXlVvx2j5v6yR6VzYJ2O5kEl",
            "20":"1zqA2LlqkoNXQGPNCnksn-SUDw89c0bDO",
            "21":"1625xpYZRtel_EhLtZDrz_IiL62EFJfZs",
            "22":"1_ZLPCzxwP8fLkdAnu2xBWD4LzGgnwgtY",
            "23":"1Ji9pnSmjDghPp3eqPpfOY1vnQZEAS2qp",
            "24":"12-ByV8VMZUP8dsXDvp_mXMDFJqtusAWp",
            "25":"1UrK_hKw52JXYuzQexTbqXZrItMZcnxkV",
            "26":"1iU2cMUPLFBhELECWsT-FCLtlewWQlRai",
            "27":"1lBBkweE2AtqjyWyV4ipr0MFzKUCyCoja",
            "28":"1eZmo4tnhhnhio1sdsPPdsvHksy2wCJgW",
            "29":"1lDR5ONISYhrfgXcVU8FMuApmhQiRWJhQ",
            "30":"1iJgux4Diw2Zjqjp4YNZ9l279JJNd8o1n",
            "31":"1hVSdCsIPu8E4jguiKQ7c_LPxYIWo52AV",
            "32":"1WiwfGunf47h1tu7ux7e4NiVWXdjajxKV",
            "33":"145EJEccV9pbgblGldMLwqLpYZy3PiAR2",
            "34":"1GpDF09HNZnq84JjrODv7BTPXJe5_KYjB",
            "35":"188F7crJgCNjocgwRqq2zMMX6ariI0-E4",
            "36":"1ZwQEIAvxHGaIzm8W84nqjQqj_rdabyVi",
            "37":"1JdiAQzRiEZm4elFPEmve3jDPf6Azuwag",
            "38":"1JtECrRsV-9vfASVAliVYVp8r7RuYwVw_",
            "39":"1Xlah_-etwEq5ZL-ATbQTJMRtIG-mQ6VF",
            "40":"1CEol1jFUvF7tSyBpb44JeFsRGxsMFuUE",
            "41":"1o-xvQBfiC-gKJgVjG6nlwYi-p1cGoYCW",
            "42":"1Y3QaCE6BJJYvtGN7XaS8Z8nkcPXEaZqE",
            "43":"1SMFrwc7Pl5spq9-8CCqQXES6ROQKhfmv",
            "44":"1hXjYoXpA6fy964yLAQ0xH8AWLqGioCsU",
            "45":"1rq-HOF-_iHEXeo2EFCWKEZF0cQrh5YIB",
            "46":"1lN_0BnCmNkjlw_uPsy1Ms9np73Mn_62L",
            "47":"10CwRZYU6usIcPZS11YHiFy29QCTGnzyO",
            "48":"1yPYwcZJGkTfOC5ZKrlNlAK54TJrbLiza",
            "49":"1HV_uWqDJuVdUKNfg-nn1EtGQJLkp0u_i",
            "50":"1hVWQPdNL0pGtKGOPLRbHOHunNaPxguxs",
            "51":"1SB2c8OxE0zY7sGhjhO2uRsFBZF50p7EP",
            "52":"1pF8TLrLkor-dRdN4gnpl9Eb6goX1drcQ",
            "53":"1TM1mIoTuL6YxuP37Ut9dHG3znyRjXItp",
            "54":"1LyfBkA_nnwMCvnUSdM5TxqSjLgtGo-Nb",
            "55":"1jrCUiNQSISqpuEZY0qR41B27OUX8DIx7",
            "56":"1f0UIz92SXQTTqOGHHchU06r8Vj3c52OK",
            "57":"10L3iSvWZy3GqEzdzk7QdhbBdbvYKWdU-",
            "58":"1Np1bAAQyVz-3QwqcfcV3szM_bNdy75-U",
            "59":"1digqGE1CUtWpj1BxwgxgQXW4EuuOgHKd",
            "60":"15eRkq5QqKQ5qlgAd8gqneNvd-OOY6gS2",
            "61":"1uTKaOedT5o7qTu8vRYrK2poYFxamePcY",
            "62":"1is2JOJqArg_dI_suF8e3_RYugK46H8Iv",
            "63":"1bxTAdYms4omWIiYoEo8VIc_z1jZw3eAt",
            "64":"1QELsToQ123GFyFSCdfbE2ziXlfaYg0Nv",
            "65":"15uo1ToK_uAJD2GQ2uAJSjhGE_WbNnoYj",
            "66":"1j70DM70XPKYCv3GHkf95GuVYrFQIevw4",
            "67":"1JW94jBIQacaIIeQqOzkk0stcrMNdKgCH",
            "68":"17yrFMKl5pjbUpcVwW9WjufqdOPD8OZmF",
            "69":"1I7VXJiPXwZHnDtKOuO01y-JEFQBFKitY",
            "70":"13v_4NKq2YXyDayTex9501k1at__mSBq_",
            "71":"17EoEbGFwSB9sRFZMOlHW1ZMR02nkrmeT",
            "72":"1E4w20miaBUigiSvFMyw10jdwoaBXeTMS",
            "73":"14h6LShJWM0e0mOhhtc21e1j-LGaaSRIi",
            "74":"1FVuDO2g8B8vl0t_4zyz0VLA_b2V_AyHB",
            "75":"1XBJgeGhMq-AG_Xh9FANh9jvE1WgfZncZ",
            "76":"1DqlizxvSjZVahnxRbJElqKXgfu6O6WNJ",
            "77":"123rYJb28oEitb3xmihLr6QgnIwZjIGWT",
            "78":"1CidEOScUZTIWjq8WmBayga0yGjGAZosW",
            "79":"1JbDjniRt2nMO5dv102e1-XHA6nSbEB-I",
            "80":"13MrjegenRRW2BAL9FyM-i2IXaT8s7j7E",
            "81":"11WZZR8IT1KrS3QTS0SSxGW36yXKYHVdl",
            "82":"1HDIF00ZPFAwChP0B5ZcS1REvsCdlqust",
            "83":"1_FwUknxPzDJI3_QVRiZS8x9jf3AwXidJ",
            "84":"1rVg88zuEmMskkJz8YxMARyjLck6Uj1nz",
            "85":"1hDOlOfwgYkm-L7ruWlpUi11HkprMSRiX",
            "86":"1i9gt71f16Y05IUGSqenCqD6X-4Tun3Y_",
            "87":"1TOQ_a-Q1-cak4846YMTRBwNBJRmV2C78",
            "88":"1_L2lN-NsUzmeFxZ8C3RZ1IGaiNPTYea_",
            "89":"1l7ciERxkz7Rv8ypRZDL_gIhVtpqt_Feb",
            "90":"11MnZ2o9Zbv6oZ4nZA93dCtNzcQ51VkVS",
            "91":"1LdrwXx48UN6NijSqQ2K8YWRJTVSb4N0e",
            "92":"1bqMbL3tvf-4CKrQ71oIbKqUsf1tqsuIp",
            "93":"1kTHSj_lgE-3qZgc0mU16QLS_ArgaXCcT",
            "94":"1QMOY8pmsyBbehHBuldlokX2_Ieq-p_Fi",
            "95":"1gBWxItF_S8932UgE86u9qwXk6nAo0y6p",
            "96":"1LpPaSc85FTRIvOBfw8n6G7UFUAMPDU8L",
            "97":"1EmQ5IRT5hYd8htGGTfUc5lT9kV0sCkrp",
            "98":"1S9zCivfrUz9aAwKFGAxFvVR9Mnq2LCF0",
            "99":"1NKkhDLhY27EX512b_vf8AHkYzmPrfLpP",
            "100":"1Sn9aMmNcgzsFocCuvMYRPOeE8QQxGj_L",
            "101":"1BIWEAxIItZQUH7jD2YQvMaMcvvxpwUaz",
            "102":"1KPWP0PbgOu9PWWqGe1Yf7St1oZTHfVpS",
            "103":"1yq3lp0SKpXtjcOtXnETgISLySLt2iDAs",
            "104":"1AhqTeqp_WNsKsqFiImDBWLnb3fo5d03n",
            "105":"1jUyFdvn_JpM5dfQKkcEG63kQpAopjLfG",
            "106":"1Dm8RXwthRnokWNKkWJD8umZS0vg1TzHK",
            "107":"1JTc7rrF4gDuNr0kH7kHnV5ko7Vn_h9Yz",
            "108":"1lvQUhxwQxOUTP71k5AtkYr9FyXTQ58oc",
            "109":"1TDXJ8lFSgBM55jSzNlssTKunpRWq-hFF",
            "110":"1CYbpy6ojKrcs45isOKgKkqncuQLik-Nz",
            "111":"1cXdnGqAQ6tSkWZKNCgOZHIQK08vXWyMV",
            "112":"1PEsSQs6Ayaa4E6rBePn1gPyntYfQHWuQ",
            "113":"1V5hC2BS6mu6tClCYZ7ZcbZWhpytiv7eA",
            "114":"19RzXUEILVZWRbCAzIM3vpn-Y3BITtdw1",
            "115":"1GBkIS9jBfwHFhnV_mPYB2ngiDNfGraJY",
            "116":"1h_GgTvJgvndiWd1UqBxhzChhz8ct9lWX",
            "117":"1qX8Yu7p7HpLBMOsyQ0J93CINdXyIXLdT",
            "118":"1_PvYaOfpT8BvDlTaYqLT1-Qoudyfq9aL",
            "119":"1JmNWP59mfPQebco0deXjNLXQsdSgLauG",
            "120":"1R3heyaPxl2CpGNDFZQtqsaV2coAMEHON",
            "121":"1q6T_vbgauoydGavd6urd3m0BWO251Nt5",
            "122":"1lkxevKdQt_1A-6a0Cnxq22j22kVEzFF3",
            "123":"1KPzr31AHeOiz6UnlrOjHTTS1pPss9kK7",
            "124":"11SJVysMPnXlzrPQDDJL5TDlu-KdA5IKq",
            "125":"11rXmHXzoRalAfZtDyy1v2UBufiY9z9tD",
            "126":"1HXfwdmY-NBqeR_eyfbx9vtz5rJj1FI0E",
            "127":"1Z1lltYpbggZnriqEqtAFaODKebLG8D0x",
            "128":"1UKnWLiUJdCHQpH4Kl4F5W98keCez888p",
            "129":"1CezDjObad8yy5oR6efhE83x7oN0D4hct",
            "130":"1kD6U_4dMZH_Wy5jY1B7n-kOI33Q6U_AR",
            "131":"10GdsxOcnmOSGsNx_IIch7KWYnw5YQTvO",
            "132":"179xhn3tt4r0Nr_B-Op4vwZF4wJ-w002Q",
            "133":"1RuWIx8Ja37M5BnOcViPlJxlr_QP52XiY",
            "134":"1zdboWb80wBQYtZvoRXc1It_B5Yqes68b",
            "135":"10HxwZQUElKjd9pRZzYvg828q5AGlvKLZ",
            "136":"1OwJ6RtgY_lUx8aRKG50HpqXNZzkqd-jM",
            "137":"1kuc1MPXf6Ghb_UgIVbd8GILe8I5m7mDW",
            "138":"1CVlyoU7K1f8lULjyIxVDKu-p-MANc8ki",
            "139":"14LocclgAk7zOY4_n-cIk_eoJEQMXk1BE",
            "140":"15CqpQ1mh39FXeYQpM8nJsr6y1NBjjczR",
            "141":"1cuJAo5Ns1A36pL0Be6xYWxDyt-V98QNl",
            "142":"1qZr08Nz6NziVqJadz0lyZPRTqxcehCfc",
            "143":"1kACapeiZTF65wyKpkhAXE5oF9u-vHXho",
            "144":"1Le55CTx7L0J408PHwZw5sC-0zLciTQJN",
            "145":"14rjxwQTRnNSgJ5ZAOSq5fi5eZfcg5-LG",
            "146":"1uhayYXIVBiSN5k5NWcBdiLTpstyrh7TT",
            "147":"1Ky6JlbmhGtcDLm3maLA3Ts79XmlfDcOo",
            "148":"11FUMy0WmH2T8A_C07vjEqrkYVuT4eMp0",
            "149":"1toCO3wbQaHMLicNGMli31JAGZzMIJqCB",
            "150":"1BdN3rKXX3xezy5NPuTThMFSOUHiJLa9G",
            "151":"16CqFLH3TjfVF1pVwmDKUPBLiX686E_eg",
            "152":"1WkwwUyU3_Ez_IMQyHwDbNui6ElTBFQUE",
            "153":"1nbfqifWgGwSZYWxcrdnmPTmmtH0v4vOG",
            "154":"13DP_U6OophnH4whLO8BowEbC_9FbaCru",
            "155":"1GWIrxW8vlqz8ODlJgmop3UCLzBDxSabF",
            "156":"1Pz6CfUFQdjDOqizMjnht3XfjTcdEzICi",
            "157":"1e08o-zdSwOMgmUzrdMz62ouk6Fc3Hav-",
            "158":"1j-kj24iVFOPaJsPlJu9Z1Nkb4O6Cnu03",
            "159":"1hX_USAtPK8cFuDc0vQhAjVhs7UncEFba",
            "160":"1BpwYMAy6VIon7ZEUiid3Dgy6GoLK_Uyz",
            "161":"1M6eaM0K0M9eGO59Vu_f5QIK9TmsWMCUm",
            "162":"1ysgbaxEEUWlwWn6Zh6hk0fHaTNVCEOy0",
            "163":"1ttxLWDQZQ3xV6ap73w0cVPz7LUbVH6gU",
            "164":"16oVCL4JapaXJy5M3LrYV3ry3A-l65ZYF",
            "165":"1V7K-PVMNiauChcMXBS8-jfGCIdTCIMNd",
            "166":"1A8XCCxz9YF6BmmFRTDCZen-bhXnOZpMQ",
            "167":"1aPvB5V7ZkJJy-zUDvfgtwtjMK1iojvk1",
            "168":"103ktqzkqugrPHGcxYY-zNgjPdfpucixv",
            "169":"1VRfVDLxPMdALoe-q51zTu1eLvFTeCf_3",
            "170":"1JxBIiY6d3T9Kd8VgdcnDg3E6-641hUD7",
            "171":"1a20nK1cWif5izSJlUGi89xcq0o7S-bhn",
            "172":"1wXW1-WrU8pLCrK6iv1l2Q7vDEG3hfQfd",
            "173":"1kvhkks80mNxE-fIZJh3KeXfrgHQxppQF",
            "174":"1I7gLIOESJ5slw-6HC8DmivxJFfr0V1C9",
            "175":"1-xNdgTyvzNgnPyWX08TWx0QFL5iRfgi9",
            "176":"1Wo53vtIitKST9_g96mTOd33cbEmYzzbg",
            "177":"1Aubs0uo4l-ncy34Ec4pkDAVi5kiGxDHe",
            "178":"1O_b38-zk6Wt4JAxBd1BUvPfDJIpbMcz6",
            "179":"1O5kbKau1Av9K12TJF8OFx3Vss-G6KnVh",
            "180":"11yFkYV4YgFRgTdpINtl9V5GI9i_hHCxg",
            "181":"1VPhPeP75tt_9nvEW-_ulQK2-Q4xfUH2w",
            "182":"15i1lHQUGq_a31ipWU1oMtdZNtIrBDidq",
            "183":"1z0c8E9A77kNBD4Sm2_ceD-AA6z_0xL18",
            "184":"117LTd2JWEpmcCsmxTcEAHoB9X3Pn24ef",
            "185":"1e9XcYu_2WjwHEkLj4O71AXRlNQmZBRE7",
            "186":"1O-v70Ccc1fb8oTHmK-sMOWfajeONFnC1",
            "187":"1vS8FpCqScI605ikr3bAy3i5TJPcYppcr",
            "188":"1dg9dcyUMUDSwu3ORJEWwDEfqn9AdvyKR",
            "189":"1X5oYhMw3JuQOfa5RlhSt-aLX5l_pzCp5",
            "190":"1Rn19IKsYg0-Lq3QCUx8Awo3Yb-p8SPm-",
            "191":"1JSumgwjPdXuvgTi-vbaheQWvcJx7dRfd",
            "192":"1KPnbEA1MU9s-920ZAMzvLNOwpQwHMIEK",
            "193":"1nLuvTDFvj_OwNdxrwHulezkSouYs0gVP",
            "194":"1uBTJP1v6rtHW58R7AkBWtujSXGWXx5A1",
            "195":"1v1InAuMvIuDOvGm1lf7F18QxY5G6vv4s",
            "196":"1usxXgYF71xj25rJezX-ot9mKnFqi5wVn",
            "197":"1_Tqk4D-6QoAUDLFf4QHdOBF6mxtfBC68",
            "198":"1_6V7gtqVrqjNV01bzGQwKT4wyB949tZ6",
            "199":"1Zo8UJMuVj2qIrCR6uspyNqCRARgzNmhB",
            "200":"1rOiJEKEH3aUfZtjrujj2QqRoXw98mJyP",
            "201":"1aN0CXgPrzyRMdYxYf5LeKzMP_piqK4kx",
            "202":"1_wns5TXfldAfaBn9eb85a5ghFnJLSVox",
            "203":"1suwT0IHkvFg-48V1fXAboUFinO14__Nk",
            "204":"1mRnUgq0jnjL93OznN0yDmPLCkG4q4wZD",
            "205":"1lRveGSygLvtYaPKVbreh5ZlswT8R_OFP",
            "206":"1N1FSPTKjt-ogSXlojc6jkaiWZSxQPi5D",
            "207":"17y8ZfyaODyjV4xZZvmvthbYyJYBWPBv_",
            "208":"1n2ML0caILP2nJrUT1mRf4EDcGqr9Y0qa",
            "209":"1vGytH0yJKcRQdBFYEmxBS_ae1bOTdiCN",
            "210":"1aPI3mq9Ndg5ILRa8LbPHUDlmsDL9YH80",
            "211":"1a_lMKXNatyKpqYvZK_cfDtoQ-euuHo0q",
            "212":"118VEKRGaWAUJqegcCyjrHVNUknLA3Yzk",
            "213":"1tGYdwc08MaMm9MSpyR_4GtZKPfCOEe8o",
            "214":"1JzJGvLFjrN0z4pH6QfynrX2pG711f3Yv",
            "215":"13oWnqAmg-AgqTADxrb6kA7nfP_sXSgDJ",
            "216":"1oR10oeY9egIiIdoe6EoSOV_Zym8qloKL",
            "217":"12r00UMUrkyxFOUdZA3zyT08dxuqxW47R",
            "218":"19sMPJi_qTpagqZ_jmKFbNCexdYvMzfbV",
            "219":"1b19vpcA4qAlyumJzb559ZnBqVG0tVSyT",
            "220":"12WdJBd2zAqAsC4rSi-14qaHwZoEGNIYV",
            "221":"1BvkJevxlV6aT4m69usudRQ9Et2_kUbkC",
            "222":"1DHs1gODrNoPjChqJ9L27haKMTkvDZ-_a",
            "223":"1LS5-3buzdoaf3Fxyxl6NWrW_3Ug2SCpS",
            "224":"126mKVhJ8-_V813iHqqTuUuslYbdsSYKa",
            "225":"14Zij4z1O80_XTx-APYeZF0LlZ9RTbsTP",
            "226":"15_bJ6VlbuAI4EgkkEj3LPLDNStJPvwHt",
            "227":"1q_yo8qYekhE59hMQygzP0raN2Q3oCP1h",
            "228":"1aU3zUUhNSy2FUNewhw7X1iwaWZIptFpz",
            "229":"1BJFdaP0UKxuiHJJbRKU_ucBXzKopABSz",
            "230":"1ea23DtkAiN3GDbszSrtd8XPhlpPQGNpj",
            "231":"1KuFJ2hLFbgn_pTRRAuijyS70V18STHSB",
            "232":"1PilHV9U7Fig7t4g7zQ6VkUS1d-oK7be-",
            "233":"1_6F-FTSVqlxfhOjEDaiNjChhxEvvCgzf",
            "234":"1kr1EHVINGAiDw-6PMTxhAtCtEvfEm1rI",
            "235":"1O7KblOg86K3d_AgXLHoPihsV68Mh6Wgn",
            "236":"1Y8TLUfQyJEwpjugbcfJyZFUcSjH1mpb7",
            "237":"1hQ4O-16UD_bjDHasu7nI35AnbE77eeV1",
            "238":"1lNVRKbua66QWRAnyKQH5DRSC-_gzaReO",
            "239":"15MIM_-KpZSbT23F1nzrkFWxQFtph8E8b",
            "240":"1BKWj6AxOFxRdHjIL55qAyKFNq3Or38GF",
            "241":"1E5Je8unqR_hjq63pNKFTJ6fcMvkGH6GW",
            "242":"1FWKwpXy1iXtF7IEzP0nHV0d_tcr464ct",
            "243":"1Gn40F4fKZHVCqKW39OqcFFpI1cBEZYjN",
            "244":"1Y1wIP1meZa7rrVJNWNpS-b1XVidWon6-",
            "245":"1iHurHfAcva2_ivzRVKz3jJ7jMefrzJ3_",
            "246":"1tBo_rvunYZfjFmpkuzfUrtV201Ul0onx",
            "247":"139yKwadHI1h5O98qMBiKwiIcR19EBs8h",
            "248":"19R6Z5R7QfGLBEz0FAVj3QUW7sGiKiuB1",
            "249":"1gS-aUpJN6XqghnuJuBvTnGqL9xIHuIEO",
            "250":"1gj06ZVFGaa7ADUtmz4-sJ_BWLhDq12Eg",
            "251":"1-shqHtyJGxTcU1NDlReF1HKCnCws6FjL",
            "252":"118jB4eajLC6fnq85by5JU3Lx0jCy2cXD",
            "253":"12qq0P3NQUZM9qWb7dnvvhdeL-AVxK9N0",
            "254":"14nqn8YGTruS3ktcYqPlPA9Ixi9mOOCdW",
            "255":"1A9m5aV8Cnoj9bRS7tRcbEYnQcOXTtmFP",
            "256":"1AYswGH5cENqawspQMD9IAQ210Ud4Ccdw",
            "257":"1BCM9xOuc6nMkqr4EDhYQNXuSHi2HxN8K",
            "258":"1CSGVauOnkCpxjZkf6R2SePVO2vWjebq1",
            "259":"1CnwKnncYJaXGsRc52v6xFzvwHnF_StuW",
            "260":"1D_hkFl1HbvSqLOkiWC9qMKN3_P6GadEb",
            "261":"1Uf9YXWjO9dENrAuZwepEPN5gNR4P2jwG",
            "262":"1Y9HBthGJ1Wb9_EBQ6GTSTZyXzX0jseuz",
            "263":"1cr-LpcMPdCIlYbLegc1U0txqORPUzGOO",
            "264":"1fkUwMklkN34FGPQRVZtNpIpllpYZW447",
            "265":"1isMzt3NuHhFIhweABTlyeovnEsXbPrDS",
            "266":"1lexQscucv2DzDk9_5Nalq0iyj7cUsAaN",
            "267":"1mFfhb43-BhrLg0tu1yzYi5z0ws793_wn",
            "268":"1xAc2GN5O95fRNDbtc0LlnCvA9HQCVnKF",
            "269":"19DWikwDXpfkTTPT27-yUpbukvaCNgbgF",
            "270":"1KXS3vRn9DbqUpmUOwIzSnNPLPj40cYpi",
            "271":"10E6upnABFPS3FQw3joPR1ToTboji8s6s",
            "272":"183Ok9R6zKsPq7iVZiCERBAOywgNCRMGM",
            "273":"19HYXt_vt7ON_uvRvUATZoK9PJNNm3gwO",
            "274":"19PB2kHrzP-QdXpSPN7WICJoKaaBX7QXP",
            "275":"1AYb8arVca4cttkstnsU3qAy8WhA70Sxp",
            "276":"1Cz3E71l5UzP61954Tf0T0cLr6ftdm10B",
            "277":"1DjR2gKtxWERsS92yEqIXwlfxwSzP9S5L",
            "278":"1HUU28DeoBmhk66nG2AtDraOwlLtwdD9Y",
            "279":"1J43BwQMMw7u1IUT98WRPCoMG-YnGtd_B",
            "280":"1Ky9XsRD8PAuxM-OYahEgsGz8mbENcn_2",
            "281":"1O8B6FdfpjTngjqpH9GWITf7HhcKDExTz",
            "282":"1OJnq97IaJJfLKkOMZzbT11pkxUX3AfWE",
            "283":"1PNdLjGTJZqSpV3HtnydqBhqwMQJyAW_E",
            "284":"1QXhqXHerab0ahYxI1i5MUdzHM32ALsJH",
            "285":"1Rl8k70cl0fX89hd6eBfbA1Gex_M9Orin",
            "286":"1SL_LB2QqYrCneEYoIqr9Bmb_cp157njT",
            "287":"1WgKYbBbLuvSFTUcXkU0nu4Qi68bR_Lai",
            "288":"1Y29zY85aym62tfmr_z30jfw-WCWad-zB",
            "289":"1agMh9mNPqDAuFMauWwgdpFtQuKU8CRyC",
            "290":"1bmk6vuacnGKHJz9ePXy6N73VonuEpSlR",
            "291":"1bwgmfXi6-cytPJlV-FPvEHA1RVC-hHtF",
            "292":"1cEGrvqUbN4CSSjgu7FV4vF9DQWUFhLrX",
            "293":"1e-QkxL8qhI7FAJywwzP3966IRfUxEOmX",
            "294":"1iJnyeCBw8Wsx9R0fFtM_Q4TO4Y-Y-vF-",
            "295":"1iYWZxHCqyFCIhaydK8WpyNMIggDs1GAc",
            "296":"1joaArCcGY93jkaItas9yrA5E8OjneUpQ",
            "297":"1lCqMUc9JJhJugxUIL4btiB3lTHcy74dW",
            "298":"1lSm8B2PCuadgRg32zW5B2gH5jG2aiIme",
            "299":"1lhVPm6hUzj1-boCL5nJ0P_Q2s_CvJW17",
            "300":"1oCXwquvKhFADJjJHoNUNd5egelSIVn6V",
            "301":"1pJgFEW0hyBtwsvF0-S2i0J6ASu0whw8K",
            "302":"1rd0Zkg5R7ZZ9Fj6_4rh0dqvJhU0VgEy6",
            "303":"1tBTp2QBXabVNf_nEDkiGlgyd2s4x-NiJ",
            "304":"1tV-l1_si6CORu5X7na1oZNSU80S0bIEz",
            "305":"1u-X4yMc1LzMkUuIeDh06e2w29QFGN5OX",
            "306":"1wHkqIfR9FbPSAquKyVOqcifxwYNtTD2a",
            "307":"10GKPi4rTJqpaH4wyxg4bL4IzdMfnabqG",
            "308":"12rsUavwfUCDlAnCxIlKxJb94p05iAXrV",
            "309":"13nVE3717NrF3XSsg_ptbe7VdgbJsK1Px",
            "310":"1AOWg8krYbf41SuvfExt-tSDeOOomsTeY",
            "311":"1JqznBD2RxOg-7GDplTF2lEd8obJJ8sbE",
            "312":"1QW2CHWd8NgQNoc0Kv3G-yWU2yUBgxgrR",
            "313":"1Tni0iaYKncRQoqMMI2rsxdSlCUD_sSre",
            "314":"10CbzeQFVdE94tLTDlHFD3PGlWCpRqTHU",
            "315":"11jeNBX3HhWtEiRKqMyQ7Gqpv3CMHhrgl",
            "316":"14dEfF6jjbmmpKsbYnlCwACwHW_uDGnCW",
            "317":"19b1taZmKR7NeSO2nYmt4qIGRLXSGO8ZU",
            "318":"1ItoGzlYzwYkBbr9qU51d0BM3L7eC_glT",
            "319":"1Kd0FMj6VKRAmLlIWG9h6-2oZLvrjZM_G",
            "320":"1P7YdFvq2g7sMPfl_266GVUn8fw3IuPDw",
            "321":"1VzoMa7emf_rFSWbNYYsBV1eT9aGz8uJp",
            "322":"1W0Op6rPWYNX3r_6dO5HQln0vy7kZhpj-",
            "323":"1pP0ArgF9JLG_F8MLL-xGS5WkNa3kIcuc",
            "324":"1r0MR9TC5AtPJdBmoHtVBAitFtrmfcJWP",
            "325":"1wX_mQ7jbc9OUw53RqszEADlI880Aebu_",
            "326":"1x6q7eCGAW2NToO1L36ItKBWuguETj5Nf",
            "327":"1yS9T7PiMZe-tFZ8JXaMKN2--sBK6shMK",
            "328":"1zG6NsZpzCsgvAJb3ka3Nll6osiH3_Wyp",
            "329":"17dlmDl_tIg1IhPiaTcufnla9tpgBVluL",
            "330":"1oExdxpJ8cw0Asmlk4kDRngktj4a3BcbR",
            "331":"1DAbOKIBJVTHw0NDCmyQIG4poJHwfBe5v",
            "332":"1iFBlbryjplrMao-Ts-Ux0pCcd3NkEgPo",
            "333":"1lmg-1Jl4fn6z8rqC7FfAdCmSPFb7Ble-",
            "334":"1n_gzP6RkgGw9t7cBqZTYgfMKapXVciYn",
            "335":"1zEeaUFMWMLRKJRynaXOQgyfit3h3gBpI",
            "336":"1EGZzm2gRfSJKwuAhe4SM6S6lA85RqEB0",
            "337":"1OmnD6r2xrzq0-KnkNZamSDmiBjvHNxcy",
            "338":"12hcozIj-xVQ0iZpHei2PDE9r62jMfffP",
            "339":"16jf_iXW77DWmypaqb1zEHHx13XajYVYH",
            "340":"16uCXpQwczHYloDBng1l0ccA-LTsUa7O5",
            "341":"19ogTdz89NNvg1Lm2kymUNsIISoodWGDa",
            "342":"1D6CHouMARvQVMtj8-kHIWakBeeasZjVs",
            "343":"1KjmTS-se0qSPIeFR7xqUIrJFcDjTk40F",
            "344":"1NRb9EXOFyniA_sBdlSne7_23bs5dz8iJ",
            "345":"1Zvz7s5EhW4bjVXz6Kh6Z7BU3qlKCyMsE",
            "346":"1b_PtjgU1NYtSSSCn1dNGqkZ6r_zW3VKs",
            "347":"1fEVIfFlHvXpUR31a0_TAJBsBf5_KCMCc",
            "348":"1gncARcAX4-rfh5ZfTX3NckJyQppiJI_x",
            "349":"1hZP0SiymdGyZ1DRs5fMR82i5YesZj20E",
            "350":"1kI3e2YR6WOFZ3XCPitFK4vJaDWtLYQ4V",
            "351":"1s69gYTx-Bma362VLoQhqDd_3o_wuyQlG",
            "352":"1ytJDR9ATEv_PdoQeHKgkhCdFa7L3RkG0",
            "353":"11R4vGTxXfjDVo-aGJkd_Vy6r3_04lyWd",
            "354":"129IYHlUJAjmBjUBIHK7zD_VJ9xLGL-6Z",
            "355":"17J5Ky4JqbMs5qeCxQzzeabCvNzdnWYvE",
            "356":"17J8qW9TXllitzc9MCiijlbm2uOV2UD2y",
            "357":"193-zsj_K1UQb01wYniJDkQFx-hdUlyGz",
            "358":"19TxpVewKHLit-iJlprdrNMLMpKokH6J_",
            "359":"1Bwto2YGzUFiqbLFE8Q_oIPnJg6ohPf8z",
            "360":"1Cgn0AF-di8sJIak11Ja5K2w07jOqEM_y",
            "361":"1DC4coS77PWZFw3yIRe1PPps6HwRFxBnS",
            "362":"1IABkRKXJvKimlHl3rxetVJ2lnHAjfyWM",
            "363":"1JzNEx3rClIWPklXjqpl44K3Ow2evP9T0",
            "364":"1M3Qg0eo9iyrZCiuwu4dEawSUViq_fKYI",
            "365":"1MAhvjWEDbVeSPc2Vr-e4BEjd4HU7Hvtf",
            "366":"1MEmVcc-ZXSlvJi_vi3Nv4F5TraXtMfKa",
            "367":"1N-1Uam-fjpbecHSuRfI435GZvcC3D2CU",
            "368":"1QSkQP9zjS4WBH9aN2tylbdSmiaRwBjWI",
            "369":"1YK7Sb7KUyBRp3iJa13DcP2cxO1r9aKrt",
            "370":"1Znxk-Ofhk5xd0hwB12qZDkJjRjS7AX_v",
            "371":"1bgjEK4mTZzeovyMkcHgGZf7OtlHR2PQK",
            "372":"1jL9iYPFWXfYa-vC1g5lR3da3CnRyjnC4",
            "373":"1nA7lu1aJs4pQ83FU4UxKXeCTCarwUA_6",
            "374":"1nofscI6HbrVgptt87qSEsv_0qs5zmaLs",
            "375":"1qFvHtF6CTLKD0PVAonOY6wHSF5wFYBhx",
            "376":"1qg4bzp8ep30XNXKMb24eaA-pysQHZ8e1",
            "377":"1sxZAD8csdd7SoIRAI4V0FszxbacLiye2",
            "378":"1yIctHAnnlDbMiDE412JUmKTf49T_oRrs",
            "379":"1zTtvLh55Z3zf2nLhOtH8Ia_rHhUuAL76",
            "380":"1zbOUmt28_p_2eLUar2rT8iBNvi0c-kfA",
            "381":"18ZCXUBwShFIhdHdFALFNliNHIpzQNWCy",
            "382":"19LNhbDGTyDjHzDhH-2kqkwnYsfgxKp8F",
            "383":"1EY9X9B2IaLCbR--5imWrP7fUdhxsM62q",
            "384":"1JN2TqPMvTcc0BCVTsjAc3t-sKZxUaJYX",
            "385":"1LgC9X8_Do7y6TrlQSwfW4P7EIPbpfmLO",
            "386":"1cNODj_qnLoygkCsBsR5elg0Egk0WMOaF",
            "387":"1ek_VKMVMeyPEGnKobiAvX2A0q-BDVvzN",
            "388":"1hye7dNY9z3K6kuh2S6zU79lVPg-Mkkfh",
            "389":"1jafs01QvPd_KYBDhuMDWIEZa8HPv3Up3",
            "390":"1kHWeQ8Oz1NXvNws_UIs2Aw4fC30PbNWY",
            "391":"1u6x-NjzJGhwT2BjF-UiLoNo29FLMYunN",
            "392":"1-62dnlfChGYqs64XgIKDGYFj4EX-R6pN",
            "393":"17ube_T6cBYAR1VOpyYMOQ0un1PssDBsl",
            "394":"19u1zOctnGRFzoD-jVxSo8mk7YFb4tR9E",
            "395":"1EikvtkV3Icwyr3jmpNHYC8iCgEPlO5kI",
            "396":"1ExQOAW-0dXJNJGBVESnSQ_HO5dLDbym0",
            "397":"1EzcvokbmCJgONx-lIhRo3VQL3f0E0N4X",
            "398":"1FjIncpcgotfubIR-ziLPDaEBPpe1ocno",
            "399":"1LvuSx7zOpaYFI9AYTdZKyMxtN9VMjiQW",
            "400":"1Y_0MvylqpGZZpIlnEwtgzS1MRRxJ2M0c",
            "401":"1c3dqQ8BTixoI1luwpwn8LFM0ZxGeraTO",
            "402":"1cFzZsVNfmzWpAHeDIIicqrmK15MjDUKH",
            "403":"1f_kHsnXx4hocSqSKNriMlxNPvKGLzEKu",
            "404":"1mJzYX-bm0oyX5mfSpucR9nVYLjXyqZn-",
            "405":"1qav90Ds4Ny9fy6yN0ooGAmDk3oqoIhlZ",
            "406":"1sEiXnJa-D-_MCMaxWc8dhTlfUu8eQWMH",
            "407":"1zcw8-YBot0EHkjiPDh1YZiAx-Nxz3vL7",
            "408":"1Gw1RIgJHB15qkt9cda-Up6oLzU_3ITTA",
            "409":"1Xh5n1mqxsUBObmvS3aS52zGtHeZuU4h0",
            "410":"14I_wr9MZr5T_ydmOszsdG8IjinkeG8_M",
            "411":"1OLJdIggCICRBI9oA1gnpHG-hNw7mXkvt",
            "412":"1ZniW5MnCU1Vn2tVXP_oislHsEgI2XunA",
            "413":"1aoaUNzKKqZ8tvhrp778C_y0j2-OnCXep",
            "414":"1l2gwKDQ0fQKKPjRBDDH_I1VkZIDJxfPB",
            "415":"1l7m8xXM7G8ILQg7LtdYekfjUiBNlEOaM",
            "416":"147XRP_QOHJ-Qb1efF5rFFwpmeYD-c3AP",
            "417":"1EDub-Qd7YZiFC-dadcH6REome-xN9jI_",
            "418":"1OfJgLddmyirFkdLHtjFgiEprbYDMUG4R",
            "419":"1TMiwE8ev01EmALhJer1MlhiwQKyRICzm",
            "420":"1mWVi2TrmhZdbGAJ_RtXIAyOgMkk5XBZq",
            "421":"1oAat4o4iwJMcMeQmtDXhMFfQVH6fW-9x",
            "422":"1OLfxc_wuUDRQft_7kSuS8snfCGPXgxzn",
            "423":"11L_5JwbLP7IrOBOMy7GRj6HN5_xuCGRX",
            "424":"18132eHCJT3F1Ap_9oYSsDpnvsDouGBbP",
            "425":"1CioHsMSZF9zlvCUkeGp0w7j4jxwzKDO-",
            "426":"1DaXh_ff9-dpeRoid_V1bAkg63ATqyAqR",
            "427":"1LAL9sMYRbjL9NzwuwGqC6jd-sMjjQ7uI",
            "428":"1WDgzvzXKs9cysQ4HzjTTWs3zHpIIIx6C",
            "429":"1ZMcah3TYnH8bqYJIK9QN-qcdGTItYRY4",
            "430":"1cu_dk9Bh8j5R-oC8OJLTEt227_HQY1IO",
            "431":"1nBbKZhVpMtRDOKnMz1zjnnvfIeUppvO6",
            "432":"1pXM-_LBn0Yp1qKobr3rfd1LSqOvcdhbC",
            "433":"1prqOgtS5P28Z1FcJGoJUjLptnE6sOeEe",
            "434":"1qcg-sOML_6_ZSiZ9V1pNv02d9MEp9giI",
            "435":"1teIyVE07ZkbYKuameLrkj22vDI8vHU1f",
            "436":"1yRv314NVGqLulgf_ec02uTpydaefeX0y",
            "437":"11SUVeE9JmywLpzebKIzumxXNIL7WIOEa",
            "438":"16TOrOISl62LRc0FYR_-ZdP3lvf6_n1Kd",
            "439":"19VPfc7l-mS6PXTXsQI2thAlV1Ac0QEdz",
            "440":"1JeJT2t5HQNl9boPTXuCcBy8rQEX-LZkr",
            "441":"1Jy77gsKGAkjMndWXgDcrKn8vnwehIoSy",
            "442":"1KV2BFMXpyLZQmKyaleiiWP35yugRFCUs",
            "443":"1PWESHbs_bYFN4lAcC75Fg5ciPiwTaN5W",
            "444":"1PibmN2CPcJ644SUMj1aVUrFLlJGniuMR",
            "445":"1S0qRlfyhPMrz4waQmCX3AzIIa3NnvECo",
            "446":"1X8YblRUuH_LYl7N3EY_Qf8cVN-ywPfbr",
            "447":"1a-nap39AgZ4zxm1jOTewbigt2uxFrn0m",
            "448":"1cM-kQa_hLWpLyAxYJrt0qFowjskcXirc",
            "449":"1df6MCir5LCfGk568Eq7S2-a8OpIomwoJ",
            "450":"1jqccu3rbdJv2ZDb5LEa9rmSK1NtiVElN",
            "451":"1kSZXdGN_FW73KTLu_bJi6B6dUFZSDBBy",
            "452":"1sbj3j50x2NfQS1WfCCDpoKiA-4YNqb0O",
            "453":"1uWv5L1vwjh85sR9oVStD4SeqhWMuxmaE",
            "454":"14Cg8wvBlI6QJjtN1MVAuL60hoYfolp2J",
            "455":"1J8J_srQJFqJoPwUk1-oW2MoKYIOmPsTS",
            "456":"1PoC5unGrE9HOh4Nxz1_1JG2Xo02Bw67K",
            "457":"1Q8E0ghYXQJ4ez4GWA4j1FlEm8vSgnFZj",
            "458":"1RpHfSpkfrufSMmdHvv7EDgvjo62Kim_s",
            "459":"1RsWqJnwlu84voEw1EICBJHPhpv8Nj5dC",
            "460":"1SXEho-sbTCUAKJw6xc-39PFvqDvmf9R0",
            "461":"1UjdU7-GetlGByPrfS0vJHy5rL2KAkcWA",
            "462":"1VUatGeKbARApGhOUuKFZFLmGJFrPVOzY",
            "463":"1s7pDFjJCn_vhDY018Gr6l1ZHLd4bQ1dC",
            "464":"1sZqQn4urJc3wE63LcqAv9QJybYOE12wL",
            "465":"1tNl-5g1gDOeQ4yhtaxWO6bp9-POQhpK7",
            "466":"1uiksZ-h3SrHf7vmAfQIsd7B6LtMAm_FP",
            "467":"1vQSOGDH5iWo5YO8W04yfUWwx9NzTzWYE",
            "468":"1wtNi9Dm1561iNKQs8qYFCPU3mkYp9qEK",
            "469":"1x9c9N_WhobasQ_a-K5TPdSRxuK4KdgbH",
            "470":"12VjhI1OV-3fB2GtOYgKwx5OX5SUzsSz9",
            "471":"13b8R5E8TxmCQCQwxJCPAHgnIwmGACFCo",
            "472":"13w1qxDLcFsOA0mIvqzhuEZhoE8scNYUa",
            "473":"15hx0zU7OY18QX64oM0sH7_E_Y5Z_LSZI",
            "474":"1KjAZp9uYF9ikG_KHPJQahHq6YqUoz6As",
            "475":"1L02Px5txsaqrg4skgzDVWqTOR108GpjB",
            "476":"1ca17sFd0vrqCdzH3VbRylV-cUvug6isP",
            "477":"1ompo2WdYrts6Ow63z6zwUitZU4yPXGTU",
            "478":"1pJoEfSWLyMbO_Qnn8TD8nVyc25sAIYTU",
            "479":"1ucgP6cssEYHv2CtLoFdTXxpXJR2bOW5Q",
            "480":"1xQxzjMo9lUbj434QKZZC-iPC3a1Ve3eJ",
            "481":"1y8J1giAMYPVvNFW2Vc9M27_HZm30xCnd",
            "482":"17ExNN8AQpEG2ZJTWxOAoTvLVeITlcaFv",
            "483":"1Aco98gH1s34tgCjIQOxuQ80IxXdaM5qH",
            "484":"1GzLcfdz-b67mdaiBLnOQVADLuxkcwuPN",
            "485":"1HQungBT6GMNHrIMHkGQ5-Y1eHDkAj11I",
            "486":"1HgFo7sdgoJe6p98bipPEb_h3YIfb7ADd",
            "487":"1fExC8MXyNSeoXF7KLaxVm_0TrChCBJQp",
            "488":"1y-TGWaPqL1DIq3VoJW2gTqT3LspybCZL",
            "489":"12Nqfj8QDyARywNUSrgjvSbx23Nr1EZT9",
            "490":"18WJFJEjhcSvYnwcgjVgSssWB-n8s2BiN",
            "491":"19SJ_Z_6qiFxA7LDV9kfx2FV55P5D9f72",
            "492":"1DELsI7ecBbELj4BiiVxR1UB7rUDSCFjN",
            "493":"1FvPQhvA3Ext7Klsjq1D5sR4Vpq1Zk7cS",
            "494":"1Zn7hYKKEdmuhsjMNMz84xnsFOKU9F3xi",
            "495":"1jsAWW7JYOet3e1NBl4KRxlRc4-CW8uNk",
            "496":"1kq2SEm_Ep9h76EZz9haElqH4E-6IW0NZ",
            "497":"1lmMNO53CdcQ-mumV0R6qpnzrqkoYkOD-",
            "498":"1pQYpjsJiFLz3Lq7r-OJ48APePXCSPiWd",
            "499":"1vftwJtuetblfthDizkLRUVUZKVb2eJNs",
            "500":"14IGHwazbZmf7LoQBGkAojTChNdDvv_Ex",
            "501":"106ZB-comJAT8LGs1zHTcy1ndWUQ6bBvk",
            "502":"1CsTC-96Ygwc5ZOTk8ii9ClLetYcdGgqi",
            "503":"1JPtvxFR6HPpUX4Ds_39_TnsINWRak4et",
            "504":"1P5_dEQV2eLWuVS-Bof83iNj5Hb9kFGnr",
            "505":"1Pd95GXSVWVpt9HY5S04L8wkdtakPBCwC",
            "506":"10cCa5S0zpPRWuzIpmXbTohU_susER1_e",
            "507":"1BK3HV-D47d2lufiEpci6Rpsdu5qdZTD3",
            "508":"1IKJyqWLCriTfT84f2RleN7OizjWBukyo",
            "509":"1cz6FULeulzmASm1IGU3aLO26eygbdLw1",
            "510":"1ge-9rz5ZovyrVVa9T9crbU7CjBKMosbN",
            "511":"1iPy3645psdR3rSoEAf9fY0L6bFV55SeC",
            "512":"1GiBbrAD-yBZRQMJmJ-FRokrOw1B-p7-a",
            "513":"11vzWw2upGjz06_g3LxMGcOCAHYNKpilV",
            "514":"1fXcFCuUFOj2zO6fYjRGBDmdJsq39Xr4T",
            "515":"16jjqca_LhQlP5Dc0eriQN5iq4HaU4Z_0",
            "516":"1VCFZqX4DqRBM_UXui5NFtc4I70eW5i4z",
            "517":"1av-RFpiTLMzKA6hQRgwBf-dNTHfyZPeP",
            "518":"1ZFj2sft5n64GHgTKxvxHVpu-zo2fGIAD",
            "519":"1_4G2B1KV1U1Vtdt3nBJxim4bgv0FlzkA",
            "520":"1ukOPodOpJIWWs9snFj8p9GKE0Ri7OL9q",
            "521":"1xntsrNb0K74vfMyluZWOUuvmLl6agBfS",
            "522":"1BWXO360SjJzzLKTLI8vBsEZDeFPS6qrv",
            "523":"1E2G7YUPmAU8rF6LYdmq0Hj-zwXm1ufJH",
            "524":"1Ev1qTJczo5dU8GIKVzvK0Um_veMSGcvS",
            "525":"1PigFoKrXFXK0NcHfoGsgWkXV9upV0jJs",
            "526":"1UtLRk7v631iK4b9w4vX3cARO7ajSoIVC",
            "527":"1lRwzddRcLmBv-C5FcB7jWxVlrJxVtpmg",
            "528":"1DP3HKZsAHiUe7D6_dNLyjI69y1sxls40",
            "529":"1DksXHhEWi4z6K7YYESyDwO_EclV6MsPK",
            "530":"1FiBQAQ7K2NgI6_ZaEnY6xUF-g8oZR_m5",
            "531":"1KZl2r8VcAgbUtqbU7TR-9FJN-cZD9Ymy",
            "532":"1QXF8_FVBypFoJXXNLCsdVz0h9pUXGNRF",
            "533":"1Txivg-eCUS_XFqbAxQXZcUq_aiHbkEAG",
            "534":"1VBCFcoMbRrzgEFUa9Ag4Lennw_0nrnCY",
            "535":"1WvsS2242X9v_HjhyIT2KpfF0XH2gfO3q",
            "536":"1dq04XjDutsnnvf7HKlyb85A09pRKV04R",
            "537":"1pgfLpPwxYZkc65wtmrl4DYR4oAxMPh0W",
            "538":"1qQrLXPudvv821z5z4Z4xi59fHES5qxfO",
            "539":"1zFUJUd7SyRF4RBd4fK2HlHgFBjQqWJTq",
            "540":"1zia8DotQRk-FaEVeQ3IaRgwM-Zs0kj07",
        },
        
        PERAWANG:{
            "1":"10-nRclr5_HCIUBXgXvJjpjWeuRZ0k91S",
            "2":"1HfIpKyHgBP4EN8RCCPKtRoLP2OUgZ7jX",
            "3":"1Kndw37wj_-sqKVWDnEbMMZYpFWOsP7Td",
            "4":"1PRFYiRUdwB2E8rc91QyHF26fHsnS1THP",
            "5":"1u95vYiVQjCrHxzV3Z-K2vIcbBJ-ZtYeX",
            "6":"1uLHgLefJPqEu5ZCJovOgL9t7XFdAkO8M",
            "7":"1ysqyQMMTY9hGvOih2iMcL38ljmnMW9VV",
            "8":"12hVWi_Phf91QgPZ45Sv6xbK0NHVlKiBI",
            "9":"157MD84z3tImOWLWuh1NEziz_os8z9p6P",
            "10":"1Az5H5YbC60B2H9V8J8-O80qIs4s7JTVA",
            "11":"1BaNw4pMdtuBuxe2pKYN19ebv5j6E0Car",
            "12":"1Hkqtotut7bqBg0swi1FxeBqX7ROcQ_x1",
            "13":"1LR64S6Utr-tY2ssxAV_WmP1K-Vpsh8b6",
            "14":"1dr6OAT0Y6pwqOjmJvmCN-vfSQnracJze",
            "15":"1ipywiOtD4WyHSM7_M5a1Av2WfSQizRhe",
            "16":"1kKTuK_NKI2-71MeYFiNGctFv-aFZ7Qs7",
            "17":"1nPu4ctTSRan1umnwfwT-5LE6xx7emlbo",
            "18":"1s58pnMce3fHaAkqE8uOSX4oIRWv3Erjg",
            "19":"1u4hLyWbkHCdpyuAdp_FKxj8eVhPUdcZa",
            "20":"1zT3vKlF07GS9zvLG0UCgIPBBmVVbFk7C",
            "21":"1C6Qw655N41Asd2MA5yH8gKwryBtBCMSy",
            "22":"1D8_KhoIQQvE59aDds0pCVVtfHB9ryLs-",
            "23":"1Dhgs4BoRhH0iW138grMIGeNw9O-tpJM4",
            "24":"1EPj_Dl49ixtjyLIw43diVHql2dbr6hh8",
            "25":"1EoLA_VbgJDu6fLVlMRj3hRpEOYPi3dzr",
            "26":"1GuutwW4CKWKtvzE8YBOa5rqtkq4yPew8",
            "27":"1IrRXqKXtqA7OnOq85WBCl2_6odaHtPGj",
            "28":"1JzdhlWxauOfsHEMAOpQGxRhpRFTfce2U",
            "29":"1ORmnOZ9FY6ztraaBG9dASvmKtODzgbwN",
            "30":"1PCeRMRgVslLil6wTXKnZJ42zHXwewgIv",
            "31":"1Pkm9o-lWjwcFqDdY7lRMo7oj0uAiqFOL",
            "32":"1R2yYHIp8SzzTDpPHLZf_jgxUZZXUUR-K",
            "33":"1SOIK5geAeo1GwqPYbM1xSKN7bbBL75B4",
            "34":"1WLdblZaTCOUOI4SPiN8EADCtM63w7ycm",
            "35":"1YUfkjR1mYF74lygBv-dPMJSD3mLw7Etj",
            "36":"1ZcTY3Wu7buLrQiRkPbaRTkQxXBNGA34C",
            "37":"1cMGn2UW53BNjNwoys8uXG58d2LxM_Pbt",
            "38":"1udj_j9DX8e1vljym6UVa5ChtLRvcORqI",
            "39":"1270DgKfzM8UmoCSDClIRCYwamtc_6ubR",
            "40":"14MsQspY1-zVr5KNwSyfJz69Tyq_FbIOL",
            "41":"14ks9I1K7kOakVCqxFfi81mKiTkaBtBgE",
            "42":"17b4-VQ_u-x80GG9DWrQTkrWqcuc7aqig",
            "43":"18vXu-oQY8Zzum5WTshsLJfHVNgfFIIly",
            "44":"1GZOoT7mKh7zlq-9hYC8Xtv-u1g2PyG84",
            "45":"1LWysLqaetIXjCelDERIeKaf-65Yd5omM",
            "46":"1MIXC1jmEID9GgjusZVsMyJbSccdaZUDk",
            "47":"1NJuXLNhg6fTmgt0_E8SxGWJS3UmggmMh",
            "48":"1UH2Lz6fP3bb6WNrvqp2i3veN67fSphfh",
            "49":"1UQeBI2ss4ZwyWho06bw-sKdr4eL4OxRh",
            "50":"1UbRnBjVovSo8Wl86qzXkNnlKJ0rELuPy",
            "51":"1Wq1sYUAw7Zkyell_Lw_u89lIezqvXl2f",
            "52":"1Y5FPNDTtaNri0xYTBWxKzRpQfJwisBHL",
            "53":"1aQsPYgfd8wmY91QFESArTRay46KQU2lt",
            "54":"1bDWuM2EHwlsYbVW4ceUCq1IpD9yQh2zE",
            "55":"1czuC-upNlDDeI5pxnjLdwLhDeOa2WrFk",
            "56":"1mbqcRe4WXUaK3Xa9c4LL0nMEpQL4_vPt",
            "57":"1mvV9QjgCkFwlUQxPDGDSna16lPtwCs5y",
            "58":"1vZLvTsQE0EEgx_hnK3ymJPmANmZW0Qx-",
            "59":"113BlseW4nMJLreig1Xlu-OwunOs9DOXx",
            "60":"12sY2hoEBONoxGB5w3gFup5MDyCZS56DE",
            "61":"182HEUVm4UZWNkf1cobl5CJL3Hy1NOz07",
            "62":"1DQ8Huz27ycA-_-_ZGtpLMf8G-xV15XrP",
            "63":"1DWW2R-npWSzTEx5wbTVesqtgEvNCEBWc",
            "64":"1Ev57PrIy7FyOtM6ff3_j5mC1__GUJZYj",
            "65":"1GH8UTFP7FxoQWOu1R1E-_H9r7WeIknJg",
            "66":"1Mp-B6D6ogUZG0Mb6N6jm_7tebGqxkj5R",
            "67":"1dhN9yS--2Pw3mu6-cgEbGHHOSq2RWHMp",
            "68":"1dxbLw2eyHT5XmD3-dOOUaa_lw_9yXw4d",
            "69":"1gw06jAo13duetFxSZ-ZHKoJiDqzVm6y2",
            "70":"1hKqpY1AJ7g72uC6QOy8YBJ6wWDaGBjzG",
            "71":"1i6E3XQB8au5pCiNXZhevvKwcimxaX11G",
            "72":"1ijxcSFC44b0I8PB_vp9-1mqIsTCgSsOs",
            "73":"1mCYUXMu1FNcgaT6kB2elyCrRVV17vYPD",
            "74":"1oI2fm0uvJAblV8qvJlrIxmxKYELmuasd",
            "75":"1oanBWfNzvRiuPvJXAACxlruRa6CXy5Fx",
            "76":"1rNMqu2cFgsAEQ2JcFAm-CFjDs99ubF14",
            "77":"1s5uzIob9yyj4bBBKYTRVeT1WkFKiGiNE",
            "78":"1zmvr1t2MjBQV8KsCv0Zsq8Q4PZ63hz_U",
            "79":"10d3y6LV4DxnVWdxW_LVKngwSrOmntIZJ",
            "80":"1AnOt-bin_GQ0boRA7zAgM4f7V7XezfoX",
            "81":"1CKml5BfJDkfvXpH3JW1-5SZ0h-nWuYVu",
            "82":"1EYTYKohwJF6YowgPZx5a7UPNC7CLWNpt",
            "83":"1OGtxUb61fySJKtGQv303I18saGqPTaCK",
            "84":"1Tgi9MotrGEpvdELiXUf5ifDS5kQVjL8y",
            "85":"1UNyK3qeIzoDqoYYvigUwBiitbLARpY90",
            "86":"1Wgor4rEEdYsMst4FWHAFO4aNsrYaz0SL",
            "87":"1Y6N428R-KEW2UgUD_1zQTNnA_VsEq9QB",
            "88":"1YOIfrB6WAUTcH-8zQbnPsjMKEz8ZBtwd",
            "89":"1a_7JpPo8C71oTbcSB2LxUAcptT1w68Xy",
            "90":"1b-mPsrJht4B0yA_dPIBK_MSFyRvdw58S",
            "91":"1cJXqP8KPNwej2Did0UI5WdrP_-qOOHUV",
            "92":"1eJL4de7vBJt903m52YTmZH5Yl4dJsVr2",
            "93":"1jJSN6cUk2JceQzKc2-xzEYMtNin5PL01",
            "94":"1mXL6IpHVyPRNp0n5VuFBg06FRV9wSPIW",
            "95":"1oPnOXYRSyy0y-NrNmvtXBadtXEUnvYCH",
            "96":"1pmMuaJqjBp8iBuY0T-YuhY4Yc61Rkfg2",
            "97":"1qtLOSApvb5oF74ZTNGFGWNg_EOgzphdI",
            "98":"1zRhm6UKapSHMq9qDrS6NP6hyTVxGI_MN",
            "99":"1fA7r4FuYXinMG9OI9ggBK-e07meyXCT_",
            "100":"1l_v5aOk8aHXYeO8EatsBq2jk7SjSILnB",
            "101":"1CzlDDhm-Aljx7kViBlycxeK5QZgPtuR1",
            "102":"1_VjYtP-obH0kK4AsbybxtowOD-7U4Q7_",
            "103":"1fBOuTdt6sUzm60G67NQQYzXzDft1wYwc",
            "104":"1fdht9BM93S5yo0TyjzXauW0zmgVr9B7D",
            "105":"1r9eRzN8_F7zNUsJfmb-G0hbLCThXKc_N",
            "106":"1vPPGU12rHTCxoMWweZqLTkuaI8j0DYh-",
            "107":"1xKP3Lq8nNqdCk_KzsYxPWS-I2KGHoPMa",
            "108":"10xeZ3Lr6reK_4zZuOhWTxu7L_BTe27Xy",
            "109":"120ExOoHihPkdNWvxVLHrnM0TTrFbrnAv",
            "110":"13s9kbSjYV2alp-OTyCJxtJz2TBN6sjBU",
            "111":"15bcAAh8nq05vkB6OqqlXkO-DhRIZBbIL",
            "112":"1CZxBiS3rsTvzVCvmNxOvWioteEb8dRfj",
            "113":"1Fu7Zpw852LLgU_x9Txz95eSyi_rCPfU9",
            "114":"1GD_W1tlpTWEqoiCV93LRq6Ex1f2J__-F",
            "115":"1Pu4AlNhfgOMXH9mfb_PXXGLKP3Ybro1q",
            "116":"1S3l5zSdHIqHORpUYVR7fgyPhaTO5pu16",
            "117":"1h5hbSxpeV46aLcHrCzNfBw4ZXLGisSqb",
            "118":"1hMFNWWtBq5Ka0t9Z0ps5KYpIDrbZlJRV",
            "119":"1oHJDn-oxtFP40bStTZClStT51ZDEIGBx",
            "120":"1qrLWOQ1oGsE9ctYlc50erJXINehJ8r34",
            "121":"1xbrYNlC-SERx1kK9fLWZqWl8Vm1MrDXb",
            "122":"1-NcJ5pwlTkwNUL3R3GFjPoOxc_EuZ_p6",
            "123":"12qsxiBjkJob6eY7INObvtweENQLb7i6y",
            "124":"1FXjrTm7LuInH-2quF94wXbVAHvXRyh3z",
            "125":"1LasP7IUpLe3vDZ-7g-ieqSIMYpe8zbtd",
            "126":"1LzWLmAPktYDH7GViyC3TSZ7V0RCVP47u",
            "127":"1Nq4kXwYJns6HORW_ZxYjGV3MLD_AHR2w",
            "128":"1Z6jveBRj45P3v_2eB35jjJGNRsLu1KIr",
            "129":"1_RJDvTV4vf8627VNhk6hYMNP4ztmluTe",
            "130":"1b7mfLCbG0PSCh6GJuY1OMA52l3gyErAc",
            "131":"1gpQYZnBjerPDYSkcy2wL8N11ebjedBya",
            "132":"1jP5zCoXqBpoGjKx9ZpebcOfZNotMAF5O",
            "133":"1l90dN9EMUXe1rXNcY1AC4yICPQBsXEjO",
            "134":"1A-gF4o62cQX8gbytpQFZSici7Sz6rWo-",
            "135":"1GHZUtknZeVzuZooWNnRvbv9Be7MqyUWW",
            "136":"1KV0GTF6EYfW2_90Mxivqa4Rsgskw9sq1",
            "137":"1Pa2fErlyRjGf-PrpGoKCBdNscrUbRrOU",
            "138":"1Paz8DvIkR04X6uKzTavOHZ8GwYNZTziF",
            "139":"1RX10YtqlDZQ8mYkJsENxuUE3RyFmfuJU",
            "140":"1ZrifDbuV2CE6OkrqiYrwpwpRM-2XTBr6",
            "141":"1aC_e1E1E1IQac8l0FCDh82ECZxlX3aoU",
            "142":"1eC_awZxKS_DLvD3K3N4O7zXRcPexbbwJ",
            "143":"1fAzpPH8Tsk_kDHHeJ2xA-rXfNmR1BoDs",
            "144":"1g-rhgKp9z3Iw8UjQ9-orpiLoAyX2R_-c",
            "145":"1jHyM4forwBgru8TYgP_jHobM2Lir50oW",
            "146":"1m_13J1QfHBDvfVWYINsxhHjeyyXzyvrY",
            "147":"1osOgYb1THrLxgJ0n0ZitNllBjhsSu6lA",
            "148":"1q3luWbf1Ai9R-00zj8NlYNlqKcGwI-Bm",
            "149":"1t-UH8EBVIMJlop-JdLsAdNBc00CoyRuG",
            "150":"1wygyVdbNs1ZBKMo5Y-HqIH8SygSCnN4M",
            "151":"1yKd6rmDx5vNNnoXX8yIt1YeXgM9T_J4v",
            "152":"12nJ4h_45Bw_2v2CuziGGJ28MCsbaXAXH",
            "153":"135yy9YWQxVWTKgaNtyspwNpcT3VipS3T",
            "154":"14rSI4gv65WLzAigZmu3owqWTCPNKy5ms",
            "155":"18O_5hXyLTKJDqyLs8mNmg8sLlXS3WHKh",
            "156":"1EFcnYsNpEG-Q5cODGYPIeF9XIe3TT0CW",
            "157":"1FADgxE4Irn1KR3YVE8EyP4LW1BuaD3tn",
            "158":"1J0-XbCb-z4ZlTu7hcn1qpXzbwKl0wGlL",
            "159":"1LY3sx1C_0qV_FggHjn5V_AlUB8-8ftqY",
            "160":"1RomtjOSCrZJpH9MdBQ_X4yCLaCSQ83h1",
            "161":"1U5kKwhVK2RofTMgdnaIGzmeIdOCYUZAU",
            "162":"1Viuk7zF0K0r5mPVTJXI2wm0gV6qoNTny",
            "163":"1cUUgePP_TjeEwozVy_dacuSZeXBfIc9q",
            "164":"1fu6EHMRK1IMNoKc_KpSnT4pDco1JcROK",
            "165":"1lGazI5AJZI47asAkSmKxAiuA-hf1trW-",
            "166":"1pMij01ax37mMCyT6FN3J940zqDxFdwbu",
            "167":"1tHO-93gPExTSCMJFpRlY_DrVVnoHxUXn",
            "168":"1u8AxUczyHl6Y7hQLavKij8aAwsMKl4lx",
            "169":"1vInYMDYacpfW0nWex28u2ai5dTWssC4H",
            "170":"12EGuI_H4Qye3dwAIOZbVi_LvyJwhMHEa",
            "171":"16sR5WkD2aZgBpsOoNY9Ry4kE_DADYvxx",
            "172":"17-OfcW5NY-l1dRYnWOeQxfFNoiCaNTc0",
            "173":"17zwvpOS8ohEANFqAk6mQRbK6Si_ocNgI",
            "174":"18XT_a-ALiZN0sk0_A6S1jVrayUA8RGo_",
            "175":"1CEIH1Sb3nGTYwpVkOn70mogC61Xa3cvS",
            "176":"1JnGqIvT25cN1WK4uSEnFrBhXYXaq4WGR",
            "177":"1Qllzd5XetPYMDcmQNklF3aby3nF9nbQf",
            "178":"1QqnJsP1xu6EZaFwLRCJvfde1eRB03cvx",
            "179":"1SKvBwLNQ9BKoJRN9DTCkHTk3rXS08YHV",
            "180":"1S_LcyHHh-tAn4C7wSVTYkcrRMfwA3j0U",
            "181":"1XNxxMahos_Rf3koMgjITIm-FcPsCF5H6",
            "182":"1_Wsy1q0_xdSE9QvsjZ4o9-lyTwsfupxe",
            "183":"1lx8uCQG6edhKDozwpMQbofzmKVjIpSWC",
            "184":"1mDDnrD2KzLc74AUe9lU7xATQRkFH5C2v",
            "185":"1my_e2zTtE1mOU946_MSpNUotpK-KTcJB",
            "186":"1ng8q5QCrd1UAUWPtXuuGPk0OYHpj-xRk",
            "187":"1oBMaTD0xJfR3051s8yAtmEywlY18AzsF",
            "188":"1-zXfkjXWE9bLnmRbd7qHXdCvrei93OQO",
            "189":"15ajmAnAw6BMkTMkIA0m5XfLLrzcfCkJP",
            "190":"17j7nPsM0WZb9sE5zfYotZe017te1YZ8c",
            "191":"1NLvwzkSW1cm3EaV5DTWIrAYaxencGYeL",
            "192":"1R-knyGu5mCjtY73k4TX3fSeOLuEcysUL",
            "193":"1dmJaA_o1B6awgxESN61dOEo55BAcRkvD",
            "194":"1gdE4_2cFaN0vYuArEfVCxD-OjlG9umbm",
            "195":"1lHYcXm3D8uEzWGzRhUuDF2cxpC0Iz71e",
            "196":"1oJ1sBha9ucSEvbXbVCi2YwzpeOkHH-Kg",
            "197":"1peYRP6Ty7o0XKvKMtE7gG--vQaeLqJuJ",
            "198":"1tukNDlWQXNZVG-Rsds8RutUUi4dW4WRs",
            "199":"1x4vP_hp8tPm738gWDBUc-u1za_TZxskj",
            "200":"1z50ZRKNwPDi_bdela0Fq9YXxSXWBFHPu",
            "201":"1AcQZ3nISzDumG9bbxmz_HXhyXorM1U2z",
            "202":"1JDZAwBICYS_4WOVTUAfEgYuNfvOyIWJT",
            "203":"1QongZRc2aSqhFk7Jz2NGoBQqnlxrAnR8",
            "204":"1RTxuoPPhjElijLLKCnwMJOBAhtQ_Q06v",
            "205":"1ZsN4r4EK951tMsddKOeHuA2aI3m2fkuW",
            "206":"1vJsswiwTaQA6MhGpNKKInC0on2qvoG92",
            "207":"14cys6s2p0Deyb9KfdlhS8hj6pAqN96DI",
            "208":"16z5FwSqY4UbZHVFBWsrChSI303BB848s",
            "209":"18WKUAyOFyvmyN8coc6jrIo2gZ9JMMcFI",
            "210":"1CvtLmR15yt1yPzkD8HPRpaQE4V9G_-69",
            "211":"1DC91dZj0LQ5p0y6sF5sk-mKeo-OdbwDm",
            "212":"1H70W7IGwoAYqJQ8Wz3ljJ24us0aL198B",
            "213":"1JmCRMeU_uK9CLJKyRbZC1s_pKdYX2lGx",
            "214":"1KZO_J73IgMaa9XCLhfcdhxTP3hPRhVXN",
            "215":"1ODhXSrMTP27v7IvE37nFlAd2KofUI0YT",
            "216":"1SQtYvi0cjK8z6VutDLGsaFboFF8Zi7ah",
            "217":"1VmhIw6HztdP-G_FzuLjx4ueyxmekxVyV",
            "218":"1XOJv7JF8bPJOegBci-KTmNCgwbU_HwgE",
            "219":"1a-U3miJXI9KW-8vHvK53tBkzSczOnzdQ",
            "220":"1a9QTnnaCD8lY6AINltkcaE6jNqS0ryRS",
            "221":"1be6jzRDjKONQfGw4KbdEt8_36GIjafZW",
            "222":"1hSE5Yk0kte2-tHOI7SjHL5B2A0TR32Ou",
            "223":"1hYHWCiYsGcWa3eP3azJAtJAd9KEiZge-",
            "224":"1js4t67kdPI60kGC3o2c9p972OKhylqEt",
            "225":"1o4aqlZocV5Y-Vv86z4WgEu0t1eN87uuk",
            "226":"1s17sMkE3ng9LFLASCoEOtcxVXmNJpgdP",
            "227":"1-Mc1K2ULG6jDjclZ_nAmF19M_47u-b1p",
            "228":"10zrou-jiAMT8FcpLnwrZiJs3ddYvY_3N",
            "229":"151Ru66CGWhXR7q8RoxE_5mEeneZ5VzEh",
            "230":"1CtpELgMM3580-tzVhFJzTIMUFq6iaHct",
            "231":"1DLrkZnF6iX_tFczLOeoraUMCTIecH76b",
            "232":"1IIzJhTLFU73gywFVNWC5tF6tESsx9Bal",
            "233":"1IdTJ4t0w43KEk_ctnooL8hgZIx8I-QFS",
            "234":"1Q7j6tlRjQfFqgVy-gjUl3t8AB11QF1Ef",
            "235":"1VU_0q8hWCPTQDzPennK2-S9HvGP_4847",
            "236":"1Y-K39Y-1CLScYA4x_iZ_frv03nOwIrnZ",
            "237":"1Z1cQKgpJ9xatjlhtx-SeLATtw9ve7_zh",
            "238":"1_xbsxaoL_evf-WAFAz0G5aTuqpWs1Ijw",
            "239":"1lq4zg8jvajhmCS5WMDHMvyymVShHdR5x",
            "240":"1nKNAnaSeKdyCrsBrCdVWa_QIawi5r-4j",
            "241":"1nNZ0Gexm0pleuiGnxn3JOvd1oXijUjkF",
            "242":"1qR2G1eCQqH65PrTSeTEEkikYyqPM4xDM",
            "243":"1rUYoKOG3VoD3PgWkSP8tVQfp6oBL37Wb",
            "244":"1smjHmMOl2okh63Z2r8L3MbR2cbNv-wkZ",
            "245":"1t9Z0u34uQ3hMOY3h6HwKoravx4PThmrE",
            "246":"1zd_ejVSu8_FFuHUUFzc-jvz7_PJCHYJW",
            "247":"10ccr5pG38378MRe83RwDqknneFJ8Djm5",
            "248":"15yQP1cxgHW9QUQ0Z13GgztFzkXsG1lS6",
            "249":"16PbY8nKB_nt25fUC9cYl2rEMeLTNwl6D",
            "250":"17cfk5tM4hh46jNqLV-uIfEZu__ozK60K",
            "251":"1DCZLdF4nfZpPNJtkYOAvv7Yf1FY7POmg",
            "252":"1GaVebYZQQ4Hlw527hk0K7Vw0lnKmgbFQ",
            "253":"1JwAZvK1CK-yAmjC42wvddXbHcDZZLivF",
            "254":"1NTUYhFhYm75sWfFQ3x7Zxhb2GihozTl2",
            "255":"1OEpih38k-88q_fCJW08yAdkqJ3f7cXtl",
            "256":"1RMwxtRnxssZrPllUzEhRGVyzjr3RxGD3",
            "257":"1U3-tmjBp3pZSrksz7iEL9vhmTouFsap0",
            "258":"1__IOOcce7URdoKH2QfJVUAwPXdieSiwJ",
            "259":"1_xfF2arQw7IRhglOnrEm4cXGpk8BXVT1",
            "260":"1hOnUfK7lYmgTRcP82cKlQGdlqyrsoaxF",
            "261":"1nJJ3FoLoFJMHk0RvTkPZunuEiaBMt8uq",
            "262":"1ndU1u5B2SwVHk4TR6sTK1juWIy7xj__L",
            "263":"1pvHExFqkvhRyCBstZ-I13knUpkX0yLYS",
            "264":"1qSgeKna_ed7cH16bfCn75MWOrO6CFgBq",
            "265":"1uu88r1kcMaooxVEAGPPpyzaxnru5y4ky",
            "266":"1wErWDNFcDJR2Ascnx2x34GqcXyQMjx8U",
            "267":"14EBcg6hMQjU3a3YGVNDcDV92YKWbBxsZ",
            "268":"14FhBH2hKOGPTbSw55VKKvAoi9ICY5Xs7",
            "269":"197W-vaSvG8Mij_Ds4QYxqLgBvL5AssXi",
            "270":"19dBq5CFA1UTZt7h2LQFZAKThjGKMFLa8",
            "271":"1Fw2VBNyzWTeZFQ8amFIRghOz6TMJLlOO",
            "272":"1GKoIbU4mdsiEXqneQYtOy_PgUWD5cGeD",
            "273":"1H9RAqKvPr3iWlS-L6GcZ5vdWhiKjjvv_",
            "274":"1JRfFZS95bt86XluQZnBiytiCa5Qbi468",
            "275":"1JlRTJvu-3IYsX-j-ueMfPXzaNWUh9LjM",
            "276":"1NJxDOu6OxbDg2uPXR_RiV5YhoM87kVXz",
            "277":"1Ne-varx2itOnW1EMBCMF87hqrEmPMjIG",
            "278":"1SvVKZjFVaVHHsiPhDL7LyzL-t44M8tvD",
            "279":"1XfOkElxI4a3HbZYfrXotVfqfZ97b1Zyj",
            "280":"1Zvq8Y6ekwF7kXMyHWSnl4ZchCE5eXbma",
            "281":"1nC7ZdIPdRyVFpfAG_ZIUSlQzXNJ4gO0D",
            "282":"1oVmYp7h0GwyqZbAP6T4PQv1asT_yEtPy",
            "283":"1wbzjCo_hcwHVDXsL6tJC7AQP0VOaQi0f",
            "284":"10DvN_PWw5McLbK98gSum5nKz15pydXkX",
            "285":"10cLLRGZD-N_WFGCHif-Yce4AByxzLvG3",
            "286":"15RKCsRz0T3_BF-qZ_WgfQIBuZMaTU4K4",
            "287":"18swUUVmgBB26cMkQDlHkC_2U23CDvLzB",
            "288":"19ZeJATZKQIjSm-hCnqRA7RWdTyLgYujN",
            "289":"1EX8GRF3gcVf66WwIk1nflaPucYsWhTDD",
            "290":"1FIsrAPG098Ex3oXnWKmTIng9DdI0GKmp",
            "291":"1KCkJxSC_gq5gjYQqde44254LJ7kThfZe",
            "292":"1Q53v4fnqBrNzAtYkZff9JF0lQQK5nXSm",
            "293":"1QCUw6ALeBxj9vvVdt8SoLQJoHBsWFYoO",
            "294":"1QMqwRG6RXKoFi3eCM1-a87eqN5dSznyJ",
            "295":"1Vjm8IrikfUmgf6WfHtiQEaWOyeZaBbfY",
            "296":"1ZZ71_MfN_ltbbK-VC1gaSaBUhN07exJj",
            "297":"1a_NOBCWRquD7a692flrvyuIma1gSQPkz",
            "298":"1c4sWpVV5BoDm1EIjwADGE4ZjdcHTZ4_a",
            "299":"1cFUmhUewJg68DGuQEAJS48zRLWY0KheI",
            "300":"1j5dcHoOtR5tBioPfZ7JonchcJHU0Xmeu",
            "301":"1cW0VxsNmH2p5eEcVZ_rHmo0JC3e-mKf6",
            "302":"1Z6S8M-lSqY_BpFwW7MyrT7KHLYc5fX5i",
            "303":"16u7Cr2vJv3GurmgdIfKJHSl5qIOEv-sq",
            "304":"17IJqjMtQKhK8_a_EJndtxtlbhOPWUKEE",
            "305":"18ubBzS1_-eIM4DinBm7eEThTqV50HZrm",
            "306":"1Lmeuz67vjN1Xz7Fggs30WGGS687fS2Ko",
            "307":"1LwnJ0Xow3h4SN-E6CVHQya_5Xw8vb_2z",
            "308":"1VFdghkqzdvXwW9KjxeBHnny-jRW_vmkK",
            "309":"1X-Lg1nkad2VZ3ThkCyPJDNbWlfw-zBh2",
            "310":"1e0LFYcxtyzJ_iqj14ZWN608R_0MCrRse",
            "311":"1eFXNh2teOs2mx8_TozoImgsiThVkwejp",
            "312":"1yxhGnnaBXuSaqFTmlsHd80EzArBJnn0p",
        },
        
        UKUI:{
            "1":"16Us8dVuDu7myzpIjj_zeV3t4SmkapvRI",
            "2":"1az9aQw3br6QG-Kdn47vN-0mflDuXVw2Y",
            "3":"1h4PVjaZ7s6s6ZZFIMF25yHiNXG2HH_Yd",
            "4":"133Jg3-A-yBtnm5u1mMIlcx44XNmkyfJT",
            "5":"1LKNftocbJ7Ms8-_-mJ2o4a80FZtKO9lL",
            "6":"1QZFyeJo6ZR9k0ifL7EZtRe8R1QlODqkS",
            "7":"1ZHyiSc8T99P9wBJBnEUT1GgOCa1c3sTl",
            "8":"1ko8T_ALjMhQmrwqubycw8DUWSmV4x12q",
            "9":"1nFek5sTU951GshYtk_NbonblQ_lIkPxE",
            "10":"1p3xJrIlMOmjZqX1iRiBsZvDOgCUlD9ef",
            "11":"1s9B9JiWuIMfjW0lSqrgku0zAwilms8fF",
            "12":"1yBSxxEYg4a1bOsFeNq9SOQ8G1uvTU39T",
            "13":"15H8m4pzbIRXVY0fYfSEJdYKQy9yJ4nRX",
            "14":"1KPsagHecUCVNtVybabwVNk6lwLjZSdKC",
            "15":"1cLzBsEv3vcejR4BmSQtdu0yD7SN99y3N",
            "16":"1iMVCMW040YIeDGPIHlagID7Oy7NbXpzE",
            "17":"1qhTn2pugSo5tNnmQ_Ek-CwcfJB57zRNj",
            "18":"1sdilItzqUfDWrvx8Xg-cB0k8p_5EV9d2",
            "19":"1BNh83NJQcaQZ-cRPqHOMsBqELUVC5PYB",
            "20":"1IIO7VgPt5SjV7G4cd8VTMVFEDBsUMnV0",
            "21":"1Izmh6HOoMy71ZTCIznQi7_-tQVCnVLj-",
            "22":"1N04fNPONoi_1eJiB0SaFqQwACXvY9-Sk",
            "23":"1OeT1X1L0STHA3i4r3Bjk98pPzqXgPTOi",
            "24":"1PGMjl8Fj-RUX4-zCZ302_Xutyp484WRf",
            "25":"1Ph4UU8DFDSoR8J8C-iw4HOSA1IoYdaPg",
            "26":"1USca6Dh__Gohc8L3ZmsJYKgwbGRKttCJ",
            "27":"1W3jeQyLRN7dCcRSMlyNCK-jTfgEEuB1-",
            "28":"1ZJtlOd-NAsj0OKje8SK_kwYAeMe7yk0W",
            "29":"1_RCSYMbrJl8-uRCBlLRBiiiTNq5B4Bg7",
            "30":"1ehoeJKqFWSFyu-RRrErmgT3RPBHKipPu",
            "31":"1ephS8HKvCABucWGdkhkfwabKKXNCDB3I",
            "32":"1lKUJTWyL7MtHeI4-OaLmltzpNONaYgUe",
            "33":"1mt3jQMYL4A3Pd3gx50xNwaZJ7dQPsDG7",
            "34":"1uEnqfi4UfvfpWLHd4_tWJXrJnVjYl0Du",
            "35":"16qGyYIMyCKFknJe1E0L7IVvhvAdezGqH",
            "36":"1DljJbYkpLNHMCUoAMlf8jip0CQqmmCW5",
            "37":"1MY9EMCG4TIx0cZMSBO4DthH-SSF-Dcyc",
            "38":"1THaHC-_8qrl2Jg897sqMum8O3w3sZ6i4",
            "39":"1yfRfX9Ll19Ney8_mmHliCPzcRELHrBJ9",
            "40":"1-CnuODgs7-KChesWulVtlChQyqgGgrdy",
            "41":"10MkB1al1iA6B1p-f_qRVmLvKsSgnykXp",
            "42":"11WRPzraJpbpS913Eoi9B02WiZYR6JCHG",
            "43":"189akNdhnAgLokaDfPl7LiLVm-00607do",
            "44":"18s3-KUa_VOILxbM622u60DCS5NagYrAH",
            "45":"19fX9kuARnt_Io6ITg_hwQW6ePTEijeG6",
            "46":"1A93l9R7u5OYO-4buuao264qqKU5Q6IDP",
            "47":"1C3Dx0pNFTjiLdkUKKuHDdMdv_ILrlPmu",
            "48":"1Fr7x0N8QugZERUhlMD8PTNib2fbOwin_",
            "49":"1H8PleyILx0S4QtgJu61zcwhfdQpJNEbS",
            "50":"1HPtdGCwXMrpY9tw2SVqp-OsNw1_Akcu1",
            "51":"1I1-8IcjktNbsXAx_xQg3goLeUgz3DKOX",
            "52":"1I6cGS4UOoUKXa7MqC1pp-fEL6m8sOf4O",
            "53":"1ILUU-6tGh5Js5RD4Dz9XQqWb9gM-7G02",
            "54":"1MXng3LTapQ335H8RzOluZOEN3dOAeGli",
            "55":"1RBpfRxNkJXnRfsXsdeDRafPuGt8F3s5E",
            "56":"1TgUpuiaIXaJrSkeE-DUOIqpeDyznjG7Q",
            "57":"1ViZ7_rfssv3-Setvsy3eLA9qg8hUt4N-",
            "58":"1WTNkz_qnKbfdy_lfooqTJOt0o9ws-Xok",
            "59":"1bDKbDWLRS9IENOSMDbp-2NWxHgD3EinZ",
            "60":"1bm7XTaQWWmar6ONT2BHXxu_1mbnwMZIY",
            "61":"1ceptTD3XwMpByn-f7pXXiXAVxzt0XWiB",
            "62":"1fAlpM1FyYhOmk1XoDqoEm7nF21cajsle",
            "63":"1fV_FQZN0rnDubskPxfdHgZYGKN2Sg_u2",
            "64":"1hIv54c-RMGh6hmc0g2VVAGvKLCsxmhoH",
            "65":"1oLGAFel7f0WQpn90MEFJ_XrGAvonkA3H",
            "66":"1p1Nofs2oVLBS3Zemqli82Y_O4EMCnn7i",
            "67":"1tCyJQRXPY6msFo3oJ3b98ehfeXSMAh4F",
            "68":"1uSSC0WsruufyoZFWJAVShC0yy6RA8IWn",
            "69":"1xVz1eEjDIB7WatShrYoOQ27ZGOkVHzRQ",
            "70":"13Rgu1WfesN3U3BiYyrU8jKtee8ZOBgqA",
            "71":"14wa9FuKDU4srwMo5bouwg2VuugszJOti",
            "72":"179fOOTXcXmXG7KGX0Xj7p7qxAWR7bX6R",
            "73":"1CQaR09bcsKxI5pv4wyJ3q7zzjRF3DpZr",
            "74":"1D1KgdKAMt0S3dQHeOTUSBDZHQ5XHQZ5s",
            "75":"1E2W01WiAx1ONH1NtA0JwqgRbWX9CxP8L",
            "76":"1HKwZmyuLb7OqijtN-Wre5d7Fy-qu91RX",
            "77":"1HjOIlnRxDNZbabpW7n1AY-mV1EBIYekr",
            "78":"1Lj_CQ_dIUu-vm0G2nXNv9IztSvfnQrnL",
            "79":"1Nq-RSzZ_UYzyG-eLxcsjSyk_tnLuB4XQ",
            "80":"1PTWZZpXPXS9_kWtVRcC4O_4thl-iEycU",
            "81":"1PWRpD3PysyW8dc2Tf_n3GV36m7IF3RhN",
            "82":"1QDI8VGaVZ-K6AFDOA7iBtPCmmlbU9CZ4",
            "83":"1Z70vCCA6zJ46GDL9ZgM5aXP193GJDM2T",
            "84":"1cmBTO8V1EITUG2Fo9pvyNt3Rw7dgAue_",
            "85":"1dEJDTi9PXueglsmuica7xpq-V434VE7B",
            "86":"1dytBzLqlQRje3kKRrOSyMFnZIhLIkFMq",
            "87":"1ebOBhCCYj3mqK-2kYvQYqxVTYTxVDSz8",
            "88":"1et8wk-fRIH-ZlzsDdhKFKsnZSLTLSmAP",
            "89":"1iNNrXtsDv4XkhTvcpHomkarxyJ4FRoAK",
            "90":"1ir71FfJV19yQ8fLGB9p2BAsO1kKtzFog",
            "91":"1jHfmBOG-iNgEPW23qpUnNIP4ImsqZ-TB",
            "92":"1jkg5YdNm9YtYr3xUfEdg-BoiFzB9_-nd",
            "93":"1rWM9io3Q5_RY7ETHMFMZhbPuU49zcBJr",
            "94":"1sWBhDo4oiejjbTxbQBEWiaA3ZE3iKe8X",
            "95":"1sm3F11XrK2HlQj-X2j461uuW0Nwy1HjI",
            "96":"1xZCCRBuUTbjyJuTiyGzq5uzyqTgmPZ0M",
            "97":"1xlcmRlowZP_4OPvsqNfOy4urKKUrgbQV",
            "98":"1yxuqDE26PS7aOo7h3nihWYgrdtcAcyFH",
            "99":"1z3wmlCgnygq3SDIg4OSiqOT4xKKjFEfU",
            "100":"103L7ZAb0RUCGCaQKoZy9PBIBma5Ogh8o",
            "101":"17vn3I9p1xoBDarmTTOEmUaw6DWc0doyx",
            "102":"1CIy-eyCJll0_py0FweyGvuhemIEnVSZv",
            "103":"1F341viXrijmObL9cMeuYvuayexct-uft",
            "104":"1H54tYE1imXAB2FVb1oUPBEu9KxKs5iqL",
            "105":"1LVFEcTGgLliLLk6F5Nlk1pyPtTSWqlV5",
            "106":"1THOhaakKy31ygf0XJMXZ9u2ZiLBqxYRv",
            "107":"1Whxt26P3HXDTWI33Ye7zXvCjRxmmQ2yE",
            "108":"1YJu7zLb0EHBuDUMS3Izz2eggF5JLwxFO",
            "109":"1_nA-CBxLlCR4rtcSLBevkvVa4t4Hps5I",
            "110":"1ctu97zp2Pd9P5JtgZT8MCnbwSRWC_mSb",
            "111":"1eOYglD9VsYm6sfD2WL6bqggOZdr1mFKJ",
            "112":"1oNE2RkjIHssXW5yeWBE93DYIeOfjydbl",
            "113":"1q2ugGfPReqI7unKGGozMqr2SC3HkS79B",
            "114":"1qKTkMVPWiNrsqxz5_E5YGBeJPpjkSCyE",
            "115":"1qb4o4FeZANM0zyttHnpK5onD95n7gdFO",
            "116":"1tzfUrTGAMlkNatAQWh_LIKudfCqGhxFK",
            "117":"1vRPab2o9zNdkDOAvS8xuoEP35s3yTzIi",
            "118":"1BCTplSFLTlT1wz02WVW7UhEkGERrtU5y",
            "119":"1FZ261_mtsbpZ6m3zb60wQVCuncSCCU4X",
            "120":"1J-l_RWfZvqQa3fL0-ykntNRl__Oe0fGb",
            "121":"1qouPrfNlzJ3nZ5HnQXFM_QjT-w96hU1S",
            "122":"1znZ3d9xyclNp6RvUgRSjHAumMFMdw8tp",
            "123":"16EPCoPrNzgI3tL0YKJg8l5JTOLFSLR09",
            "124":"1AYri81Gq9XS0whINoI0UN9QPUaonwT17",
            "125":"1QHhufmjnB5eCnAX5cEQo-wHCoL9BGGz4",
            "126":"1ZlaZAzN0Yd9Aw0Pvh-SOdJ3YsQBxwHX2",
            "127":"1olq11u59CTBqfUqHWX5s4Rd7MkgCnG7b",
            "128":"1dyT2lHSKgv4yim--zykcU-eq3SOFUdxD",
            "129":"1rj4UrJYY4C-nuON0kOfMmXLQizObvQ_w",
            "130":"1k2JkTKVT3W5fyyFnCUdnJFx1Yh9K-WTl",
            "131":"1d7RLkIqXVOyhKkFepkZSIFmAEg0pt_FZ",
            "132":"1qmI1yVpzkjAp7m1tlfvCzwfec92xHWcl",
            "133":"1Vcr9OUtRIHjFcZlexK2lc-8dx6oRUarw",
            "134":"1cDFNGKMqWgpLrQibQ-BUs37-Ehm2gRgF",
        },
        
        LIRIK:{
            "1":"11YEYqwMqbC1X0p0xfMOIe38zKvyEuSQZ",
            "2":"13IXxGHQLZxztEgxxucoxfX460_5TZES0",
            "3":"14LL52VkGfGCnum8svrMHS3Yn_8CxV6Kv",
            "4":"14LPnIVF7hWHTnwGUVStZO2IYCf782RIe",
            "5":"18l6ELi-0U9_Mirkiht55aWCyX3P2Hduf",
            "6":"19HLOHnQCgiiTIOIHXfrXC2NVhiDBsioC",
            "7":"19y8xjguFSPhqh_IK6rscR4iAtqnUvkij",
            "8":"1ARc5kcpMX36-3AZ8hoLLZtGbLBDF7bVl",
            "9":"1Au4Gn0-ni_j9ZdLLBOALBg_bqQfxgltn",
            "10":"1C5KTidr0NEd-QV8ggHsBKSOPoZvWlfC1",
            "11":"1CDFQ65d1O2cIEfAwzc8Go1sIce7a7ZAT",
            "12":"1Cmn7XdElgSuWpnlc5DoLBlmml3H3xRyQ",
            "13":"1E-JthiSTHNgO9d111T60S0jCbIG6MxWO",
            "14":"1EPiVDA9TBmgugddfQn3odx105azy8EUQ",
            "15":"1GH5alH5VQmbh0JSHsYbNjdSlyNVTGupH",
            "16":"1GV99y911XmjdKlfhq85A-JFJ8qFMHbAU",
            "17":"1GZjFIN_4FP166ZPj-fP7X5nMI3m_Q_Nr",
            "18":"1LhWgbP4G86BCYUedXOsOh_aKLMBRHwNm",
            "19":"1LwjVmQXr_mzTRN8xoYatdYIjqrjSpjX-",
            "20":"1Mc6k22VFO1ek4xxEtKICYXOxPNNp-pfV",
            "21":"1Mkz7-OPaDE_xIBQJGHQbuD6JFzp2MTaC",
            "22":"1NK0IvMFFJpV2-Jc-3nBO288jq3XSoIGx",
            "23":"1S_7vKLuryBq7dkdq0SgiZ_R0c6lucBkc",
            "24":"1Uk321zSRP4UB05DKDunughxBhib2HHws",
            "25":"1_DceLMmzLzjuQco4Qc6ehRE1yBe1xXwG",
            "26":"1f2SFRuLCaLUZ9-rWD1lYj6QcYegkLX-f",
            "27":"1gYa2gUZxWrI9Q5d3AG2cBDP0hTBABw21",
            "28":"1kTzji8WiNEQ7q32RKWGiCLnoWc1wCOdx",
            "29":"1vObPlZ4IEDVneHUYqTwbfolQirDGPd7d",
            "30":"1yOLoxKtmZk41bH_X9-7LzoW-PImd8CjZ",
            "31":"13VjZU5x_BlLi-D5GxJAGGXhg-VrkBns8",
            "32":"18DCyUQZBBMDBSdHz70q99h79hJKgEixZ",
            "33":"18tdvxbLLvr7_k_qJ2p25CBGeUkDXK44w",
            "34":"1A344p04J2Uk7ZdoLI46SIG5yLGtTiCNI",
            "35":"1E6nN98SMYrD6PtxvoOrPbdGb57g1jLdK",
            "36":"1ExiZlDPZu9q97Epfkd-rtEtgE6wpUJtG",
            "37":"1G1vo9XzltccvrWb0mmchiOfEuRV-FUb7",
            "38":"1J4OAC3yT4IlRMwMMi3Ob-PkKSwH02EUQ",
            "39":"1Mztq6aJibaKtO4m1K1lOMFKZ6gCylv1s",
            "40":"1QbvyJB9OZzNAsTtV1g1j0fuGiP9hyV7P",
            "41":"1Ua7RcsEevB9149vdeeeKCJtOxmPdncO-",
            "42":"1Vv73QgLQQfse-LR155mjnFVW_Lfu_Ugu",
            "43":"1ZL1dKtGUDo27L-w180WZROWpueHljDDL",
            "44":"1a-TrOBrvrAYxmak8C7tMEk4bWAx4Cpbb",
            "45":"1aE2OfZ7kTCKD-yPVmTMBnD8e-2kd9tOo",
            "46":"1dduuqQTlMecj555pD-33iydlgj4My3dR",
            "47":"1h2EpeKVNthnLrez_8NWcleAuws5zqLTH",
            "48":"1io6Suof1GkTxt5_PK5eAqZWvN_VcRBLz",
            "49":"1itquMHT18q_UOAj6i4xMb17s2TOGvpfY",
            "50":"1j7Iji67jEWj8Nn2f-m4KtFjhkT_k3Ycm",
            "51":"1kttmVwAFFTINyWE4HTMoqmRU0YkswlEg",
            "52":"1lJc6hafxpJDjn53wjkOZyZA7ea7yu2rS",
            "53":"1m9n569aSC0aK-ogpl-5Y65LlW6Mze-9l",
            "54":"1nhAlCudzcnKeDnO_K-wFCnvXi_PILLpA",
            "55":"1o4GIiaZ0w448P5VuF30TAOcAVzOEpLr7",
            "56":"1oBj3-Z-FOcor69M2iQTZEf-EtW_tkgpJ",
            "57":"1pAn6S_eUQCQHcOd6TEa9KsYavP_kHLlu",
            "58":"1pf6jiKH_4JcDnaZ2Vj7PD-ur6KGVTr1C",
            "59":"1q2q61HpkvUUZGuQEL-BbGO7MDtTuui_A",
            "60":"1rkqYq2Z60rAV776Mm0WoSTOjMU59yoeT",
            "61":"1uOKAiASjsbEv2XVpotQcJeqDQNM8thE-",
            "62":"1v1PSTOunvisbe90a1ZGaQKpaUo8PLfQh",
            "63":"1y7bWbIT93SjXAVanotOjkO3FvC6JSVNk",
            "64":"13RWIpkO03p9NKE5L4P4nJXp1UgfN3Uw8",
            "65":"14f65qh4BDi2vQGsLHiea00_Ou7SbWmqM",
            "66":"19FAQX3w4TFNRATBulEspzemKKjlWigkS",
            "67":"1BVFqfplQGKh3tvypdGqZJXmp-NQ1-Srq",
            "68":"1BYccix8OXdH0NcEPaCDske2U1jkMhgPj",
            "69":"1HDZk4B8gG-MdPzC0lyAJkaZgtdrR27wF",
            "70":"1L8a8FAMadHWZ22dZhORwkYt6VlAUeCWW",
            "71":"1NjXL8rIyn56luzgZ_MEPeSYvoIIhbJvd",
            "72":"1VSGBsQP_bpRLn3mBz-RaUIvdvAJ_V-TH",
            "73":"1_yZFE66MbKbDAJgrKjN45920UMd_khwO",
            "74":"1nSJX9NZO7GStITCT7wuhBDaQppLWgNlc",
            "75":"1rK1RdU_Pa4cYPApPZGvalfsjv01JukR9",
            "76":"1ruG-3LluPPbtRKuCB8AnKRDfpobEkqLU",
            "77":"1wi5up7yyWhjVaK61NhSchwc8c8rEudwB",
            "78":"1-UauaXiw-augcZzjWzr1unsv-KcbpFgn",
            "79":"10WFrG1Mz-i2QyBvkQG8WQIk3V5wIVksG",
            "80":"1DvT_G95ZHhv9Vf2oD-b4r0bzP5mOLM2s",
            "81":"1F7IReQV6yYq0PduK_8aTO6tRGycj6WL8",
            "82":"1H_MGFaUceVAr7Au8NY58kXA1u-_WICbQ",
            "83":"1IcqZ3E_NvH70CXJxJ8bX6dfDPebNWNhZ",
            "84":"1J45nqWfXfynpUUmu_IiDMyk2_qHEdaBd",
            "85":"1P1-XCakelMqdIr1_-iRLV2NiSr0VjsWH",
            "86":"1QGzIjZYcEbmNLnqWfpdaWCErZsgoK_GW",
            "87":"1SpxvupdVH8x-ifKYy0xtveBu3Bfl92uh",
            "88":"1Uh2IWH1mWyKOhGtRcIGhMkdF2HlPTheW",
            "89":"1g_r9_P0rpAk-WpLPpSDNPdi9zyElBVcx",
            "90":"1hvG1nw0-ANT0QogLU4S1Ix8D7IpNWDIz",
            "91":"1kAKGSrm0GAOirFdU0sXfTr1fvnUBF3Zt",
            "92":"1m9r6y7NNOy0asemgcomY0Q8o0BJ3M0S6",
            "93":"1ql7hGp46nAesDqUiPtdZtLvZ1LiHiiep",
            "94":"1xZV2Z88JFZaUNUb09dO9cyTsglZsiSMH",
            "95":"1za1OKbQUaAaqy6R7919InDMCzR1cXGad",
            "96":"1-DZfP_cRgiYWOHWXM-ja_2jNbV6WoDMa",
            "97":"16HB-nMhBKf90PCbmEsD8N9z-gKeww0Aa",
            "98":"18XwuEdPv4MdYDa4kdCvmbalR9Pu_ehvq",
            "99":"19GoBh9mFyoAVJwegBOfMOyxFN6tDAbzG",
            "100":"1A7kvKnUc-oueCjhJ7bQVtQERBJhO3iaT",
            "101":"1CRWsKOnFg4QQSpfwxMUsMTu7iMbwb2Iz",
            "102":"1Csh0RzRP01ExYg9DNeVc0MT5bXdcXB5l",
            "103":"1FUg9viiDi1X_A7v4o7l4Y_ame4A_vlwl",
            "104":"1ILDSg4VvfBHOffFnpS_5N3DaFCqLGXQr",
            "105":"1IkQSmJ93IsCGQAZo-Dm7cj5RVVysTEU-",
            "106":"1KAabZBNp4uBkspLTMfgyfvFLzYT-AZ9H",
            "107":"1Mg6JQtDdI_cV0sAFE3YNoFyT9wj7WKoR",
            "108":"1OZraueeB16gPrJ8kElckFFCyTnhvpjF0",
            "109":"1P2cc_UeC8J2Cu4P_51KIm-fHW3BtIRBz",
            "110":"1Q5bnb-LlnzNZwHVbzSoC6FT8jY5AxcXT",
            "111":"1QWyZQQzlUv_FqhvY0HNOwYYKtJm6sFdB",
            "112":"1R6ixVNqDzoGWCurTcfE6UPF6y90DW8Hz",
            "113":"1TV4qO4SIdyHfjSPln62i7WEfsh4CEe_G",
            "114":"1aw0yx1EC47hTWMiFzJksMvbyXaqLcg4B",
            "115":"1dmEEGrKg1ThAYcQYL0ImrX3hDa-c366T",
            "116":"1gj9pqDpRJKvjq9FjWJwF_MClwpvCzjAh",
            "117":"1if48YpQwgDbhjrzRqTCTYl2e4zumKpRN",
            "118":"1mBa21m5DNXpm1wSCs2kDsHJBJgHd-OUZ",
            "119":"1pPIutnZ67F1EnD7HqfsVM3RuPDGUIXHi",
            "120":"1rAoOiYPHXIqhsONgObdJcJzioRm7CfWn",
            "121":"1sA-lquq0cAjhQhY8d4n59hMB9phY-0cN",
            "122":"1u7C9T-NvpSiMPcc4LayetS49gmbO8ndA",
            "123":"1v2A25chZEu7Uj2sDdjzo-VYY5b_wGpLv",
            "124":"1wKSW7X0AoD1zn8xudPyRZVMdh8_IFdOi",
            "125":"1wr7S8WMP4LyIqev161cSLmPH3xSCghcU",
            "126":"1-VHQtJgZU1TOuO1v1_Irkm6kGvJt1fIZ",
            "127":"13J-AGSPglKpcSowvdmae_xzT4junnEcX",
            "128":"13VpjF8XueGHxKCarft4tNm6QvDJtGUN9",
            "129":"14wMllj6K0uQbQ9yL7fLgeL5v6NslYfJS",
            "130":"16VTUTjUzeZrexKuxrEBNqTZ_qz2tRhrV",
            "131":"1C2Iwtt5HyQMvtRff-7E3rkE0-zPfT52v",
            "132":"1D1HpanAPinWIdL9Vi8kxQhhdXgO7KziX",
            "133":"1GK4dVTTWODPIPht0HCQxmoc2wyoLtxcE",
            "134":"1KKRdHuJ3u8wl3Ro-tB3BbT9MBUI5Dt3Q",
            "135":"1N-fu9rC5uF18kzc8MvRLLTqthMRIGBzJ",
            "136":"1NVabP-VQP_cOmYXg5PFQ6cb34wCwkH7m",
            "137":"1SCP9sEu26rAk2tIsxLDzP_GBl2XiRy6k",
            "138":"1WfQKuVjCUHc5aKMpHRx3TfvqmEq6UpZH",
            "139":"1Y9o8Ngj-JI8teJpN_tANp5AnCc1ADj9s",
            "140":"1_e3qfez7LnxvHYaSCfgLIWugIugPiNLm",
            "141":"1eA1GkKf82tsNoagPA3NwPVpvH4jAK6mR",
            "142":"1fFZX0lx_aDEmr38vP9c7QBRAvoiBo4gF",
            "143":"1hew9OQnycM_rMd6xGv07vooAZfz_5OTn",
            "144":"1i1B3AM0N4CH_u905BHXqgqL-km0MC06x",
            "145":"1kqDrzdiuXZ4xIZF6e2Oi4HVZEKIN_Zhd",
            "146":"1m1gCk5Pzr99J7P3s4I0jDxsg6OIsqAy5",
            "147":"1oZjQVbfxxSJc-ux17eQQuyBrVp3FSsXF",
            "148":"1pFw6QR-4dEUNdkgdpZHKi07s65lEoTSM",
            "149":"1qC3iLU-W-Q4ASoabf3Kj0N1m4MZWJjeD",
            "150":"1rJNwErooCbXc2m5UYLLIm41OK0aHP28K",
            "151":"1t3tY6ee9RepGh-KVjq1QiN-tVVf6GXws",
            "152":"1x7VehDwBu3kFd6MDlvIX-XzehyGb4jiB",
            "153":"12M1v67RgsMmgjxGxv75ZO6gWuGPcC_LG",
            "154":"17FTw7AaAcOnPEhaUuMG-qAjf_PxQnwcr",
            "155":"1tTCIroFhIHXa1g0rOZalh2f8hX90ib0_",
            "156":"1xeyIlBhJ9SWtmJHL7ub4ftz6_sOjGGdf",
        },
        
        DEMANG_LEBAR_DAUN:{
            "1":"10pFaoj-meuUSyFPfm2qeR2IwfkP9JnU3",
            "2":"140Rm-s35Z-XzhRYaIxVdjV1sZ0DA-EfR",
            "3":"1EZKyXAWMS8UcfFCip_I_aXzD0h-_DO0y",
            "4":"1NWuwB2K0bGeK1abeKMPB2nfoFW2O4T6z",
            "5":"1NmGy9faTCpuM0ykeFWpzqX6_d_ErlUdK",
            "6":"1NqgEkhBddopTkDHEJujrp7Wax_uzr6sE",
            "7":"1OFQ2I7TaPi2g339u05ya9QWMTiHdJDqG",
            "8":"1OR9aACSoZfpnEPXvypv6UxCSv3avhACA",
            "9":"1OSAXapA13kIQ0_zCoP0bA-87Ej5mhNP0",
            "10":"1RhAjbMNAOTr5AB7ykgb1sLQu8lz3-qyQ",
            "11":"1ViAKwGZfARJWxlIHU46sLuBXiuRzhjxZ",
            "12":"1YSO3WdU_xbDf0xest1K9Op3wonNp2-mg",
            "13":"1ZZbGJww3zpql7bfeVs0eC-9TVIm684nw",
            "14":"1_nhIeS37ySdmwo3O9XsnJfBx4P0ad5aO",
            "15":"1cKEXlTh558_Du7EUEam6l51hsbciBweu",
            "16":"1egWLjyZhVHx2jwZMIT4phhzLO3hwsYro",
            "17":"1gSINdx1mdc7eYffomUgdPgyD8R1ykaSE",
            "18":"1gkw8RN-IXC0n6pTXRSchClliDPhWuTtq",
            "19":"1jfELzWCd5q1Iqfskl7n8ygnVdBpC1GrU",
            "20":"1kriWpBmbdxRM6lbuiZQRxC84Rp508AV8",
            "21":"1tjQDcCZXG-ikJR-X3JuHl24ldc6MRnts",
            "22":"10cO0thZQn_MwWEXueFXOxN1UkK2huWXS",
            "23":"19N9VfAiVbrylDeP1JpjUUkVrD6kVbbjk",
            "24":"1A6VJAWZFmgW5-BgdkIQ7MY4lYdjz_kf2",
            "25":"1JVoB9vWMqeBmZBspjra7gc6GOURNNLuv",
            "26":"1NZsVSAlJ7Y0595GOshVFJudecoKRKERd",
            "27":"1U7UE4ZAAAhbuLayFCjLD-Qak0sob7c6K",
            "28":"1XLD6fzejDWqE71_D0Kp51CVK1rst8QaV",
            "29":"1_C5seg2E7tmrnogZ4wP12Iqu8jy9PRqc",
            "30":"1_GTVcfXp_y6g5ezvrgnoKVqEz_7PJXE8",
            "31":"1_P-Hs2zGzK_dV31g6gmKZP0faTrFe4OI",
            "32":"1b5o8stybwxsCgPB0jIuzVWCSNjs1tvHt",
            "33":"1dr3m28-iQJQc830wzuoz7c-PLH7PN_Ld",
            "34":"1jqFXedCcfhOb8xUMXmDxnOyj8DhGRD8C",
            "35":"1kBeJ9zNcnHDRg4Hu0eEIGd_zjqqux_Bf",
            "36":"1kZtqVSTsOtIP7rJ7-ms9_EVpfN1Lt-g2",
            "37":"1l0y1rrfgx_FaeMIroOhbvR6t_2FK6bdE",
            "38":"1mMWD5zXiCb-e0hsboKhMElncxRIxyhti",
            "39":"1nyWhP7bW-Ygkv07hD_ahayZPbCQlbajL",
            "40":"1wLyifKDglo8qmE21XXSwg9gWJuv8m2IT",
            "41":"1yWKEgLQzBzO_f5iguvM5sJUNJp-uhWuR",
            "42":"1zcKquBUX4O5sUAhYm6Mq-LNoJXiq9-Mj",
            "43":"1-HkOZjlgfcnzpEwbV1A0sIt-CANVBL8m",
            "44":"11gNmkUgMncV1JarE83hn_UOYLGEOSvwe",
            "45":"18v5wLa2Yd9M5191WFwUa0MZ-K9swZrbO",
            "46":"1EY4qJd5-5i6yMT5vRjbQKkEZ-7f3YYzg",
            "47":"1Es335pOR0h5Ym-MCAyq113XYq5eEafLv",
            "48":"1G6MukOxxac4quQHex1sVPsm6bNRT1uDc",
            "49":"1MFCMGI_hP_FrcHIX4n9ESya733MKWlQ7",
            "50":"1P4Nxuh9E9mQhR-nORLUqLPj4C15_bABt",
            "51":"1Q7WJrFph-Tu3B_mjj9DCaiFwcyFUAJ-_",
            "52":"1QKMldziRuI8XbVSjOK7bU-MJaIyCpxjj",
            "53":"1SMW9w2HJr5qDbMlEESlRO69vWCtOaB35",
            "54":"1Ujt_kqJdYpoCLEDY9COoHcIY1vsG_paN",
            "55":"1UrQjU-N26-3OdooXpbQNY7jRDKfsT9Gr",
            "56":"1YQt5Ng3MXw18aC6zvCi5lXYD21iWb0bP",
            "57":"1aTiIGXMYcOGxcHFX2DeLhhbrOVa7YETZ",
            "58":"1dFs-5tdJ7X3F5qNw7zQNf7XTWlpdY3Af",
            "59":"1duol7s8k5HSoRLfPyahmekxBtJGoghNk",
            "60":"1fmai_RTbD2zW_7HUGlHiJP8Hup2iAUCz",
            "61":"1iwtBq_nSOFzGk4yJm078jTZk4HuQYxF_",
            "62":"1rJccnHPu_Wjyfki3gEHPlN6b1vA32EW1",
            "63":"1-02Nw16z5xV1jPm4HSNYDYHuLVcZ2aEj",
            "64":"11lhLhI02XLdqtii0Mf-gWXN3KXzb-7A2",
            "65":"14gwA6cPOIoOQo2wgJHf_9iExuhfijh5d",
            "66":"15MqldF-KlfbHcovV1q4tSu7Wnu4kV7mH",
            "67":"15wPDGv5xbM63MOPUk-e0For-bt7RFBwz",
            "68":"1C4i8vTgvs73G2SYXajKl1r1RYl1NVVmu",
            "69":"1FMeEpibjlrmEEy-vBhK6BSJGV9vBb2vb",
            "70":"1HL5RsEb_wW35xR3Vo4XEOcNQsnf7QyqQ",
            "71":"1KjP93fMM2lH1geQ18UzlMhY158Xsub4p",
            "72":"1LN3iHm_6R0KHi3MsDPh_L2rWwHeNCcOu",
            "73":"1ReYte8gpR1rnusxtv8o8wzq7kPDJsag-",
            "74":"1UnRnBHsQywDcbD_rdF4Ty8p9pNorYw55",
            "75":"1UtSZ8QhcAo19Ro40fanRgyY8Fubbkhv4",
            "76":"1Xa5OlAhJHzIm1pxk3ctqoe-Alcf9X5-o",
            "77":"1_p9Fsr2oXTS-SAR0fRra0CjkRRVVTJHI",
            "78":"1mlSKasxYFubslZXNqN055PMi9FdosTEj",
            "79":"1o_x1tIFXYJrZRnBU9S8zqUuaOrid2wiL",
            "80":"1qwvHd7xHldwgWczybRv2SnnaKptizpm1",
            "81":"1s0arV3b97PRC9NdH2thfwVqoQyoidlkw",
            "82":"14V7ePADg4H356qmawvTIMRh7Og65qmjE",
            "83":"160jxleiJLY1b996awmJOGyvrNZsXpKuM",
            "84":"16pGVAiCAXkgrrabVVq4YIhhoXBG7SxBQ",
            "85":"1As-1crjTCa6p0rm27TtgvSPQMvCvSrJ3",
            "86":"1EbmILbOFxA23dYFRZW0zAIvrokkO1TDx",
            "87":"1GAqib_Oy6ZDUdUvClLHg32JOBPzaActl",
            "88":"1J7W-F3K2ZwI4-kVxJkNF4TuiSMtuLE7l",
            "89":"1JSTFAzKlTt5BeY0iEoTKZHhbvD4HVNKj",
            "90":"1MBpaWcxQbZeBavuK-Sw-OpdtuyPxudJf",
            "91":"1NW432K4u4UKp0JCzVi64E5-DRBCLfnx4",
            "92":"1PpYEdb-waLX1pNrt7UtsECUiaLPpg25Q",
            "93":"1WgjPwni9vLK5Bm6MivTDHZ_Xhf7KAG77",
            "94":"1Y-lAeaLNYJcVF8EAx2lXxleBkvYWC4n9",
            "95":"1c7DDF0IuDMXvhAon-eqpsoGD3z5ma6fS",
            "96":"1c7ocvW9SHrgrjw5GkJo2SYhvrgbF4MAj",
            "97":"1qD9No4Z7aApzGlj-5LT_buY5uxF2RY84",
            "98":"1wdFuvg7p_FUY9bJWt2Tzu4M2pXTNHiIu",
            "99":"1-Lh331--9F-FKm-s8XPxTi-NBF85_L3l",
            "100":"1wUpMt-JHWGWnLvzd969LWH8dgIJmOxCM",
            "101":"1BUN4AuxdajnlKDZwYIA8nq5ux0r3My32",
            "102":"1GajSl68X_t9_O4BKBX1MRzOutwsXs-Ov",
            "103":"1RB36UsJdvXZxO7m2BKS1iKk-S92LG_He",
            "104":"1icv2bSkAwceClu7A-DQWMOAw6XXSAMQO",
            "105":"1ruADpNiFxWK3Lpy9B9-gQDDukgvg-cOr",
            "106":"1uZZqQvgkZnmHgT6nTWMWRT4E4vGV87Vw",
            "107":"1udPBQ8ztJ-vKVL1VmjtNvudgbFGv01Bn",
        },
        
        SUTAMI:{
            "1":"11GJ6wL3JHWISWq7mcRBbwGiIyeOSxX9k",
            "2":"12cFmg1dEo0iGglDYkEdR2-3aSwTz6XI9",
            "3":"13EZ-8Y6stHGsTVUg9Jvso61cQ9FLxdJF",
            "4":"16iDSHhJtvCARH4STlr7_zAXqTS-BPrpn",
            "5":"16ijSZnQ6Ss9tQvJkDxGK_sCBg07fIZLc",
            "6":"18sn6kPi-TXx14LoNo2_IlHSewKr1BIKp",
            "7":"1A60TWBHF_RaKk2vr9EONAYcCsdaWgH1j",
            "8":"1DLQTyESULJSEAWnR0Ye3ZdUNtf1H8qKM",
            "9":"1ECulLwUvmx0b41LvBVzfbZzmaXL5Ehq7",
            "10":"1KBCI_LXk73gD_LJcugS9YNJHwgeUkVqe",
            "11":"1MPDvwAhPWt3wW06bU6uCwebkxV0IUg9K",
            "12":"1MYHI6GZVgqcdBwqpAo7y9wFil_eScbO6",
            "13":"1OnoPqSEBbF_bBIAHpcQ7CndjHAbLqD_b",
            "14":"1P_IdoZCDttgP_FPYWL7xV5yIpK5S0mBR",
            "15":"1Q7B1EKh8BqMzqd11vJxsGxTmi9N6_ihS",
            "16":"1VrP5SbJpMRTv-JNrHPvj7OGqvvovHbGy",
            "17":"1Xnr4hXrGK2UvbXp3c-1qrSd1NPjoH2bt",
            "18":"1YxUjA3s028tgsUt3zoBG1znbH5C_oLbm",
            "19":"1dmyHa0ultC916C-TYYFumz6U2ClPEBdS",
            "20":"1ei9adkmWI9jvpV41a4TT3jQ-KfmwCGMj",
            "21":"1f0luMzl6fuF9FnmY3TqUlOncvA5C9Uf3",
            "22":"1fO1iDzEQUt6NIjk3__IXPCIwH_7Z7_iU",
            "23":"1nrnqNfDwKq66reQWxEj6puUQmL79CkNV",
            "24":"1vsXsIk46YLuR7J98jZX9iPQDKR2HAMHQ",
            "25":"1w0_0Xi2Ajke-vPiA8AEeVQDCPhyx8ya3",
            "26":"1wb7A74lqx50Cvzj1876xcO6PXAn5Od1w",
            "27":"1xrgUJMRuc82CANvwjYDlNBrld1ca6mx3",
            "28":"1uvNfS5piLKU8GWAVJga-bye-hV3Q1ybr",
            "29":"1-AtdcNEmU7PP0-7wOjnZmMyAi6SUAhMK",
            "30":"1-ywMg_aaWIZuqUzJd87hGTNa0Z6wh6dl",
            "31":"11ONSnm5gkqXBVXWkt_doBPsd7xa9NwIw",
            "32":"11xo9a7gGJp8IYzwsg1kv0gAr7HVMIWTX",
            "33":"134MYNA_wlRg0zE_sxqdyH8INMI4qJ6UX",
            "34":"15Kzgugpzx6KFcerkOGLX3-CUCHrXbrsp",
            "35":"15sQqzu_iByzh6ebWskj8qEpbr4yArwyf",
            "36":"1BcC2L2AAeAkeb0CVGkolDxKaVoI3hD8B",
            "37":"1CkLV_G2MJ7BUyMPzApgQ1Lr1Nq4cbTmP",
            "38":"1Clj_vVuqdUtr-ItgdJOBVzTZTwfk31DQ",
            "39":"1Dtb-9Q_jc--Ecg3rKUe3oLoSO6lS9Aq3",
            "40":"1JJXaF8dlmOMgSFgTABItKDCfN4y-C3Tu",
            "41":"1JWyf690OXKLREVeF1NhMh0-VCQ__IGkM",
            "42":"1Kh9FS82FPzF31zQZA-AGQEeYOmcrDWUD",
            "43":"1NA8JfsXnnhRqBIA-WnzN57c6BgizL5Eo",
            "44":"1UBAPfDKe7kFAB2XtOErCbjQMMbtXssJ6",
            "45":"1Vo_l8KFDwUzirVts52O9PPs1WfO2oEfJ",
            "46":"1VxILWcGdtj40NnsgKKZw_NMDdcQzPalB",
            "47":"1W-EPWkaNhMKC6_t0Wg5OXzSaqIHZHZuF",
            "48":"1WWJcnOWFx8fROeL5J_SfNGRcBZzhT_q0",
            "49":"1XYJmpj2MjBt1w_PHXJIxF692LrqtDkNu",
            "50":"1YYxq6eSZAcR5FtJHl-bfg4Su3VY2bL1A",
            "51":"1_67H5fnsF3CtaGCP3dhaVYpdcFhbfti4",
            "52":"1_XM9KbSPeo0kkgc62zAaLSliLvBkdnHK",
            "53":"1bNPhnT9rbaJgCAXho93DpcdN5N6ZsRSa",
            "54":"1bUN30-jlFvw0aUxSRC8apfInMYt5J_S-",
            "55":"1f-Z9-d0Jg9Mcr0K0k1_ONihBy_bKMSdz",
            "56":"1jdCraPorDx3O625mjBc_viB-_c3lLFS3",
            "57":"1oY3BCsfet6jTpNxuLMjwbhmKhzU9eYGO",
            "58":"1onhkpMKzXL5xv8zVrNlqKKDYz54hMEPO",
            "59":"1usR_udCAMBtYqkHraB9GVCRB9SCM8ECh",
            "60":"1w7urv57Caj1_a7qXUve27k8PjWXhyR-c",
            "61":"1wx8ilupgp1QcrCW3oKL-IxOkx1-1WUgQ",
            "62":"1xDRhUG67OlwKuXeSWHSydhJEpiD1-VdS",
            "63":"10j4Vwp3ylIaRQR7tVTJJy1XggNDEWbws",
            "64":"14vYDnBNb5c_KywyEcbzl1WPUQgHj5N_P",
            "65":"16n95P2rMhw9-kKf0OlQio0DMfL8qTtBP",
            "66":"17252Xt9eCzGdIBCb_EjEgc7sMvk8GlAz",
            "67":"18JHlqjm5s263rZ_-PvjkE-DSsr8JtrdR",
            "68":"19iCKn6l0bwQLB0VsHtPIQlwXsXzCPCck",
            "69":"1CYkewv6fpMRltA0aV-LD0WmkI4TNWSoY",
            "70":"1DJ9-HRo_YEYoXgHoZwK4YxnJNtrvZ2nb",
            "71":"1FcQuZJlsyzkaWWzCsDBb56acSFKVnYJI",
            "72":"1HapQorqVY8-3lqdV6Y34TB97iSNWio8s",
            "73":"1IeXO5gXtQ03v0aZOqjEHVX1iD_p438T9",
            "74":"1N9rG6hzmlywBxdU9ZadjYxlZ_yutix4s",
            "75":"1POfKi9gW9qWijfWLhJWou2_eV9jGQaZv",
            "76":"1RpbtZi7Hic6mxVQJpurM-Mott9rJ_Q29",
            "77":"1YKTQEe-NXtDko4cTJtudEeuz2C1PCl4d",
            "78":"1Z9I59anbSoPQtbnlksqYTYkgsIHIVuqU",
            "79":"1ZdPbVOKJswDw3Sk8dKJa_rhdVqQPskRS",
            "80":"1_LCebp_9D-gwKAVsV2Axh3i1a6XFfEfy",
            "81":"1caO8cFJOjBpj-pULse05AmoJenP8IYx7",
            "82":"1fyANiOuiEdYEWo8zIfVFCC43aKKcE5dN",
            "83":"1hs56j6iwvQulSkZ6JlFX4vpytoI0FZL1",
            "84":"1iPBtzZ7dMWkDfFbhjfZAM92IIIQtEEiv",
            "85":"1jSbjCc4JQUOzq--RvoIDWcI9sGGlP2cn",
            "86":"1jpSpCs1NvYdskF8upr12AGLwlfVd3XaL",
            "87":"1kAC4YiHPkF_xTFRvXfaELh2AnEmLIafO",
            "88":"1kOezmZW_1KPjmTj6kqatuwsp8kEQM9g1",
            "89":"1kR1pVBtbbUT7ZiPUr6IpYcpJvv7LsfW8",
            "90":"1okGpfY8GE2YLzrTi35XKzqwRa8EFt6M9",
            "91":"1qY_bKUjeNZxFkrxUtcz77y-yvsT9O0BV",
            "92":"1qooJM8iN6WAEZWD7EvDdpn_cV5J9AObF",
            "93":"1rDLrk-iU1cUiox16pM_Mriu24Nhlq5VV",
            "94":"1raDno46huRGSWMlM1XIqfYliQqTZVwIa",
            "95":"1vcjnspkCLO9-g9TQO_ge_-E-2KjgYd_Z",
            "96":"1yZz3o0NcD4xrdvjfH7brU-c675Vy2v2a",
            "97":"1299WzXEC3HvjRcjKDcil9pk_fAhnlMjb",
            "98":"12x4Tps9Iq6U44xVnR5Zj0XdIxQhKmnNK",
            "99":"15S6Q8t3kW8FZXTVNC5Fw-RnzUGaw3qAA",
            "100":"1A3T6Xk1ejMYEpoiEQMieNe7Oy9687tyL",
            "101":"1EmTUwztzHf9bQs4N7MKnHaBYL8XJi8YM",
            "102":"1Gc7Q_kq83giRRx-z7FkXzN3R5o9R5lT-",
            "103":"1IPNRd1-7C1MNtFiVAYujohsuReGe6JLt",
            "104":"1K_nOd3Muw59FwzlQ31MTOXl77ABioPbC",
            "105":"1Lsx4EIqnLfpxWvsc3pCR0cDVUk29NhZy",
            "106":"1P591UJh5QaSSwijwTAALXlFe5IoXbGvM",
            "107":"1Q0hLJSuPUD0pFkuZAfRWCEeZqT-_cSaV",
            "108":"1Q2BiVKt3aHEuXtoS0PYx7_IbYcfHVMpq",
            "109":"1Rs_CzXKreuHqtpk9RBAvKQb_F4ed2zgW",
            "110":"1Tq9k2phsqaRXGnhFnickzvXMmdHaXgzO",
            "111":"1XwROyE88PWEeIkk-LVRO5youf3tNsWKL",
            "112":"1YQnKTexje8Ng6kzo-VFOB5kA3tSI2ymr",
            "113":"1k8w_cKLM90mV-tEXOvyXBDxhcmCIaWX1",
            "114":"1l6vpZ4VOhtj-8fqpQqJEJnpq2XRUOGHH",
            "115":"1nQR3NTMFiNHbpG-vuil59hOrA0ndRR6b",
            "116":"1nhDWxdKf8VunQk9xtBtUYDq7VO8cIfsq",
            "117":"1olZAuYQF_95F4JiqsB2RRXf9_R9Vxg9G",
            "118":"1sDxRcNLAe2y4dxMPZ8XjRi5bctifpVQB",
            "119":"1stTkeYlmKjEnGnHsMLn7ElZ9rfY0Uv9I",
            "120":"1uAGvp0ZHj0Salv0eH98lWs0cBscjDjQy",
            "121":"1ud8XtbeU_857TuBwz3Rdnke5XnbKXzWd",
            "122":"1vJ31bACIv53_u4es097C2kJ4ZdG0MlmK",
            "123":"1x41xZYLYz8A2BvLr0x3F-XG9EUFZ_f-0",
            "124":"1xGaOkR4SEGdV_Ri5RCPzern4DNdOf9Ch",
            "125":"16BSU8xAQS_9CqOmmJpW9CwHsUNGlavXX",
            "126":"1JgL9zH3rR4nmj4L7xu-ydPvsgiNXMN2L",
            "127":"1_C6gQvwZkGPfCYGhSr77xtKqoo1fIqjf",
            "128":"1dNj6ZqUoaH9EN7kPRiXK_eTyjzgHZHL2",
            "129":"1dj9vqSEXZ0ZE53SND1NSH9eX70mM99R6",
            "130":"1hxTDnm-7PRL7niKv6BAkuEi1EgpBajfO",
            "131":"1iFhhjWqBMrwZW7FgMYWLO5ByVBg0YKV-",
            "132":"1306omL4PXIsviMv92uxa9pzUeWNGiXmD",
            "133":"14XeDLzTvtO-VFiSCFeaK9HadF1zZrStM",
            "134":"18H5gzwmWkQlaypU6M7ZKhYLXqsrLeWZr",
            "135":"19zdY8F_VRSvPDnqjTxkYPsgB0KtWwjpe",
            "136":"1BA1TiFzMHx6O3PZ9qMqNZCA469UMWxfi",
            "137":"1BPjhAu_dC-6vdqy_uag6QOg1cH4B9Egx",
            "138":"1EHifmdL0joCQE-o7fBMJszqokdGWhnZp",
            "139":"1EXJjmlBB244rD6YUT2KNo-dY3ydbfIuH",
            "140":"1Eeny08XOuBOGKRiIFPLRG0npa6JZlRfg",
            "141":"1F_wfptS7xIqTmC6njuvXOMl_PNvKZj2o",
            "142":"1FfLdynaGtbEB90r6GK80apXZltmhQjaP",
            "143":"1H2DxFqGdyL-HjIbydGJlD1QBAnS53kfK",
            "144":"1H9dee0UqiOjNvgmgKr7jUcc15arce53N",
            "145":"1HjCPVyNb78IPPB2amW0rNVN1a6j8cL4t",
            "146":"1LEeXlPKZX9r8mATgRv3R13EmKGgUk6ze",
            "147":"1OkACllLHmoltFxeDuY_I8ThCxJ2Zudbz",
            "148":"1S8X4ZZTHCy9RZ6qv4SPO42AipSycInX8",
            "149":"1UKW_lvgC-W3h-qfHmr8xD7YgcWI93-q1",
            "150":"1UtGpP2hoyaJFA3x0g3G3NTwY2O2ZjtV1",
            "151":"1XSfYsm2ckTSdoxjbJN1m3D6NNOPWvaZW",
            "152":"1a9ModiCGtIe8Tvlw8gt2Xh185WaM_9Dh",
            "153":"1agpX4OU8LBSpLoJospEXGVDwE4BUEHT3",
            "154":"1dIPAqgibXpMIZsVZtaA6QQlKiD0FfBHj",
            "155":"1gU-1djlPi1AovcjYoFUsnnQefRnsWkM4",
            "156":"1j1yUkW48p5oBuQQeDw5TBLAda_Ur7pLC",
            "157":"1jqd4inzqjP_bwvtnfyLAxCIouCs30Cv4",
            "158":"1lkd3ZyOtS4JgXqhsFkXkdN7jBCQ2kYx6",
            "159":"1mDqdS7GvkNF4NWvgg-U1MVNyDDwmeHhV",
            "160":"1ncu7-IoXoigf47dlF21VxSPufuZly8NF",
            "161":"1ob86zlBchHu4w7VwuZqqnv4sBs1Asht0",
            "162":"1p44pam9Q00YnG6IrnlEn0qv5H5RGnNeq",
            "163":"1t8U5whIW9S39Czh5DlyhaA8PUva-XQtR",
            "164":"1tzFFTG-KwVg4dOyYJY0sd_5HngT9GZKU",
            "165":"1yjAze6I12A7m4mOy8Vrola9y863XUDiu",
            "166":"1-NX4f_i3ie73t6JOLF_0C5C_9FILCuBX",
            "167":"10Iy-AT0VNmN-SHJxav6g5lPrkoMmmfyN",
            "168":"11dFtCSNPlQPB3WcqzAeCFctpcXTRybBM",
            "169":"15XM1WF0pIa2302av_xiqORtqKeCaJZQr",
            "170":"1Cf2YxobrZDmrp3ZJdjF-X8oYfg56c5fj",
            "171":"1EPrf5tUamJh9HddWjBdEXrVnkwv120_p",
            "172":"1GJg0SutojcisbrjrIczalQdCBknOwmQs",
            "173":"1H8miqjFIkTuUumrHANn8RzNfiUlBvwao",
            "174":"1IPltFauzT73GBDDnRUmPKicKzQ7M2vAe",
            "175":"1LzWbkcn6YRAPyFzHlXxLR2bfoO_nYFlA",
            "176":"1NtUj6EJu6OL3NQBL3zfoR6b6BieK4JR4",
            "177":"1O91VEFRn8uDWpq-T6N7ltDdQJJgsaB59",
            "178":"1Owt6BUCi3Mm2HS2HPbDLxNvHNFlhLwr6",
            "179":"1TwMytIOGK90fuQ_2ugVtpmvGq3UVvbBs",
            "180":"1WSup9u7dDIn5cKYPaEl-l4__5x5Kat6S",
            "181":"1XZJ731rDeuGk4EZ1HI9eBIIU89LmrIER",
            "182":"1Y_3946QbyktkYwslJkBgkJ-wKEuMAEBM",
            "183":"1YxPASPQyzZgUbw-PtPT_mksun0MQpVhT",
            "184":"1ZQylB9KIrVXf61LxU3cgl_9oNykViAcv",
            "185":"1ZgHNkJpUd2D86oBuvO3pameWXzAJokZY",
            "186":"1_JjVT7jPYZ-CiQkdUDFFYUrhYX_53_Ct",
            "187":"1_UZfMIuGbBsiT6SBrKuNOYIK-lM8eg5H",
            "188":"1ckJxhXU6IV_2_K1A5tBKZez6bXap5saU",
            "189":"1cl6-jUfVp9Z8EwpHI0kSRvCd-kRyQaqK",
            "190":"1fu-riMiBt8-lbpG3wpftrA0x7ikbD99G",
            "191":"1gMbK9KJBgACDcoQ1ibpItlrHM9B4kwVg",
            "192":"1kBllcI18G3K3W-GoB5GClNAvI3CuOjYd",
            "193":"1o26836vN2mwd1dB-H-gybw9oUT_iTG7i",
            "194":"1oLDOMVNl_rh3M462z_FA_r59wN_fy2DE",
            "195":"1pCpWsSzvYrACyFOrETPdQYs4QGUvYSf_",
            "196":"1qCsJlY3Z2ODVQTwvWTFQJ0D7VVfdvh78",
            "197":"1qOGs1tMY5Nl4jS6J0ET0jjvVy8j-_dm0",
            "198":"1qsYtM8Yks2NKOtffc0y-TgCOGtMBQipK",
            "199":"1rXJWacmYHYZ5q3wGZLvXsJ8AIJcYbatu",
            "200":"1sn4vtJBObF03VZOlwjSH5lfrNc-51xme",
            "201":"1t1Ky91q9tz6ZLZQruJb1FEYtQ0BfjyKg",
            "202":"14KmJ2fbdGs1MvsPy542aZZ_FeJVOFKUL",
            "203":"16rJmr_ex2_FR0fXugqQ_aUXYKqgON1QL",
            "204":"17WghEI-Ep3uyScjjDInTPliFHqSoPq5u",
            "205":"187AuHiYsHR0f_AIvQby2Ixn4tjzOYOO4",
            "206":"18pVHfsOXJE_UOYQYDIH2QA__Gfd7dYVa",
            "207":"19jbeDNa2TqGKlE3aU6IjNpzmsjfp5GTH",
            "208":"1D0Mf0lIL-8HZtRa58cAZLx77LiA_1dHe",
            "209":"1GOvpjPfpo8EBsvjkbsj6FqQoIbSoeL7U",
            "210":"1JiudxztDAF4rAJ7FVRRULjOfXMNMhBd5",
            "211":"1MykwXv4tUcbSUKtUDxNGvo1UvrFC1i5h",
            "212":"1Q3qAS1b0eFxB6iUknhkcOOmOHGocNlta",
            "213":"1TGCb1apbvXKr-Qf7n4WK6uHldP5D0DQL",
            "214":"1V38tDi4BYuXzDtnmLLYJ2SKHJY-VKrrr",
            "215":"1Y6c5SNKHRoFUwFROmC1EOT7Tvkwd87-O",
            "216":"1ZwIE-Rb1yw86hCoTI4uYQNKCagHj4vw_",
            "217":"1crROV17z9vsgCgUPwc3flZiwZgMLL9sU",
            "218":"1d5HQrnwEbzFlDe28QD25jN1EihVxkPva",
            "219":"1eyzVJaZrY_bYs2WK5KtfTVHGzmH8bC2M",
            "220":"1g7EQj3IEeKzhzacwT7_8Ol2RGEvvL2vw",
            "221":"1jZ-NSiXS6PvLU4c76iJueZR9Ev7qRKrO",
            "222":"1xId9rPrx4HdUJUq6R6XAcGNr2s1a9GFq",
            "223":"1yz6-FW8akstpZPaitPPxi5RE7NX9wv8g",
            "224":"1zYrLhdMHfUfvNuYSmFlRQuon2FpiVXVg",
            "225":"1-FcLqDSyjS1dhYHRQZgxIU8ZdlwoLSiw",
            "226":"15dvSSB-SR57r56WVVvYygXMRGO_doUXD",
            "227":"1DAHrnLxafFuSsGeiLwm1cTGe1JVe1RXz",
            "228":"1H2Ekqxpmfv_qxvxJbAG2Vq8TkPQTTkHC",
            "229":"1IvrPsHs43iKzeydtL2jQ8glVaLMuslsv",
            "230":"1Kt26ksBL6Mmemww0MlNdNxSaaK-3gbn2",
            "231":"1LA9vPh1ObqbGGPUc6-0hHL9zlu_TYvLV",
            "232":"1RYWrBRR2zTdURPo-HHeZeySWhFom3NDk",
            "233":"1VN2UKTb9VZKizWQX9CPiOCTJ_VBO5RkW",
            "234":"1ZmdOQhDgN8JTGI0xbmWnDxOCi5F8Sthd",
            "235":"1e6G-draDhhG-uBhgKLioUOUMm0cqmUYU",
            "236":"1laOwSLeKhVVauMfjAd-MkLp9nesnZeqM",
            "237":"1qykbDveWOL-UbXpcGDXr4-5OgfY_CRY0",
            "238":"1sTrMHs3R3OpiR26pJzMypqjSIDVXR6QB",
            "239":"1uuirZx2jX5QZEjhu1O_s5s2Iw6DmAvE1",
            "240":"1wJa_1n7Xk5Ecz_L8uUt-6owcsJZIigR2",
            "241":"1yGDHz5WTOb1UBDu1il5W1fgDK-Vi3ftP",
            "242":"1-8Ze3VMCXSCIKGZ8ZVc4KUCspX-Vd5Re",
            "243":"10CooqbpjT3-XsKfKh6Gwu65JKkIQfV1o",
            "244":"12WT83-rpL5Kg1OsRgeRAxF9v6M1uM9Oj",
            "245":"15OjoCNm35syeVRXWU9S7mxTBEAv_nZR8",
            "246":"16jkY8asrb-DdawbzN4FRmloBx3IPXb5M",
            "247":"180_hso-7Wbf3Myl14orInKBJCIm7OEwH",
            "248":"18TT-qLDoZyBIii8D2iyWxLuutNdcbfcZ",
            "249":"1A1TQFqaT6xdVAWwZ36-WH6Hkl5LdHU2I",
            "250":"1APGkG9ievaWZ0EofiXRYGPzoLQB1TR6z",
            "251":"1DdQrnyyt-AWShJm5YBAPcLI6he-Ry_HL",
            "252":"1G_7LTSkyjY7LN6QIFz6CZx3XylSNTdIC",
            "253":"1GrkVBXaIo6yFrPTZcU6pcTn1QoSy9dZ5",
            "254":"1KZzXmLjdLNCaF7I0NHmjaxVpM6HZHNXE",
            "255":"1L9q8038GmyA_kq06fH32ybpZkWwHFlRR",
            "256":"1LCjIkC9EpEu6pRx-ptzCDjGerTmZSSUF",
            "257":"1LXqKdD_N-BLEjfpfw001lgsb_FFIPnkC",
            "258":"1MLXHcHjFPNGyi0uzlog6Nn8JUU1Uv23O",
            "259":"1MjQZ9JDHuR3dYMY9ANX2LDlqtzZzqCjJ",
            "260":"1Ox0F9ZZjniDYp0Rw1i4XWwdArUjTioTy",
            "261":"1Q_tfYaCN1SwE6mTsiQk0j_5BuN0rdbXh",
            "262":"1RwpONvT-mHeJqNYUnjINFzhJyVOvnRVy",
            "263":"1TGrYPmjwgk6RlGfLtkbiFPAn5tFy2HWf",
            "264":"1UGDbGd4FcOZZYphJmQ7I9G_mn7ooVL3d",
            "265":"1XHeGEOMcEeMgp4vEMjV3al63igpHdMT1",
            "266":"1YYNvwMjshaIDu8NYrOXADtPStTdOz4up",
            "267":"1ciZmgwF4EgukuXHwdJGhQ8cpXL3c8eSX",
            "268":"1d63pWup4LArxJ2RJacLDnmKFvyoYDKBH",
            "269":"1dGl2aJtit7V0rebRflFCcj_m-Y4dp-nM",
            "270":"1ig6P8gUOxt7WS4o0pQoLepPK4OEyTk17",
            "271":"1jUGiVuZHVjahWjJnnbEpNbKlPGaxCg4O",
            "272":"1k9dVJbNgb0mcZ2lplxFHEbi8zLcaZkcm",
            "273":"1miEjZ7FemkBD7iSgkHWtiqZV9lfj-grR",
            "274":"1nkHbvsKkGl-lvLZK5W6bpRnxyl154beF",
            "275":"1oBROOLeVwZMGJgcBJTidXZjk5BoJth29",
            "276":"1rQ0mvbIGT_USRzpOnhO3vJMep3S1U192",
            "277":"1td9eB5e3davhLzkP8mP8JvkUKt1AogAA",
            "278":"1vCeJSDND4pVSPD-qX7Nea5LBRF3RIYJp",
            "279":"1y9mbzpBU-bfeD9XtvdUEgBeWN0iccDEF",
            "280":"1zT8HL9DPjPc4lCyCAQX4LllrmFXjNOcA",
            "281":"10DUACTQY_lLPMiEj4d09TLnXrm2dQlAP",
            "282":"10OPCM7zGSNPbwJ6YisBJ9WJDQbhhxaXo",
            "283":"10bFtGp480l06jeDe7MTooCDykPfVyfVk",
            "284":"12abggFB8W74pbzhYn2GUtuc9Nylp_KOc",
            "285":"17XaEnOLFR8rAmULD7_E17RipH8WIStFq",
            "286":"19XZkN5izIsf5V7cwMcqDtnDKe5kKfIm5",
            "287":"1BS0J7dwDEux8LYyAnf7A1goCpqG8vpIA",
            "288":"1DKH41b0NfY-NNE9QUMrrINFh0AdDAuN_",
            "289":"1De6yEnqzkW6R6p1cLzq-NsswesIgxIBQ",
            "290":"1HwIvCudkvcWT571JxeXkJtpoihVwEGVh",
            "291":"1IW1J09FkjM5i59umsBJG_hBI79nVerMe",
            "292":"1Izo3gl16TZGNqf6gHxRLfaGY96K34tro",
            "293":"1J59y5fZhwl9GSD10GzuqrSpEJW1jqh6u",
            "294":"1JRlDnzw-9uV_CBdCDdTu-TAHebAdeueW",
            "295":"1MFC_ALYJs0V3O2VyCheEJkuGBELQtiwk",
            "296":"1WYSYAtMnVhBFTVUydELfkLa4eycS4KJG",
            "297":"1ZWtp0b6vGpfwB_z0qxbod_dbVqk4Rmyn",
            "298":"1ZdHjjUHIkt3_Wc1T_cb3w_s2bxhIjo1F",
            "299":"1ZrqnS9Vdu12Eb332v0TgLdo4jrC0jg2h",
            "300":"1_0WYmY_EBF402rOcQK9RYaJDXEtFXmZ3",
            "301":"1_QQstTH7_xqley0tvecabQ8HLTzeqBBm",
            "302":"1anuGfSXvMmJLJLdily4bXD0DfvGswxzD",
            "303":"1cDsxB0RAR5p5y1vEENRxCeBE4orV3tSK",
            "304":"1iJi2GdJ1OHo12e5TIb1xwlGjoutuqIQC",
            "305":"1iTj7HAJRdzCOGiEpDb-T55j2MX1LZDpv",
            "306":"1ijn2ZABNA9qjXr-zqR5rNcwdAwVGx9TX",
            "307":"1jax7a17Oy3Np7jrlj7SgsGVyOEgvwfHj",
            "308":"1mlFtNW-GLvrIko2mlIpiHvVjyk7hMR5m",
            "309":"1na9IZwxC3DMi3JAaitub9HP_vshLfj63",
            "310":"1pMWQjCAEzniUOHh985NW979aA-ol-kZ5",
            "311":"1rdHK9y6TpJ0NckZK_dZmGeOMOXa2LNbu",
            "312":"1sYbyA33jEm-wl7thy_piJU6VcmP1QUHA",
            "313":"1t1kc9ZR7ExpvnAYcoPazypcov8bkdo44",
            "314":"1v9rFexfCnMd1mzU7ZJ6DI7y5V4euqZUh",
            "315":"1xJgKJ3NOO5vGfKZh8bochwkozpJYP_ul",
            "316":"1yuFWkJKgEtEdFF_jyJlgJgEYMaketqfq",
            "317":"1zIc_gW9l92BP1G4tUlytty66r_kJ5QV6",
            "318":"16k6bqtT1bK58c07OcZzPRfd34TYHgjkj",
            "319":"1DpzlIrhRvoTp_QOvztMCSavKn6wlPDRX",
            "320":"1IZgV7VfyuZdH0E2iQBGE_Q_wfJQukSGO",
            "321":"1WZh_vnxWhWtgD-0VRJR5QewI50N_1pB6",
            "322":"1_dS8w24mysMxLPWjos94LQLK1JBSfSIz",
            "323":"1f24F7GPp80JsYz-y9eaBGh8HGdvNBF-2",
            "324":"1mBBX4RGU_cgezwPte4rC8N2ZYCpDQ8Rw",
            "325":"182d0bJ_XeXWxveKPWZAauMdI6elOHHip",
            "326":"1Ku1T1bW5Pfhl_ARkUi0h3Vq6OuapNhCE",
            "327":"1cilOxtORdM8l_3PHhtp_fpFJrr6l7kix",
            "328":"1nya_X9J107zux6AmBERbMaEcadqL0ais",
            "329":"11qbWI1ajMF_Am_anfxnN1l6eEVCidtBb",
            "330":"12aLD3OQY4jobFxmzhEuGdQ60oHTfWZxx",
            "331":"15ZDKLzMFYe9wpuDBFZeOLsCGCketZxSM",
            "332":"1KlPn7tNmZaUFQ6bVLKhSZgJYIpTP3nkk",
            "333":"1qiKonI3NJjbmHsyQ73SvA6MfTaG5SFM0",
            "334":"1xb4JxzbgoFD_TsdzLhOKW2Cz7nwh2iJB",
            "335":"1zWSG18ir9QEIbWldNIMO66zEni4vac6B",
            "336":"1CH1_6wa9IH_nOEhko0BxWS5gdfTrTF6q",
            "337":"1It5KQFRrHzj7odcc5TBi3Dd5do4AHc4g",
            "338":"1SgsrNDkpja5c84WBdeHXfUJw-uOrKTOT",
            "339":"1lHz-huO2NXcYA7jJ-oPbxXSv5TqBUUUI",
            "340":"1mJAX9IMxhBn46v21iIKf8DGapQytmpLk",
        },
        
        SEKAMPUNG_UDIK:{
            "1":"1yk3vYMbaRBKM1_i6s81AvcxFcgOMjO-d",
            "2":"10kDEmY0U3jR8sywQYEVz6uPYgeT1TDLR",
            "3":"14UkWpVLtLH-_P5ACdkyF7gQqUCEkc5Qz",
            "4":"191ywmJ2iR1ze3NNS4ZbxBZ8R89naAhcq",
            "5":"19MET-awWIueVgjXoWGQxW-gKeASD3YqQ",
            "6":"1AiYEDjS0DqtexJ1JgSZyh_jXdPoNXEhV",
            "7":"1BQXYIAtF3nZVbFAYyqfbMxVFly8t6JHO",
            "8":"1FtPOCXTKQHD3ggztmnTu4n2J-G2lVuov",
            "9":"1GxdrsSjHibDX_X8-Kr-9N3fApAi9ffuf",
            "10":"1H7CmexlE8BR4yqMGmSHXJp8hLf9-Obvk",
            "11":"1Mw3n6Z3FcnIh1_-2FUxb8sJRSDDs47i0",
            "12":"1Okc8Fyqwibd-GeDOCF4-ukLnI_CQX4LH",
            "13":"1RMKK26aYgxe7pDkshFanjov4_MqFjZh_",
            "14":"1TDTRvr3VxCpwYr94YBGHLTk2zSCcR4cm",
            "15":"1UCFS1mgBox2GlZtMxcYOLC0mOcxjkZJo",
            "16":"1XGn8Harcdis8rVdeaMZK-Y9ZWVH5Zi5a",
            "17":"1YD1IrAcM7IkcMYdkgHg9kowqbfDwI6p-",
            "18":"1ZQSm7Dz_PL5hTwNykG7bdgbjA31gas2A",
            "19":"1Z_r2RIqv7_twMXjokPMORECZJdMH8Qf5",
            "20":"1d1fkCb5YoS8k9VtqXcCHQWn1UZbWeizs",
            "21":"1dPG4KptVrmuG2TFd3iUjngJfyMzoiyAX",
            "22":"1eStNoW8Qj1gElOMDA8RQ45vLN0sW9Kuq",
            "23":"1fNvatYC8qktTY3ypeOVd0DIpRrVAJJfh",
            "24":"1kpd37vqrVgL9OhoOfU8QXmoFCVjw2F0f",
            "25":"1lQfLBVEGfEDcBJo893fBLLsuS_4nPEAz",
            "26":"1oDOh0gYW2LMKBbJWnLxfJyvWnZEAT1zK",
            "27":"1rHKZDAncXUwU8xI3vXs6YueRo02NBG6v",
            "28":"1tR-fNtOemRomj_HwrlHnBtLAn7v4PQt0",
            "29":"1uQ7r75I47i_Nk4cs19zeaudz-OI7j-8s",
            "30":"1yHIxkxlKffHhIjv0TyYK0IDFWBmOVtcD",
            "31":"12jbTPxFsdt2BobNVLpIMrJNEm2Fh32EM",
            "32":"1342xxN5qcVtjGHfXkA56PqxvJ-M-BZ9S",
            "33":"16ykZJI4IKpcPaEWvYV5RVtcIX-FuFr9A",
            "34":"18zS2bEtqT6x6ua8f8AAGIIDX2CdV9Ehl",
            "35":"19vE_qsng9Jk6t4vkSTeoB-odjRP0ivb6",
            "36":"1DIzLqJXR7evCDocoIhkcY3aHntxp6-zz",
            "37":"1FW20P0KBfevecOSTpNsyexLXylkkKwv3",
            "38":"1INZAJ9SakpkfNy6oALMst-G6RbKW-3bQ",
            "39":"1M2eUDheyd7rLnpXQMgG5bTL0FNS1ArTB",
            "40":"1NAhzxjpCS5I7ztgfZV4kwbQrfXRumsqs",
            "41":"1On0Ct8HbOlZKvSMB3RX5RglJ9y9m37GW",
            "42":"1RZTLO7NynV1EEAaVUeXS0Sqg4tChaBBR",
            "43":"1RyuIQM-b3GkYdcM0Om7oJbSK8kGwkUNs",
            "44":"1WwtmiSseOLP0CabKeKhEQoKRXmL-rNm2",
            "45":"1_1hICyLkIBn843Qhwa0PMACvI5hK5D_P",
            "46":"1_aTrBKiE54yhsxPeMmBaVWJIoVg0b0UV",
            "47":"1_lacXqwCHrC9EHwbMvTfWT-pmMMP8NBi",
            "48":"1bBNbEjr94zQyfYy3JnVWlk0gWsT7IxvX",
            "49":"1bqiDin2c_EZO6XT_j6p2rEjC393XBbCs",
            "50":"1c_503shb6uNc5PFWbqHUPbX9sdM5G_Ir",
            "51":"1dwMiEttHjjBapBzKvBqnpR3ww2rGy6My",
            "52":"1jGzRylax8zvUbO5GP-Rqi096keyeJv0Y",
            "53":"1mLFFdX_st1ncpEAn8-MVpRgppMemGEa8",
            "54":"1mLZUi9T_743lNheljYJr_9puB8Zpt7VD",
            "55":"1nHbUTPiY8JHmee37oJQwQjH4fgttO0Yu",
            "56":"1nSVKxoRmQtVyQ5ILG8EPP6tcdgiZ4QCz",
            "57":"1owDuovBzmBA6N9PSG--eHeM7O9ttu6l4",
            "58":"1rRcTF40RC1CX4D7bwnQjqwv6H968wLSy",
            "59":"1uJAO3pHNCcdgBBb8DXwPjrHPw1eTtTdq",
            "60":"1vZ5izwt4vaDYQ0ZxYjmvEBJBPgc3abT_",
            "61":"1wH_LGwyyram7WYDvev7FOOB4gyAXuJ9X",
            "62":"1wSnHsK9Sf6v0YLdegmnJoEboCdZb5SVN",
            "63":"1whXezPObh6KjOJxSSK-i6CWFisyKMwc1",
            "64":"1xMLEJce_ijcO8oVOPb0Mx8KpvgU0yE07",
            "65":"1-bcM-RAWeAjSTZToPHSGRApBPrPbudqG",
            "66":"12jE7AbmLXuYikV2dBGRsb8T9hcyPNAzj",
            "67":"13le_2NUAVyW48Cuf3ca_8Ry3eQe7vzSe",
            "68":"15KwKeJ_BalaqeYbbaw9QPNAsRZo0tdEB",
            "69":"18IxscYzA4jiIHZInZvPjVH1y-ZGiPXmS",
            "70":"1A3bbWCVrS60l8PVHyJCX3mDgLphdgzux",
            "71":"1Cy3zaKofZyDYp7s-PzZ49zoEXzW7TfTK",
            "72":"1DNnXm40pbWo8Z4Zpek60-ehctImN5Hga",
            "73":"1HVUsq7P86gJ6rqaRUa6DDSDSrt0iaJ6Y",
            "74":"1Hjz6rGccEqwWn_6rkm-ak6cEB7Cy0IyM",
            "75":"1KaLLIY8uBC9piwArf2cdBrVlIVqGrRug",
            "76":"1KrC-Pdm_hcn6Mwirh4XzgwSjmSSHZL-S",
            "77":"1M2RDREAGGwQGauXiGGqhLbbi9_32nxz8",
            "78":"1NmRRadp05lWCgBKR8exKqBYPYsftDpCb",
            "79":"1RyfBvHnpvNUAPKKevlXKw-yfbpqQO05u",
            "80":"1T-VcFV9oYV8aWh4V5htvbCIcLwcpNHJF",
            "81":"1V1p7qtw1SgzFT7NZpstgJ4nocBjQjgkr",
            "82":"1WCgFTUtJoSSSP_N-dvaFl1yzv3TnGwpN",
            "83":"1WKDWT3m00AtDjjxFYMAjBbcxrYZf4pEN",
            "84":"1X4ygsyrOBD2KSVcgjEeNbZo6dbwq2Ed5",
            "85":"1XJI3WAxfMTbxryBtEtI9HOM24QaRhMVI",
            "86":"1ZU1nl6PpHt7RRQqt3X-IzOnB8nOudWYb",
            "87":"1_hwWASHQWd79oo41jclna4emw7ojzlMo",
            "88":"1cR4BiX_WdgBe-MkpxVDps8UGlHlYwuYm",
            "89":"1dwK2jZQYV70ixfq0GQWN6QYCpU_9y6ef",
            "90":"1gGiywfQmtGGXVM5x67Y9tezrOY0TbH4D",
            "91":"1gY806QizvWLOZHlOb5TXiCUeP0I5d36c",
            "92":"1gkSVw7pFuHxxKVtibgJBWym0g5Jef0GS",
            "93":"1i5bvBrdofSb2L0GB2_tLsyuSharheiO0",
            "94":"1ndtjc_lS5Neb9fdQaGOMX164fX-91Ylh",
            "95":"1qBbljAuoe0X6ySkZywr6iYtDGxNc4FwV",
            "96":"1rM0Qq-3Uf8ETCAgSRNG1xIYFbP3Qa0zw",
            "97":"1stuh8W3U7QxxbfniwLK2s0r0saH8rgC5",
            "98":"1v0RS7ECfxIxosF3DJOcLjpIFcdtg-dWU",
            "99":"1w6kbOEq39hqAHqqjF_SZwWpitNg7Q7Cb",
            "100":"1weCPUPiNTUyO6AIxOb_uJ4pTK9Sm5pth",
            "101":"1TAdXmopxuD3d3wdaeoGoPUki6sOklDgo",
            "102":"1bEDsSekviqvm24ITTz4lbsvNibNC5RX7",
            "103":"1lU65TpxF51sfNPuAOn1xPjMMUiYeN3el",
            "104":"1wVSTFPqpKMNQ9FziYhhRiOsWLmtzd5XH",
            "105":"10w3ZFiujkjsbfVcIDtsLXMxYO0yJL0Yc",
            "106":"114Zr8w1-RIJvWUHI8PA6CaDmqwArj3Yi",
            "107":"11OVRUT6HyfWShP_0o6_p5UyFOHvMKok2",
            "108":"1308rBhOgGcOo9d5bPEanbc-5E9ARjoKD",
            "109":"13F8LbDbAn_wQLRq5eCvme90cEIuca88Z",
            "110":"13WYR1BjB9MTyXwW4FB6m9q0B-iRh2H2q",
            "111":"14rJYYquYTlosxtX_BRM1Jb8vRnxr3sQ6",
            "112":"1BeLUaRdESlbmqSr-iVX2t8Kxg8tgMjgW",
            "113":"1DgASnDfmLv74UBsWiKFInG-BCv4QBmQw",
            "114":"1FY36YbJ6-VfPl07mE3Qkb2E2HPRtnF5g",
            "115":"1GAhH-TWdWHFyUbLiX_4wHg4kq-t1GkX6",
            "116":"1GF2CjxMlk2u5llv4Xtpl2CJAtsaNxwrj",
            "117":"1HfaPJbGkIBUbEIPLhTvyXwYpo15Ey2uK",
            "118":"1Ji7Gm67oCJs_BQnvVuxM2KWZ7T7NqinJ",
            "119":"1K77XeO5j_qF6lMqZs1S0OeEYr0Y1UiJI",
            "120":"1N7OLYuazpGU6VMZf1eaAxIaQkHPqJg8m",
            "121":"1SCRuuktuTWPNSeRCl3d32xrF5cmGFHd5",
            "122":"1UMsw_2yWEDGmqZUF0_EsrBZf6DK59YXd",
            "123":"1UlC-55PXd8PYr_RkqnvWioAPz0s-7KMF",
            "124":"1Xxc3ZJqVz6ijswqbs5ssxKAypq7Vqz79",
            "125":"1ZClAr4hxc0JwOtGZ0crCMYgoFTYUq4Ee",
            "126":"1_tfzcsXuOLOzCq8H9gH_F_GdF4DWJP1D",
            "127":"1aA_KSagoodPZl5NLIoDjfOSRMior7HDD",
            "128":"1aD6oq8dPkP6Aj25SD3WFNrpkJzvTUGr2",
            "129":"1avYjc63A6FcVpVzgJ8i-rFC6w0CDcyYW",
            "130":"1bvwI2DsbvS-hNLU7ZzwATFvfH5lhL1Ce",
            "131":"1eJ0BTsxuJAeaYfU1M9APGNsXfTu0W5If",
            "132":"1ev01umkRB9cbcgfyrGWN08Sadh7aMtkv",
            "133":"1fdcern_cXsK7jD7hJNCLq-nIBIPvJVna",
            "134":"1flITjy6r1JVIdAIGv3kftjYbwoR_J-ie",
            "135":"1jDdz1X9oAPcqM1Hv-wQvk3sxE4jrfgEb",
            "136":"1ksrdsRz_GL2wu9TXNc9Cn-j1-D9IZeXQ",
            "137":"1lhDyCz3neakM8OjyxuBgGMpQ0Iq6APOv",
            "138":"1n_miJlmq_b9K76kyUFF4GROzGjBUDK6w",
            "139":"1pRYJ8uSMr0zIaDzKCR7vzSZi6X2JhLQn",
            "140":"1qMpC_iHNBdYzQeedcN-nEoYn80Dnx-dT",
            "141":"1rQPq2zhlKkosWTgbfAaGB-3obJmR7mKg",
            "142":"1rfWJquh_7ldJWVurb89e8k8G2OVxvNB1",
            "143":"1t9xQ-8Kjpj5plZVGBMhQifICVF944cVA",
            "144":"1w8yU2niIlR2y3kfO_nG4xGzof8PTDGYE",
            "145":"1-ZLKLTOuUrpEZ0NpAH64V-zOSkvhZ6M6",
            "146":"11Rbc1CqTDJECrrTeuOIvmdSSUDbzzOHa",
            "147":"12-aM7Aqi_VuZ5kUz0GGwckVns0_C3Njw",
            "148":"12mwc1Slmhlz0PHpRdLDGdg8w94BlLIhV",
            "149":"13PJ-7SZA2ags6VyWqM-o4orYngiviowf",
            "150":"13S3UtJzKydA_Fwb04f8FsbKpuLZRMgx2",
            "151":"14jsJ-4li6OJ6D8MIn3rFQhbEVA5vgrXP",
            "152":"16XrBS1L36EsfBJau_mJaVy9N2LIDaQ89",
            "153":"189yl8pENB0TkOlPibxeRc9kjycygtiba",
            "154":"18tyfT-5wolcw8XCMfSw_IHwvWoanXm3e",
            "155":"1AC5nPhunjw2TYH3l8ACfF0XP2skXPanJ",
            "156":"1CAewiuaNPa1DIAjLN7e-kg6ZI_kZ7EH0",
            "157":"1C__VPeE6rSGG04MPT1AOoXd4OPtpBMre",
            "158":"1D9CViAMx8dW4P6dE4zv3qJ5TNMvt51IX",
            "159":"1EwxNZQIskC0mWaumwVHbThTBvmJCkyRz",
            "160":"1FZp8AWFOCRASfEGjjNc4KmxacT3kTGqs",
            "161":"1G2gKJOBpTx2K5m5xcHMeX7ZiT5_AqRfP",
            "162":"1HFFUHZcRH7tejqvsiP3Nl1CUmBWP1dZb",
            "163":"1LkZmZfj-GiIdCTTm18H-E1kUWvK-d0Df",
            "164":"1M2kiluASGB-y43NkEjU1bKhLxS-gVV2T",
            "165":"1S8Zv2DA62mU2BPO9OYTx79vsz6wIO57f",
            "166":"1Tm-SydJnv07e4u_9hA2s4p7XL_3zE_Ay",
            "167":"1YFlj5NCDD8IiMqZHFVsREl4d1bMXWvZg",
            "168":"1bbpTHfBDkgmTZU_UxxA6MC3a30kZqdT6",
            "169":"1cMRUeTb_n-sGE281YzcLm773bh44nUa3",
            "170":"1dHAzbV_0yJIvf73-aKXt1DX98Y-m3hGo",
            "171":"1gGO4SFPE_bvRkCmAVV5aPkZCrP1MTd5w",
            "172":"1hGCvghcJnCbF2BJOiUxQEAs40nycoYT7",
            "173":"1kcFRHVxuseUfW_LYNlVDDqbqgRlfxgTS",
            "174":"1keVWB44wmVxibQp3RX52sAQJY8qKuSi1",
            "175":"1nFILxJV1d6Z4iTSNo7MaUxz7j_zDMTmN",
            "176":"1p_yOocnE8dcD9yXV4CVjUsh82U6Woz_J",
            "177":"1q_PxIgUZ_Pxh_oKDiAK5Uy2cSZacLOCg",
            "178":"1v_fK-1jggSbzm_d_4WuM06vci8Pn0VVt",
            "179":"1vc59whb5x_bl1DLuUOVr3PV9yb1PDVEH",
            "180":"1y9nXycrQqF8aUY9C97mEafv8K8F4WbqC",
            "181":"1yrGmIC7As1Lvx9nTlh22HIgqER0iLDYy",
            "182":"1zDoqIahnMof_1mopf-GWqewPrkpJNIcu",
            "183":"1zo2Nt-JOv2oleOFBnOerheLlQnk3gBP5",
            "184":"1--_EO9ELGHTKkN5NGPW7TM41bn_BEU16",
            "185":"10cygAeeNra_QofsKWOLUyJD4PHgxX7Mu",
            "186":"11GZ_Jxp2g9v8uZi_lFFI7uqK19oLQO2v",
            "187":"1CH4Dy7F6fjIwEgE_mL-SyDexkhOj0fNF",
            "188":"1E-BxcBZ5XLOhCBdZlolsEWVR6hOyAO4I",
            "189":"1ENTRHqmRce6loMBMIVK0CBxlbogA1ATa",
            "190":"1FkEUr-45jOt6GUhySvBmqP9ZjArVa-FW",
            "191":"1Id8hcKr9MlmFwkvrcZuDBvnfhFnGUi3e",
            "192":"1SE8SzNh1C-HTkVj3Ygi6hMFzf3-KBZfk",
            "193":"1V-6EM1HdKNkcSguCOXbN-CQHi2N5zVpa",
            "194":"1V7eQWVORTzczCv-_vSljtM3wdDRAMV4d",
            "195":"1X6irByb9mBImtwg7Mz4rjG4LEsdFk_O9",
            "196":"1ggYHZZmyZeeEZVMT8Z9WYse5IZv2wTMN",
            "197":"1lOXoRTeZjKdhISsb3DwT9X-MUpCQsQCb",
            "198":"1mFzx7piGov8zVrooRoEm2MlXbPvzHvtS",
            "199":"1mWTa9NKv7v683WAJLlBaGAIAKOIkZqFK",
            "200":"1w2YgtDY0l7Q0wSEn9PFrjxxmmxlsNuCE",
            "201":"15OxQVVM8PlMuteK042tq5oz6W6Vh-ssp",
            "202":"17IiBoCybTLHRZYZeOSAV1cMZ0VSu_NZW",
            "203":"1FQERjO5vnuUM0hDJhhFbJifgLuqClALh",
            "204":"1M4FNA4RxVMQXndGEaH3Fnw79vk25Ob5R",
            "205":"1MNhF7aSgnhyqAQ06jkAomedD8bKn2m6i",
            "206":"1Tke0NiHzZWe9T_PPoQeyvUVraVOK2ELi",
            "207":"1UBlECofVDEjQxFlzr_bXkJ_bn8wWkHHD",
            "208":"1VeukbLuwxOQb6lVWCzeGKr_897dbzFDu",
            "209":"1YBSViqpF9P6ZEMWxZVwJcoFPpf5Yaobv",
            "210":"1YGSTligKivWXdgzh79RHqTr5f9doBqqZ",
            "211":"1YlMDwgQbRFaODHLttpki63UQB9urH2M4",
            "212":"1ZhrGM3y6HegL184B6lH1aBesGMcmTxBm",
            "213":"1c4U-ifZ9Qe92vjKBV3GXh1lmUbKdToDJ",
            "214":"1jPUjWdN_zV6wsYddaBOz0ADYXnIu0vsZ",
            "215":"1oogIjGlDISpn4uoIIoBurtjVwNZVLdX2",
            "216":"1qihsKY16F5wdC5Oh4MUcDbg52xrfspJN",
            "217":"1-Bb96SXJEhrdb11vKT_jOIAH4e_FuQSp",
            "218":"1-V2GLCBY9D9kNaD245aHWxxGjo3wepg3",
            "219":"1-hcRT2_zr0An_aUJks1Qj7IN8dEE0GNL",
            "220":"107XjKOKc3Cb1qUza2akS6VOb59E19xPj",
            "221":"11u9y46ZKIHmNKLy_Lj_4DIUFuo3_24WM",
            "222":"12XDap3gloX8tVlJZBcWXfaaeJPzii8iE",
            "223":"13MvXiD2_53k85yTgkgfdSqdk6Lfl_o4s",
            "224":"14gYmBWkkBmfz3quoe7uTEuKdfZYRrXMe",
            "225":"1FdEXl2X5eDvLa0DtwFuAPlUxk_az7NQJ",
            "226":"1Ia7Y6F9u46FlXrhQjhvsU0scLn_h9KN5",
            "227":"1IkRKaAJ9NmlNosrOz8JlmYyvNs7R3_PM",
            "228":"1KaMYCIqWU5AY3d_dg3l16McZ6KLG1Zb8",
            "229":"1KmviVZWprzwz31rNRggBLtgXrK8jbArt",
            "230":"1MtJnmNQtouwoddl-9A3Ilw8ZU-CBxpab",
            "231":"1NkiHW9qgv3mqZq-pmhwUCKwlhUEmpl7q",
            "232":"1O-9lS4kjyUu3OV9C87dKb2iyZK3epRpm",
            "233":"1RC7V9saf7RX6V0L--SIPOATYYalMuB_t",
            "234":"1SCe5ViqzcwztAw0GhzqhMe52sAGAEXx4",
            "235":"1SE2PAwRIQmOqtjik_EJ9vbI_ohVKEBxN",
            "236":"1SPxhkXu7aC7Fy9WXhzAqrw0ffLuQTP9m",
            "237":"1Vhi3hO_Kyy4Hyd6R_WXO_mMSC-rzrdsA",
            "238":"1VqaSHQOe9kXrVDeFr3A5wcj5vzAXyrfA",
            "239":"1ZZBgMf0aaA_-4J4jDyxHJlBkSZdUhrOI",
            "240":"1aHGU_4x9CSf_7wZdCebUQjm_tz25yDse",
            "241":"1dprZPqG84nkGkmmhiXjihQS2gZgzULSq",
            "242":"1eWoH-0aQqmW_k3FChZcJfJvUCnNBlqBl",
            "243":"1fkloOOrDYiCSUpK0s_Mail2AxVScEvfH",
            "244":"1fnfPHwNafVKVNFUjJZ17tC2zw_l5e1Xe",
            "245":"1kr1wMhQcjsBuK1lKI7fm7SP2lqk8Edvx",
            "246":"1mD4cr9D29Qr4AriV2VgIDv7jcXXxLG3W",
            "247":"1qlGHIQybr2jNhqE75fmw6RO8xet7bPtw",
            "248":"1rk5ZJc_7yiTbjaJDlWrSp65NvGBrxq-D",
            "249":"1wEyfmEwGhexy3u8MYpEZE6vEMk1pmAdp",
            "250":"1wf9ididNdjoUIfLnq4XYSxJTWJ8v6xps",
            "251":"1ni5aBtPKlXW41pLCnZI-4jSO204R79uS",
            "252":"14ueqL545TI7fVLpOhsN_yiqakcldWDcd",
            "253":"1G7x9O6cgrSOm4wg431LGQ-xYdzzFXyXx",
            "254":"1ViamHKYiZDp7SS3mNGxr-awukzN7EyVQ",
            "255":"1ewt1aaYTuNLgQ3XSVntlOJ7jvJqlLta5",
            "256":"1ryjjd6NrzHA4zeWS6WyihUiC4tFSnsJo",
            "257":"1vdhXch0XGyjtJV1CxQtEwjK7JfSi0h8R",
            "258":"1z7KsnUszEVo-VT1Pza16bQRFVGgDVfVI",
        },
        
    };
	
 const photostockData = [
  { id: 'DEB2', label: 'DEB2' },
  { id: 'BELAWAN', label: 'BELAWAN' },
  { id: 'SICANANG', label: 'SICANANG' },
  { id: 'PAYA_PASIR', label: 'PAYA PASIR' },
  { id: 'PASAR_IX', label: 'PASAR IX' },
  { id: 'PANARAN', label: 'PANARAN' },
  { id: 'DUMAI', label: 'DUMAI' },
  { id: 'PERAWANG', label: 'PERAWANG' },
  { id: 'UKUI', label: 'UKUI' },
  { id: 'LIRIK', label: 'LIRIK' },
  { id: 'DEMANG_LEBAR_DAUN', label: 'DEMANG LEBAR DAUN' },
  { id: 'SUTAMI', label: 'SUTAMI' },
  { id: 'SEKAMPUNG_UDIK', label: 'SEKAMPUNG UDIK' },
];


   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock background new2.jpeg`}
						
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_sor1;
