import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Psimodeller_pgn = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var Psimodellerlist = {
        
        Segmentpipetagging:{
            "1":"1PNRnIlXPp0Z1Si_--y3bjWcLG4uAicaK",
        },
        
    };
	
 const Psimodeller = [
  { id: 'Segmentpipetagging', label: 'Segment Pipe Tagging' },
];

const [openSections, setOpenSections] = useState({});
const toggleCollapse = (section) => {
		setOpenSections((prevState) => ({
		  ...prevState,
		  [section]: !prevState[section],
		}));
	};

   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPsimodeller, setPrmPsimodeller] = useState('');
  const maxKey = Psimodellerlist[prmPsimodeller] ? Math.max(...Object.keys(Psimodellerlist[prmPsimodeller]).map(Number)) : 1;
  
  const [selectedTablesPsimodeller, setSelectedTablesPsimodeller] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};
	
	const styles = {
		
		
		
	};
	
	const handleClick = (prm_Psimodeller) => {
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(Psimodellerlist[prm_Psimodeller]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${Psimodellerlist[prm_Psimodeller][1]}/preview`;
	setPrmPsimodeller(prm_Psimodeller);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_Psimodeller}`).style.display = "none";
    document.getElementById(`ttp_${prm_Psimodeller}`).style.display = "block";
    
    
  };

  const handlealert = () => {
    alert("Coming Soon");
    
  };
  
  const handleClose = (prm_Psimodeller) => {
    document.getElementById(`bk_${prm_Psimodeller}`).style.display = "block";
    document.getElementById(`ttp_${prm_Psimodeller}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPsimodeller, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPsimodeller, idNav);
};


  const updateIframeSrc = (PrmPsimodeller, idNav) => {
  console.log('PrmPsimodeller:', PrmPsimodeller);
  console.log('idNav:', idNav);
  if (!Psimodellerlist[PrmPsimodeller] || !Psimodellerlist[prmPsimodeller][idNav]) {
    console.log('Psimodellerlist data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${Psimodellerlist[prmPsimodeller][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPsimodellerOptions, setTablesPsimodellerOptions] = useState([
    { value: '', label: 'ModellingStock' },
    
	{ value: 'psimodeller_pgn', label: '1. Perusahaan Gas Negara' },
	{ value: 'psimodeller_pertagas', label: '2. Pertamina Gas' },
	{ value: 'psimodeller_nr', label: '3. Nusantara Regas' },
	{ value: 'psimodeller_pli', label: '4. PLI' },
	{ value: 'psimodeller_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'psimodeller_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'psimodeller_pertasamtan', label: '7. Perta Samtan Gas' },
	{ value: 'psimodeller_lng', label: '8. PGN LNG Indonesia' },
	{ value: 'psimodeller_widar', label: '9. Widar Mandripa Nusantara' },
	{ value: 'psimodeller_saka', label: '10. Saka Energi Indonesia' },
	{ value: 'psimodeller_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'Psimodeller_pertagasniaga', label: '12. Pertagas Niaga' },
	{ value: 'psimodeller_pertaarungas', label: '13. Perta Arun Gas' },
	{ value: 'psimodeller_pertadayagas', label: '14. Perta Daya Gas' },
	{ value: 'kosong', label: '15. PGN-SSWJ' },
	{ value: 'kosong', label: '16. PGN-SOR1' },
	{ value: 'kosong', label: '17. PGN-SOR2' },
	{ value: 'kosong', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPsimodeller) {
		
		if(selectedTablesPsimodeller.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesPsimodeller(null);
		}else{
			navigate("/"+selectedTablesPsimodeller.value);
		}
	  
    }
  }, [selectedTablesPsimodeller, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tnts" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPsimodellerOptions}
			  onChange={setSelectedTablesPsimodeller}
			  placeholder="ModellingStock"
			  value={selectedTablesPsimodeller}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							<li>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  style={{ fontSize: '13px', color: 'black' }}
										  onClick={() => handlealert()}
										>
										  PNID (Piping and instrument diagram)
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							</li>
							
							<li>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  style={{ fontSize: '13px', color: 'black' }}
										  onClick={() => handlealert()}
										>
										  PFD (Process Flow Diagram)
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							</li>
							
							<li>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  style={{ fontSize: '13px', color: 'black' }}
										  onClick={() => handlealert()}
										>
										  Master Equipment List
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							</li>
							
							<li>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  style={{ fontSize: '13px', color: 'black' }}
										  onClick={() => handlealert()}
										>
										  Segment Pipe Tagging
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							</li>
							
							<li>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  style={{ fontSize: '13px', color: 'black' }}
										  onClick={() => handlealert()}
										>
										  Virtual Tagging
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							</li>
							
							<li>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  style={{ fontSize: '13px', color: 'black' }}
										  onClick={() => toggleCollapse('tgdmodeller')}
										>
										  3D Modeller
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							</li>
							
							{openSections['tgdmodeller'] && (
							
							<div>
								<li style={{marginLeft: '25px'}}>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn"></span>
										  </td>
										  <td>
											<a
											  style={{ fontSize: '13px', color: 'black' }}
											  onClick={() => handlealert()}
											>
												Piping Proses
											</a>
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
								
								<li style={{marginLeft: '25px'}}>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn"></span>
										  </td>
										  <td>
											<a
											  style={{ fontSize: '13px', color: 'black' }}
											  onClick={() => handlealert()}
											>
												Instrument
											</a>
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
								
								<li style={{marginLeft: '25px'}}>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn"></span>
										  </td>
										  <td>
											<a
											  style={{ fontSize: '13px', color: 'black' }}
											  onClick={() => handlealert()}
											>
												Fire and Gas System
											</a>
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
								
								<li style={{marginLeft: '25px'}}>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn"></span>
										  </td>
										  <td>
											<a
											  style={{ fontSize: '13px', color: 'black' }}
											  onClick={() => handlealert()}
											>
												Electrical
											</a>
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
								
								<li style={{marginLeft: '25px'}}>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn"></span>
										  </td>
										  <td>
											<a
											  style={{ fontSize: '13px', color: 'black' }}
											  onClick={() => handlealert()}
											>
												Building
											</a>
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
								
								<li style={{marginLeft: '25px'}}>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn"></span>
										  </td>
										  <td>
											<a
											  style={{ fontSize: '13px', color: 'black' }}
											  onClick={() => handlealert()}
											>
												Fire Hydrant
											</a>
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
								
								</div>
							
							)}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/psimodeller2.jpeg`}
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Psimodeller_pgn;
